import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardImg,
} from "reactstrap"

import ButtonToggle from "reactstrap"

import placeholderImg from "../../assets/images/contentTypeExcluded-PlaceholderImg.png"

//components
import UiModal from "./DeleteModal"

const AdminSettingsDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText>
              <h5>Content Type Excluded</h5>
            </CardText>
            <Row style={styles.Container.Row.AddZoneBtnRow}>
              <Link to={"/admin/settings/contentTypeExcluded/addNew"} style={{ textAlign: "end" }}>
                <Button color="warning" className="col-1">
                  <i className="fa fa-plus"> Add Content</i>
                </Button>
              </Link>
            </Row>

            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Mall ID</th>
                    <th>Content Type Excluded</th>
                    <th>Images</th>
                    <th>Created On</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">LM31</th>
                    <td>
                      Alcohol, Cigerrete, Nudity, Politics, Religious...
                    </td>
                    <td><CardImg src={placeholderImg} height={50} width={50}   /></td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LM32</th>
                    <td>
                      Alcohol, Cigerrete, Nudity, Politics, Religious...
                    </td>
                    <td><CardImg src={placeholderImg} height={50} width={50}   /></td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LM33</th>
                    <td>
                      Alcohol, Cigerrete, Nudity, Politics, Religious...
                    </td>
                    <td><CardImg src={placeholderImg} height={50} width={50}   /></td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/settings/contentTypeExcluded/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminSettingsDashboard
