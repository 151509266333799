import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

const AdOrdersDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <Row>
              <Col>
                <Label>From</Label>
                <Input type="date" placeholder="From"></Input>
              </Col>
              <Col>
                <Label>To</Label>
                <Input type="date" placeholder="To"></Input>
              </Col>
              <Col>
                <Label>Content Type</Label>
                <Input type="select" placeholder="" className="form-select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Label>Status</Label>
                <Input type="select" placeholder="All" className="form-select">
                  <option>All</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
            {/* <Row>
              <MultiLevelProgressBar />
            </Row> */}
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Booking ID</th>
                    <th>Building Name</th>
                    <th>Slots (Nos)</th>
                    <th>Content Type</th>
                    <th>Date Of Payment</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 Mar 2022</td>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        B 5201 CAM 0522
                      </Link>
                    </th>
                    <td>Dalma Mall</td>
                    <td>3</td>
                    <td>Food & Beverages</td>
                    <td>20 Mar 2022</td>
                    <td>11,976</td>
                    <td style={{ color: "blue" }}>Payment Completed</td>
                  </tr>
                  <tr>
                    <td>10 Mar 2022</td>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        B 5201 CAM 0522
                      </Link>
                    </th>
                    <td>Marina Mall</td>
                    <td>10</td>
                    <td>Clothing</td>
                    <td>--</td>
                    <td>11,976</td>
                    <td style={{ color: "orange" }}>Payment Completed</td>
                  </tr>
                  <tr>
                    <td>10 Mar 2022</td>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        B 5201 CAM 0522
                      </Link>
                    </th>
                    <td>Lulu Mall</td>
                    <td>3</td>
                    <td>Food & Beverages</td>
                    <td>20 Mar 2022</td>
                    <td>11,976</td>
                    <td style={{ color: "blue" }}>Payment Completed</td>
                  </tr>
                  <tr>
                    <td>10 Mar 2022</td>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        B 5201 CAM 0522
                      </Link>
                    </th>
                    <td>Dalma Mall</td>
                    <td>3</td>
                    <td>Food & Beverages</td>
                    <td>20 Mar 2022</td>
                    <td>11,976</td>
                    <td style={{ color: "blue" }}>Payment Completed</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
        <Row>
        <Table striped>
                <thead>
                  <tr>
                    <th>No Of Properties</th>
                    <th>Total Slots</th>
                    <th>Total Spending</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>7</td>
                    <td>2,566</td>
                    <td>11,976,566</td>
                  </tr>
                </tbody>
              </Table>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default AdOrdersDashboard
