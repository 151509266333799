import React from "react"
import {
  TabContent,
  TabPane,
  Card,
  Button,
  CardText,
  Row,
  Col,
} from "reactstrap"

import EventsCardIndoor from "./Cards/EventsCardIndoor"
import EventsCardOutdoor from "./Cards/EventsCardOutdoor"
import { Link } from "react-router-dom"

export default class EventsProperties extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <div className="page-content ps-5 pe-5" fluid>
        <Row className="mt-3 mb-3">
          <Card className="shadow-sm">
            <CardText className="fs-2 ms-5">Events</CardText>
          </Card>
          <Row className="mt-3 mb-3">
            <Col className="text-end">
              <Link to="/events/eventAddForm" className="waves-effect">
                <Button
                  style={{
                    backgroundColor: "#f97316",
                    color: "white",
                    width: "170px",
                    borderRadius: 8,
                    paddingRight: "26px",
                    height: "45px",
                  }}
                >
                  Add
                </Button>
              </Link>
            </Col>
          </Row>
        </Row>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <EventsCardIndoor />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <EventsCardOutdoor />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}
