import React, { useState } from "react"
import { Link } from "react-router-dom"
import { axiosApi } from "helpers/api_helper"
import {
  Card,
  Row,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Button,
} from "reactstrap"

const BookingDetailsPaymentPending = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)
  }
  const queryString = window.location.search
  const [id, setId] = useState(queryString.slice(4))
  const [paymentRefNo, setPaymentRefNo] = useState("")
  const [paymentBank, setPaymentBank] = useState("")
  const [paymentTransactionDate, setPaymentTransactionDate] = useState("")
  const [paymentAmount, setPaymentAmount] = useState("")
  const [fileInput, setFileInput] = useState(null)

  const handleAccept = () => {
    const formData = new FormData()
    if (
      paymentRefNo &&
      paymentBank &&
      paymentTransactionDate &&
      paymentAmount &&
      fileInput.files[0] != null
    ) {
      formData.append("invoice", fileInput.files[0])
      formData.append("paymentRefNo", paymentRefNo)
      formData.append("paymentBank", paymentBank)
      formData.append("paymentTransactionDate", paymentTransactionDate)
      formData.append("paymentAmount", paymentAmount)
      formData.append("id", id)

      axiosApi
        .post("/event-organizer/update-payment-info", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => console.log("acceptRes", res))
      window.location.href = "/events/bookings/"
    } else {
      alert("Please fill the form before submit")
    }
  }

  return (
    <React.Fragment>
      <Container s className="page-content" fluid>
        <Card className="shadow-sm mt-3">
          <CardText className="fs-5 ms-5">Update Payment Info</CardText>
        </Card>
        <Row>
          <Col className="col-6">
            <Label>Reference Number</Label>
            <Input
              type="text"
              name="paymentRefNo"
              value={paymentRefNo}
              onChange={e => setPaymentRefNo(e.target.value)}
            />
            <Label>Bank</Label>
            <Input
              type="text"
              name="paymentBank"
              value={paymentBank}
              onChange={e => setPaymentBank(e.target.value)}
            />
            <Label>Transaction Date</Label>
            <Input
              type="date"
              name="paymentTransactionDate"
              value={paymentTransactionDate}
              onChange={e => setPaymentTransactionDate(e.target.value)}
              required
            />
            <Label>Amount</Label>
            <Input
              type="number"
              name="paymentAmount"
              value={paymentAmount}
              onChange={e => setPaymentAmount(e.target.value)}
              placeholder="AED"
              required
            />
            <Label className="mt-3">Invoice</Label>
            <Input
              type="file"
              name="invoice"
              onChange={e => setFileInput(e.target)}
              required
            />
          </Col>
          <Row className="ms-1 mt-3 d-inline">
            <Button
              color="primary"
              style={{ width: "100px" }}
              onClick={handleAccept}
            >
              Confirm
            </Button>
            <Link to="../bookings">
              <Button color="danger" style={{ width: "100px" }}>
                Back
              </Button>
            </Link>
          </Row>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default BookingDetailsPaymentPending
