import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

const ImageRenderer = (props) => {
  return (
    <Stack direction="horizontal" className="h-100">
      <Image thumbnail src={props.getValue()} className="h-100 w-auto" />
    </Stack>
  );
};

export default ImageRenderer;