// Authmiddleware.jsx

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  allowedRoles,
  userType,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const authUser = sessionStorage.getItem("authUser");
      const userRoles = authUser ? JSON.parse(authUser).role : [];

      if (isAuthProtected && !authUser) {
        return (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        );
      }

      if (isAuthProtected && allowedRoles && !allowedRoles.some(role => userRoles.includes(role))) {
        // If user does not have any of the allowed roles, redirect to an "access denied" route
        return <Redirect to="/access-denied" />;
      }

      if (userType && authUser && JSON.parse(authUser).role !== userType) {
        // If user type does not match, redirect to a different route or show an access denied page
        return <Redirect to="/access-denied" />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  userType: PropTypes.string,
};

export default Authmiddleware;
