import React from "react"

import { useState } from "react"
import { CardText, CardTitle, CardImg } from "reactstrap"

import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ImageOverlay,
} from "react-leaflet"
import { Link } from "react-router-dom"

import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "bootstrap/dist/css/bootstrap.min.css"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

//mallJson
import position from "../Common/Data/mallData.json"
import { leafletData } from "../../propertyOwner/Common/Data/LeafletData"

export const AdLeaflet = () => {
  const [levelId, setLevelId] = useState("0")
  const [imagePaths, setImagePaths] = useState(
    leafletData[levelId].floorPlanImg
  )
  return (
    <MapContainer
      style={{ height: "45vh", width: "100%", zIndex: "1" }}
      center={[0, 50]}
      zoom={1}
      scrollWheelZoom={true}
      minZoom={1}
      maxZoom={4}
      maxBoundsViscosity={0}
      dragging={0}
      bounceAtZoomLimits={true}
      boundsOptions={L.FitBoundsOptions}
    >
      <ImageOverlay
        key={imagePaths}
        url={imagePaths}
        bounds={[
          [-100, -400],
          [200.5, 500],
        ]}
      />
      {position.map(data => (
        <Link
          to="/agency/bookNow/propertyDetails"
          onClick={() => {
            localStorage.mallId = data.pid - 1
          }}
        >
          <Marker position={[data.lat, data.lon]} key={data.lat}>
            <Popup>
              <CardTitle>{data.buildingNickname}</CardTitle>
              <CardImg src={data.mediaUploadUrl} />
              <CardText>Slots: {data.slotsAvailable}</CardText>
            </Popup>
          </Marker>
        </Link>
      ))}
    </MapContainer>
  )
}
