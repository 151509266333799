import React, { useEffect, useState } from "react"
import { Button, CardImg, CardTitle, Col, Row, Table } from "reactstrap"
import logo from "../../assets/images/Statements/ListingRecived/image/favicon.png"
import banner from "../../assets/images/Statements/ListingRecived/image/banner-sample4.jpg"
import logo2 from "../../assets/images/Statements/ListingRecived/image/logo2.png"
import { axiosApi } from "helpers/api_helper"
import MultiLevelProgressBar from "./ProgressBar"
const steps = [
  "Portfolio Shared",
  "Slot Selected",
  "Request Accepted",
  "Payment",
  "Booking Confirmed",
  "Installation",
]

function ListingExportStatement() {
  const [formData, setFormData] = useState()
  const shareEventRes = JSON.parse(localStorage.getItem("shareEventRes"))
  const currentPageBase64 = btoa(window.location.href)

  // Get the token from the navToUrl
  const [navToUrl, setNavToUrl] = useState(shareEventRes.data.data.navTo)
  const token = navToUrl.split("token=")[1]

  useEffect(() => {
    setFormData({
      id: shareEventRes.data.data.id,
      htmlBody: currentPageBase64,
    })
  }, [])

  const handleSubmit = () => {
    axiosApi
      .post("/event-organizer/send-quote-mail", formData)
      .then(res => {
        // localStorage.setItem("shareEventRes", JSON.stringify(res))
        window.location.href = `/events/bookings/enquirySent?id:${token}`
        // localStorage.removeItem("shareEventRes");         ///testPending
      })
      .catch(error => {
        // handle error
      })
  }

  return (
    <div className="page-content">
      <Row style={{ height: "30vh" }}>
        <CardImg
          src={shareEventRes.data.data.bannerURL || banner}
          style={{ height: "inherit" }}
        />
      </Row>
      <Row id="header" className="m-2">
        <Row>
          <h4>Greetings From Mcgettigans,</h4>
        </Row>
        <Row>
          <h5>
            We are excited to offer you our portfolio of advertising slots for
            the Christmas Fans Fest. We look forward to working together
          </h5>
        </Row>
        <Row
          style={{
            backgroundColor: "#045B9E",
            color: "white",
            height: "600px",
          }}
        >
          <Row className="m-4 p-4">
            <Col>
              {" "}
              <CardImg
                src={shareEventRes.data.data.logoURL || logo2}
                style={{ width: 50, height: 50 }}
              />
            </Col>
            <Col>
              <CardTitle></CardTitle>
              <h6></h6>

              <CardImg
                style={{ width: 50, height: 50, float: "right" }}
                src={logo}
              />
            </Col>
          </Row>
          <Row>
            <h5>SLOT PORTFOLIO PRESENTED TO</h5>
            <Table id="table1" className="border">
              <tr style={{ textAlignLast: "start", color: "white" }}>
                <td className="fw-bold" style={{ width: "150px" }}>
                  <div>Name</div>
                  <div>Company</div>
                  <div>From</div>
                </td>
                <td style={{ borderRight: "1px solid white" }}>
                  <div>{shareEventRes.data.data.contactName}</div>
                  <div>{shareEventRes.data.data.companyName}</div>
                  <div>{shareEventRes.data.data.from}</div>
                </td>
                <td className="fw-bold" style={{ width: "150px" }}>
                  <div> Date</div>
                  <div>Vaild Till</div>
                  <div>Payment Term</div>
                </td>
                <td>
                  <div>{shareEventRes.data.data.date}</div>
                  <div>{shareEventRes.data.data.validTill}</div>
                  <div>Offline</div>
                </td>
              </tr>
            </Table>
          </Row>
          <Row className="mt-5 mb-5">
            <h6>PROGRESS</h6>
            <MultiLevelProgressBar />
            {/* <Box>
              <Stepper
                activeStep={1}
                alternativeLabel
                sx={{ flexWrap: "wrap" }}
              >
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box> */}
          </Row>
        </Row>
        <Row id="table2">
          <h5>
            <u>SUMMARY</u>
          </h5>

          <tr>
            <td className="p-4">
              <tr>Period</tr>
              <tr>Slots(Nos)</tr>
              <tr>Slot Type</tr>
            </td>
            <td className="m-4">
              <tr>{shareEventRes.data.data.period}</tr>
              <tr>{shareEventRes.data.data.slotsNos}</tr>
              <tr>{shareEventRes.data.data.slotTypes}</tr>
            </td>
          </tr>
          <h5>
            Please go through the following link and choose the slots you are
            interested in
          </h5>
          <a href={shareEventRes.data.data.navTo}>
            {shareEventRes.data.data.navTo}
          </a>
        </Row>
        <Row className="m-2">
          <h4>
            <u>Important information</u>
          </h4>
          <Col>
            1. Please remember to examine this statement and inform the related
            party of any discrepancy within 3 days from the statement generation
            date.
            <br />
            2. The booking confirmation will be made under the complete
            discretion of the property owner. The Property owner will have the
            right to not accept the booking request in full or partial as he/she
            wishes.
            <br />
            3. Once the booking request is accepted by the Property owner, the
            payment has to be made within 3 working days after which the booking
            will be confirmed for the concerned party. The payment has to be
            transferred to Hangitonme account via bank cheque, or online payment
            portal as deemed suitable. The amount will be reserved by Hangitonme
            till the booking start date.
            <br />
            4. In the event of non submission of 'the required documents',
            before 7 days from the start of the booking period, the Property
            owner has the discretion to terminate the booking and the refund of
            the amount paid will not be refunded.
            <br />
            5. In the event of cancellation of the booking 20 days before the
            start date of the booking period, the 50% of the booking amount will
            be reserved as a penalty
          </Col>
          <Col>
            6. Foreign Transaction Charge - This tee (Up to 2.99% ot the
            transaction amount) is charged in addition to the standard
            processing fee and is applicable to transactions performed acquired
            internationally or in any currency other than the UAE Dirham. The
            exchange rate is determined byVisa from withina range of wholesale
            market rates in e ect on the date of conversion.
            <br />
            7. The installation of the collaterals will be the responsibility of
            the booking agency and the property owner will not be responsible
            for providing the
            <br />
            معلومات مهمة هذا البيان وإبلاغ الطرف ذي الصلة بأي تناقض فيغضون 3
            أيام من تاريخ إصدار البيان .1 يرجى تذكرة العقار بالكامل. يحق لمالك 2
            سيتم إجراء تأكيد الحجز وفقا لتقدير. طلب الحجز كليا أو جزئيا كما
            يشاء. العقارة تم السداد فيغضون ك العقار ، يجب 3 بمجرد قبول طلب الـ 3
            أيام عمل وبعد ذلك سيتم تأكيد الحجز للطرف المعنى. يجب تحويل الدفعة
            إلى
          </Col>
        </Row>
        <Row>
          <p className="text-center">
            Hangitonme-PO Box 25604, Dubai, UAE.| Tel: 04-267 5244 |
            hangitonme.com| contactus@hangitonme.com
          </p>
        </Row>
        <Row>
          <CardImg src={shareEventRes.data.data.footerURL} />
        </Row>
        <Row className="justify-content-center">
          <Button
            color="primary"
            className="col-2 mt-3 mb-2"
            onClick={handleSubmit}
          >
            Sent
          </Button>
        </Row>
      </Row>
    </div>
  )
}

export default ListingExportStatement
