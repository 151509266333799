import React from "react"

import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

const BuildingReports = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Row className="mb-3">
            <CardText>
              <h4>Building Reports</h4>
            </CardText>
          </Row>
          <Card>
            <Row className="mb-3">
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>From</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>To</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Indoor/Outdoor</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Property Name</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col className="col=2" style={styles.tableBtnCol}>
                <Button color="primary">Print</Button>
              </Col>
            </Row>
            <Row>
              <Table striped style={styles.tableStyle}>
                <thead style={styles.tableStyle.thead}>
                  <tr>
                    <th>ID</th>
                    <th>Property Name</th>
                    <th>Slot Types</th>
                    <th>Avg Booking Duration</th>
                    <th>No Of Zones</th>
                    <th>No Of Slots</th>
                    <th>No Of Smart Slots</th>
                    <th>Storage Space</th>
                    <th>Ceiling</th>
                    <th>Wall</th>
                    <th>Floor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td># 131</td>
                    <td>Dalma Mall</td>
                    <td>Ceiling, Wall, Floor</td>
                    <td>28 Days</td>
                    <td>34</td>
                    <td>1256</td>
                    <td>34</td>
                    <td>50gb</td>
                    <td>450</td>
                    <td>368</td>
                    <td>256</td>
                  </tr>
                  <tr>
                    <td># 131</td>
                    <td>Dalma Mall</td>
                    <td>Ceiling, Wall, Floor</td>
                    <td>28 Days</td>
                    <td>34</td>
                    <td>1256</td>
                    <td>34</td>
                    <td>50gb</td>
                    <td>450</td>
                    <td>368</td>
                    <td>256</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  tableStyle: {
    backgroundColor: "lightblue",
    thead: {
      backgroundColor: "lightgray",
    },
  },
  tableBtnCol: {
    textAlign: "right",
  },
}

export default BuildingReports
