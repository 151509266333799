import { map } from "lodash"
import React from "react"
import { Link } from "react-router-dom"
import {
    Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

//img
import installImg1 from "../../assets/images/adAgencyInstallReqImg1.png"
import installImg2 from "../../assets/images/adAgencyInstallReqImg2.png"
import installImg3 from "../../assets/images/adAgencyInstallReqImg3.png"
import installImgWorker1 from "../../assets/images/adAgencyInstallReqImgWorker1.png"

export const AdNotificationsInstallationReqestDetail = () => {
  const cardData = [
    {
      cardImg: installImg1,
      cardTitle: "CAM12",
      collateralType: "Ceiling",
      zoneName: "Central Lobby",
      contentType: "Food & Beverages",
    },
    {
      cardImg: installImg2,
      cardTitle: "CAM14",
      collateralType: "Wall",
      zoneName: "Central Lobby",
      contentType: "Medical",
    },
    {
      cardImg: installImg3,
      cardTitle: "CAM15",
      collateralType: "Floor",
      zoneName: "Dam Area",
      contentType: "Food & Beverages",
    },
  ]

  const workerData = [
    {
      name: "William",
      role: "Technician",
      phone: "0534672909",
      email: "williamwill@gmail.com",
      workerImg: installImgWorker1,
    },
  ]
  return (
    <Container className="page-content" fluid>
      <Row>
        <Card>
          <CardTitle className="text-center">Booked Details</CardTitle>
          <Row>
            <Col>
              {workerData.map(data => (
                <Card>
                  <CardImg src={data.workerImg} className="w-25"/>
                  <CardBody>
                    {data.name}<br/>
                    {data.role}<br/>
                    {data.phone}<br/>
                    {data.email}
                  </CardBody>
                </Card>
              ))}
            </Col>
            <Col className="col-8">
              <Card className="bg-light p-3">
                <Row>
                  <CardText className="p-2"><span className="bg-primary text-white p-1 rounded">B 5201 CAM 0522</span>   <b>Dalma Mall   Level 01 Zone 01</b></CardText>
                </Row>
                <Row>
                  {cardData.map(data => (
                    <Col>
                    <Card className="bg-light">
                      <CardImg src={data.cardImg} className="w-75" />
                      <Row className="w-100">
                        <Col>
                          <CardText>{data.cardTitle}<br/>{data.collateralType}<br/>{data.zoneName}</CardText>
                        </Col>
                        <Col>
                            <CardText>{data.contentType}</CardText>
                        </Col>
                      </Row>
                    </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="d-inline">
            <Link to="./"><Button color="warning">Cancel</Button></Link>
            <Link to="/agency/bookings"><Button color="primary">Complete</Button></Link>
          </Row>
        </Card>
      </Row>
    </Container>
  )
}
