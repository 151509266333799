import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
} from "reactstrap"

import { cardDataOutdoor } from "../../Common/Data/EventsOutdoorData"
// import images
import img5 from "../../../assets/images/small/img-5.jpg"

const EventsCardOutdoor = props => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            {cardDataOutdoor.map(cards => (
              <Col xs={4} key={cards.id}>
                <Card>
                  <CardImg top className="img-fluid" src={img5} alt="Veltrix" />
                  <CardBody>
                    <CardTitle className="h4">{cards.title}</CardTitle>
                    <p className="card-text">{cards.description}</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Last updated 3 mins ago
                      </small>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EventsCardOutdoor
