import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardImg,
  Container,
  CardText,
  Table,
  Button,
  Input,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
//components
import AddZoneImg from "../../assets/images/AddZoneImg.png"
import { axiosApi } from "helpers/api_helper"
import img from "../../assets/images/placeholderImg.webp"
import { Accordion } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"

const EventsBookingDetailsListingReceived = props => {
  const queryString = window.location.search
  const [tokenId, setTokenId] = useState(queryString.slice(4))
  const [apiRes, setApiRes] = useState()
  const [apiRes1, setApiRes1] = useState()
  const [uniqueAgencies, setUniqueAgencies] = useState([])
  const [uniqueSlots, setUniqueSlots] = useState([])

  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes1(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    axiosApi
      .get(`/event-organizer/listing-to-statement?id=${tokenId}`)
      .then(res => {
        setApiRes(res.data.data)
        const uniqueSlots = res.data.data
          .map(row => row.slotName)
          .filter((name, index, array) => array.indexOf(name) === index)
          .reduce((acc, name) => {
            acc.push({ value: name, label: name })
            return acc
          }, [])
        setUniqueSlots(uniqueSlots)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    if (apiRes) {
      const uniqueAgencies = apiRes
        .map(row => {
          const selectedType =
            apiRes1 && apiRes1.find(type => type.id === row.type)
          const collateralTypeName = selectedType ? selectedType.Name : "-"
          return {
            value: row.type,
            label: collateralTypeName,
          }
        })
        .filter(
          (agency, index, array) =>
            array.findIndex(a => a.value === agency.value) === index
        )
      setUniqueAgencies(uniqueAgencies)
    }
  }, [apiRes])

  const i = queryString.slice(1) - 1
  // const [addZone, setAddZone] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [selectedSlots, setSelectedSlots] = useState([])
  const [selectedSlotsGen, setSelectedSlotsGen] = useState([])

  const handleClick = event => {
    const { checked, value } = event.target
    const [eventId, slotId, totalAmount] = value.split(",")
    if (checked) {
      setSelectedSlots(prevSelected => [
        ...prevSelected,
        { eventId, slotId, totalAmount },
      ])
      setSelectedSlotsGen(prevSelected => [
        ...prevSelected,
        { eventId, slotId },
      ])
    } else {
      setSelectedSlots(prevSelected =>
        prevSelected.filter(
          selected =>
            selected.eventId !== eventId ||
            selected.slotId !== slotId ||
            selected.totalAmount !== totalAmount
        )
      )
      setSelectedSlotsGen(prevSelected =>
        prevSelected.filter(
          selected => selected.eventId !== eventId || selected.slotId !== slotId
        )
      )
    }
  }

  const arr = selectedSlots.map(item => item.totalAmount)
  const totalSelectedAmount = arr.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue),
    0
  )

  const [genStateData, setGenStateData] = useState()
  const [discount, setDiscount] = useState("0")
  const [isValid, setIsValid] = useState(false)
  const [selectedSlotName, setSelectedSlotName] = useState("")
  const [selectedCollateralType, setSelectedCollateralType] = useState("")
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("Please select slot")

  const handleSlotNameChange = e => {
    setSelectedSlotName(e.target.value)
  }

  const handleCollateralTypeChange = e => {
    setSelectedCollateralType(e.target.value)
  }

  const discountamt = (totalSelectedAmount * discount) / 100
  const balanceAmount = totalSelectedAmount - discountamt

  const fileInput = document.getElementById("statement")
  const handleDiscountChange = e => {
    const inputVal = e.target.value
    if (/^(90|[1-8][0-9]?|0)$/.test(inputVal)) {
      // regex validation
      setDiscount(inputVal)
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  useEffect(() => {
    setGenStateData({
      id: tokenId,
      slots: selectedSlotsGen,
      discount: discount,
    })
  }, [selectedSlotsGen])

  const handleAccept = () => {
    if (selectedSlots.length >= 1 && fileInput) {
      const formData = new FormData()
      formData.append(
        "data",
        JSON.stringify({ id: tokenId, discount: discount })
      )
      formData.append("statement", fileInput.files[0])
      axiosApi
        .post("/event-organizer/approve-listing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          window.location.href = "/events/bookings/"
        })
        .catch(error => {
          // Handle error response
          console.error("Error approving listing:", error)
          // Show error message to the user
          // Replace the following line with your own code to display the error message
          setbasic(true)
          setSweetTitle("Upload Invoice File Please")
        })
    } else {
      setbasic(true)
      setSweetTitle("Please select slot & Upload Invoice File ")
    }
  }
  const handleReject = () => {
    if (selectedSlots.length >= 1) {
      axiosApi
        .post("/event-organizer/reject-listing", {
          id: tokenId,
        })
        .then(res => (window.location.href = "/events/bookings"))
    } else {
      setbasic(true)
      setSweetTitle("Please select slot")
    }
  }
  const genStatement = () => {
    if (selectedSlots.length >= 1) {
      axiosApi
        .post("/event-organizer/generate-event-statement", genStateData)
        .then(res => {
          // Handle successful response
          const responseData = res.data // Assuming the response contains the data you want to pass
          const redirectUrl =
            "/events/bookings/listingReceived/generateStatement"
          const state = { data: responseData } // Store the data in the state object
          window.location.href = `${redirectUrl}?data=${encodeURIComponent(
            JSON.stringify(state)
          )}`
        })
        .catch(error => {
          // Handle error response
          console.error("Error generating event statement:", error)
          // Show error message to the user
          // Replace the following line with your own code to display the error message
          setbasic(true)
          setSweetTitle("Failed to generate event statement. Please try again.")
        })
    } else {
      setbasic(true)
      setSweetTitle("Please select slot")
    }
  }

  return (
    <Container className="page-content" fluid>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      <Card className="shadow-sm mb-3 mt-3">
        <CardText className="fs-3 ms-5">Listing Received</CardText>
      </Card>

      <Card>
        <CardBody>
          <Row></Row>
        </CardBody>
        <Accordion defaultActiveKey="0" className="shadow-sm">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters</Accordion.Header>
            <Accordion.Body>
              <CardBody>
                <Row>
                  <Col>
                    <Label>Slot Name</Label>
                    <Input
                      type="select"
                      placeholder="Cam Agency"
                      className="form-select"
                      value={selectedSlotName}
                      onChange={handleSlotNameChange}
                    >
                      <option value="">All</option>
                      {uniqueSlots.map(agency => (
                        <option key={agency.value} value={agency.value}>
                          {agency.label}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Label>Collateral Type</Label>
                    <Input
                      type="select"
                      placeholder="All"
                      className="form-select"
                      value={selectedCollateralType}
                      onChange={handleCollateralTypeChange}
                    >
                      <option value="">All</option>
                      {uniqueAgencies.map(agency => (
                        <option key={agency.value} value={agency.value}>
                          {agency.label}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </CardBody>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <CardBody>
          <div className="table-responsive">
            <Table className="table table-striped mb-0">
              <thead align="center" className="table-light">
                <tr className="align-baseline">
                  <th>#</th>
                  <th>Default Image</th>
                  <th>Current Image</th>
                  <th>Slot Name</th>
                  <th>Collateral Type</th>
                  <th>
                    Capacity
                    <br />
                    kg
                  </th>
                  <th>
                    Slot Details
                    <br />
                    cm
                  </th>
                  <th>Pricing Category</th>
                  <th>Amount</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody align="center">
                {apiRes &&
                  apiRes
                    .filter(
                      row =>
                        row.slotName.includes(selectedSlotName) &&
                        row.type.includes(selectedCollateralType)
                    )
                    .map(row => {
                      const selectedType = apiRes1.find(
                        type => type.id === row.type
                      )
                      const collateralTypeName = selectedType
                        ? selectedType.Name
                        : "-"

                      return (
                        <tr key={row.id}>
                          <th scope="row">
                            <Link to="" style={styles.tagBtnStyle}>
                              {row.id}
                            </Link>
                          </th>
                          <td>
                            <CardImg
                              style={{ width: "100px", height: "100px" }}
                              src={row.defaultImage ? row.defaultImage : img}
                            />
                          </td>
                          <td>
                            <CardImg
                              style={{ width: "100px", height: "100px" }}
                              src={row.currentImage ? row.currentImage : img}
                            />
                          </td>
                          <td>{row.slotName}</td>
                          <td>{collateralTypeName}</td>
                          <td>{row.capacity}</td>
                          <td>{row.slotDetails}</td>
                          <td>{row.priceCategory}</td>
                          <td>{row.totalAmount}</td>
                          <td>
                            <Input
                              type="checkbox"
                              value={[row.eventId, row.id, row.totalAmount]}
                              onChange={handleClick}
                            />
                          </td>
                        </tr>
                      )
                    })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Row style={{ flexDirection: "row-reverse" }}>
        <Row className="mb-2 me-1 float-end flex-row-reverse">
          <Button
            style={{ flexBasis: "fit-content" }}
            className="col-3"
            color="primary"
            onClick={genStatement}
          >
            Generate Statement
          </Button>
        </Row>
        <Row className="text-end me-2 float-end flex-row-reverse">
          <Label style={{ maxWidth: "210px", font: "-webkit-control" }}>
            Attach Invoice
            <Input
              type="file"
              name="statement"
              id="statement"
              style={{ maxWidth: "450px" }}
            />
          </Label>
        </Row>
      </Row>
      <Row className="col-4">
        <Col style={{ marginLeft: "15px" }}>
          <CardText> Amount {totalSelectedAmount} (AED)</CardText>
          <CardText>
            Add Discount %
            <Input
              placeholder="0 %"
              type="text"
              value={isValid ? discount : ""}
              onChange={handleDiscountChange}
            />
          </CardText>
          <CardText>Total Amount {balanceAmount}</CardText>
        </Col>
        <Col></Col>
      </Row>
      <Row className="d flex-row-reverse">
        <Button
          className="col-2 me-2 ms-2"
          color="primary"
          onClick={handleAccept}
        >
          Accept
        </Button>
        <Button className="col-2" color="danger" onClick={handleReject}>
          Reject
        </Button>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody style={{ display: "none" }}>
              <Row>
                <Col sm={6} md={4} xl={3}>
                  <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                    style={{ minWidth: 750, marginTop: "80px" }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">SLOT LISTING</h5>
                      <button
                        type="button"
                        onClick={() => {
                          tog_center()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Slot Name</Label>
                            <Input type="text" placeholder="Slot 01" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Collateral Type</Label>
                            <Input type="select" className="form-select">
                              <option>Ceiling</option>
                              <option>Floor</option>
                              <option>Wall</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>Description</Label>
                            <Input
                              type="textarea"
                              placeholder="Beside store 101"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Default Image</Label>
                            <Input type="file" />
                          </FormGroup>
                          <FormGroup>
                            <Label>GPS Coordinates</Label>
                            <Input
                              type="text"
                              placeholder="3585567.83,11163.84,-106.13"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Documents</Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="selectMulti"
                              id="exampleSelectMulti"
                              multiple
                            >
                              <option>Police Permit</option>
                              <option>Muncipality Permit</option>
                              <option>Media Permit</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Slot Limits</Label>
                            <CardImg src={AddZoneImg} />
                          </FormGroup>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>a</Label>
                                <Input type="text" />
                              </FormGroup>
                              <FormGroup>
                                <Label>b</Label>
                                <Input type="text" />
                              </FormGroup>
                              <FormGroup>
                                <Label>Power</Label>
                                <Input type="select" className="form-select">
                                  <option>Power Available</option>
                                  <option>Power Unavailable</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>c</Label>
                                <Input type="text" />
                              </FormGroup>
                              <FormGroup>
                                <Label>d</Label>
                                <Input type="text" />
                              </FormGroup>
                              <FormGroup>
                                <Label>Weight</Label>
                                <Input type="text" placeholder="kg" />
                              </FormGroup>
                            </Col>
                            <FormGroup>
                              <Label>Price Category</Label>
                              <Input type="select" className="form-select">
                                <option disabled>Select Price Category</option>
                              </Input>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row className="text-end">
                      <Col style={styles.modalBtnStyle} className="mb-3">
                        <Button
                          className="btn btn-primary waves-effect waves-light me-2"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                          color="danger"
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light me-3"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

const cardStyle = {
  fontSize: 20,
  padding: 10,
  fontWeight: "bold",
  mainCard: {
    width: 250,
    height: 180,
  },
}

export default EventsBookingDetailsListingReceived
