import { useState } from "react";
import "./VisitedRenderer2.css"; // Import CSS file with custom styles

const VisitedRenderer = (props) => {
  const [isChecked, setIsChecked] = useState(props.data.underMaintenance);
  const [data1, setData1] = useState()
  const [data2, setData2] = useState()
  const toggleButton = () => setIsChecked(!isChecked);
  const [data3, setData3] = useState(props.dataFromChild[0])
  const [data4, setData4] = useState(props.dataFromChild[1])

  const handleOnChange = () => {
    props.onMaintenanceChange();
    setIsChecked(!isChecked)
  };
console.log("isChecked",isChecked);
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
      />
      <span className="slider"></span>
      <span className="text">{isChecked ? "YES" : "NO"}</span>
    </label>
  );
};

export default VisitedRenderer;