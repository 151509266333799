import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Container,
  Button,
  CardImg,
  CardText,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//img
import cardImg from "../../assets/images/propertyBookingContentImg.png"
import userImg from "../../assets/images/userAvatar/installationImg.png"

const UiModal = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <i
        onClick={() => {
          tog_center()
        }}
      >
        <CardImg src={userImg} style={{ width: 30 }} height={30} />
      </i>

      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                  style={{maxWidth: 600}}
                >
                  <div className="modal-header">
                    <Row>
                    <CardText><h4>Image Review</h4></CardText>
                      <Col>
                        <CardTitle>BOOKING ID</CardTitle>
                        <Link to="" style={styles.tagBtnStyle}>
                          B 5201 CAM 0522
                        </Link>
                      </Col>
                      <Col>
                        <CardTitle>AGENCY</CardTitle>
                        <CardBody>Cam Agency</CardBody>
                      </Col>
                      <Col>
                        <CardTitle>CONTENT TYPE</CardTitle>
                        <CardBody>Food & Beverages</CardBody>
                      </Col>
                      <Col>
                        <CardTitle>SLOTS (Nos)</CardTitle>
                        <CardBody>10</CardBody>
                      </Col>
                      <Col>
                        <CardTitle>ZONE</CardTitle>
                        <CardBody>Central Lobby</CardBody>
                      </Col>
                    </Row>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <span style={{ textAlign: "right" }}>
                        <Label>
                          Accept All <Input type="checkbox" />
                        </Label>
                      </span>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <span style={{ textAlign: "right" }}>
                        <Label>
                          Accept All <Input type="checkbox" />
                        </Label>
                      </span>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <span style={{ textAlign: "right" }}>
                        <Label>
                          Accept All <Input type="checkbox" />
                        </Label>
                      </span>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardImg src={cardImg} />
                          <span style={{ marginTop: -15, textAlign: "right" }}>
                            <Input type="checkbox" />
                          </span>
                          <Row>
                            <Col>
                              <CardText>Cam12</CardText>
                            </Col>
                            <Col>
                              <CardText>20cm(l) x 10cm (h)</CardText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardText>Ceiling</CardText>
                            </Col>
                            <Col>
                              <CardText>{">"}10kg</CardText>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col style={styles.modalBtnStyle}>
                      <Button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        Accept
                      </Button>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
