import React from "react"
import MetaTags from "react-meta-tags"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
} from "reactstrap"

import { cardDataOutdoor } from "../../Common/Data/PropertyOutdoorData"

// import images

import img5 from "../../../assets/images/small/img-5.jpg"

import { Link } from "react-router-dom"

const PropertyCardOutdoor = props => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true} className="mt-3">
          <Row>
            {cardDataOutdoor.map((cards) => (
              <Col xs={12} sm={6} md={4} lg={3} key={cards.id}>
                <Card style={{ marginBottom: '20px' }}>
                  <CardImg top className="img-fluid" style={{ maxWidth: '100%' }} src={img5} alt="Veltrix" />
                  <CardBody>
                    <CardTitle className="h4" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {cards.title}
                    </CardTitle>
                    <p className="card-text">{cards.description}</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Last updated 3 mins ago
                      </small>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PropertyCardOutdoor
