import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardBody,
} from "reactstrap"

import ButtonToggle from "reactstrap"

const AdminReportsDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Col>
            <Link to="/admin/reports/adminRepoertsDetails">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col>
            <Link to="">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col>
            <Link to="">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col>
            <Link to="">
              <Card style={styles.Container.cardStyle}>
                <Row>
                  <Col className="col-2">
                    <i className="fa fa-building fa-2x p-3"></i>
                  </Col>
                  <Col>
                    <CardTitle>Agency</CardTitle>
                    <CardText>Generate Reports</CardText>
                  </Col>
                  <Col>
                    <CardBody>
                      New Joining 10%
                      <br />
                      Bookings 20%
                      <br />
                      Revenue 10%
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
    cardStyle: {
      backgroundColor: "skyblue",
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminReportsDashboard
