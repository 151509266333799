import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardText,
} from "reactstrap"
import Switch from "react-switch"
import { axiosApi } from "helpers/api_helper"
import "./datatables.scss"
import { Link } from "react-router-dom"
import UiModal from "./EventsDeleteModal"
import AddNew from "./EventPricingAddNew"
import "./EventsPricingCategoryDashboard.css"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  )
}

export const EventsPricingCategoryDashboard = () => {
  const [isLoading, setLoading] = useState(true)
  const [apiRes, setApiRes] = useState()

  useEffect(() => {
    const tokenWithQuotes = sessionStorage.getItem("jwt")
    const tokenWithoutQuotes = tokenWithQuotes.replace(/"/g, "")

    axiosApi
      .get("/common/pricing", {
        headers: {
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .then(res => {
        setApiRes(res.data.data)
        setLoading(false)
      })
  }, [sessionStorage.getItem("jwt")])
  const [dataRow, setDataRow] = useState()
  const [switch1, setswitch1] = useState(true)

  const obj = JSON.parse(sessionStorage.getItem("authUser"))

  useEffect(() => {
    setDataRow(
      apiRes
        ? apiRes.map(item => ({
            id: item.id,
            categoryName: item.name,
            minRange: item.minRange,
            maxRange: item.maxRange,
            faceValue: item.faceValue,
            lastUpdate: item.updatedAt,
            slots: item.faceValue,
            lastUpdateBy: obj.userName,
            status: (
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#02a499"
                onChange={() => {
                  setswitch1(!switch1)
                }}
                checked={switch1}
              />
            ),
            Calender: (
              <Link
                to={`../events/reservations?id=${item.id} `}
                className="fa fa-calendar"
              />
            ),
            edit: (
              <UncontrolledDropdown>
                <DropdownToggle
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                >
                  <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    minWidth: "5%",
                    textAlign: "center",
                    height: "85px",
                  }}
                >
                  <DropdownItem>
                    <i className="fa fa-edit"></i>
                  </DropdownItem>
                  <br />
                  <UiModal />
                </DropdownMenu>
              </UncontrolledDropdown>
            ),
            // delete: <UiModal />,
          }))
        : "null"
    )
  }, [apiRes])

  // const DatatableTables = () => {
  const data = {
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "CATEGORY NAME",
        field: "categoryName",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "MIN RANGE",
      //   field: "minRange",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "MAX RANGE",
      //   field: "maxRange",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "FACE VALUE ",
        field: "faceValue",
        sort: "asc",
        width: 150,
        className: "text-right",
      },
      {
        label: "LAST UPDATED",
        field: "lastUpdate",
        sort: "asc",
        width: 100,
      },
      {
        label: "LASTED UPDATED BY",
        field: "lastUpdateBy",
        sort: "asc",
        width: 100,
      },
      {
        label: "SLOTS",
        field: "slots",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "STATUS",
      //   field: "status",
      //   sort: "disabled",
      //   width: 100,
      // },
      {
        label: "CALENDAR",
        field: "Calender",
        sort: "disabled",
        width: 100,
      },
      {
        label: "ACTION",
        field: "edit",
        sort: "disabled",
        width: 100,
      },
      //  {
      //   label: "",
      //   field: "delete",
      //   sort: "disabled",
      //   width: 100,
      // },
    ],
    rows: dataRow,
  }
  if (isLoading) {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }
  return (
    <div className="page-content">
      <Card className="shadow-sm mt-3">
        <CardText className="fs-4 ms-5">Pricing Table</CardText>
      </Card>
      <div>
        <AddNew />
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="shadow-sm mt-3">
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    noBottomColumns
                    data={data}
                    style={{ textAlign: "center" }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
