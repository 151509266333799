import React from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardImg,
  Label,
} from "reactstrap"

import { Link } from "react-router-dom"

//img
import imgIndoor from "../../assets/images/adAgencyWizardIndoor.png"
import imgOutdoor from "../../assets/images/adAgencyWizardOutdoor.png"
import imgDigital from "../../assets/images/adAgencyWizardDigital.png"
import imgCeiling from "../../assets/images/wizardCeiling.png"
import imgCeilingVertical from "../../assets/images/wizardCeilingVertical.png"
import imgWall from "../../assets/images/wizardWall.png"
import imgFloor from "../../assets/images/wizardFloor.png"

const AdFormWizardDimensionsCustom = () => {
  return (
    <Row>
      <Col>
        <CardTitle>Ceiling</CardTitle>
        <CardImg src={imgCeilingVertical} />
      </Col>
      <Col>
        <CardTitle>
          The collateral type ceiling contains extra parameters like
        </CardTitle>
        <Row>
          <Label>
            W<Input type="text" />
            kilogram
          </Label>
          <CardText>Weight of the modal</CardText>
        </Row>
        <Row>
          <Label>
            d<Input type="text" />
            meters
          </Label>
          <CardText>height of the ceiling to the base of the modal</CardText>
        </Row>
      </Col>
      <Col>
        <CardText>
          560
          <br />
          Slots out of
          <br />
          70,382
          <br />
          Available
        </CardText>
      </Col>
    </Row>
  )
}

export default AdFormWizardDimensionsCustom
