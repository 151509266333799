import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.loginUser,
  //       user.email,
  //       user.password
  //     )
  //     yield put(loginSuccess(response))
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtLogin, {
  //       email: user.email,
  //       password: user.password,
  //     })
  //     sessionStorage.setItem("authUser", JSON.stringify(response))
  //     yield put(loginSuccess(response))
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeLogin, {
  //       email: user.email,
  //       password: user.password,
  //     })
  //     sessionStorage.setItem("authUser", JSON.stringify(response.data.user))
  //     localStorage.setItem("jwt", JSON.stringify(response.data.jwt))
  //     yield put(loginSuccess(response))
  //   }

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

      try {
        const response = yield call(postFakeLogin, {
          email: user.email,
          password: user.password,
        })
        if(response.data.user)
         sessionStorage.setItem("authUser", JSON.stringify(response.data.user))
        if(response.data.jwt)
         sessionStorage.setItem("jwt", JSON.stringify(response.data.jwt))
         localStorage.setItem("jwt", JSON.stringify(response.data.jwt))
        yield put(loginSuccess(response))
      } catch (error) {
        if (error.response) {
          yield put({ type: LOGIN_FAILED, payload: error.response.data.message });
          yield put(apiError(error))
          // Redirect to login page here
          history.push("/login")
        } else {
          yield put(apiError(error))
        }
      }
    }

    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    // console.log("obj =>",obj.role)
    if(obj.role == "owner"){
    history.push("/dashboard")
    }else if(obj.role == "agency"){
      history.push("/agency")
    }else if(obj.role == "admin"){
      history.push("/admin")
    }else if(obj.role == "event"){
      history.push("/events")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
