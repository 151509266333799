const mallData = [
    {
      pid: "1",
      buildingName: "Marina Mall",
      buildingNickname: "Marina Mall",
      address: "Marina Mall, King Salman Bin Abdulaziz Al Saud Street, Al Kasir, Abu Dhabi, Abu Dhabi Emirate, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "230,000",
      assignedTo: "John",
      lat: "24.47603995",
      lon: "54.32143232113238",
      mediaUploadUrl: "https://www.wmeglobal.com/wp-content/uploads/2020/07/marina-mall-2019-Main.jpg",
      country: "uae",
      slotsAvailable: "320"
    },
    {
      pid: "2",
      buildingName: "Lulu Mall",
      buildingNickname: "Lulu Mall",
      address: "LuLu Mall, Al Maktoum Road, Seh Al Rahi, Fujairah, Fujairah Emirate, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "250,000",
      assignedTo: "Jack",
      lat: "25.11926095",
      lon: "56.328465899155546",
      mediaUploadUrl: "https://imagevars.gulfnews.com/2021/09/02/Stock---Lulu-Dubai-Silicon-Oasis_17ba5579098_medium.jpg",
      country: "uae",
      slotsAvailable: "100"
    },
    { pid: "3",
      buildingName: "Abu Dhabi Mall",
      buildingNickname: "Abu Dhabi Mall",
      address: "Abu Dhabi Mall, Abu Dhabi Mall Parking Exit, Al Zahiyah, Abu Dhabi, Abu Dhabi Emirate, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "260,000",
      assignedTo: "James",
      lat: "24.495989299999998",
      lon: "54.383282826099894",
      mediaUploadUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_KJbJdKHFmAa5CGQIm8pZsvbiMaQcxP1m1Q&usqp=CAU",
      country: "uae",
      slotsAvailable: "220"
    },
    { pid: "4",
      buildingName: "Khalidiyah Mall",
      buildingNickname: "Khalidiyah Mall",
      address: "Khalidiyah Mall, Mubarak Bin Mohammed Street, Al Manhal, Abu Dhabi, Abu Dhabi Emirate, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "160,000",
      assignedTo: "Dave",
      lat: "24.4699333",
      lon: "54.351822100472944",
      mediaUploadUrl: "https://media-cdn.tripadvisor.com/media/photo-s/0f/e1/f6/ff/khalidiya-mall.jpg",
      country: "uae",
      slotsAvailable: "120"
    },
    { pid: "5",
      buildingName: "Madina Mall",
      buildingNickname: "Madina Mall",
      address: "Madina Mall, Beirut Street, Al Qusais Industrial Area, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "220,000",
      assignedTo: "Mark",
      lat: "25.282085799999997",
      lon: "55.39818191437184",
      mediaUploadUrl: "https://wl-img-prd.s3-accelerate.amazonaws.com/08158dba-8e37-4af9-9f1f-10da2bbf9554-h.jpeg",
      country: "uae",
      slotsAvailable: "120"
    },
    { pid: "6",
      buildingName: "Dubai Mall",
      buildingNickname: "Dubai Mall",
      address: "The Dubai Mall, Financial Center Street, Downtown Dubai, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "320,000",
      assignedTo: "Jason",
      lat: "25.197095949999998",
      lon: "55.279025287625956",
      mediaUploadUrl: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0d/8c/0c/1a/taken-5-years-ago-it.jpg?w=1200&h=1200&s=1",
      country: "uae",
      slotsAvailable: "420"
    },
    { pid: "7",
      buildingName: "Mall of the Emirates",
      buildingNickname: "Mall of the Emirates",
      address: "Mall of the Emirates, 1, Al Sab Street, Al Barsha 1, Al Barsha, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "270,000",
      assignedTo: "Henry",
      lat: "25.11824445",
      lon: "55.200361535152325",
      mediaUploadUrl: "https://www.visitdubai.com/-/media/images/leisure/campaigns/dsf/dsf-header-02-dtcm.jpg",
      country: "uae",
      slotsAvailable: "250"
    },
    { pid: "8",
      buildingName: "Mercato Shopping Mall",
      buildingNickname: "Mercato Shopping Mall",
      address: "Mercato Shopping Mall, 73a Street, Jumeirah, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "160,000",
      assignedTo: "Steve",
      lat: "25.21635",
      lon: "55.25299736792856",
      mediaUploadUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Mercato_Shopping_Mall_on_5_June_2007_Pict_2.jpg/1200px-Mercato_Shopping_Mall_on_5_June_2007_Pict_2.jpg",
      country: "uae",
      slotsAvailable: "140"
    },
    { pid: "9",
      buildingName: "Dubai Festival City",
      buildingNickname: "Dubai Festival City",
      address: "Dubai Festival City, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "520,000",
      assignedTo: "Matt",
      lat: "25.21990035",
      lon: "55.359607838658675",
      mediaUploadUrl: "https://www.constructionweekonline.com/cloud/2021/07/07/dNHXF3sz-Dubai-Festival-City-Festival-Bay-3-new-1-1200x837.jpg",
      country: "uae",
      slotsAvailable: "620"
    },
    { pid: "10",
      buildingName: "Dubai Marina Mall",
      buildingNickname: "Dubai Marina Mall",
      address: "Dubai Marina Mall, Al Marsa Street, Dubai Marina, Dubai, United Arab Emirates",
      contentTypeExcluded: [
        "Alcohol",
        "Cigerrete",
        "Nudity"
      ],
      annualFootFall: "200,000",
      assignedTo: "Mark",
      lat: "25.07657775",
      lon: "55.14039241229611",
      mediaUploadUrl: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/07/4b/5c/3c/dubai-marina-mall.jpg?w=1200&h=-1&s=1",
      country: "uae",
      slotsAvailable: "320"
    }
  ]

  export {mallData}