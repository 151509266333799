import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

import ButtonToggle from "reactstrap"

//components
import UiModal from "./DeleteModal"

const AdminNotificationsDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText>
              <h5>Notifications</h5>
            </CardText>
            <Row style={styles.Container.Row.AddZoneBtnRow}>
              <Link to={"/admin/notifications/addNew"} style={{ textAlign: "end" }}>
                <Button color="warning" className="col-1">
                  <i className="fa fa-plus"> Add</i>
                </Button>
              </Link>
            </Row>

            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Notifications ID</th>
                    <th>Priority</th>
                    <th>Notification Name</th>
                    <th>Description</th>
                    <th>Created On</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">LM31</th>
                    <td>
                      <i className="fa fa-circle">Medium</i>
                    </td>
                    <td>Maintanance Notification</td>
                    <td>
                      There will be maintenance from 1- June 2022 to 19 Jun 2022
                    </td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/notifications/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/notifications/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LM32</th>
                    <td>
                      <i className="fa fa-circle">High</i>
                    </td>
                    <td>Portal Unavailable</td>
                    <td>
                      Viewitonme portal will be unavailable on 02 April due to
                      site maintenance
                    </td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/notifications/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/notifications/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LM33</th>
                    <td>
                      <i className="fa fa-circle">Medium</i>
                    </td>
                    <td>Maintanance Notification</td>
                    <td>
                      There will be maintenance from 1- June 2022 to 19 Jun 2022
                    </td>
                    <td>10 May 2022</td>
                    <td>David Moose</td>
                    <td>
                      <td>
                        <Link to="/admin/notifications/view"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/notifications/edit"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminNotificationsDashboard
