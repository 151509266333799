import imagePath from "../../../assets/images/floorPlan1.png"
import imagePath2 from "../../../assets/images/mallplan2.png"
import imagePath3 from "../../../assets/images/mallplan3.png"
import imagePath4 from "../../../assets/images/mallplan4.png"

const leafletData = [
  {
    levelID: "0",
    levelName: "01",
    floorPlanImg: imagePath,
  },
  {
    levelID: "1",
    levelName: "02",
    floorPlanImg: imagePath2,
  },
  {
    levelID: "2",
    levelName: "03",
    floorPlanImg: imagePath3,
  },
]

export { leafletData }
