import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  CardImg,
  CardBody,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardText,
} from "reactstrap"

//components
import UiModal from "./InstallationImage"
import { Accordion } from "react-bootstrap"
import { axiosApi } from "helpers/api_helper"

export const EventsBookingDetailsOngoing = () => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)
  }
  const queryString = window.location.search
  const [selectedSlotName, setSelectedSlotName] = useState("")
  const [selectedCollateralType, setSelectedCollateralType] = useState("")
  const [uniqueAgencies, setUniqueAgencies] = useState([])
  const [uniqueSlots, setUniqueSlots] = useState([])
  const [tokenId, setTokenId] = useState(queryString.slice(4))
  const [apiRes, setApiRes] = useState()

  const handleSlotNameChange = e => {
    setSelectedSlotName(e.target.value)
  }

  const handleCollateralTypeChange = e => {
    setSelectedCollateralType(e.target.value)
  }

  useEffect(() => {
    axiosApi
      .get(`/event-organizer/listing-to-statement?id=${tokenId}`)
      .then(res => {
        setApiRes(res.data.data)

        const uniqueAgencies = res.data.data
          .map(row => row.type)
          .filter((name, index, array) => array.indexOf(name) === index)
          .reduce((acc, name) => {
            acc.push({ value: name, label: name })
            return acc
          }, [])
        setUniqueAgencies(uniqueAgencies)

        const uniqueSlots = res.data.data
          .map(row => row.slotName)
          .filter((name, index, array) => array.indexOf(name) === index)
          .reduce((acc, name) => {
            acc.push({ value: name, label: name })
            return acc
          }, [])
        setUniqueSlots(uniqueSlots)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  return (
    <Container className="page-content" fluid>
      <Row>
        <Card>
          <Card className="shadow-sm mb-0 mt-3">
            <CardText className="fs-3 ms-5">OnGoing</CardText>
          </Card>
          <CardBody>
            <Accordion defaultActiveKey="0" className="shadow-sm">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filters</Accordion.Header>
                <Accordion.Body>
                  <CardBody>
                    <Row>
                      <Col>
                        <Label>Slot Name</Label>
                        <Input
                          type="select"
                          placeholder="Cam Agency"
                          className="form-select"
                          value={selectedSlotName}
                          onChange={handleSlotNameChange}
                        >
                          <option value="">All</option>
                          {uniqueSlots.map(agency => (
                            <option key={agency.value} value={agency.value}>
                              {agency.label}
                            </option>
                          ))}
                        </Input>
                      </Col>
                      <Col>
                        <Label>Collateral Type</Label>
                        <Input
                          type="select"
                          placeholder="All"
                          className="form-select"
                          value={selectedCollateralType}
                          onChange={handleCollateralTypeChange}
                        >
                          <option value="">All</option>
                          {uniqueAgencies.map(agency => (
                            <option key={agency.value} value={agency.value}>
                              {agency.label}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </CardBody>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </CardBody>
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-striped mb-0">
                <thead align="center" className="table-light">
                  <tr className="align-baseline">
                    <th>#</th>
                    <th>Default Image</th>
                    <th>Current Image</th>
                    <th>Slot Name</th>
                    <th>Collateral Type</th>
                    <th>
                      Capacity
                      <br />
                      kg
                    </th>
                    <th>
                      Slot Details
                      <br />
                      cm
                    </th>
                    <th>Pricing Category</th>
                    <th>Amount</th>
                    <th>Current Image</th>
                  </tr>
                </thead>
                <tbody align="center">
                  {apiRes &&
                    apiRes
                      .filter(
                        row =>
                          row.slotName.includes(selectedSlotName) &&
                          row.type.includes(selectedCollateralType)
                      )
                      .map(row => (
                        <tr>
                          <th scope="row">
                            <Link to="" style={styles.tagBtnStyle}>
                              {row.id}
                            </Link>
                          </th>
                          <td>
                            <UiModal />
                          </td>
                          <CardImg
                            style={{ width: "100px", height: "100px" }}
                            src={row.currentImage || image}
                          ></CardImg>
                          <td>{row.slotName}</td>
                          <td>{row.type}</td>
                          <td>{row.capacity}</td>
                          <td>{row.slotDetails}</td>
                          <td>{row.priceCategory}</td>
                          <td>{row.totalAmount}</td>
                          <td>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="Upload">
                                Upload
                              </label>
                              <input type="file" className="form-control" />
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      {/* 👇️ show elements on click */}
      {isShown && (
        <Card
          style={{
            width: 250,
            backgroundColor: "lightgrey",
            padding: 20,
            marginTop: "-25%",
            marginLeft: "30%",
            position: "absolute",
          }}
        >
          <Row className="mb-3">
            <Col>
              <Label>From</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Label className="pt-3">To</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Button onClick={handleClick}>Done</Button>
          </Row>
        </Card>
      )}
      <Row>
        <Link to="../bookings">
          <Button className="col-1 me-2 ms-2 float-end" color="primary">
            Update
          </Button>
        </Link>
      </Row>
    </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}
