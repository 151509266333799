import React, { useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap"
import { Link } from "react-router-dom"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// import image
import img1 from "../../../assets/images/small/img-1.jpg"
import img2 from "../../../assets/images/small/img-2.jpg"
import img3 from "../../../assets/images/small/img-3.jpg"
import img4 from "../../../assets/images/small/img-4.jpg"
import img5 from "../../../assets/images/small/img-5.jpg"
import img6 from "../../../assets/images/small/img-6.jpg"
import img7 from "../../../assets/images/small/img-7.jpg"

//import data
import { cardDataIndoor } from "../../Common/Data/PropertyIndoorData"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const queryString = window.location.search
const i = queryString.slice(1) - 1



const UiLightbox = (props) => {
  const [photoIndex, setphotoIndex] = useState(0)
  const [isFits, setisFits] = useState(false)
  const [isEffects, setisEffects] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [isOpen1, setisOpen1] = useState(false)
  const [modal, setmodal] = useState(false)
  const images = [cardDataIndoor[props.i].subImg1, cardDataIndoor[props.i].subImg2, cardDataIndoor[props.i].subImg3, cardDataIndoor[props.i].subImg4]
  // alert(props.i)
  return (
    <React.Fragment>
        <Container fluid={true}>

          {isFits ? (
            <Lightbox
              mainSrc={images[photoIndex]}
              enableZoom={false}
              imageCaption={
                "Caption. Can be aligned it to any side and contain any HTML."
              }
              onCloseRequest={() => {
                setisFits(!isFits)
              }}
            />
          ) : null}

          {isEffects ? (
            <Lightbox
              mainSrc={images[3]}
              enableZoom={false}
              onCloseRequest={() => {
                setisEffects(!isEffects)
              }}
            />
          ) : null}

          {isGallery ? (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              enableZoom={true}
              onCloseRequest={() => {
                setisGallery(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex((photoIndex + images.length - 1) % images.length)
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % images.length)
              }}
              imageCaption={"Project " + parseFloat(photoIndex + 1)}
            />
          ) : null}

          {isGalleryZoom ? (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex((photoIndex + images.length - 1) % images.length)
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % images.length)
              }}
            />
          ) : null}

          <Card>
            <CardBody>
              <CardTitle className="h4">Lightbox gallery</CardTitle>
              <div className="popup-gallery d-flex flex-wrap">
                <div className="img-fluid float-left m-1">
                  <img
                    src={cardDataIndoor[props.i].subImg1}
                    onClick={() => {
                      setisGallery(true)
                      setphotoIndex(0)
                    }}
                    alt=""
                    width="200"
                    height="180"
                  />
                </div>
                <div className="img-fluid float-left m-1">
                  <img
                    src={cardDataIndoor[props.i].subImg2}
                    onClick={() => {
                      setisGallery(true)
                      setphotoIndex(1)
                    }}
                    alt=""
                    width="200"
                    height="180"
                  />
                </div>
                <div className="img-fluid float-left m-1">
                  <img
                    src={cardDataIndoor[props.i].subImg3}
                    onClick={() => {
                      setisGallery(true)
                      setphotoIndex(2)
                    }}
                    alt=""
                    width="200"
                    height="180"
                  />
                </div>
                <div className="img-fluid float-left m-1">
                  <img
                    src={cardDataIndoor[props.i].subImg4}
                    onClick={() => {
                      setisGallery(true)
                      setphotoIndex(3)
                    }}
                    alt=""
                    width="200"
                    height="180"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
    </React.Fragment>
  )
}

export default UiLightbox
