import React from "react"

import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
} from "reactstrap"

const LogsDashboard = props => {
  return (
    <Container className="page-content" fluid>
      <Row>
        <Row>
          <CardText>
            <h4>Logs</h4>
          </CardText>
        </Row>
        <Card>
          <Row className="mb-3">
            <Col className="col=2">
              <Input type="select" placeholder="All" className="form-select">
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col className="col=2">
              <Input type="select" placeholder="All" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col className="col=6"></Col>
            <Col className="col=2">
              <Input placeholder="Search"></Input>
            </Col>
          </Row>
          <Row>
            <Table striped style={styles.tableStyle}>
              <thead style={styles.tableStyle.thead}>
                <tr>
                  <th>#</th>
                  <th>Timstamp</th>
                  <th>User ID</th>
                  <th>User</th>
                  <th>Activity</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">#00999</th>
                  <td>9-28-2011- 20:24:15.131</td>
                  <td>0324</td>
                  <td>David</td>
                  <td>New user created ID 673</td>
                  <td>Alvin</td>
                </tr>
                <tr>
                  <th scope="row">#00998</th>
                  <td>9-28-2011- 20:24:15.131</td>
                  <td>0324</td>
                  <td>William</td>
                  <td>Completed the installation</td>
                  <td>B1033 c332</td>
                </tr>
                <tr>
                  <th scope="row">#00997</th>
                  <td>9-28-2011- 20:24:15.131</td>
                  <td>0324</td>
                  <td>John</td>
                  <td>Created a slot at Zone 06</td>
                  <td>SL1132</td>
                </tr>
                <tr>
                  <th scope="row">#00996</th>
                  <td>9-28-2011- 20:24:15.131</td>
                  <td>0324</td>
                  <td>David</td>
                  <td>Deleted the slot ID 673</td>
                  <td>Deleted 51673</td>
                </tr>
                <tr>
                  <th scope="row">#00995</th>
                  <td>9-28-2011- 20:24:15.131</td>
                  <td>0324</td>
                  <td>John</td>
                  <td>Completed the installation</td>
                  <td>SL132</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Card>
      </Row>
    </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  tableStyle: {
    backgroundColor: "lightblue",
    thead: {
      backgroundColor: "lightgray",
    },
  },
}

export default LogsDashboard
