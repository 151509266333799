import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//img
import slotDescSample from "../../assets/images/slotDescSample.png"

const ProspectCreateModal = props => {
  //prospectCreate Api call
  const [contactName, setContactName] = useState()
  const [companyName, setCompanyName] = useState()
  const [mobile, setMobile] = useState()
  const [email, setEmail] = useState()
  const [formData, setFormData] = useState()

  useEffect(() => {
    setFormData({
      email: email,
      contactName: contactName,
      companyName: companyName,
      mobileNo: mobile,
    })
  }, [mobile, email, contactName, companyName])

  // useEffect(()=>{

  //   console.log("mob",formData);
  // },[formData])

  //modal config
  const [modal_center, setmodal_center] = useState(false)



  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }


  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [prospects, setProspects] = useState([])

  // handle onChange for selecting company name
  function handleCompanyNameChange(event) {
    setSelectedCompanyName(event.target.value)
    const matchingProspect = prospects.find(
      prospect => prospect.companyName === event.target.value
    )
    // populate other input fields with matching prospect data
    if (matchingProspect) {
      document.querySelector("#mobileNo").value = matchingProspect.mobileNo
      document.querySelector("#email").value = matchingProspect.email
    }
  }

  const handleSubmit = () => {
    axiosApi
      .post("/event-organizer/create-event-prospect", formData)
      .then(res => tog_center())
  }

  return (
    <div className="m-3">
      <Row>
        <Row style={{ font: "menu" }} className="d flex-row-reverse w-20">
          <Button
            className="col-3"
            color="primary"
            onClick={() => {
              tog_center()
            }}
          >
            Add New
          </Button>
        </Row>
        <Col>
          <Card>
            <CardBody style={{ display: "none" }}>
              <Row>
                <Col sm={6} md={4} xl={3}>
                  <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                    // style={{ minWidth: 750 }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Portfolio Add</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <FormGroup>
                          <Label>Contact Name</Label>
                          <Input
                            type="text"
                            id="conactName"
                            value={contactName}
                            onChange={e => setContactName(e.target.value)}
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Input
                            type="text"
                            id="companyName"
                            value={companyName}
                            onChange={e => setCompanyName(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Mobile Number</Label>
                          <Input
                            type="text"
                            id="mobileNo"
                            value={mobile}
                            onChange={e => setMobile(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Email ID</Label>
                          <Input
                            type="text"
                            id="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Row>
                    </div>
                    <Row className="text-end">
                      <Col style={styles.modalBtnStyle} className="mb-3">
                        <Button
                          className="btn btn-primary waves-effect waves-light me-2"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                          color="danger"
                        >
                          Back
                        </Button>

                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light me-3"
                          onClick={() => {
                            handleSubmit()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default ProspectCreateModal
