import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Label,
  Input,
  CardImg,
  FormGroup,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//img
import AddZoneImg from "../../assets/images/AddZoneImg.png"
import { useRef } from "react"
import TagsInput from "events/Events/TagsInput"
import SweetAlert from "react-bootstrap-sweetalert"

const UiModal = props => {
  const selectedTags = tags => setDocuments(tags)
  const [apiRes, setApiRes] = useState()
  const [apiRes1, setApiRes1] = useState([])
  const [apiRes4, setApiRes4] = useState([])
  const [apiRes3, setApiRes3] = useState([])
  const [gpsLat, setGpsLat] = useState("")
  const [gpsLon, setGpsLon] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const isFirstRun = useRef(true)
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("Please ")
  const [dimension, setDimension] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  useEffect(() => {
    if (!isFirstRun.current) {
      tog_center()
    }
    isFirstRun.current = false
  }, [props.isOpen])

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }
  //to show pricing category dropDown
  useEffect(() => {
    axiosApi.get("/common/pricing?show=dropdown").then(response => {
      setApiRes(response.data.data)
    })
  }, [])
  useEffect(() => {}, [apiRes])

  //to create slot
  const [eventId, setEventId] = useState(props.id)
  const [zoneId, setZoneId] = useState("")
  const [defaultImage, setDefaultImage] = useState([])
  const [currentImage, setCurrentImage] = useState([])
  const [slotName, setSlotName] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  const [capacity, setCapacity] = useState("")
  const [slotDetails, setSlotDetails] = useState("")
  const [description, setDescription] = useState("")
  const [priceCategory, setPriceCategory] = useState("")
  const [slotDescription, setSlotDescription] = useState("")
  const [weight, setWeight] = useState("")
  const [dimensions, setDimensions] = useState([])
  const [documents, setDocuments] = useState("")
  const [powerAvailability, setPowerAvailability] = useState("")

  function handleFile(event) {
    setDefaultImage(event.target.files)
  }
  function handleFile1(event) {
    setCurrentImage(event.target.files)
  }

  const handleInputChange = (e, key, index) => {
    const updatedApiRes4 = { ...apiRes4 }
    if (!Array.isArray(updatedApiRes4.dimensions[key])) {
      updatedApiRes4.dimensions[key] = []
    }
    updatedApiRes4.dimensions[key][0] = e.target.value
    setDimensions(updatedApiRes4)
  }

  let handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("eventId", eventId)
    formData.append("defaultImage", defaultImage[0])
    formData.append("currentImage", currentImage[0])
    formData.append("zoneId", zoneId)
    formData.append("slotName", slotName)
    formData.append("type", type)
    formData.append("status", status)
    formData.append("capacity", capacity)
    formData.append("slotDetails", slotDetails)
    formData.append("description", description)
    formData.append("gpsCoordinate", [gpsLat, gpsLon])
    formData.append("priceCategory", priceCategory)
    formData.append("slotDescription", slotDescription)
    formData.append("weight", weight)
    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.a &&
      dimensions.dimensions.a.length > 0
    ) {
      formData.append("a", dimensions.dimensions.a[0])
    }

    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.b &&
      dimensions.dimensions.b.length > 0
    ) {
      formData.append("b", dimensions.dimensions.b[0])
    }

    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.c &&
      dimensions.dimensions.c.length > 0
    ) {
      formData.append("c", dimensions.dimensions.c[0])
    }
    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.d &&
      dimensions.dimensions.d.length > 0
    ) {
      formData.append("d", dimensions.dimensions.d[0])
    }
    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.e &&
      dimensions.dimensions.e.length > 0
    ) {
      formData.append("e", dimensions.dimensions.e[0])
    }
    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.f &&
      dimensions.dimensions.f.length > 0
    ) {
      formData.append("f", dimensions.dimensions.f[0])
    }
    if (
      dimensions &&
      dimensions.dimensions &&
      dimensions.dimensions.g &&
      dimensions.dimensions.g.length > 0
    ) {
      formData.append("g", dimensions.dimensions.g[0])
    }

    formData.append("documents", documents)
    formData.append("powerAvailability", powerAvailability)
    axiosApi
      .post("/event-organizer/create-event-slot", formData)
      .then(response => {
        setbasic(true)
        setSweetTitle("Success")
        location.reload()
      })
      .catch(error => {
        setbasic(true)
        setSweetTitle("Please Fill Data")
        console.error(error)
      })
  }

  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes1(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [type])

  useEffect(() => {
    axiosApi
      .get(`/common/collateralTypes?id=${type}`)
      .then(response => {
        setApiRes4(response.data.data)
        const dimensionValues = response.data.data.dimensions
        setDimension(dimensionValues)
        if (response.data.data.noOfDimensions > 0) {
          setShowForm(true)
        }
      })
      .catch(error => {
        console.error(error)
        setShowForm(false)
      })
  }, [type])

  useEffect(() => {
    axiosApi
      .get(`/event-organizer/zone?eventId=${eventId}`)
      .then(response => {
        setApiRes3(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return (
    <Row>
      <Row>
        <Col xs={12} sm={6}>
          <Card>
            <CardBody style={{ display: "none" }}>
              <Row>
                <Col xs={12} sm={6}>
                  <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                    style={{ marginTop: "80px", minWidth: 'auto' }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Add Slots</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Col xl="3" lg="4" sm="6" className="mb-2">
                        {basic ? (
                          <SweetAlert
                            title={sweetTitle}
                            onConfirm={() => {
                              setbasic(false)
                            }}
                          />
                        ) : null}
                      </Col>
                      <Row>
                        <Col xs={6}>
                          <FormGroup row style={{ width: "235px" }}>
                            <Label sm={3}>Zone Name</Label>
                            <Input
                              type="select"
                              className="form-select  form-control-sm"
                              value={zoneId}
                              onChange={e => setZoneId(e.target.value)}
                              style={{ marginLeft: "10px" }}
                            >
                              <option>Plaese Select</option>
                              {apiRes3.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.zoneName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>Slot Name</Label>
                            <Input
                              type="text"
                              placeholder="Slot 01"
                              value={slotName}
                              onChange={e => setSlotName(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>status</Label>
                            <Input
                              type="text"
                              placeholder="Vacant"
                              value={status}
                              onChange={e => setStatus(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>capacity</Label>
                            <Input
                              type="text"
                              placeholder="0"
                              value={capacity}
                              onChange={e => setCapacity(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>Description</Label>
                            <Input
                              type="textarea"
                              placeholder="Beside store 101"
                              value={description}
                              onChange={e => setDescription(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>slot Details</Label>
                            <Input
                              type="textarea"
                              placeholder=""
                              value={slotDetails}
                              onChange={e => setSlotDetails(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>slot Description</Label>
                            <Input
                              type="textarea"
                              placeholder="Beside store 101"
                              value={slotDescription}
                              onChange={e => setSlotDescription(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label sm={3}>Gps Coordinate</Label>
                            <div className="d-flex">
                              <Input
                                value={gpsLat}
                                className="mr-3"
                                placeholder="Latitude"
                                onChange={e => setGpsLat(e.target.value)}
                                required
                                style={{ width: "45%" }}
                              />
                              <Input
                                value={gpsLon}
                                className="ml-3"
                                placeholder="Longitude"
                                onChange={e => setGpsLon(e.target.value)}
                                required
                                style={{ width: "45%" }}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>Documents</Label>
                            <Row>
                              <TagsInput selectedTags={selectedTags} />
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={6}>Slot Limits</Label>
                            <CardImg
                              src={AddZoneImg}
                              className="img-fluid"
                              style={{ width: "auto" }}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "235px" }}>
                            <Label sm={3}>Collateral Type</Label>
                            <Input
                              type="select"
                              className="form-select"
                              value={type}
                              onChange={e => setType(e.target.value)}
                            >
                              <option>Plaese Select</option>
                              {apiRes1.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.Name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <Row>
                            {showForm && (
                              <Col sm={3}>
                                {Object.entries(apiRes4.dimensions).map(
                                  ([key, value], index) => (
                                    <FormGroup
                                      style={{ width: "235px" }}
                                      key={key}
                                    >
                                      <Label sm={3}>{key}</Label>
                                      <Input
                                        type="text"
                                        value={value[0] || ""}
                                        onChange={e =>
                                          handleInputChange(e, key, index)
                                        }
                                      />
                                    </FormGroup>
                                  )
                                )}
                              </Col>
                            )}
                            <Col>
                              <FormGroup style={{ width: "235px" }}>
                                <Label sm={3}>Weight</Label>
                                <Input
                                  type="text"
                                  placeholder="kg"
                                  value={weight}
                                  onChange={e => setWeight(e.target.value)}
                                />
                              </FormGroup>
                              <FormGroup style={{ width: "235px" }}>
                                <Label sm={3}>Power</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  value={powerAvailability}
                                  onChange={e =>
                                    setPowerAvailability(e.target.value)
                                  }
                                >
                                  <option>Plaese Select</option>
                                  <option>Power Available</option>
                                  <option>Power Unavailable</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label sm={3}>Price Category</Label>
                                <Input
                                  style={{ width: "235px" }}
                                  type="select"
                                  className="form-select"
                                  value={priceCategory}
                                  onChange={e =>
                                    setPriceCategory(e.target.value)
                                  }
                                >
                                  <option>Plaese Select</option>
                                  {apiRes
                                    ? apiRes.map(item => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))
                                    : null}
                                </Input>
                              </FormGroup>
                            </Col>
                            <FormGroup>
                              <Label sm={3}>Default Image</Label>
                              <Input type="file" onChange={handleFile} />
                            </FormGroup>
                            <FormGroup>
                              <Label sm={3}>Current Image</Label>
                              <Input type="file" onChange={handleFile1} />
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row sm={3}>
                      <Col
                        className="mb-3 ps-4"
                        xs={12}
                        md={6}
                        style={{ maxWidth: "fit-content" }}
                      >
                        <Button
                          className="btn btn-primary waves-effect waves-light me-2"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                          color="danger"
                        >
                          Back
                        </Button>

                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light me-3"
                          onClick={handleSubmit}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
