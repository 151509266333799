import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

export const EventsEventsDashboard = () => {
  return (
    <Container className="page-content" fluid>
      <Card>
        <CardTitle>Event Details</CardTitle>
        <CardBody>
          <Form>
            <Row>
              <Col>
                <Label>Event Name</Label>
                <Input type="text" placeholder="Christmas Fest" />
                <Label>Footfall/Day</Label>
                <Input type="text" placeholder="100,000" />
                <Label>Address</Label>
                <Input
                  type="textarea"
                  placeholder="Downtown Dubai Ground, Beside Burj Al Arab, 500001"
                />
                <Label>Features</Label>
                <br />
                <FormGroup>
                  <Label>
                    Kid Friendly
                    <Input type="radio" value="Kid Friendly" />
                  </Label>{" "}
                  <Label>
                    Pets Allowed
                    <Input type="radio" value="Pets Allowed" />
                  </Label>{" "}
                  <Label>
                    Alcohol Allowed
                    <Input type="radio" value="Alcohol Allowed" />
                  </Label>{" "}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Duration</Label>
                  <Row>
                    <Col>
                      <Label>From</Label>
                      <Input type="date" />
                    </Col>
                    <Col>
                      <Label>To</Label>
                      <Input type="date" />
                    </Col>
                  </Row>
                </FormGroup>
                <Label>Ad Content Type Excluded</Label>
                <Input type="select" className="form-select">
                  <option>Alcohol</option>
                  <option>Smoking</option>
                </Input>
                <Label>GPS Coordinates</Label>
                <Input type="textarea" />
              </Col>
              <Col>
                <Label>Upload Event Images</Label>
                <Input type="file" className="fa fa-upload"></Input>
              </Col>
            </Row>
            <Row className="d-flex row-reverse">
              <Link to=".." className="btn btn-danger col-1 me-2">
                Cancel
              </Link>
              <Link to="/events/properties" className="btn btn-primary col-1">
                Add
              </Link>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}
