import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Input,
  Label,
  FormGroup,
  CardImg,
  Form,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//img
import AddZoneImg from "../../assets/images/AddZoneImg.png"

const AddZoneModal = () => {
  const [defaultImage, setDefaultImage] = useState("")
  const [currentImage, setCurrentImage] = useState("")
  const [slotName, setSlotName] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  const [slotDetails, setslotDetails] = useState("")
  const [underMaintenance, setUnderMaintenance] = useState("")
  const [underMaintenanceFrom, setUnderMaintenanceFrom] = useState("1234")
  const [underMaintenanceTo, setUnderMaintenanceTo] = useState("1234")
  const [description, setDescription] = useState("")
  const [gpsCoordinates, setGpsCoordinates] = useState("")
  const [priceCategory, setPriceCategory] = useState("")
  const [slotDescription, setSlotDescription] = useState("")
  const [weight, setWeight] = useState("")
  const [a, setA] = useState("")
  const [b, setB] = useState("")
  const [c, setC] = useState("")
  const [d, setD] = useState("")

  let handleSubmit = async e => {
    e.preventDefault()

    const formData = {
      eventId: "1",
      defaultImage: defaultImage,
      currentImage: currentImage,
      slotName: slotName,
      type: type,
      status: status,
      slotDetails: slotDetails,
      underMaintenance: underMaintenance,
      underMaintenanceFrom: underMaintenanceFrom,
      underMaintenanceTo: underMaintenanceTo,
      description: description,
      gpsCoordinates: gpsCoordinates,
      priceCategory: priceCategory,
      slotDescription: slotDescription,
      weight: weight,
      a: a,
      b: b,
      c: c,
      d: d,
    }

    axiosApi
      .post("/event-organizer/create-event-slot", formData)
      .then(window.location.replace("./slots"))
  }

  const [modal_center, setmodal_center] = useState(true)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                  style={{ minWidth: 750 }}
                >
                  <Form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">SLOT LISTING</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Slot Name</Label>
                            <Input
                              onChange={e => setSlotName(e.target.value)}
                              type="text"
                              placeholder="Slot 01"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Collateral Type</Label>
                            <Input
                              onChange={e => setType(e.target.value)}
                              type="select"
                              className="form-select"
                              required
                            >
                              <option selected disabled>
                                Ceiling
                              </option>
                              <option>Ceiling</option>
                              <option>Floor</option>
                              <option>Wall</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>Description</Label>
                            <Input
                              onChange={e => setSlotDescription(e.target.value)}
                              type="textarea"
                              placeholder="Beside store 101"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Default Image</Label>
                            <Input
                              onChange={e => setDefaultImage(e.target.value)}
                              type="file"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>GPS Coordinates</Label>
                            <Input
                              onChange={e => setGpsCoordinates(e.target.value)}
                              type="text"
                              placeholder="3585567.83,11163.84,-106.13"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Documents</Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="selectMulti"
                              id="exampleSelectMulti"
                              multiple
                            >
                              <option>Police Permit</option>
                              <option>Muncipality Permit</option>
                              <option>Media Permit</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Slot Limits</Label>
                            <CardImg src={AddZoneImg} />
                          </FormGroup>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>a</Label>
                                <Input
                                  onChange={e => setA(e.target.value)}
                                  type="text"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>b</Label>
                                <Input
                                  onChange={e => setB(e.target.value)}
                                  type="text"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>Power</Label>
                                <Input
                                  onChange={e =>
                                    setUnderMaintenance(e.target.value)
                                  }
                                  type="select"
                                  className="form-select"
                                  required
                                >
                                  <option selected disabled>
                                    Select Power Status
                                  </option>
                                  <option>false</option>
                                  <option>true</option>
                                  <option>Power Unavailable</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>c</Label>
                                <Input
                                  onChange={e => setC(e.target.value)}
                                  type="text"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>d</Label>
                                <Input
                                  onChange={e => setD(e.target.value)}
                                  type="text"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>Weight</Label>
                                <Input
                                  onChange={e => setWeight(e.target.value)}
                                  type="text"
                                  placeholder="kg"
                                />
                              </FormGroup>
                            </Col>
                            <FormGroup>
                              <Label>Price Category</Label>
                              <Input
                                onChange={e => setPriceCategory(e.target.value)}
                                type="select"
                                className="form-select"
                              >
                                <option selected disabled>
                                  Select Price Category
                                </option>
                                <option>Price Category 01</option>
                              </Input>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row className="text-end">
                      <Col style={styles.modalBtnStyle} className="mb-3">
                        <Button
                          className="btn btn-primary waves-effect waves-light me-2"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                          color="danger"
                        >
                          Back
                        </Button>

                        {/* <Button
                        color="primary"
                        type="submit"
                        className="btn btn-primary waves-effect waves-light me-3"
                        // onClick={() => {
                        //   tog_center()
                        // }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        Submit
                      </Button> */}
                        <Button color="primary" name="submit">
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default AddZoneModal
