import React, { useState, useEffect } from "react"
import { Button, Row } from "reactstrap"
import { MapContainer, ImageOverlay } from "react-leaflet"
import { axiosApi } from "helpers/api_helper"
import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import placeholderImage from "../../assets/images/placeholderImg.webp"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

const LeafletEditor = props => {
  const [zoneApiRes, setZoneApiRes] = useState([])
  const [ids, setIds] = useState([])
  const [imagePaths, setImagePaths] = useState([])

  useEffect(() => {
    axiosApi.get("/event-organizer/zone").then(res => {
      setZoneApiRes(res.data.data)
    })
  }, [])

  useEffect(() => {
    const viewid = props.viewQuoteData
      ? props.viewQuoteData.slots.map(item => item.zoneId)
      : null
    setIds(viewid)
  }, [props.viewQuoteData])

  useEffect(() => {
    if (zoneApiRes.length > 0) {
      const updatedImagePaths = zoneApiRes
        .map(item => ({
          levelId: item.id,
          floorPlanImg: item.image,
          Name: item.zoneName,
        }))
        .filter(item => props.zoneId.some(id => id === item.levelId))

      setImagePaths(updatedImagePaths)
    }
  }, [props.zoneId, zoneApiRes])

  useEffect(() => {
    document.getElementsByClassName("leaflet-control")[1].style.display = "none"
  }, [])

  useEffect(() => {
    // Find the index of the image based on the props.zoneId
    const imageIndex = imagePaths.findIndex(
      path => path.levelId === props.zoneId
    )

    if (imageIndex !== -1) {
      const updatedImagePaths = [...imagePaths]
      updatedImagePaths[imageIndex] = {
        ...updatedImagePaths[imageIndex],
        levelId: props.zoneId[0], // Change the image based on props.zoneId
      }
      setImagePaths(updatedImagePaths)
    }
  }, [props.zoneId])

  return (
    <Row>
      <MapContainer
        style={{ height: "70vh", width: "100%", zIndex: "1" }}
        center={[0, 50]}
        zoom={1}
        scrollWheelZoom={true}
        minZoom={1}
        maxZoom={4}
        maxBoundsViscosity={0}
        bounceAtZoomLimits={true}
        boundsOptions={L.FitBoundsOptions}
      >
        {imagePaths.length === 0 && (
          <ImageOverlay
            url={placeholderImage}
            bounds={[
              [-100, -400],
              [200.5, 500],
            ]}
          />
        )}
        {imagePaths.map(path => (
          <ImageOverlay
            key={path.levelId}
            url={path.floorPlanImg}
            bounds={[
              [-100, -400],
              [200.5, 500],
            ]}
          />
        ))}
      </MapContainer>
      <span
        style={{
          zIndex: 999,
          position: "absolute",
          width: "min-content",
          marginLeft: 40,
          marginTop: 40,
        }}
      >
        {imagePaths.map(btn => (
          <Button style={{ width: "150px" }} className="mt-2" key={btn.levelId}>
            {btn.Name}
          </Button>
        ))}
      </span>
    </Row>
  )
}

export default LeafletEditor
