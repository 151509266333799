import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  Container,
  Label,
  Input,
  Spinner,
  CardText,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
import "./EventsBookingDashboard.css"
import { Accordion } from "react-bootstrap"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { FormGroup } from "@material-ui/core"

const EventsBookingDashboard = props => {
  const [apiRes, setApiRes] = useState()
  const [rows, setRows] = useState([])

  useEffect(() => {
    axiosApi
      .get("/event-organizer/list-event-booking")
      .then(res => setApiRes(res.data.data))
  }, [])

  const onGridReady = params => {
    params.api.sizeColumnsToFit()
  }

  const handleStatus = s => {
    switch (s) {
      case "N":
        return "#New"
      case "ES":
        return "enquirySent"
      case "LR":
        return "listingReceived"
      case "PS":
        return "proposalSent"
      case "PAY":
        return "paymentPending"
      case "B":
        return "booked"
      case "O":
        return "ongoing"
      case "C":
        return "completed"
      case "E":
        return "expired"
      case "R":
        return "rejected"
      case "BI":
        if (role && role === "agency") {
          return "Booking Initiated"
        } else if (role && role === "owner") {
          return "Review"
        }
      default:
        return ""
    }
  }
  useEffect(() => {
    if (apiRes) {
      setRows(
        apiRes.map(row => ({
          id: (
            <Link
              to={`/events/bookings/${handleStatus(row.bookingStatus)}?id=${
                row.id
              }`}
            >
              {" "}
              {row.id}{" "}
            </Link>
          ),
          agency: row.companyName,
          slot_nos: row.noSlots,
          from: row.bookingFrom,
          to: row.bookingTo,
          days: row.days,
          amount: row.amount,
          status: row.statusMessage,
        }))
      )
      const uniqueAgencies = apiRes
        .map(row => row.companyName)
        .filter((name, index, array) => array.indexOf(name) === index)
        .reduce((acc, name) => {
          acc.push({ value: name, label: name })
          return acc
        }, [])
      setUniqueAgencies(uniqueAgencies)
    } else {
      setRows([])
      setUniqueAgencies([])
    }
  }, [apiRes])

  const bookingData = {
    columns: [
      {
        headerName: "Booking Id",
        field: "id",
        sortable: true,
        width: "220",
        cellRendererFramework: ({ value, data }) => (
          <Link
            className="bookinglink"
            to={`/events/bookings/${handleStatus(data.bookingStatus)}?id=${
              data.id
            }`}
          >
            {value}
          </Link>
        ),
      },
      {
        headerName: "Agency",
        field: "agency",
        filter: "agencyFilter",
        sortable: true,
        width: "235px",
        suppressMenu: true,
      },
      {
        headerName: "Slot Nos",
        field: "slot_nos",
        sortable: true,
      },
      {
        headerName: "From",
        field: "from",
        filter: "fromFilter",
        sortable: true,
        suppressMenu: true,
      },
      {
        headerName: "To",
        field: "to",
        filter: "toFilter",
        sortable: true,
        suppressMenu: true,
      },
      {
        headerName: "Days",
        field: "days",
        sortable: true,
      },
      {
        headerName: "Amount",
        field: "amount",
        sortable: true,
      },
      {
        headerName: "Status",
        field: "status",
        filter: "statusFilter",
        sortable: true,
        pinned: "right",
        width: "150px",
        suppressMenu: true,
        cellStyle: params => {
          switch (params.value) {
            case "New":
              return { textAlign: "center", backgroundColor: "#77ba99" }
            case "Enquiry Sent":
              return { textAlign: "center", backgroundColor: "#d7c0d0" }
            case "Expired":
              return { textAlign: "center" }
            case "Rejected":
              return { textAlign: "center" }
            case "Completed":
              return { textAlign: "center" }
            case "Confirmed":
              return { textAlign: "center" }
            default:
              return { textAlign: "center" }
          }
        },
      },
    ],
    rows: rows,
  }
  const [agencyFilter, setAgencyFilter] = useState("")
  const [fromFilter, setFromFilter] = useState("")
  const [toFilter, setToFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [uniqueAgencies, setUniqueAgencies] = useState([])
  const [api, setApi] = useState(null)

  useEffect(() => {
    if (api) {
      api.setFilterModel({
        agency: {
          type: "equals",
          filter: agencyFilter,
        },
        from: {
          type: "equals",
          filter: fromFilter,
        },
        to: {
          type: "equals",
          filter: toFilter,
        },
        status: {
          type: "equals",
          filter: statusFilter,
        },
      })
    }
  }, [api, agencyFilter, fromFilter, toFilter, statusFilter])


  if (apiRes == null) {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }
  return (
    <Container className="page-content" fluid>
      <Card className="shadow-sm mt-3">
        <CardText className="fs-4 ms-5">Booking Dashboard</CardText>
      </Card>
      <Card className="shadow-sm">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters</Accordion.Header>
            <Accordion.Body>
              <Row xs={2} md={4}>
                <Col>
                  <FormGroup className="mb-4">
                    <Label>Agency</Label>
                    <Input
                      onChange={e => setAgencyFilter(e.target.value)}
                      type="select"
                      className="form-select"
                    >
                      <option value="">Select agency...</option>
                      {uniqueAgencies.map(agency => (
                        <option key={agency.value} value={agency.value}>
                          {agency.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-4">
                    <Label>Status</Label>
                    <Input
                      type="select"
                      placeholder="All"
                      className="form-select"
                      onChange={e => setStatusFilter(e.target.value)}
                    >
                      <option value={""}>All</option>
                      <option value={"New"}>New</option>
                      <option value={"Enquiry Sent"}>Enquiry Sent</option>
                      <option value={"Listing Received"}>
                        Listing Received
                      </option>
                      <option value={"Proposal Sent"}>Proposal Sent</option>
                      <option value={"Payment Pending"}>Payment Pending</option>
                      <option value={"Booked"}>Booked</option>
                      <option value={"Ongoing"}>Ongoing</option>
                      <option value={"Completed"}>Completed </option>
                      <option value={"Expired"}>Expired</option>
                      <option value={"Rejected"}>Rejected</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup style={{ width: "auto" }} className="mb-4">
                    <Label>From</Label>
                    <Input
                      onChange={e => setFromFilter(e.target.value)}
                      type="date"
                      placeholder="From"
                      value={fromFilter}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup style={{ width: "auto" }} className="mb-4">
                    <Label>To</Label>
                    <Input
                      onChange={e => setToFilter(e.target.value)}
                      type="date"
                      placeholder="To"
                      value={toFilter}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
      <Card className="shadow-sm pt-3">
        <AgGridReact
          columnDefs={bookingData.columns}
          rowData={rows ? rows : "null"}
          pagination={true}
          paginationPageSize={10}
          onGridReady={params => setApi(params.api)}
          domLayout={"autoHeight"}
          className="ag-theme-alpine page-content pt-3"
        />
      </Card>
    </Container>
  )
}

export default EventsBookingDashboard
