import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

import ButtonToggle from "reactstrap"

const AdDepartments = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <Row style={styles.Container.Row.AddZoneBtnRow}>
              <Link to={"/agency/settings/departments/addNew"} style={{ textAlign: "end" }}>
                <Button color="warning" className="col-2">
                  <span className="fa fa-plus"> Add Departments</span>
                </Button>
              </Link>
            </Row>
            <CardText>
              <h5>Departments</h5>
            </CardText>
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td>Mail Management</td>
                    <td>Lorem ipsum dolor sit</td>
                    <td>10 April 2021</td>
                    <td>
                      <span className="fa fa-eye"></span>
                    </td>
                    <td>
                      <span className="fa fa-edit"></span>
                    </td>
                    <td>
                      <span className="fa fa-trash"></span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="#" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td>Floor Management</td>
                    <td>Lorem ipsum dolor sit</td>
                    <td>10 April 2021</td>
                    <td>
                      <span className="fa fa-eye"></span>
                    </td>
                    <td>
                      <span className="fa fa-edit"></span>
                    </td>
                    <td>
                      <span className="fa fa-trash"></span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdDepartments
