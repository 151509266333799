import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardImg,
  CardBody,
} from "reactstrap"

import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet"

import Accordion from "react-bootstrap/Accordion"

//components
import UiModal from "../Common/WizardModal"

//data
import { data } from "../Common/Data/AdGridViewData"

//img
import img1 from "../../assets/images/gridViewDigitalImg.png"
import imgHook from "../../assets/images/hook.png"

// import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

const AdGridViewDigital = props => {
  return (
      <Container style={styles.Container} className="page-content">
        <Row>
        <Col className="w-100">
          <button type="button" class="btn btn-outline-primary w-50 btn-lg">
            <Link to="/agency/bookNow">
                Map
            </Link>
          </button>
          <button type="button" class="btn btn-outline-primary w-50 btn-lg">
            <Link
              to="/agency/bookNow/gridView"
            >
              Grid
            </Link>
          </button>
        </Col>
        <Card></Card>
        <Card>
            <Row>
              <Col className="col-10">
              </Col>
              <Col className="col-12">
                <Row>
                        <Col>
                          <Label>Booking</Label>
                          <Input
                            type="select"
                            placeholder="Cam Agency"
                            className="form-select"
                          >
                            <option></option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </Col>
                        <Col>
                          <Label>Region</Label>
                          <Input type="text" placeholder="Dubai" />
                        </Col>
                        <Col>
                          <Label>From</Label>
                          <Input type="date" placeholder="From"></Input>
                        </Col>
                        <Col>
                          <Label>To</Label>
                          <Input type="date" placeholder="To"></Input>
                        </Col>
                        <Col>
                          <Label>Slot Type</Label>
                          <Input
                            type="select"
                            placeholder="All"
                            className="form-select"
                          >
                            <option>Period</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </Col>
                        <Col>
                          <Label>Content Type</Label>
                          <Input type="text" placeholder="Food, Clothing..." />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label>Dimensions</Label>
                          <Input type="text" placeholder="a x b x c x d" />
                        </Col>
                        <Col>
                          <Label>Price min</Label>
                          <Input type="text" placeholder="min" />
                        </Col>
                        <Col>
                          <Label>Price Max</Label>
                          <Input type="text" placeholder="max" />
                        </Col>
                        <Col>
                          <Label>w (Kilogram)</Label>
                          <Input type="text" />
                        </Col>
                        <Col>
                          <Label>d (Meter)</Label>
                          <Input type="text" />
                        </Col>
                  </Row>
                  <Card>


                  </Card>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row>
          <Card>
            <Row>
              <Col className="col-11">
                <CardTitle>Popular Slots</CardTitle>
              </Col>
              <Col>
                <Button>Sort</Button>
              </Col>
            </Row>
            <Row>
              {data.map(data => (
                <Col>
                  <Card style={styles.cardOne} key={data.id}>
                    <Row>
                      <CardImg src={img1} className="p-0" />
                      <Row style={styles.cardRowOne}>
                        <Col className="col-10">
                          <CardText>
                            {data.mallName}
                            <br />
                            {data.mallNameSub}
                          </CardText>
                        </Col>
                        <Col style={styles.cardOne.checkBox}>
                          <Input type="checkbox" />
                        </Col>
                      </Row>
                    </Row>
                    <Row
                      id="cardDescRow"
                      className="p-2"
                      style={styles.cardDescRow}
                    >
                      <Row>
                        <Col>
                          <CardText>
                            <CardImg
                              src={imgHook}
                              style={styles.cardDescRow.imgHookStyle}
                            />
                            {data.cardDescription.hookName}
                          </CardText>
                        </Col>
                        <Col>
                          <CardText>{data.cardDescription.zoneName}</CardText>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-8">
                          <CardText>
                            <i className="fa fa-ruler" />{" "}
                            {data.cardDescription.dimensions.length}ft (l) x{" "}
                            {data.cardDescription.dimensions.height}ft z(h)
                          </CardText>
                        </Col>
                        <Col>
                          <CardText>
                            <i className="fa fa-weight" /> {">"}
                            {data.cardDescription.weight}kg
                          </CardText>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Row>
      </Container>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
    statusTd: {
      color: "black",
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  wizardIconRow: {
    justifyContent: "right",
    wizardIcon: {
      marginTop: -50,
      borderRadius: "100%",
      width: 70,
      height: 70,
      backgroundColor: "orange",
    },
  },
  cardOne: {
    width: 250,
    height: 200,
    checkBox: {
      marginTop: 20,
    },
  },
  cardRowOne: {
    marginTop: -50,
    backgroundColor: "#0000003a",
    padding: 0,
    marginLeft: 0,
    color: "white",
  },
  cardDescRow: {
    backgroundColor: "lightgrey",
    imgHookStyle: {
      height: 17,
      width: 17,
    },
  },
  bookNowViewBtns: {
    padding: 5,
    margin: 2,
    borderRadius: 2,
    backgroundColor: "#f0ebeb",
  },
}

export default AdGridViewDigital
