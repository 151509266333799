import React from "react"

import { CardText, Col, Container, Row } from "reactstrap"

const EventsReportsDashboard = props => {
  return (
    <React.Fragment>
      <Container className="page-content">
        <Row>
          <Col className="menu-title">
            <CardText>Hello</CardText>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default EventsReportsDashboard
