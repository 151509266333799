import React, { useState } from "react"
import { useEffect } from "react"
import { axiosApi } from "helpers/api_helper"
import { Row, CardBody, Modal, ModalHeader, Button } from "reactstrap"

const EventsSlotsDelete = props => {
  const [apiRes, setApiRes] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  function onDeleteClick(data) {
    axiosApi
      .delete("/event-organizer/delete-event-slot", {
        data: { eventId: data.eventId, slotId: data.slotId },
      })
      .then(response => {
        setApiRes(response)
        window.location.reload()
      })
      .catch(error => {
        console.error("Error deleting event slot:", error)
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message
          setErrorMessage(errorMessage)
        }
      })

    setShowModal(true)
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  useEffect(() => {}, [apiRes])

  return (
    <Row>
      <span
        className="fa fa-trash mt-2"
        onClick={() => {
          tog_center()
          onDeleteClick({
            eventId: props.data.eventId,
            slotId: props.data.slotId,
          })
        }}
      ></span>

      <CardBody style={{ display: "none" }}>
        <Row>
          <Modal isOpen={modal_center} toggle={tog_center} centered={true}>
            <ModalHeader style={{ textAlign: "center", alignSelf: "center" }}>
              {errorMessage || "Success"}
            </ModalHeader>
            <Button
              className="bg-primary mb-2"
              style={{ width: "fit-content", alignSelf: "center" }}
              onClick={tog_center}
            >
              OK
            </Button>
          </Modal>
        </Row>
      </CardBody>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default EventsSlotsDelete
