import React from "react"
import { Row } from "reactstrap"
import EventsDetailCard from "./Cards/EventsDetailCard"

const EventsDetails = props => {
  return (
    <Row>
      <EventsDetailCard />
    </Row>
  )
}

export default EventsDetailCard
