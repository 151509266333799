import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

const AdAddDepartments = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card>
          <Form>
            <CardTitle>Add Department</CardTitle>
            <Row>
              <Col className="col-4">
                <Label>Department Name</Label>
                <Input
                  type="text"
                  name="name"
                  required
                />
                <Label>Date</Label>
                <Input type="text" />
                <Label>Description</Label>
                <Input type="textarea" />
              </Col>
            </Row>
            <Row>
              <Col className="col-10"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
                <Link to="./">
                  <Button color="primary">Add</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default AdAddDepartments