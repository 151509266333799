import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  FormGroup,
  InputGroup,
  Label,
} from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const UiModal = props => {
  const [modal_center, setmodal_center] = useState(true)

  useEffect(() => {
    setmodal_center(props.isShow)
    console.log("props.isShow", props.isShow)
  }, [props.rowIndex])


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    props.onModalToggle(!modal_center)
    removeBodyCss()
  }



  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">
                      Select Maintenance Period
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        tog_center()
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <FormAdvanced /> */}
                    <FormGroup className="mb-4">
                      <Label>Date Range</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          onChange={selectedDates => {
                            if (selectedDates[1]) {
                              const startDate = selectedDates[0]
                                .toISOString()
                                .slice(0, 10)
                              const endDate = selectedDates[1]
                                .toISOString()
                                .slice(0, 10)
                              // console.log(startDate, endDate)
                              props.onData([startDate, endDate])
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  {/* <Row>
                    <Col style={styles.modalBtnStyle}>
                      <Button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row> */}
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
