import { useState } from "react"
import FullCalendar from "@fullcalendar/react"
import daygridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import { Container, Modal, Button, Form } from "react-bootstrap"
import { v4 as uuid } from "uuid"
import { Col, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { axiosApi } from "helpers/api_helper"
import { useEffect } from "react"
import { useRef } from "react"

export const EventsReservationsDashboard = () => {
  const [events, setEvents] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [eventName, setEventName] = useState("")
  const [bookingFrom, setBookingFrom] = useState("")
  const [bookingTo, setBookingTo] = useState()
  const [selectedInfo, setSelectedInfo] = useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const [basic, setbasic] = useState(false)
  const [abasic, setAbasic] = useState(false)

  const handleCheckboxChange = event => {
    const selectedDay = event.target.name
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedDays([...selectedDays, selectedDay])
    } else {
      setSelectedDays(selectedDays.filter(day => day !== selectedDay))
    }
  }

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]
  const params = new URLSearchParams(window.location.search)
  const id = params.get("id")
  const faceValue = params.get("faceValue")
  console.log("id cal", id) // will log '1234' in the console
  console.log("facevalue cal", faceValue)

  const handleSelect = info => {
    // Check if the selected date range overlaps with any existing events
    const overlap = events.some(event => {
      const eventStart = new Date(event.start)
      const eventEnd = new Date(event.end)
      const selectedStart = new Date(info.startStr)
      const selectedEnd = new Date(info.endStr)
      return (
        (selectedStart >= eventStart && selectedStart < eventEnd) ||
        (selectedEnd > eventStart && selectedEnd <= eventEnd) ||
        (eventStart >= selectedStart && eventStart < selectedEnd) ||
        (eventEnd > selectedStart && eventEnd <= selectedEnd)
      )
    })

    if (overlap) {
      setbasic(true)
      // alert("This date range has already been selected.")
    } else {
      // Proceed with selecting the date range
      setBookingFrom(info.startStr)
      setBookingTo(info.endStr)
      setSelectedInfo(info)
      setShowModal(true)
    }
  }

  console.log("selectedDays", selectedDays)
  const handleSubmit = () => {
    if (eventName && selectedInfo) {
      const categoryColor = document.getElementById("category").value
      const eventDate = selectedDays
      const dayOfWeek = [eventDate]
      const eventTitle = `${dayOfWeek} - ${eventName}`

      let dateStr = selectedInfo.endStr
      let date = new Date(dateStr)
      date.setDate(date.getDate() + 1) // increase the day by 1
      dateStr = date.toISOString().slice(0, 10) // convert back to string format
      console.log("dataStr", dateStr) // "2023-04-28"

      axiosApi
        .post("/common/set-special-price", postData)
        .then(res => {
          if (res.status === 200) {
            location.reload()
            console.log("res33", res)
          }
        })
        .catch(err => {
          console.log("error post", err)
        })
      setEvents([
        ...events,
        {
          start: selectedInfo.startStr,
          end: dateStr,
          title: eventTitle,
          name: eventName,
          color: categoryColor,
          days: selectedDays,
          id: uuid(),
        },
      ])
      setEventName("")
      setSelectedInfo(null)
      setShowModal(false)
    }
  }

  const handleRemove = id => {
    const updatedEvents = events.filter(event => event.id !== id)
    setEvents(updatedEvents)
  }

  const handleBookingToChange = e => {
    const toDate = e.target.value
    if (toDate > bookingFrom) {
      setBookingTo(toDate)
      setSelectedInfo({
        ...selectedInfo,
        endStr: toDate,
      })
    } else {
      setAbasic(true)
      // alert("Select a higher bookingToDate")
    }
  }
  const postData = {
    id: id,
    value: eventName,
    fromDate: selectedInfo ? selectedInfo.startStr : null,
    toDate: selectedInfo ? selectedInfo.endStr : null,
    monday: selectedDays.monday,
    tuesday: selectedDays.thursday,
    wednesday: selectedDays.wednesday,
    thursday: selectedDays.thursday,
    friday: selectedDays.friday,
    saturday: selectedDays.saturday,
    sunday: selectedDays.sunday,
  }
  console.log("apiRes vdf=>", postData)
  const [apiRes, setApiRes] = useState()
  const [dataRow, setDataRow] = useState()
  const [currentMonth, setCurrentMonth] = useState(null)
  const [currentYear, setCurrentYear] = useState(null)
  const calendarRef = useRef(null)

  useEffect(() => {
    axiosApi
      .get("/common/pricing-calendar-view", {
        params: {
          id: id,
          month: currentMonth,
          year: currentYear,
        },
      })
      .then(res => {
        setApiRes(res)
        console.log("apiRes =>", res)
      })
  }, [currentMonth, currentYear])
  console.log("currentMonthf=>", currentMonth)
  console.log("currentYearf=>", currentYear)
  useEffect(() => {
    if (calendarRef.current) {
      const currentDate = calendarRef.current.getApi().getDate()
      if (currentDate) {
        setCurrentMonth(currentDate.getMonth() + 1)
        setCurrentYear(currentDate.getFullYear())
      }
    }
  }, [calendarRef])

  const handleViewChange = () => {
    if (calendarRef.current) {
      const currentDate = calendarRef.current.getApi().getDate()
      if (currentDate) {
        setCurrentMonth(currentDate.getMonth() + 1)
        setCurrentYear(currentDate.getFullYear())
      }
    }
  }

  console.log("apiRes vdf=>", apiRes)

  return (
    <div sm={2} md={3} lg={3}>
      <Container className="page-content">
        <FullCalendar
          ref={calendarRef}
          // viewDidMount={handleViewChange}

          selectable
          events={[
            ...events,
            ...(apiRes
              ? apiRes.data.data.map(item => ({
                  title: item.price.toString(), // Convert the price to a string
                  start: item.date,
                }))
              : []),
          ]}
          dataRow={dataRow}
          select={handleSelect}
          headerToolbar={{
            start: "today prev next",
            center: "title",
            end: "dayGridMonth dayGridWeek dayGridDay",
          }}
          plugins={[daygridPlugin, interactionPlugin]}
          views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
          datesSet={handleViewChange}
        />
        {events.map(event => (
          <div key={event.id}>
            <span>{event.title}</span>
            <button onClick={() => handleRemove(event.id)}>Remove</button>
          </div>
        ))}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          style={{ marginTop: "80px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="bookingFrom">Booking From:</label>
            <input
              disabled
              type="date"
              id="bookingFrom"
              name="bookingFrom"
              value={bookingFrom}
            />
            {console.log("endStr:", selectedInfo ? selectedInfo.endStr : null)}
            {console.log("selectedInfo:", selectedInfo)}
            <label htmlFor="bookingTo">Booking To:</label>
            <input
              type="date"
              id="bookingTo"
              name="bookingTo"
              value={bookingTo}
              onChange={handleBookingToChange}
            />
            <br></br>
            Enter Amount
            <Form.Control
              type="number"
              value={eventName}
              onChange={e => setEventName(e.target.value)}
            />
            <br></br>
            {daysOfWeek.map(day => (
              <label key={day}>
                <input
                  type="checkbox"
                  name={day}
                  checked={selectedDays.includes(day)}
                  onChange={handleCheckboxChange}
                />
                {day}
              </label>
            ))}
            <p>Selected days: {selectedDays.join(", ")}</p>
            <Input
              type="select"
              name="category"
              id="category"
              label="Select Category"
              validate={{
                required: { value: true },
              }}
              value={event ? event.category : "bg-primary"}
            >
              <option value={"red"}>Danger</option>
              <option value={"green"}>Success</option>
              <option value={"blue"}>Primary</option>
              <option value={"info"}>Info</option>
              <option value={"black"}>Dark</option>
              <option value={"yellow"}>Warning</option>
            </Input>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title="This date range has already been selected."
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {abasic ? (
          <SweetAlert
            title="Select a higher bookingToDate."
            onConfirm={() => {
              setAbasic(false)
            }}
          />
        ) : null}
      </Col>
    </div>
  )
}

export default EventsReservationsDashboard
