import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardImg,
  Progress,
  Row,
  Col,
  Container,
  CardText,
} from "reactstrap"

import img5 from "../../assets/images/FloorPlanEditor.png"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ImageOverlay,
  FeatureGroup,
} from "react-leaflet"

import "leaflet/dist/leaflet.css"
import L from "leaflet"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "bootstrap/dist/css/bootstrap.min.css"

import { EditControl } from "react-leaflet-draw"

import imagePath from "../../assets/images/floorPlan1.png"
import imagePath2 from "../../assets/images/mallplan2.png"
import imagePath3 from "../../assets/images/mallplan3.png"
import imagePath4 from "../../assets/images/mallplan4.png"
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'

//component
import LeafletEditor from "./LeafletEditor"

const buttonData = [
  {
    levelName: "01",
    floorPlanImg: "",
  }
]

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

class FloorPlanEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="page-content">
        <Row className="pt-3 pb-5">
          <MultiLevelProgressBar /*levels={['Step 1', 'Step 2', 'Step 3']}*/ />
        </Row>
        <LeafletEditor/>
      </div>
    )
  }
}

const styles = {
  buttonStyle: {
    // width: 100,
    // marginBottom: -10,
    color: "white",
    backgroundColor: "#859bad",
    dark: {
      backgroundColor: "#464647",
      color: "white",
    },
  },
  roundBtn: {
    // width: "10%",
    // marginTop: '5%'
  },
  btnSpan: {
    marginTop: "50px",
  },
  containerStyle: {
    marginLeft: "-10%",
    zIndex: -1,
    maxWidth: "100%",
  },
  colStyle: {
    display: "flex",
  },
  mapIconBtn: {
    float: "right",
    marginTop: "-70px",
    position: "relative",
    marginRight: "50px",
  },
  addZoneBtn: {
    position: "relative",
    float: "right",
    marginTop: "-35%",
  },
}
export default FloorPlanEditor
