import React from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  CardImg,
  Button,

} from "reactstrap"

import Editable from "react-bootstrap-editable"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Img
import mallimg from "../../assets/images/adminPropertyViewMallImgpng.png"

const AdminPropertyDetailsView = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Property Details</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardTitle>Property Details</CardTitle>
                <CardBody>
                  <div className="table-responsive">
                    <Table responsive striped className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td>Mall Name</td>
                          <td>
                            <Editable
                              alwaysEditing={false}
                              disabled={false}
                              editText="Lulu Mall"
                              id={null}
                              isValueClickable={false}
                              mode="inline"
                              placement="top"
                              showText
                              type="textfield"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Annual Footfall</td>
                          <td>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="1,60,000"
                              id={null}
                              isValueClickable={false}
                              label={null}
                              mode="inline"
                              onSubmit={null}
                              onValidated={null}
                              placement="top"
                              showText
                              type="textfield"
                              validate={null}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Mall Nickname</td>
                          <td>
                            <Editable
                              alwaysEditing={false}
                              disabled={false}
                              editText="Lulu Mall"
                              id={null}
                              isValueClickable={false}
                              mode="inline"
                              placement="top"
                              showText
                              type="textfield"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Content Type Excluded</td>
                          <td>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Alcohol, Cigerrete, Nudity"
                              id={null}
                              isValueClickable={false}
                              label={null}
                              mode="inline"
                              onSubmit={null}
                              onValidated={null}
                              options={["Alcohol", "Cigerrete", "Nudity"]}
                              placement="top"
                              showText
                              type="select"
                              validate={null}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
                              id={null}
                              isValueClickable={false}
                              label={null}
                              mode="inline"
                              onSubmit={null}
                              onValidated={null}
                              placement="top"
                              showText
                              type="textarea"
                              validate={null}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>GPS Cordinates</td>
                          <td>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
                              id={null}
                              isValueClickable={false}
                              label={null}
                              mode="inline"
                              onSubmit={null}
                              onValidated={null}
                              placement="top"
                              showText
                              type="textarea"
                              validate={null}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <CardImg style={styles.cardImgStyle} src={mallimg} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Link to="./" style={styles.cancelBtn}>
            <Button color="danger">Cancel</Button>
          </Link>
        </Container>
      </div>
    </React.Fragment>
  )
}

const styles = {
    cardImgStyle: {
        width: 300, height: 200
    }, cancelBtn: {
        float: "right",
    },
}
export default AdminPropertyDetailsView
