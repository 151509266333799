import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardImg,
  Progress,
  Row,
  Col,
  Container,
  CardText,
} from "reactstrap"

import img5 from "../../assets/images/FloorPlanEditor.png"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ImageOverlay,
  FeatureGroup,
} from "react-leaflet"

import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "bootstrap/dist/css/bootstrap.min.css"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

console.log("DefaultIcon =>", DefaultIcon)

import { EditControl } from "react-leaflet-draw"

// import "leaflet-draw/dist/leaflet.draw.css"
import { leafletData } from "../Common/Data/LeafletData"

//components
import AddZoneModal from "./AddZoneModal"

const LeafletEditor = () => {
  const [levelId, setLevelId] = useState("0")
  const [imagePaths, setImagePaths] = useState(
    leafletData[levelId].floorPlanImg
  )
  const [addZone, setAddZone] = useState(false)

  // useEffect(()=>{
  //   setAddZone(true)
  // })
  // const _created = () => {
  //   // setAddZone(true)
  //   console.log("_created =>", _created)
  // }
  useEffect(() => {
    document.getElementsByClassName("leaflet-control")[1].style.display = "none"
  })

  var layer = '';
  const _created = (e) => {
    layer = e.layerType;
    if (layer == 'marker') {
      // setMarkerModal(true)
      // setMarkerModal((state) => {
      //   return state;
      // });
      setAddZone(true)
      document.getElementsByClassName("leaflet-control")[1].style.display = "block"
    }
    else if(layer == 'rectangle' || layer == 'polyline'){
      // setZoneModal(true)
      // setZoneModal((state) => {
      //   return state;
      // });
    }
   
    console.log("layer after=>",layer);
  }
  return (
    <Row>
      {addZone ? <AddZoneModal /> : null}
      <MapContainer
        style={{ height: "70vh", width: "100%", zIndex: "1" }}
        center={[0, 50]}
        zoom={1}
        scrollWheelZoom={true}
        minZoom={1}
        maxZoom={4}
        maxBoundsViscosity={0}
        bounceAtZoomLimits={true}
        boundsOptions={L.FitBoundsOptions}
      >
        {/* {useEffect(() => {

            })} */}
        <ImageOverlay
          key={imagePaths}
          url={imagePaths}
          bounds={[
            [-100, -400],
            [200.5, 500],
          ]}
        />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={_created}
            draw={{ polygon: true, circlemarker: true, circle: true, polyline: false, circlemarker: false }}
          />
        </FeatureGroup>

        {/* <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url={imagePath}
                  maxZoom={5}
                  minZoom={2}
                  bounds={[
                    [40.712, -74.227],
                    [40.774, -74.125]
                  ]}
                /> */}
        {/* <Marker position={[24.194, 53.718]}>
                  <Popup>
                    <CardText>UAE</CardText>
                  </Popup>
                </Marker> */}
      </MapContainer>
      <span
        style={{
          zIndex: 999,
          position: "absolute",
          width: "min-content",
          marginLeft: 40,
          marginTop: 40,
        }}
      >
        <Link to="./floorPlans">
          <Button color="primary" style={{ width: "110px" }}>
            Select Level
          </Button>
        </Link>
        {leafletData.map(btn => (
          <Button className="mt-2" key={btn.levelID} onClick={ () => setImagePaths(leafletData[btn.levelID].floorPlanImg)}>Level {btn.levelName}</Button>
        ))}
      </span>
      <span
        style={{
          zIndex: 999,
          position: "absolute",
          width: "min-content",
          marginLeft: "73%",
          marginTop: 40,
        }}
      >
        <Button
          color="primary"
          style={{ width: "110px", marginLeft: -50 }}
          onClick={() => {
            document.getElementsByClassName(
              "leaflet-control"
            )[1].style.display = "block"
            console.log(
              "EditControl =>",
              document.getElementsByClassName("leaflet-control")
            )
          }}
        >
          Add Zone <span className="fa fa-plus"></span>
        </Button>

        {addZone
          ? leafletData.map(btn => (
              <Button className="mt-2">Zone {btn.levelName}</Button>
            ))
          : null}
      </span>
    </Row>
  )
}

export default LeafletEditor
