import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardImg,
  CardBody,
} from "reactstrap"

//data
import { data } from "../Common/Data/AdGridViewData"

//img
import img1 from "../../assets/images/gridViewImg1.png"
import imgHook from "../../assets/images/hook.png"

//component
import AdGridDrawer from "./AdGridDrawer"

export const AdBookingGridDetail = () => {
  return (
    <Container className="page-content" fluid>
      <AdGridDrawer/>
    </Container>
  )
}

const styles = {
    Container: {
      paddingLeft: 50,
      paddingRight: 50,
      maxWidth: "100%",
      statusTd: {
        color: "black",
      },
    },
    tagBtnStyle: {
      color: "white",
      backgroundColor: "#0066ff",
      borderRadius: 7,
      textAlign: "center",
      padding: 2,
    },
    wizardIconRow: {
      justifyContent: "right",
      wizardIcon: {
        marginTop: -50,
        borderRadius: "100%",
        width: 70,
        height: 70,
        backgroundColor: "orange",
      },
    },
    cardOne: {
      width: 250,
      height: 200,
      checkBox: {
        marginTop: 20,
      },
    },
    cardRowOne: {
      marginTop: -50,
      backgroundColor: "#0000003a",
      padding: 0,
      marginLeft: 0,
      color: "white",
    },
    cardDescRow: {
      backgroundColor: "lightgrey",
      imgHookStyle: {
        height: 17,
        width: 17,
      },
    },
    bookNowViewBtns: {
      padding: 5,
      margin: 2,
      borderRadius: 2,
      backgroundColor: "#f0ebeb",
    },
  }
