import React from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Col,
  Row,
  Card,
  CardTitle,
} from "reactstrap"

const PropertyAddForm = props => {
  return (
    <Container className="page-content">
       <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h1 className="page-title">Add New Property</h1>
              </Col>
            </Row>
          </div>
      <Container style={{ width: "99%", marginTop: 10 }}>
        <Card style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Row>
            <Col className="col-6 my-sm-4">
              <Form>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Building Name</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="The Dubai Mall"
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Building Nickname</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Dubai Mall"
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleText">Address</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="exampleText"
                    placeholder="Financial Center Street, Along Sheikh Zayed Road, Next to Burj Khalifa - Dubai - United Arab Emirates"
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleSelect">Content Type Excluded</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    className="form-select"
                  >
                    <option>Alcohol</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Annual Footfall</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="with a placeholder"
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleSelect">Assigned To</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    className="form-select"
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="gps">StreetView Link</Label>
                  <Label for="gps"></Label>
                  <Input placeholder="https://bit.ly/3cqscv8"></Input>
                </FormGroup>
              </Form>
            </Col>
            <Col className="col-6 my-sm-4">
              <FormGroup style={styles.formGroup}>
                <Label for="gps">GPS Coordinates</Label><br/>
                <Label for="gps">Latitude</Label>
                <Input placeholder="40.741895" className="mb-3"></Input>
                <Label for="gps">Longitude</Label>
                <Input placeholder="-73.989308"></Input>
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="exampleFile">File</Label>
                <Input type="file" name="file" id="exampleFile" />
                <FormText color="muted">
                 <Row>Please choose an image for the property to be added</Row> 
                </FormText>
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="req-doc">Required Docs</Label><br/>
                <Label for="req-doc" className="me-1">Police Permit</Label>
                <Input type="checkbox" className="mb-3 me-3"/>
                <Label for="req-doc" className="me-1">Media Permit</Label>
                <Input type="checkbox" className="mb-3 me-3"/>
                <Label for="req-doc" className="me-1">Content Permit</Label>
                <Input type="checkbox" className="mb-3 me-3"/>
                <Label for="req-doc" className="me-1">RTA Approval</Label>
                <Input type="checkbox" className="mb-3 me-3"/>
                <Label for="req-doc" className="me-1">XYZ Authority Approval</Label>
                <Input type="checkbox" className="mb-3 me-3"/>
              </FormGroup>
            </Col>
            <Row>
              <Col
                style={{ display: "inline-flex", flexDirection: "row-reverse" }}
              >
                <Link to="../properties">
                  <Button
                    color="danger"
                    style={{ marginBottom: 10, marginLeft: 5 }}
                  >
                    Cancel
                  </Button>
                </Link>
                <Link to="../floorPlans">
                  <Button color="primary" style={{ marginBottom: 10 }}>
                    Save
                  </Button>
                </Link>
              </Col>
            </Row>
          </Row>
        </Card>
      </Container>
    </Container>
  )
}
const styles = {
  formGroup: {
    paddingBottom: 15,
  }
}
export default PropertyAddForm
