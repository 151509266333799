import React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import "./progress.css"

let steps = [
  "Booking Requested",
  "Request Accepted",
  "Payment Honoured",
  "Documents Submitted",
  "Installation",
  "Payment Transferred",
]

export default function HorizontalLinearStepper(prop) {
  const [activeStep, setActiveStep] = React.useState(1)
  const [skipped, setSkipped] = React.useState(new Set())

  if (prop.levels) {
    steps = prop.levels
  }

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#eb975c", // Replace with your desired color for the Stepper
      },
    },
  })


  const isStepSkipped = step => {
    return skipped.has(step)
  }

  // ... rest of the component code ...

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <Stepper activeStep={activeStep} sx={{ color: "white" }}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            // Customize the label color
            labelProps.style = {
              color: "white", // Change label text color to white
              fontWeight: "bold", // Add additional styles if needed
            }

            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  StepIconProps={{
                    // Customize the StepIcon component
                    classes: {
                      root: "custom-icon-root", // Add a custom class for styling
                      active: "custom-icon-active", // Customize active circle
                    },
                  }}
                >
                  <span style={{ color: "white" }}>{label}</span>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    </ThemeProvider>
  )
}
