import React from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Table,
  CardImg,
} from "reactstrap"
import classnames from "classnames"

import img1 from "../../assets/images/adAgency/bookingDetailImg1.png"
import img2 from "../../assets/images/adAgency/bookingDetailImg2.png"

export default class AdBookingDetails extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <div className="page-content">
        <Row>
          <Card>
            <Row>
              <Col>
                <CardTitle>Booking ID</CardTitle>
                <CardText>Indoor</CardText>
              </Col>
              <Col>
                <CardTitle>Date</CardTitle>
                <CardText>01 Mar 2022</CardText>
              </Col>
              <Col>
                <CardTitle>Collateral Type</CardTitle>
                <CardText>Ceiling, Wall, Floor</CardText>
              </Col>
              <Col>
                <CardTitle>Content Type</CardTitle>
                <CardText>Food & Beverages</CardText>
              </Col>
              <Col>
                <CardTitle>No Of Slots</CardTitle>
                <CardText>13</CardText>
              </Col>
              <Col>
                <CardTitle>Price</CardTitle>
                <CardText>53,445 AED</CardText>
              </Col>
              <Col>
                <CardTitle>Status</CardTitle>
                <CardText style={{ color: "blue" }}>Pending Approval</CardText>
              </Col>
            </Row>
          </Card>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1")
              }}
            >
              By Group
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2")
              }}
            >
              By Properties
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <CardTitle>Group 01 08 Slots</CardTitle>
              <Table striped>
                <thead>
                  <tr>
                    <th>Images</th>
                    <th>Property Name</th>
                    <th>Slot Name</th>
                    <th>Period</th>
                    <th>No Of Days</th>
                    <th>Price Per Day</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CardImg src={img1} />
                    </td>
                    <td>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                    </td>
                    <td>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                    </td>
                    <td>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                    </td>
                    <td>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                    </td>
                    <td>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                    </td>
                    <td>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                    </td>
                    <td>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CardTitle>Group 01 08 Slots</CardTitle>
              <Table striped>
                <thead>
                  <tr>
                    <th>Images</th>
                    <th>Property Name</th>
                    <th>Slot Name</th>
                    <th>Period</th>
                    <th>No Of Days</th>
                    <th>Price Per Day</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CardImg src={img2} />
                    </td>
                    <td>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                      <tr>Dalma Mall</tr>
                      <tr>Lulu Mall</tr>
                    </td>
                    <td>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                    </td>
                    <td>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                    </td>
                    <td>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                    </td>
                    <td>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                    </td>
                    <td>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                    </td>
                    <td>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <CardTitle>Dalma Mall 01 08 Slots</CardTitle>
              <Table striped>
                <thead>
                  <tr>
                    <th>Images</th>
                    <th>Property Name</th>
                    <th>Slot Name</th>
                    <th>Period</th>
                    <th>No Of Days</th>
                    <th>Price Per Day</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CardImg src={img1} />
                    </td>
                    <td>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                    </td>
                    <td>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                    </td>
                    <td>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                    </td>
                    <td>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                    </td>
                    <td>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                    </td>
                    <td>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                    </td>
                    <td>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CardTitle>Dalma Mall 01 08 Slots</CardTitle>
              <Table striped>
                <thead>
                  <tr>
                    <th>Images</th>
                    <th>Property Name</th>
                    <th>Slot Name</th>
                    <th>Period</th>
                    <th>No Of Days</th>
                    <th>Price Per Day</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CardImg src={img2} />
                    </td>
                    <td>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                      <tr>Group 01</tr>
                      <tr>Group 02</tr>
                    </td>
                    <td>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                      <tr>Slot 23</tr>
                      <tr>Slot 33</tr>
                    </td>
                    <td>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                      <tr>01 Mar 2022 - 08 Mar 2022</tr>
                    </td>
                    <td>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                      <tr>08 Days</tr>
                    </td>
                    <td>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                      <tr>499 AED/Day</tr>
                    </td>
                    <td>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                      <tr>4000 AED</tr>
                    </td>
                    <td>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                      <tr>
                        Approved
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}
