import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
  Card,
  Tooltip,
  CardText,
  Spinner,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//components
import "./styles.scss"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// Form Editor
import { EditorState, convertToRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const EventEditForm = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // Function to handle changes in the editor state
  const handleEditorChange = newEditorState => {
    setEditorState(newEditorState)
  }

  const [apiRes, setApiRes] = useState()
  const [i, setI] = useState("")
  const [id, setId] = useState("")
  const [eventName, setEventName] = useState("")
  const [footFall, setFootFall] = useState("")
  const [address, setAddress] = useState("")
  const [features, setFeatures] = useState("")
  const [eventFrom, setEventFrom] = useState("")
  const [eventTo, setEventTo] = useState("")
  const [adExcluded, setAdExcluded] = useState("")
  const [gpsLat, setGpsLat] = useState("")
  const [gpsLon, setGpsLon] = useState("")
  const [images, setImages] = useState([])
  const [banner, setBanner] = useState([])
  const [logo, setLogo] = useState([])
  const [footer, setFooter] = useState([])
  const [deletedImages, setDeletedImages] = useState([])
  const [contractualAgreement, setContractualAgreement] = useState("")
  const [description, setDescription] = useState("")
  const [webLink, setWebLink] = useState("")
  const [isPublic, setIsPublic] = useState()
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    instagram: '',
    facebook: '',
    twitter: ''
  });
  useEffect(() => {
    const queryString = window.location.search
    setI(queryString.slice(1))
  })

  useEffect(() => {
    axiosApi.get("/event-organizer/events").then(res => {
      setApiRes(res)
    })
  }, [])

  useEffect(() => {
    if (apiRes) {
      const filteredData = apiRes.data.data.filter(datas => datas.id == i)
      setId(filteredData[0].id)
      setEventName(filteredData[0].eventName)
      setDescription(filteredData[0].description)
      setFootFall(filteredData[0].footFall)
      setAddress(filteredData[0].address)
      setFeatures(filteredData[0].features.map(feature => feature))
      setTags([...filteredData[0].features.map(feature => feature)])
      setEventFrom(filteredData[0].eventFrom)
      setEventTo(filteredData[0].eventTo)
      setAdExcluded(filteredData[0].adExcluded)
      setTags2([...filteredData[0].adExcluded.map(adExclud => adExclud)])
      setGpsLat(filteredData[0].gpsCoordinate.lat)
      setGpsLon(filteredData[0].gpsCoordinate.lon)
      setContractualAgreement(filteredData[0].contractualAgreement)
      setWebLink(filteredData[0].webLink)
      setIsPublic(filteredData[0].isPublic)
      setDeletedImages("[]")
      setSocialMediaLinks(JSON.parse(filteredData[0].socialMediaLinks));
      
    }
  }, [apiRes])


  function handleFile(event) {
    setImages(event.target.files)
  }
  function handleFile1(event) {
    setBanner(event.target.files)
  }
  function handleFile2(event) {
    setLogo(event.target.files)
  }
  function handleFile3(event) {
    setFooter(event.target.files)
  }
  const [editorContent, setEditorContent] = useState(null)
  let handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()

    formData.append("eventName", eventName)
    formData.append("description", description)
    formData.append("webLink", webLink)
    formData.append("footFall", footFall)
    formData.append("address", address)
    formData.append("features", tags)
    formData.append("eventFrom", eventFrom)
    formData.append("eventTo", eventTo)
    formData.append("adExcluded", tags2)
    formData.append("gpsCoordinate", [gpsLat, gpsLon])
    formData.append("images", images[0])
    formData.append("banner", banner[0])
    formData.append("logo", logo[0])
    formData.append("footer", footer[0])
    formData.append("id", id)
    formData.append("deletedImages", deletedImages)
    formData.append("isPublic", isPublic)
    formData.append("contractualAgreement", JSON.stringify(editorState))
    formData.append("socialMediaLinks", JSON.stringify(socialMediaLinks));
    axiosApi
      .post("/event-organizer/edit-event", formData)
      .then(response => {
        window.location.href = "/events/events"
      })
      .catch(error => {
        console.error(error)
      })
  }
  if (editorContent != null) {
  }
  // Tooltip Open state
  const [eventNameOpen, seteventNameOpen] = React.useState(false)
  const [footFallOpen, setFootFallOpen] = React.useState(false)
  const [addressOpen, setAddressOpen] = React.useState(false)
  const [featuresOpen, setFeaturesOpen] = React.useState(false)
  const [fromOpen, setFromOpen] = React.useState(false)
  const [toOpen, setToOpen] = React.useState(false)
  const [fileOpen, setFileOpen] = React.useState(false)
  const [contentTypeExcOpen, setContentTypeExcOpen] = React.useState(false)
  const [gpsOpen, setGPSOpen] = React.useState(false)
  const [bannerOpen, setBannerOpen] = React.useState(false)
  const [logoOpen, setLogoOpen] = React.useState(false)
  const [footerOpen, setFooterOpen] = React.useState(false)
  const [contractOpen, setContractOpen] = React.useState(false)
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)
  const [webLinkOpen, setWebLinkOpen] = React.useState(false)
  const selectedTags = tags => setFeatures(tags)
  const selectedTags2 = tags2 => setAdExcluded(tags2)

  //Tags Component1
  const [tags, setTags] = React.useState([])
  const addTags = event => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value])
      event.target.value = ""
    }
  }
  const removeTags = index => {
    setTags([...tags.filter(tag => tags.indexOf(tag) !== index)])
  }

  //Tags Component2
  const [tags2, setTags2] = React.useState([])
  const addTags2 = event => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags2([...tags2, event.target.value])
      // props.selectedTags2([...tags2, event.target.value])
      event.target.value = ""
    }
  }
  const removeTags2 = index => {
    setTags2([...tags2.filter(tag => tags2.indexOf(tag) !== index)])
  }
  if (apiRes) {
    return (
      <div className="page-content">
        <Card className="shadow-sm mt-3">
          <CardText className="fs-3 ms-5"> Edit Event</CardText>
        </Card>

        <Card
          className="shadow-sm pt-2"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Row sm={1} md={2} lg={2}>
              <Col>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Event Name</Label>
                  <span
                    id="TooltipExample4"
                    className="ms-1 fa fa-info-circle"
                  />
                  <Tooltip
                    isOpen={eventNameOpen}
                    placement="right"
                    target="TooltipExample4"
                    toggle={() => {
                      seteventNameOpen(!eventNameOpen)
                    }}
                  >
                    eg. abc
                  </Tooltip>
                  <Input
                    type="text"
                    value={eventName}
                    className="w-100"
                    id="exampleEmail"
                    onChange={e => setEventName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Description</Label>
                  <span
                    id="TooltipExample5"
                    className="ms-1 fa fa-info-circle"
                  />
                  <Tooltip
                    isOpen={descriptionOpen}
                    placement="right"
                    target="TooltipExample5"
                    toggle={() => {
                      setDescriptionOpen(!descriptionOpen)
                    }}
                  >
                    eg. Description about item
                  </Tooltip>
                  <Input
                    type="textarea"
                    className="w-100"
                    value={description}
                    id="DescriptionExample3"
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>
               
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleText">Address</Label>
                  <span
                    id="TooltipExample3"
                    className="ms-1 fa fa-info-circle"
                  />
                  <Tooltip
                    isOpen={addressOpen}
                    placement="right"
                    target="TooltipExample3"
                    toggle={() => {
                      setAddressOpen(!addressOpen)
                    }}
                  >
                    eg. Financial Center Street, Along Sheikh Zayed Road, Next
                    to Burj Khalifa - Dubai - United Arab Emirates
                  </Tooltip>
                  <Input
                    type="textarea"
                    className="w-100"
                    value={address}
                    id="exampleText"
                    onChange={e => setAddress(e.target.value)}
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">WebLink</Label>
                  <span
                    id="TooltipExample6"
                    className="ms-1 fa fa-info-circle"
                  />
                  <Tooltip
                    isOpen={webLinkOpen}
                    placement="right"
                    target="TooltipExample6"
                    toggle={() => {
                      setWebLinkOpen(!webLinkOpen)
                    }}
                  >
                    eg. www.viewitonme.com
                  </Tooltip>
                  <Input
                    type="text"
                    value={webLink}
                    id="exampleEmail"
                    onChange={e => setWebLink(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
              <Label for="socialMediaLinks">Social Media </Label>
              <span id="TooltipExample11" className="ms-1 fa fa-info-circle" />
          <Input
          className="mb-1"
        type="url"
        name="instagram"
        placeholder="Instagram URL"
        value={socialMediaLinks.instagram}
        onChange={e => setSocialMediaLinks({ ...socialMediaLinks, instagram: e.target.value })}
      />
      <Input
        className="mb-1"
        type="url"
        name="facebook"
        placeholder="Facebook URL"
        value={socialMediaLinks.facebook}
        onChange={e => setSocialMediaLinks({ ...socialMediaLinks, facebook: e.target.value })}
      />
      <Input
      className="mb-1"
        type="url"
        name="twitter"
        placeholder="Twitter URL"
        value={socialMediaLinks.twitter}
        onChange={e => setSocialMediaLinks({ ...socialMediaLinks, twitter: e.target.value })}
      />
       <Input
        type="url"
        name="linkedIn"
        placeholder="linkedIn URL"
        value={socialMediaLinks.linkedIn}
        onChange={e => setSocialMediaLinks({ ...socialMediaLinks, linkedIn: e.target.value })}
      />
          </FormGroup>
              </Col>
              <Row>
                <Col>
                  <FormGroup style={styles.formGroup}>
                    <Row>
                      <Col>
                        <Label>From</Label>
                        <span
                          id="TooltipFrom"
                          className="ms-1 fa fa-info-circle"
                        />
                        <Tooltip
                          isOpen={fromOpen}
                          placement="right"
                          target="TooltipFrom"
                          toggle={() => {
                            setFromOpen(!fromOpen)
                          }}
                        >
                          Event start date
                        </Tooltip>
                        <Input
                          type="date"
                          className="w-100"
                          value={eventFrom}
                          onChange={e => setEventFrom(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Label>To</Label>
                        <span
                          id="TooltipTo"
                          className="ms-1 fa fa-info-circle"
                        />
                        <Tooltip
                          isOpen={toOpen}
                          placement="right"
                          target="TooltipTo"
                          toggle={() => {
                            setToOpen(!toOpen)
                          }}
                        >
                          Event end date
                        </Tooltip>
                        <Input
                          type="date"
                          className="w-100"
                          value={eventTo}
                          onChange={e => setEventTo(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup style={styles.formGroup}>
                    <Label for="gps">GPS Coordinates</Label>
                    <span id="TooltipGPS" className="ms-1 fa fa-info-circle" />
                    <Tooltip
                      isOpen={gpsOpen}
                      placement="right"
                      target="TooltipGPS"
                      toggle={() => {
                        setGPSOpen(!gpsOpen)
                      }}
                    >
                      eg. lat 40.741895 lon -73.989308
                    </Tooltip>
                    <Row
                      sm={1}
                      md={2}
                      lg={2}
                      style={{ marginLeft: "auto" }}
                      className="justify-content-between"
                    >
                      <Input
                        value={gpsLat}
                        className="mb-3"
                        placeholder="lat"
                        onChange={e => setGpsLat(e.target.value)}
                        style={{ width: "45%" }}
                      />

                      <Input
                        value={gpsLon}
                        className="mb-3"
                        placeholder="lon"
                        onChange={e => setGpsLon(e.target.value)}
                        style={{ width: "45%" }}
                      />
                    </Row>
                  </FormGroup>
                  <FormGroup style={styles.formGroup}>
                    <Label for="exampleEmail">Show Public</Label>
                    <Input
                      type="select"
                      className="w-50"
                      value={isPublic ? "true" : "false"}
                      onChange={e => setIsPublic(e.target.value)}
                    >
                      <option>Select</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Input>
                  </FormGroup>
                  <FormGroup style={styles.formGroup}>
                  <Label for="exampleEmail">Footfall/Day</Label>
                  <span
                    id="TooltipExample2"
                    className="ms-1 fa fa-info-circle"
                  />
                  <Tooltip
                    isOpen={footFallOpen}
                    placement="right"
                    target="TooltipExample2"
                    toggle={() => {
                      setFootFallOpen(!footFallOpen)
                    }}
                  >
                    eg 100.000
                  </Tooltip>
                  <Input
                    type="text"
                    className="w-100"
                    value={footFall}
                    id="exampleEmail"
                    onChange={e => setFootFall(e.target.value)}
                  />
                </FormGroup>
                  <Col>
                    <FormGroup style={styles.formGroup} className="mt-3">
                      <Label for="req-doc">Content Type Excluded</Label>
                      <span
                        id="TooltipContentTypeExc"
                        className="ms-1 fa fa-info-circle"
                      />
                      <Tooltip
                        isOpen={contentTypeExcOpen}
                        placement="right"
                        target="TooltipContentTypeExc"
                        toggle={() => {
                          setContentTypeExcOpen(!contentTypeExcOpen)
                        }}
                      >
                        eg. Smoking, Drinking, Nudity etc...
                      </Tooltip>
                      <br />
                      {/* Tags Component2 */}
                      <Form action="javascript:void(0)">
                        <div className="tags-input">
                          <ul id="tags">
                            {tags2.map((tag, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTags2(index)}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            onKeyUp={event =>
                              event.key === "Enter" ? addTags2(event) : null
                            }
                            placeholder="Press enter to add tags"
                          />
                        </div>
                      </Form>
                    </FormGroup>
                    <FormGroup style={styles.formGroup}>
                      <Label for="req-doc">Features</Label>
                      <span
                        id="TooltipFeatures"
                        className="ms-1 fa fa-info-circle"
                      />
                      <Tooltip
                        isOpen={featuresOpen}
                        placement="right"
                        target="TooltipFeatures"
                        toggle={() => {
                          setFeaturesOpen(!featuresOpen)
                        }}
                      >
                        eg. Kid Friendly, Pet Friendly etc...
                      </Tooltip>
                      <br />
                      <br />
                      {/* Tags component */}
                      <Form action="javascript:void(0)">
                        <div className="tags-input">
                          <ul id="tags">
                            {tags.map((tag, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTags(index)}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            onKeyUp={event =>
                              event.key === "Enter" ? addTags(event) : null
                            }
                            placeholder="Press enter to add tags"
                          />
                        </div>
                      </Form>
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <br />
              <Row style={{ width: "100%" }} sm={1} md={4} lg={4}>
                <Col>
                  <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                    <Label for="exampleFile">Logo</Label>
                    <span id="TooltipLogo" className="ms-1 fa fa-info-circle" />
                    <Tooltip
                      isOpen={logoOpen}
                      placement="right"
                      target="TooltipLogo"
                      toggle={() => {
                        setLogoOpen(!logoOpen)
                      }}
                    ></Tooltip>

                    <Input type="file" name="images" onChange={handleFile2} />
                    <FormText color="muted">
                      <Row className="mt-2 px-2">
                        Please choose an image for the event to be added
                      </Row>
                    </FormText>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                    <Label for="exampleFile">Banner</Label>
                    <span
                      id="TooltipBanner"
                      className="ms-1 fa fa-info-circle"
                    />
                    <Tooltip
                      isOpen={bannerOpen}
                      placement="right"
                      target="TooltipBanner"
                      toggle={() => {
                        setBannerOpen(!bannerOpen)
                      }}
                    ></Tooltip>

                    <Input type="file" name="images" onChange={handleFile1} />
                    <FormText color="muted">
                      <Row className="mt-2 px-2">
                        Please choose an image for the event to be added
                      </Row>
                    </FormText>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                    <Label for="exampleFile">Footer</Label>
                    <span
                      id="TooltipFooter"
                      className="ms-1 fa fa-info-circle"
                    />
                    <Tooltip
                      isOpen={footerOpen}
                      placement="right"
                      target="TooltipFooter"
                      toggle={() => {
                        setFooterOpen(!footerOpen)
                      }}
                    ></Tooltip>

                    <Input type="file" name="images" onChange={handleFile3} />
                    <FormText color="muted">
                      <Row className="mt-2 px-2">
                        Please choose an image for the event to be added
                      </Row>
                    </FormText>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                    <Label for="exampleFile">Images</Label>
                    <span id="TooltipFile" className="ms-1 fa fa-info-circle" />
                    <Tooltip
                      isOpen={fileOpen}
                      placement="right"
                      target="TooltipFile"
                      toggle={() => {
                        setFileOpen(!fileOpen)
                      }}
                    ></Tooltip>

                    <Input
                      type="file"
                      multiple
                      name="images"
                      onChange={handleFile}
                    />
                    <FormText color="muted">
                      <Row className="mt-2 px-2">
                        Please choose an image for the event to be added
                      </Row>
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup style={{ ...styles.formGroup, width: "100%" }}>
                <Label for="exampleText">Contractual Agreement</Label>
                <span id="TooltipContract" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={contractOpen}
                  placement="right"
                  target="TooltipContract"
                  toggle={() => {
                    setContractOpen(!contractOpen)
                  }}
                >
                  eg. abc
                </Tooltip>
                <CKEditor
                  editor={ClassicEditor}
                  data={contractualAgreement.replace(/"/g, "")}
                  onReady={editor => {}}
                  onChange={(event, editor) => {
                    const newData = editor.getData()
                    // Perform any additional logic or actions with the newData
                    console.log({ event, editor, newData })
                    // Call your desired function passing the newData
                    handleEditorChange(newData)
                  }}
                />
              </FormGroup>
              <Row style={{ width: "100%" }}>
                <Col
                  style={{
                    display: "inline-flex",
                    justifyContent: "end",
                    marginBottom: "25px",
                  }}
                >
                  <Link to="/events/events">
                    <Button
                      color="danger"
                      style={{ marginBottom: 10, marginLeft: "20px" }}
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    color="primary"
                    style={{ marginBottom: 10, marginLeft: "20px", width: 80 }}
                    name="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Card>
      </div>
    )
  } else {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }
}
const styles = {
  formGroup: {
    paddingBottom: 15,
  },
}
export default EventEditForm
