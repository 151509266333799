import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const EventDetail = () => {
  const [events, setEvents] = useState([]);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const id = urlParts[urlParts.length - 1];

  useEffect(() => {
    fetch('http://localhost:4004/get-all-public-events')
      .then((response) => response.json())
      .then((data) => setEvents(data.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const selectedEvent = events.find((event) => event.id.toString() === id);

  console.log("selectedEvent", selectedEvent);

  // Configuration for the carousel
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <div>
      {selectedEvent ? (
        <>
          <Row>
            <Col style={{width:'40%'}}>
              <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000}>
                {selectedEvent.imageURLs.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', height:'500px', borderRadius:'15px' }} />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col>
              <h1>{selectedEvent.eventName}</h1>
              <p>&#128197; {selectedEvent.eventFrom} - {selectedEvent.eventTo}</p>
              <p><i className="fa fa-map-marker"></i> {selectedEvent.address}</p>
              <p><i className="fa fa-globe"></i> {selectedEvent.webLink}</p>
              <p>{selectedEvent.description}</p>
              <div className="d-flex align-items-center">
                <i className="fa fa-facebook mr-2"></i>
                <i className="fa fa-instagram mr-2"></i>
                <i className="fa fa-twitter mr-2"></i>
                <Button>Book Event</Button>
              </div>
            </Col>
          </Row>
          <Row className='m-2' style={{ backgroundColor: 'gray', height: '200px', borderRadius: '15px' }}>
            <h3>Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</h3>
            <Button className='m-2' style={{ width: '200px', height: '40px', borderRadius: '25px' }}>Know More</Button>
            <Button className='m-2' style={{ width: '200px', height: '40px', borderRadius: '25px' }}>Get Started</Button>
          </Row>
        </>
      ) : (
        <p>No event found with the given ID</p>
      )}
    </div>
  );
};

export default EventDetail;
