import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cardDataIndoor } from '../../propertyOwner/Common/Data/PropertyIndoorData';

class Doughnut extends Component {

    getOption = () => {
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            color: ['#6366F1', '#6366F1', '#6366F1'],
            series: [
                {
                    name: 'Collateral Type',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    data: [
                        { value: cardDataIndoor[0].ceiling, name: 'Ceiling' },
                        { value: cardDataIndoor[0].wall, name: 'Wall' },
                        { value: cardDataIndoor[0].floor, name: 'Floor' },
                    ],
                    itemStyle: {
                        borderRadius: 1,
                        borderColor: '#fff',
                        borderWidth: 5
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '70',
                            fontWeight: 'bold'
                        },
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    };

    // getOption = () => {
    //     return {

    //         toolbox: {
    //             show: false,
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: "{a} <br/>{b}: {c} ({d}%)"
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             x: 'left',
    //             data: ['Ceiling', 'Wall', 'Floor'],
    //         },
    //         color: ['#3c4ccf', '#3c4ccf', '#3c4ccf'],
    //         series: [
    //             {
    //                 name: 'Collateral Type',
    //                 type: 'pie',
    //                 radius: ['50%', '70%'],
    //                 avoidLabelOverlap: false,
    //                 itemStyle: {
    //                     borderRadius: 1,
    //                     borderColor: '#fff',
    //                     borderWidth: 5
    //                 },
    //                 label: {
    //                     normal: {
    //                         show: false,
    //                         position: 'center'
    //                     },
    //                     emphasis: {
    //                         label: {
    //                             show: true,
    //                             fontSize: '70',
    //                             fontWeight: 'bold'
    //                         }
    //                     },
    //                 },
    //                 labelLine: {
    //                     normal: {
    //                         show: false
    //                     }
    //                 },
    //                 data: [
    //                     { value: cardDataIndoor[0].ceiling, name: 'Ceiling' },
    //                     { value: cardDataIndoor[0].wall, name: 'Wall' },
    //                     { value: cardDataIndoor[0].floor, name: 'Floor' },
    //                 ]
    //             }
    //         ]
    //     };
    // };
    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
            </React.Fragment>
        );
    }
}
export default Doughnut;