import React from "react"
import { Link } from "react-router-dom"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
  CardText,
  Table,
  Button,
  Input,
} from "reactstrap"
import Doughnut from "./CollateralType"
import { cardDataIndoor } from "../../Common/Data/PropertyIndoorData"

//components
import UiLightbox from "./UiLightbox"
// import images

import img5 from "../../../assets/images/small/img-5.jpg"

const PropertyDetailCard = props => {
  const queryString = window.location.search
  const i = queryString.slice(1) - 1
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card style={{ margin: 20 }}>
            <Card
              style={{
                background: "#F1F5F9",
                margin: 10,
                padding: 10,
                maxHeight: 500,
                display: "inline-table",
              }}
              id="main-card"
            >
              <Row>
                <Col xs={12} md={6} lg={4} >
                  <CardTitle className="h3">
                    {cardDataIndoor[i].title}
                  </CardTitle>
                </Col>
                <Col>
                  <Link to="../properties/floorPlans">
                    <Button
                      className="btn btn-sm"
                      color="danger"
                      style={{
                        float: "right",
                        marginBottom: 10,
                        borderRadius: "8px",
                        width: 150,
                      }}
                    >
                      FLOOR PLANS
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={4} >
                  <CardText
                    id="card-description"
                    style={{
                      whiteSpace: "-moz-pre-wrap",
                      height: 400,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cardDataIndoor[i].description}
                  </CardText>
                </Col>
                <Col xs={12} md={6} lg={4} >
                  <CardImg
                    top
                    className="img-fluid"
                    src={cardDataIndoor[i].img}
                    alt="Veltrix"
                  />
                </Col>
                <Col xs={12} md={6} lg={4} >
                <UiLightbox i={i}/>
                  {/* <table className="w-100">
                    <tr>
                      <td>
                        <CardImg
                          top
                          className="img-fluid"
                          src={cardDataIndoor[i].subImg1}
                          alt="Veltrix"
                          style={cardStyle.mainCard}
                        />
                      </td>
                      <td>
                        <CardImg
                          top
                          className="img-fluid"
                          src={cardDataIndoor[i].subImg2}
                          alt="Veltrix"
                          style={cardStyle.mainCard}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CardImg
                          top
                          className="img-fluid"
                          src={cardDataIndoor[i].subImg3}
                          alt="Veltrix"
                          style={cardStyle.mainCard}
                        />
                      </td>
                      <td>
                        <CardImg
                          top
                          className="img-fluid"
                          src={cardDataIndoor[i].subImg4}
                          alt="Veltrix"
                          style={cardStyle.mainCard}
                        />
                      </td>
                    </tr>
                  </table> */}
                </Col>
              </Row>
            </Card>
            <Row>
              <Col>
                <table className="w-100">
                <Col>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            background: "#F1F5F9",
                            margin: 1,
                            padding: 0,
                          }}
                        >
                          <Row id="levels-card">
                            <Col className="col-5">
                              <CardTitle className="h5 ps-2 pt-2 pb-2">
                                Levels/Zones
                              </CardTitle>
                              <h1 className="p-2 pt-0">
                                {/* {EventsIndoorData[i]
                                  ? EventsIndoorData[i].zones
                                  : "10"} */}
                                  10
                              </h1>
                            </Col>
                            <Col>
                              {/* <Link
                                xs={3}
                                // to={`../events/floorPlans?id=${apiRes[0].id}`}
                                className="btn mt-2 mb-2"
                                // style={styles.primarybtn}
                              >
                                ADD LEVEL/ZONE
                              </Link> */}
                              <h5>  ADD LEVEL/ZONE</h5>
                              <table>
                                <tr>
                                  <td>Maintenance</td>
                                  <td>
                                    {/* {EventsIndoorData[i]
                                      ? EventsIndoorData[i].maintenanceZone
                                      : "null"} */}
                                      null
                                  </td>
                                </tr>
                                <tr>
                                  <td>Leased</td>
                                  <td>
                                    {/* {EventsIndoorData[i]
                                      ? EventsIndoorData[i].LeasedZone
                                      : "null"} */}
                                      null
                                  </td>
                                </tr>
                              </table>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          style={{
                            background: "#F1F5F9",
                          }}
                        >
                          <Row>
                            <Col>
                              <CardTitle className="h5 p-2">Slots </CardTitle>
                              <h1 className="p-2 pt-0">
                                {/* {EventsIndoorData[i]
                                  ? EventsIndoorData[i].slots
                                  : "100"} */}
                                  100
                              </h1>
                            </Col>
                            <Col>
                              {/* <Button
                                className="mt-2 mb-2"
                                onClick={() => {
                                  tog_center(apiRes[0].id)
                                }}
                                // style={styles.primarybtn}
                              >
                                ADD SLOTS<span className="fa fa-plus"></span>
                              </Button> */}
                               <h5>  ADD SLOTS</h5>
                              <table>
                                <tr>
                                  <td>Maintenance</td>
                                  <td>
                                    {/* {EventsIndoorData[i]
                                      ? EventsIndoorData[i].maintenanceSlot
                                      : "null"} */}
                                      null
                                  </td>
                                </tr>
                                <tr>
                                  <td>Leased</td>
                                  <td>
                                    {/* {EventsIndoorData[i]
                                      ? EventsIndoorData[i].LeasedSlot
                                      : "null"} */}
                                      null
                                  </td>
                                </tr>
                              </table>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Card
                        style={{
                          background: "#F1F5F9",
                          marginTop: 10,
                          padding: 10,
                        }}
                      >
                        <CardTitle className="h5">Top 10 Slots</CardTitle>
                        <Table className="table table-striped mb-0">
                          <thead style={{ background: "#bcc5d1" }}>
                            <tr>
                              <th>Slot Id</th>
                              <th>Zone</th>
                              <th>Avg Booking</th>
                              <th>Revenue (Aed)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">SL130</th>
                              <td>02</td>
                              <td>85%</td>
                              <td>2334</td>
                            </tr>
                            <tr>
                              <th scope="row">SL110</th>
                              <td>01</td>
                              <td>85%</td>
                              <td>2304</td>
                            </tr>
                            <tr>
                              <th scope="row">SL101</th>
                              <td>01</td>
                              <td>85%</td>
                              <td>2000</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </Row>
                  </Col>
                  <tr>
                    <td colSpan={2}></td>
                  </tr>
                </table>
              </Col>
              <Card className="">
                {/* <Row>
                  <Col className="col-8"></Col>
                  <Col>
                    <Input
                      type="select"
                      className="form-select mb-3"
                      style={{ width: '100%', marginBottom: '10px' }}
                    >
                      <option>1 month</option>
                      <option>3 month</option>
                      <option>6 month</option>
                      <option>1 year</option>
                    </Input>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Card
                      style={{
                        background: "#F1F5F9",
                        margin: '10px', padding: '10px', maxWidth: '100%'
                      }}
                    >
                      <CardTitle className="h4">Top 10 Slots</CardTitle>
                      <Table className="table table-striped mb-0">
                        <thead style={{ background: "#bcc5d1" }}>
                          <tr>
                            <th>Slot Id</th>
                            <th>Zone</th>
                            <th>Avg Booking</th>
                            <th>Revenue (Aed)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">SL130</th>
                            <td>02</td>
                            <td>85%</td>
                            <td>2334</td>
                          </tr>
                          <tr>
                            <th scope="row">SL110</th>
                            <td>01</td>
                            <td>85%</td>
                            <td>2304</td>
                          </tr>
                          <tr>
                            <th scope="row">SL101</th>
                            <td>01</td>
                            <td>85%</td>
                            <td>2000</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{ background: "#F1F5F9", margin: 10, padding: 10 }}
                    >
                      <CardTitle className="h4">Collateral Type</CardTitle>
                      <CardBody>
                        <div id="doughnut-chart" className="e-chart">
                          <Doughnut />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

const cardStyle = {
  fontSize: 20,
  padding: 10,
  fontWeight: "bold",
  mainCard: {
    width: 250,
    height: 180,
  },
}

export default PropertyDetailCard

//  <Row>
// <Col>
//   <CardTitle className="h4">{cardDataIndoor[0].title}</CardTitle>
// </Col>
// <Col>
//   <Link to="../properties/floorPlans">
//     <Button
//       color="primary"
//       style={{ float: "right", marginBottom: 10 }}
//     >
//       FLOOR PLANS
//     </Button>
//   </Link>
// </Col>
// <Card>
//   <Row>
//     <Col className="col-3">
{
  /* {alert("props", props.cardid)} */
}
//       <CardText>{cardDataIndoor[0].description}</CardText>
//     </Col>
//     <Col className="col-5">
//       <Card>
//         <Col>
//           <CardImg
//             top
//             className="img-fluid"
//             src={cardDataIndoor[0].img}
//             alt="Veltrix"
//           />
//         </Col>
//       </Card>
//     </Col>

//     <Col className="col-2">
//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg1}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>

//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg2}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>
//     </Col>
//     <Col className="col-2">
//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg3}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>

//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg4}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>
//     </Col>
//   </Row>
// </Card>
// </Row>
// <Row>
// <Col className="col-6">
//   <Row>
//     <Col className="col-6">
//       <Card>
//         <CardTitle style={cardStyle}>Zones</CardTitle>
//         <Row>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 50,
//                 padding: 10,
//                 fontWeight: "bold",
//               }}
//             >
//               {cardDataIndoor[0].zones}
//             </CardBody>
//           </Col>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 15,
//                 padding: 10,
//                 fontWeight: "semi-bold",
//               }}
//             >
//               Maintantance {cardDataIndoor[0].maintenanceZone}
//               <br />
//               Leased {cardDataIndoor[0].LeasedZone}
//             </CardBody>
//           </Col>
//         </Row>
//       </Card>
//     </Col>
//     <Col className="col-6">
//       <Card>
//         <CardTitle style={cardStyle}>Slots</CardTitle>
//         <Row>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 50,
//                 padding: 10,
//                 fontWeight: "bold",
//               }}
//             >
//               {cardDataIndoor[0].slots}
//             </CardBody>
//           </Col>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 15,
//                 padding: 10,
//                 fontWeight: "semi-bold",
//               }}
//             >
//               Maintantance {cardDataIndoor[0].maintenanceSlot}
//               <br />
//               Leased {cardDataIndoor[0].LeasedSlot}
//             </CardBody>
//           </Col>
//         </Row>
//       </Card>
//     </Col>
//   </Row>
//   <Col className="col-12">
//     <CardTitle>Top 10 Slots</CardTitle>
//     <CardBody>
//       <Table striped>
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>First Name</th>
//             <th>Last Name</th>
//             <th>Username</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <th scope="row">1</th>
//             <td>Mark</td>
//             <td>Otto</td>
//             <td>@mdo</td>
//           </tr>
//           <tr>
//             <th scope="row">2</th>
//             <td>Jacob</td>
//             <td>Thornton</td>
//             <td>@fat</td>
//           </tr>
//           <tr>
//             <th scope="row">3</th>
//             <td>Larry</td>
//             <td>the Bird</td>
//             <td>@twitter</td>
//           </tr>
//         </tbody>
//       </Table>
//     </CardBody>
//   </Col>
// </Col>
// <Col className="col-6">
//   <Card>
//     <CardTitle style={cardStyle}>Collateral Type</CardTitle>
//     <Row>
//       <Col className="col-4">
//         <CardBody style={{ fontSize: 20 }}>
//           Ceiling {cardDataIndoor[0].ceiling}
//           <br />
//           Wall {cardDataIndoor[0].wall}
//           <br />
//           Floor {cardDataIndoor[0].floor}
//         </CardBody>
//       </Col>
//       <Col className="col-8">
//         <CardBody>
//           <CardImg
//             top
//             className="img-fluid"
//             src={cardDataIndoor[0].subImg5}
//             alt="Veltrix"
//           />
//         </CardBody>
//       </Col>
//     </Row>
//   </Card>
// </Col>
// </Row>
