import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
} from "reactstrap"


const UiModal = () => {
  const [modal_center, setmodal_center] = useState(false)


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }


  return (
    <Row>
      <i
        className="fa fa-trash"
        onClick={() => {
          tog_center()
        }}
      ></i>

      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Confirm Delete</h5>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete the selected entry?</p>
                  </div>
                  <Row>
                    <Col style={styles.modalBtnStyle}>
                      <Button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Yes
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
