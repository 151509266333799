import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import {
  Form,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  Button,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logodarkImg from "../../assets/images/logo-dark.png"
import logosmImg from "../../assets/images/logo-sm.png"
import logolightImg from "../../assets/images/logo-light.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { useEffect } from "react"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const history = useHistory()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const handleAdminButtonClick = () => {
    const authUser = {
      id: personid,
      userName: personuserName,
      email: personEmail,
      role: "admin",
      owner: personowner,
    }

    sessionStorage.setItem("authUser", JSON.stringify(authUser))
    location.reload()
    window.location.href = "/admin"
  }

  const handleMediaButtonClick = () => {
    const authUser = {
      id: personid,
      userName: personuserName,
      email: personEmail,
      role: "owner",
      owner: personowner,
    }

    sessionStorage.setItem("authUser", JSON.stringify(authUser))
    location.reload()
    window.location.href = "/dashboard"
  }

  const handleAgencyButtonClick = () => {
    const authUser = {
      id: personid,
      userName: personuserName,
      email: personEmail,
      role: "agency",
      owner: personowner,
    }

    sessionStorage.setItem("authUser", JSON.stringify(authUser))
    location.reload()
    window.location.href = "/agency"
  }

  const handleEventButtonClick = () => {
    const authUser = {
      id: personid,
      userName: personuserName,
      email: personEmail,
      role: "event",
      owner: personowner,
    }

    sessionStorage.setItem("authUser", JSON.stringify(authUser))
    location.reload()
    window.location.href = "/events"
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const obj = JSON.parse(sessionStorage.getItem("authUser"))
  const [userRole, setUserRole] = useState("Media Owner")
  if (obj.role == "owner") {
    useEffect(() => {
      setUserRole("Media Owner")
    }, [userRole])
  } else if (obj.role == "agency") {
    useEffect(() => {
      setUserRole("Ad Agency")
    }, [userRole])
  } else if (obj.role == "event") {
    useEffect(() => {
      setUserRole("Event Organizer")
    }, [userRole])
  } else {
    useEffect(() => {
      setUserRole("Administrator")
    }, [userRole])
  }

  let authUserString = sessionStorage.getItem("authUser")
  let authUser = JSON.parse(authUserString)
  let personRole = authUser.role
  let personEmail = authUser.email
  let personid = authUser.id
  let personowner = authUser.owner
  let personuserName = authUser.userName

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodarkImg} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logolightImg} alt="" height="50" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu"></i>
            </button>
            <div>
              <span className="mt-3 d-block d-md-none d-lg-none">
                {userRole}
              </span>
            </div>

            <div className="d-none d-sm-block">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
                className="pt-3 d-inline-block"
              >
                <DropdownToggle
                  className="btn btn-secondary dropdown-toggle-split"
                  style={{ height: "50px" }}
                >
                  <h4 style={{width:'170px'}} className="mt-0 d-none d-md-block d-lg-block">
                    {userRole}
                  </h4>
                </DropdownToggle>
                <i className="mdi mdi-chevron-down"></i>
                <DropdownMenu>
                  <DropdownItem>
                    {" "}
                    <Button onClick={handleAdminButtonClick} className="w-100">
                    Administrator
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    {" "}
                    <Button onClick={handleMediaButtonClick} className="w-100">
                      Media Owner
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    {" "}
                    <Button onClick={handleAgencyButtonClick} className="w-100">
                      Ad Agency
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    {" "}
                    <Button onClick={handleEventButtonClick} className="w-100">
                      Event Organizer
                    </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <div className="d-flex">
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </form>

            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="mdi mdi-magnify"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            <LanguageDropdown />
            <Input
              type="select"
              className="form-select btn header-item noti-icon waves-effect"
              style={{ width: 100 }}
            >
              <option>AED</option>
              <option>USD</option>
              <option>INR</option>
            </Input>

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen"></i>
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog-outline"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
