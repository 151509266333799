import React from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
} from "reactstrap"
import classnames from "classnames"
import PropertyCardIndoor from "./Cards/PropertyCardIndoor"
import PropertyCardOutdoor from "./Cards/PropertyCardOutdoor"
import { Link } from "react-router-dom"

export default class Properties extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <Container className="page-content" fluid>
        <Nav pills className="navtab-bg nav-justified ind_out_navbar">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1")
              }}
            >
              Indoor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2")
              }}
            >
              Outdoor
            </NavLink>
          </NavItem>
        </Nav>
        <Row className="mt-2 mb-2">
          <Col className="col-10"></Col>
          <Col>
            <Link to="/properties/propertyAddForm" className="waves-effect">
              <Button color="primary" style={{marginBottom:'6px'}}>
                <i class="fas fa-plus-circle"></i> New Property
              </Button>
            </Link>
          </Col>
        </Row>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <PropertyCardIndoor />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <PropertyCardOutdoor />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    )
  }
}
