import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

//image
import userAvatar from "../../assets/images/userAvatar.png"

const EventsPOUserView = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card>
          <Form>
            <CardTitle>New User</CardTitle>
            <Row>
              <Col>
                <Label>Username</Label>
                <Input
                  type="text"
                  name="username"
                  value="Ebin Mathew"
                  required
                  disabled
                />
                <Label>DOB</Label>
                <Input type="text" value="22 January 1986" disabled />
                <Label>Mobile No</Label>
                <Input type="text" value="+61 7345212343" disabled />
                <Label>Gender</Label>
                <Input type="text" value="Male" disabled />
                <Label>Roles</Label>
                <Input type="select" className="form-select" disabled>
                  <option>Manager</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
                <Label>Assign To</Label>
                <Input type="select" className="form-select" disabled>
                  <option>David - Manager</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
              </Col>
              <Col>
                <Label>Email</Label>
                <Input type="email" value="ebinmathew@gmail.com" disabled />
                <Label>Designation</Label>
                <Input type="text" value="Manager" disabled />
                <Label>Location</Label>
                <Input type="text" value="221 Baker Street" disabled />
                <Label>Emirates ID</Label>
                <Input type="text" value="748-5469-1234567-1" disabled />
                <Label>Restricted Level</Label>
                <Input type="select" className="form-select" disabled>
                  <option>Level 02</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
              </Col>
              <Col>
                <CardImg src={userAvatar} />
              </Col>
            </Row>
            <Row>
              <Col className="col-10"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default EventsPOUserView
