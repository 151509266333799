import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert";
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic";
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/1index_legacy"
// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import TypiconsIcon from "../pages/Icons/IconTypicons"
import IconIon from "../pages/Icons/IconIon";
import ThemifyIcon from "../pages/Icons/IconThemify"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesInvoice from "../pages/Utility/PagesInvoice";
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesGallery from "../pages/Utility/PagesGallery";
import PagesDirectory from "../pages/Utility/PagesDirectory";


//propertyOwner
import Properties from "../propertyOwner/Properties/Properties"
import PropertyDetails from "../propertyOwner/Properties/PropertyDetails"
import PropertyCardIndoor from "../propertyOwner/Properties/Cards/PropertyCardIndoor"
import PropertyCardOutdoor from "../propertyOwner/Properties/Cards/PropertyCardOutdoor"
import PropertyDetailCard from "../propertyOwner/Properties/Cards/PropertyDetailCard"
import FloorPlans from "propertyOwner/Pages/FloorPlans"
import PropertyAddForm from "propertyOwner/Pages/PropertyAddForm"
import FloorPlanEditor from "propertyOwner/Pages/FloorPlanEditor"
import FloorPlanEditor2 from "propertyOwner/Pages/FloorPlanEditor2"
import FloorPlanModal from "propertyOwner/Modals/FloorPlanModal"
import BookingDashboard from "propertyOwner/Bookings/BookingDashboard"
// import BookingDetailList from "propertyOwner/Bookings/BookingDetailList"
import BookingDetailsReview from "propertyOwner/Bookings/BookingDetailsReview"
import BookingDetailsPaymentPending from "propertyOwner/Bookings/BookingDetailsPaymentPending"
import BookingDetailsInstallation from "propertyOwner/Bookings/BookingDetailsInstallation"
import BookingDetailsCompleted from "propertyOwner/Bookings/BookingDetailsCompleted"
import BookingDetails from "propertyOwner/Bookings/BookingDetails"
import ZonesDashboard from "propertyOwner/Zones/ZonesDashboard"
import AddZone from "propertyOwner/Zones/AddZone"
import SlotDashboard from "propertyOwner/Slots/SlotsDashboard"
import { PricingCategoryDashboard } from "propertyOwner/PricingCategory/PricingCategoryDashboard"
import UsersDashboard from "propertyOwner/Users/UsersDashboard"
import AddUser from "propertyOwner/Users/AddUser"
import POUserEdit from "propertyOwner/Users/POUserEdit"
import POUserView from "propertyOwner/Users/POUserView"
import NotificationsDashboard from "propertyOwner/Notifications/NotificationsDashboard"
import ReservationsDashboard from "propertyOwner/Reservations/ReservationsDashboard"
import LogsDashboard from "propertyOwner/Logs/LogsDashboard"
import SubscriptionDashboard from "propertyOwner/Subscription/SubscriptionDashboard"
import ReportsDashboard from "propertyOwner/Reports/ReportsDashboard"
import RevenueReports from "propertyOwner/Reports/RevenueReports"
import BuildingReports from "propertyOwner/Reports/BuildingReports"
import ZoneReports from "propertyOwner/Reports/ZoneReports"
import BookingReports from "propertyOwner/Reports/BookingReports"
import SmartSlotReports from "propertyOwner/Reports/SmartSlotReports"
import PricingReports from "propertyOwner/Reports/PricingReports"
import SlotReports from "propertyOwner/Reports/SlotReports"
import PaymentReports from "propertyOwner/Reports/PaymentReports"
import SettingsDashboard from "propertyOwner/Settings/SettingsDashboard"
import Departments from "propertyOwner/Settings/Departments"
import AddDepartment from "propertyOwner/Settings/AddDepartment"
import Designation from "propertyOwner/Settings/Designation"
import AddDesignation from "propertyOwner/Settings/AddDesignation"
import PricingTable from "propertyOwner/Settings/PricingTable"
import AddPricingTable from "propertyOwner/Settings/AddPricingTable"
// import ContentExclusion from "propertyOwner/Settings/ContentExclusion"
import SettingsProperties from "propertyOwner/Settings/SettingsProperties"
import AddContentDetails from "propertyOwner/Settings/AddContentDetails"
import RolesAndPermissions from "propertyOwner/Settings/RolesAndPermissions"

//adAgency
import AdDashboard from "adAgency/Dashboard/AdDashboard"
import AdBookingDashboard from "adAgency/Bookings/AdBookingDashboard"
import AdBookNowMapView from "adAgency/Bookings/AdBookNowMapView"
import AdFormWizard from "adAgency/Bookings/AdFormWizard"
import AdGridView from "adAgency/Bookings/AdGridView"
import AdGridViewDigital from "adAgency/Bookings/AdGridViewDigital"
import { AdBookingGridDetail } from "adAgency/Bookings/AdBookingGridDetail"
import { AdBookingPlanView } from "adAgency/Bookings/AdBookingPlanView"
import AdBookingDetails from "adAgency/Bookings/AdBookingDetails"
import AdminPropertyDetailCard from "adAgency/Bookings/AdminPropertyDetailCard"
import AdNotificationsDashboard from "adAgency/Notifications/AdNotificationsDashboard"
import { AdNotificationsInstallationReqestDetail } from "adAgency/Notifications/AdNotificationsInstallationReqestDetail"
import AdOrdersDashboard from "adAgency/Orders/AdOrdersDashboard"
import AdUsersDashboard from "adAgency/Users/AdUsersDashboard"
import AdUserView from "adAgency/Users/AdUserView"
import AdUserEdit from "adAgency/Users/AdUserEdit"
import AdAddUsers from "adAgency/Users/AdAddUsers"  
import AdReportsDashboard from "adAgency/Reports/AdReportsDashboard"
import AdSubscriptionDashboard from "adAgency/Subscription/AdSubscriptionDashboard"
import AdLogsDashboard from "adAgency/Logs/AdLogsDashboard"
import AdFAQsDashboard from "adAgency/FAQs/AdFAQsDashboard"
import AdHelpDashboard from "adAgency/Help/AdHelpDashboard"
import AdSettingsDashboard from "adAgency/Settings/AdSettingsDashboard"
import AdDepartments from "adAgency/Settings/AdDepartments"
import AdAddDepartments from "adAgency/Settings/AdAddDepartments"
import AdDesignation from "adAgency/Settings/AdDesignation"
import AdAddDesignation from "adAgency/Settings/AdAddDesignation"
import AdRolesAndPermissions from "adAgency/Settings/AdRolesAndPermissions"

//admin
import AdminDashboard from "admin/Dashboard/AdminDashboard"
import AdminUserManagementDashboard from "admin/UserManagement/AdminUserManagementDashboard"
import AdminAddUser from "admin/UserManagement/AdminAddUser"
import AdminRolesAndPermissions from "admin/UserManagement/AdminRolesAndPermissions"
import AdminUserListView from "admin/UserManagement/AdminUserListView"
import AdminUserListEdit from "admin/UserManagement/AdminUserListEdit"
import AdminPropertiesDashboard from "admin/Properties/AdminPropertiesDashboard"
import AdminPropertyDetailsView from "admin/Properties/AdminPropertyDetailsView"
import AdminAddMall from "admin/Properties/AdminAddMall"
import AdminBookingsDashboard from "admin/Bookings/AdminBookingDashboard"
import AdminAddBookings from "admin/Bookings/AdminAddBookings"
import AdminSmartSlotsDashboard from "admin/SmartSlots/AdminSmartSlotsDashboard"
import AdminAddSmartSlots from "admin/SmartSlots/AdminAddSmartSlots"
import AdminNotificationsDashboard from "admin/Notifications/AdminNotificationsDashboard"
import AdminAddNewNotifications from "admin/Notifications/AdminAddNewNotifications"
import AdminViewNotification from "admin/Notifications/AdminViewNotification"
import AdminEditNotification from "admin/Notifications/AdminEditNotification"
import AdminReportsDashboard from "admin/Reports/AdminReportsDashboard"
import AdminRepoertsDetails from "admin/Reports/AdminRepoertsDetails"
import AdminFAQsDashboard from "admin/FAQs/AdminFAQsDashboard"
import AdminHelpDashboard from "admin/Help/AdminHelpDashboard"
import AdminSettingsDashboard from "admin/Settings/AdminSettingsDashboard"
import AdminCollateralType from "admin/Settings/AdminCollateralType"
import AdminAddNewContentTypeExcluded from "admin/Settings/AdminAddNewContentTypeExcluded"
import AdminContentTypeExcludedView from "admin/Settings/AdminContentTypeExcludedView"
import AdminContentTypeExcludedEdit from "admin/Settings/AdminContentTypeExcludedEdit"
import AdminCollateralTypeView from "admin/Settings/AdminCollateralTypeView"
import AdminCollateralTypeEdit from "admin/Settings/AdminCollateralTypeEdit"

//events
import EventsDashboard from "events/Dashboard/EventsDashboard"
import {EventsEventsDashboard} from "events/Events/EventsDashboard"
import EventsProperties from "../events/Events/EventsProperties"
import EventsDetails from "../events/Events/EventsDetails"
import EventsCardIndoor from "../events/Events/Cards/EventsCardIndoor"
import EventsCardOutdoor from "../events/Events/Cards/EventsCardOutdoor"
import EventsDetailCard from "../events/Events/Cards/EventsDetailCard"
import EventsFloorPlans from "events/Pages/EventsFloorPlans"
import EventsAddForm from "events/Events/EventsAddForm"
import EventEditForm from "events/Events/EventEditForm"
import EventsFloorPlanEditor from "events/Pages/EventsFloorPlanEditor"
import EventsFloorPlanEditor2 from "events/Pages/EventsFloorPlanEditor2"
import EventsFloorPlanModal from "events/Modals/EventsFloorPlanModal"
import EventsBookingDashboard from "events/Bookings/EventsBookingDashboard"
// import BookingDetailList from "events/Bookings/BookingDetailList"
import EventsBookingDetailsEnquirySent from "events/Bookings/EventsBookingDetailsEnquirySent"
import { EventsBookingGridDetail } from "events/Client/EventsBookingGridDetail"
import EventsClient from "events/Client/EventsClient"
import EventsBookingDetailsListingReceived from "events/Bookings/EventsBookingDetailsListingReceived"
import {ListingReceivedStatement} from "events/Statements/ListingReceivedStatement"
import ListingExportStatement from "events/Statements/ListingExportStatement"
import EventsBookingDetailsPaymentPending from "events/Bookings/EventsBookingDetailsPaymentPending"
import { EventsBookingDetailsProposalSent } from "events/Bookings/EventsBookingDetailsProposalSent"
import { EventsBookingDetailsBooked } from "events/Bookings/EventsBookingDetailsBooked"
import { EventsBookingDetailsOngoing } from "events/Bookings/EventsBookingDetailsOngoing"
import EventsBookingDetailsCompleted from "events/Bookings/EventsBookingDetailsCompleted"
import EventsBookingDetails from "events/Bookings/EventsBookingDetails"
import EventsZonesDashboard from "events/Zones/EventsZonesDashboard"
import EventsAddZone from "events/Zones/EventsAddZone"
import EventsSlotDashboard from "events/Slots/EventsSlotsDashboard"
import { EventsPricingCategoryDashboard } from "events/PricingCategory/EventsPricingCategoryDashboard"
import EventsUsersDashboard from "events/Users/EventsUsersDashboard"
import EventsAddUser from "events/Users/EventsAddUser"
import EventsPOUserEdit from "events/Users/EventsPOUserEdit"
import EventsPOUserView from "events/Users/EventsPOUserView"
import EventsNotificationsDashboard from "events/Notifications/EventsNotificationsDashboard"
import EventsReservationsDashboard from "events/Reservations/EventsReservationsDashboard"
import EventsLogsDashboard from "events/Logs/EventsLogsDashboard"
import EventsSubscriptionDashboard from "events/Subscription/EventsSubscriptionDashboard"
import EventsReportsDashboard from "events/Reports/EventsReportsDashboard"
import EventsRevenueReports from "events/Reports/EventsRevenueReports"
import EventsBuildingReports from "events/Reports/EventsBuildingReports"
import EventsZoneReports from "events/Reports/EventsZoneReports"
import EventsBookingReports from "events/Reports/EventsBookingReports"
import EventsSmartSlotReports from "events/Reports/EventsSmartSlotReports"
import EventsPricingReports from "events/Reports/EventsPricingReports"
import EventsSlotReports from "events/Reports/EventsSlotReports"
import EventsPaymentReports from "events/Reports/EventsPaymentReports"
import EventsSettingsDashboard from "events/Settings/EventsSettingsDashboard"
import EventsDepartments from "events/Settings/EventsDepartments"
import EventsAddDepartment from "events/Settings/EventsAddDepartment"
import EventsDesignation from "events/Settings/EventsDesignation"
import EventsAddDesignation from "events/Settings/EventsAddDesignation"
import EventsPricingTable from "events/Settings/EventsPricingTable"
import EventsAddPricingTable from "events/Settings/EventsAddPricingTable"
// import EventsContentExclusion from "events/Settings/EventsContentExclusion"
import EventsSettingsProperties from "events/Settings/EventsSettingsProperties"
import EventsAddContentDetails from "events/Settings/EventsAddContentDetails"
import EventsRolesAndPermissions from "events/Settings/EventsRolesAndPermissions"

import Landing from "landingPage/Landing"
import AccessDenied from "AccessDenied/AccessDenied"
import Authmiddleware from "./middleware/Authmiddleware"
import EventDetail from "landingPage/logged_out/components/home/EventDetail"

const user = {
  role: "admin",
  role:"event",
  role:"owner",
  role:"agency"
};


const userRoutes = [
  { path: "/dashboard", component: Dashboard , allowedRoles: ["owner"], userType: "owner"},

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

    // Email Template
    { path: "/email-template-alert", component: Emailtemplatealert },
    { path: "/email-template-basic", component: Emailtemplatebasic },
    { path: "/email-template-billing", component: Emailtemplatebilling },
  
  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },

  // Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-themify", component: ThemifyIcon },
  { path: "/icons-typicons", component: TypiconsIcon },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-gallery", component: PagesGallery },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> , allowedRoles: ["owner"], userType: "owner"},

  //propertyOwner
  { path: "/properties", component: Properties , allowedRoles: ["owner"], userType: "owner"},
  { path: "/propertyDetails", component: PropertyDetails, allowedRoles: ["owner"], userType: "owner" },
  { path: "/properties/propertyCardIndoor", component: PropertyCardIndoor, allowedRoles: ["owner"], userType: "owner" },
  { path: "/properties/propertyCardOutdoor", component: PropertyCardOutdoor, allowedRoles: ["owner"], userType: "owner" },
  { path: "/properties/propertyDetails", component: PropertyDetailCard, allowedRoles: ["owner"], userType: "owner" },
  { path: "/properties/floorPlanModal", component: FloorPlanModal, allowedRoles: ["owner"], userType: "owner"},
  { path: "/properties/floorPlans", component: FloorPlans, allowedRoles: ["owner"], userType: "owner"},
  { path: "/properties/floorPlanEditor", component: FloorPlanEditor, allowedRoles: ["owner"], userType: "owner"},
  { path: "/properties/floorPlanEditor2", component: FloorPlanEditor2, allowedRoles: ["owner"], userType: "owner"},
  { path: "/properties/PropertyAddForm", component: PropertyAddForm, allowedRoles: ["owner"], userType: "owner"},
  { path: "/bookings", component: BookingDashboard, allowedRoles: ["owner"], userType: "owner"},
  // { path: "/bookingDetailList", component: BookingDetailList},
  { path: "/bookings/review", component: BookingDetailsReview, allowedRoles: ["owner"], userType: "owner"},
  { path: "/bookings/paymentPending", component: BookingDetailsPaymentPending, allowedRoles: ["owner"], userType: "owner"},
  { path: "/bookings/installation", component: BookingDetailsInstallation, allowedRoles: ["owner"], userType: "owner"},
  { path: "/bookings/completed", component: BookingDetailsCompleted, allowedRoles: ["owner"], userType: "owner"},
  { path: "/bookingDetails", component: BookingDetails, allowedRoles: ["owner"], userType: "owner"},
  { path: "/zones", component: ZonesDashboard, allowedRoles: ["owner"], userType: "owner"},
  { path: "/zones/addZone", component: AddZone, allowedRoles: ["owner"], userType: "owner"},
  { path: "/slots", component: SlotDashboard, allowedRoles: ["owner"], userType: "owner"},
  { path: "/pricingCategory", component: PricingCategoryDashboard, allowedRoles: ["owner"], userType: "owner"},
  { path: "/users", component: UsersDashboard, allowedRoles: ["owner"], userType: "owner"},
  { path: "/users/addNew", component: AddUser, allowedRoles: ["owner"], userType: "owner"},
  { path: "/users/editUser", component: POUserEdit, allowedRoles: ["owner"], userType: "owner"},
  { path: "/users/viewUser", component: POUserView, allowedRoles: ["owner"], userType: "owner"},
  { path: "/notifications", component: NotificationsDashboard, allowedRoles: ["owner"], userType: "owner"},
  { path: "/reservations", component: ReservationsDashboard , allowedRoles: ["owner"], userType: "owner"},
  { path: "/logs", component: LogsDashboard , allowedRoles: ["owner"], userType: "owner"},
  { path: "/subscription", component: SubscriptionDashboard , allowedRoles: ["owner"], userType: "owner"},
  { path: "/reports", component: ReportsDashboard , allowedRoles: ["owner"], userType: "owner"},
  { path: "/reports/revenueReports", component: RevenueReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/buildingReports", component: BuildingReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/zoneReports", component: ZoneReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/bookingReports", component: BookingReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/smartSlotReports", component: SmartSlotReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/pricingReports", component: PricingReports , allowedRoles: ["owner"], userType: "owner"},
  { path: "/reports/slotReports", component: SlotReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/reports/paymentReports", component: PaymentReports, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings", component: SettingsDashboard , allowedRoles: ["owner"], userType: "owner"},
  { path: "/settings/departments", component: Departments, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/departments/addNew", component: AddDepartment, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/designation", component: Designation, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/designation/addNew", component: AddDesignation, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/pricingTable", component: PricingTable, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/pricingTable/addNew", component: AddPricingTable, allowedRoles: ["owner"], userType: "owner" },
  // { path: "/settings/contentExclusion", component: ContentExclusion },
  { path: "/settings/properties", component: SettingsProperties, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/properties/addNew", component: AddContentDetails, allowedRoles: ["owner"], userType: "owner" },
  { path: "/settings/rolesAndPermissions", component: RolesAndPermissions, allowedRoles: ["owner"], userType: "owner" },

  //adAgency
  { path: "/agency", component: AdDashboard , allowedRoles: ["agency"],userType: "agency"},
  { path: "/agency/bookings", component: AdBookingDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow", component: AdBookNowMapView, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/formWizard", component: AdFormWizard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/gridView", component: AdGridView, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/gridViewDigital", component: AdGridViewDigital, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/gridDetail", component: AdBookingGridDetail, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/details", component: AdBookingDetails, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/planView", component: AdBookingPlanView, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/bookNow/propertyDetails", component: AdminPropertyDetailCard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/notifications", component: AdNotificationsDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/notifications/details", component: AdNotificationsInstallationReqestDetail, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/orders", component: AdOrdersDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/users", component: AdUsersDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/users/viewUser", component: AdUserView, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/users/editUser", component: AdUserEdit, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/users/addUser", component: AdAddUsers, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/reports", component: AdReportsDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/subscription", component: AdSubscriptionDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/logs", component: AdLogsDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/faqs", component: AdFAQsDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/help", component: AdHelpDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings", component: AdSettingsDashboard, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings/departments", component: AdDepartments, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings/departments/addNew", component: AdAddDepartments, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings/designation", component: AdDesignation, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings/designation/addNew", component: AdAddDesignation, allowedRoles: ["agency"],userType: "agency" },
  { path: "/agency/settings/rolesAndPermissions", component: AdRolesAndPermissions, allowedRoles: ["agency"],userType: "agency" },

  //admin
  { path: "/admin", component: AdminDashboard, allowedRoles: ["admin"], userType: "admin" },
  { path: "/admin/userManagement/userList", component: AdminUserManagementDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/userManagement/rolesAndPermissions", component: AdminRolesAndPermissions, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/userManagement/userList/addUser", component: AdminAddUser, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/userManagement/userList/view", component: AdminUserListView , allowedRoles: ["admin"], userType: "admin" },
  { path: "/admin/userManagement/userList/edit", component: AdminUserListEdit, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/properties", component: AdminPropertiesDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/properties/addMall", component: AdminAddMall, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/properties/PropertyDetailsView", component: AdminPropertyDetailsView, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/bookings", component: AdminBookingsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/bookings/addBookings", component: AdminAddBookings, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/smartSlots", component: AdminSmartSlotsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/smartSlots/addNew", component: AdminAddSmartSlots, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/notifications", component: AdminNotificationsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/notifications/addNew", component: AdminAddNewNotifications, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/notifications/view", component: AdminViewNotification, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/notifications/edit", component: AdminEditNotification, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/reports", component: AdminReportsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/reports/adminRepoertsDetails", component: AdminRepoertsDetails, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/faqs", component: AdminFAQsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/help", component: AdminHelpDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/contentTypeExcluded", component: AdminSettingsDashboard, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/collateralType", component: AdminCollateralType, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/contentTypeExcluded/addNew", component: AdminAddNewContentTypeExcluded, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/contentTypeExcluded/view", component: AdminContentTypeExcludedView, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/contentTypeExcluded/edit", component: AdminContentTypeExcludedEdit, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/collateralType/view", component: AdminCollateralTypeView, allowedRoles: ["admin"], userType: "admin"  },
  { path: "/admin/settings/collateralType/edit", component: AdminCollateralTypeEdit , allowedRoles: ["admin"], userType: "admin" },

  //event organizer
  { path: "/events", component: EventsDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/properties", component: EventsProperties, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/eventDetails", component: EventsDetails, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/eventCardIndoor", component: EventsCardIndoor, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/eventCardOutdoor", component: EventsCardOutdoor, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/eventDetails", component: EventsDetailCard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/floorPlanModal", component: EventsFloorPlanModal, allowedRoles: ["event"], userType: "event" },
  { path: "/events/floorPlans", component: EventsFloorPlans, allowedRoles: ["event"], userType: "event" },
  { path: "/events/floorPlanEditor", component: EventsFloorPlanEditor, allowedRoles: ["event"], userType: "event" },
  { path: "/events/floorPlanEditor2", component: EventsFloorPlanEditor2, allowedRoles: ["event"], userType: "event" },
  { path: "/events/eventAddForm", component: EventsAddForm, allowedRoles: ["event"], userType: "event" },
  { path: "/events/eventEditForm", component: EventEditForm, allowedRoles: ["event"], userType: "event" },
  { path: "/events/events", component: EventsProperties, allowedRoles: ["event"], userType: "event" },
  { path: "/events/events/addNew", component: EventsEventsDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings", component: EventsBookingDashboard, allowedRoles: ["event"], userType: "event" },
  // { path: "/events/bookingDetailList", component: EventsBookingDetailList},
  { path: "/events/bookings/enquirySent", component:EventsClient, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/listingReceived", component: EventsBookingDetailsListingReceived, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/listingReceived/generateStatement", component: ListingReceivedStatement, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/proposalSent", component: EventsBookingDetailsProposalSent, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/paymentPending", component: EventsBookingDetailsPaymentPending, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/booked", component: EventsBookingDetailsBooked, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/ongoing", component: EventsBookingDetailsOngoing, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookings/completed", component: EventsBookingDetailsCompleted, allowedRoles: ["event"], userType: "event" },
  { path: "/events/bookingDetails", component: EventsBookingDetails, allowedRoles: ["event"], userType: "event" },
  { path: "/events/zones", component: EventsZonesDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/zones/addZone", component: EventsAddZone, allowedRoles: ["event"], userType: "event" },
  { path: "/events/slots", component: EventsSlotDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/slots/listingExportStatement",component:ListingExportStatement, allowedRoles: ["event"], userType: "event" },
  { path: "/events/pricingTable", component: EventsPricingCategoryDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/users", component: EventsUsersDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/users/addNew", component: EventsAddUser, allowedRoles: ["event"], userType: "event" },
  { path: "/events/users/editUser", component: EventsPOUserEdit, allowedRoles: ["event"], userType: "event" },
  { path: "/events/users/viewUser", component: EventsPOUserView, allowedRoles: ["event"], userType: "event" },
  { path: "/events/notifications", component: EventsNotificationsDashboard, allowedRoles: ["event"], userType: "event" },
  { path: "/events/reservations", component: EventsReservationsDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/logs", component: EventsLogsDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/subscription", component: EventsSubscriptionDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports", component: EventsReportsDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/revenueReports", component: EventsRevenueReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/buildingReports", component: EventsBuildingReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/zoneReports", component: EventsZoneReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/bookingReports", component: EventsBookingReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/smartSlotReports", component: EventsSmartSlotReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/pricingReports", component: EventsPricingReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/slotReports", component: EventsSlotReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/reports/paymentReports", component: EventsPaymentReports, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings", component: EventsSettingsDashboard, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/departments", component: EventsDepartments, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/departments/addNew", component: EventsAddDepartment, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/designation", component: EventsDesignation, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/designation/addNew", component: EventsAddDesignation, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/pricingTable", component: EventsPricingTable, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/pricingTable/addNew", component: EventsAddPricingTable, allowedRoles: ["event"], userType: "event"  },
  // { path: "/events/settings/contentExclusion", component: EventsContentExclusion },
  { path: "/events/settings/properties", component: EventsSettingsProperties, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/properties/addNew", component: EventsAddContentDetails, allowedRoles: ["event"], userType: "event"  },
  { path: "/events/settings/rolesAndPermissions", component: EventsRolesAndPermissions , allowedRoles: ["event"], userType: "event" },
  
]

const authRoutes = [
  { path: "/access-denied", component: AccessDenied },
  { path: "/home", component: Landing },
  { path: "/event/:eventId", component: EventDetail },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
