import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
import "./EventPricingAddNew.css"

const AddNew = props => {
  const [name, setName] = useState("")
  const [faceValue, setFaceValue] = useState("")
  const [currency, setCurrency] = useState("")
  const [nameError, setNameError] = useState("")
  const [faceValueError, setFaceValueError] = useState("")
  const [currencyError, setCurrencyError] = useState("")
  const [formData, setFormData] = useState()

  useEffect(() => {
    setFormData({
      name: name,
      faceValue: faceValue,
      minRange: "0",
      maxRange: "0",
      currency: currency,
    })
  }, [name, faceValue, currency])

  const resetFormData = () => {
    setName("")
    setFaceValue("")
    setCurrency("")
  }

  const [modal_center, setmodal_center] = useState(false)


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }



  const handleSubmit = event => {
    event.preventDefault()
    if (name && faceValue && currency) {
      axiosApi
        .post("/common/pricing", formData)
        .then(res => tog_center(), resetFormData(), location.reload())
    } else {
      if (!name) {
        setNameError("Please enter a name.")
      }
      if (!faceValue) {
        setFaceValueError("Please enter a face value.")
      }
      if (!currency) {
        setCurrencyError("Please enter a currency.")
      }
    }
  }

  return (
    <Row>
      <Row className="d flex-row-reverse">
        <Button
          style={{ width: "150px" }}
          color="primary"
          onClick={() => {
            tog_center()
          }}
        >
          Add New
        </Button>
      </Row>
      <Row>
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Modal
                isOpen={modal_center}
                toggle={() => {
                  tog_center()
                }}
                centered={true}
                style={{ marginTop: "50px", maxWidth: 550 }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Add To Price Table</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setmodal_center(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row xs={1} sm={1} md={2}>
                    <Col>
                      <CardBody>
                        Duis non reprehenderit voluptate laboris nostrud aliquip
                        quis.
                      </CardBody>
                      <CardBody>
                        Duis non reprehenderit voluptate laboris nostrud aliquip
                        quis.
                      </CardBody>
                      <CardBody>
                        Duis non reprehenderit voluptate laboris nostrud aliquip
                        quis.
                      </CardBody>
                    </Col>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Label>
                          Name:
                          <Input
                            type="text"
                            placeholder={nameError}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </Label>
                        <Label>
                          Face Value:
                          <Input
                            type="text"
                            placeholder={faceValueError}
                            value={faceValue}
                            onChange={e => setFaceValue(e.target.value)}
                          />
                        </Label>
                        <Label>
                          Currency:
                          <Input
                            type="text"
                            placeholder={currencyError}
                            value={currency}
                            onChange={e => setCurrency(e.target.value)}
                          />
                        </Label>
                      </Form>
                    </Col>
                  </Row>
                </div>
                <Row className="text-end">
                  <Col style={styles.modalBtnStyle} className="mb-3">
                    <Button
                      className="btn btn-primary waves-effect waves-light me-2"
                      onClick={() => {
                        tog_center()
                      }}
                      data-toggle="modal"
                      data-target=".bs-example-modal-center"
                      color="danger"
                    >
                      Back
                    </Button>

                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light me-3"
                      onClick={e => {
                        handleSubmit(e)
                      }}
                      data-toggle="modal"
                      data-target=".bs-example-modal-center"
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default AddNew
