import React from "react"
import {
  Card,
  CardText,
  CardBody,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import CardPricing from "../../pages/Utility/card-pricing"

const AdSubscriptionDashboard = () => {
  const pricings = [
    {
      id: 1,
      title: "Free",
      description: "Sed ut neque unde",
      icon: "ion ion-ios-airplane",
      price: "19",
      duration: "Per month",
      link: "",
      features: [
        { icon: "mdi mdi-check", title: "Number Of Days: 50 Days" },
        { icon: "mdi mdi-check", title: "Number Of Users: 10" },
        { icon: "mdi mdi-check", title: "Number Of Search: 30" },
        { icon: "mdi mdi-check", title: "Number Of PriceListing	10" },
        { icon: "mdi mdi-check", title: "Number Of Booking: 10" },
        { icon: "mdi mdi-check", title: "Number Of Properties: 10" },
        { icon: "mdi mdi-check", title: "Number Of Zones: 10" },
        { icon: "mdi mdi-check", title: "Number Of Smart Slots: 2" },
        { icon: "mdi mdi-check", title: "Collateral Type: Ceiling" },
        { icon: "mdi mdi-check", title: "Locked By Geolocation: UAE" },
        { icon: "mdi mdi-check", title: "Storage Space Alloted: 10GB" },
      ],
    },
    {
      id: 2,
      title: "Gold",
      description: "Quis autem iure",
      icon: "ion ion-ios-trophy",
      price: "29",
      duration: "Per month",
      link: "",
      features: [
        { icon: "mdi mdi-check", title: "Number Of Days: 6 Months" },
        { icon: "mdi mdi-check", title: "Number Of Users: 30" },
        { icon: "mdi mdi-check", title: "Number Of Search: 100" },
        { icon: "mdi mdi-check", title: "Number Of PriceListing	Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Booking: 100" },
        { icon: "mdi mdi-check", title: "Number Of Properties: 100" },
        { icon: "mdi mdi-check", title: "Number Of Zones: Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Smart Slots: 30" },
        { icon: "mdi mdi-check", title: "Collateral Type: Ceiling, Wall" },
        { icon: "mdi mdi-check", title: "Locked By Geolocation: UAE, India" },
        { icon: "mdi mdi-check", title: "Storage Space Alloted: 50GB" },
      ],
    },
    {
      id: 3,
      title: "Platinum",
      description: "Sed ut neque unde",
      icon: "ion ion-ios-umbrella",
      price: "39",
      duration: "Per month",
      link: "",
      features: [
        { icon: "mdi mdi-check", title: "Number Of Days: 1 Year" },
        { icon: "mdi mdi-check", title: "Number Of Users: 50" },
        { icon: "mdi mdi-check", title: "Number Of Search: Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of PriceListing	Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Booking: Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Properties: Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Zones: Unlimited" },
        { icon: "mdi mdi-check", title: "Number Of Smart Slots: Unlimited" },
        { icon: "mdi mdi-check", title: "Collateral Type: Ceiling, Wall, Floor" },
        { icon: "mdi mdi-check", title: "Locked By Geolocation: UAE, India" },
        { icon: "mdi mdi-check", title: "Storage Space Alloted: Unlimited" },
      ],
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription | Viewitonme</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="Viewitonme" breadcrumbItem="Subscription" />

          <Row style={{ justifyContent: "center" }}>
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>
          <Card>
            <Row className="mt-5">
              <Col>
                <CardText>
                  <b>Payment Method</b>
                </CardText>
                <Row style={{ width: 300 }}>
                  <Card
                    style={{
                      backgroundColor: "#ddebf0",
                      paddingTop: 20,
                      borderRadius: 10,
                    }}
                  >
                    <Row>
                      <Col className="col-4">
                        <Card
                          style={{ backgroundColor: "lightgreen", width: 60 }}
                        >
                          <CardText style={{ textAlign: "center" }}>
                            Default
                          </CardText>
                        </Card>
                      </Col>
                      <Col>
                        <CardText>
                          <b>VISA</b> 5567 Exp 12/23{" "}
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <Row style={{ marginLeft: -22 }}>
                  <Card>
                    <CardText
                      style={{
                        backgroundColor: "#ddebf0",
                        textAlign: "center",
                        width: 100,
                        borderRadius: 7,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                    >
                      Add Card
                    </CardText>
                  </Card>
                </Row>
              </Col>
              <Col>
                <CardText>
                  <b>Invoice</b>
                </CardText>
                <Row>
                  <Card>
                    <CardText
                      style={{
                        backgroundColor: "#ddebf0",
                        textAlign: "center",
                        width: 200,
                        borderRadius: 7,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                    >
                      Download Latest Invoice
                    </CardText>
                    <CardBody>
                      <Table>
                        <thead>
                          <td>Invoice</td>
                          <td>Period</td>
                          <td>Total (aed)</td>
                        </thead>
                        <tbody>
                          <tr>
                            <td>G19934-33989</td>
                            <td>23 Mar 2022</td>
                            <td>499</td>
                          </tr>
                          <tr>
                            <td>G19934-33989</td>
                            <td>23 Mar 2022</td>
                            <td>499</td>
                          </tr>
                          <tr>
                            <td>G19934-33989</td>
                            <td>23 Mar 2022</td>
                            <td>499</td>
                          </tr>
                          <tr>
                            <td>G19934-33989</td>
                            <td>23 Mar 2022</td>
                            <td>499</td>
                          </tr>
                          <tr>
                            <td>G19934-33989</td>
                            <td>23 Mar 2022</td>
                            <td>499</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
const styles = {
  Container: {
    marginTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    cardStyle: {
      paddingLeft: 50,
      paddingRight: 50,
      colTypes: {
        backgroundColor: "#ddebf0",
      },
      colFree: {
        backgroundColor: "#133c4b49",
        btnActive: {
          backgroundColor: "gray",
          color: "white",
          borderRadius: 4,
          textAlign: "center",
          padding: 2,
        },
      },
      colGold: {
        backgroundColor: "#5ea1b998",
        btnActive: {
          backgroundColor: "blue",
          color: "white",
          borderRadius: 4,
          textAlign: "center",
          padding: 2,
        },
      },
      colPlatinum: {
        backgroundColor: "#2db0e0ff",
        btnActive: {
          backgroundColor: "darkgrey",
          color: "white",
          borderRadius: 4,
          textAlign: "center",
          padding: 2,
        },
      },
      subTableTypesTd: {
        backgroundColor: "lightblue",
        borderBottom: 0,
      },
      subTableFreeTd: {
        backgroundColor: "silver",
        borderBottom: 0,
        textAlign: "center",
      },
      subTableGoldTd: {
        backgroundColor: "lightgrey",
        borderBottom: 0,
        textAlign: "center",
      },
      subTablePlatinumTd: {
        backgroundColor: "skyblue",
        borderBottom: 0,
        textAlign: "center",
      },
    },
  },
}

export default AdSubscriptionDashboard
