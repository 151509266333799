import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import {
  Card,
  Row,
  Col,
  Input,
  Table,
  CardImg,
  CardBody,
  Form,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
import "./style.scss"
//img
import img1 from "../../assets/images/gridViewImg1.png"
//component
import LeafletEditor from "./LeafletEditor"
import css from "./EventsClient.css"

const EventsClient = () => {
  const [state, setState] = useState({
    right: false,
  })

  const [apiRes, setApiRes] = useState()
  const [apiRes1, setApiRes1] = useState()
  const [uniqueAgencies, setUniqueAgencies] = useState([])
  const [uniqueSlots, setUniqueSlots] = useState([])
  const [selected, setSelected] = useState([])
  const [selectedZone, setSelectedZone] = useState([])
  const [totalAmount, setTotalAmount] = useState("")
  const [showForm, setShowForm] = useState(false)
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: "#fc8160",
    width: "45%",
    marginLeft: "auto",
  })
  const [showTable, setShowTable] = useState(true)
  const [selectedSlotName, setSelectedSlotName] = useState("")
  const [selectedCollateralType, setSelectedCollateralType] = useState("")

  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes1(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  // Get the token from the navToUrl
  const token = window.location.search

  const handleRaiseBookingRequest = () => {
    setShowForm(true)
    setButtonStyle({ display: "none" })
    setShowTable(false)
  }

  const handleCancel = () => {
    setShowForm(false)
    setButtonStyle({
      backgroundColor: "#fc8160",
      width: "45%",
      marginLeft: "auto",
    })
    setShowTable(true)
  }

  function getNumberOfDays(fromDate, toDate) {
    const oneDay = 24 * 60 * 60 * 1000 // Number of milliseconds in one day
    const diffInMs = toDate.getTime() - fromDate.getTime() // Difference in milliseconds
    const diffInDays = Math.round(diffInMs / oneDay) // Difference in days, rounded to the nearest integer
    return diffInDays
  }

  // Example usage
  const fromDate = new Date(apiRes ? apiRes.bookingFrom : null)
  const toDate = new Date(apiRes ? apiRes.bookingTo : null)
  const numDays = getNumberOfDays(fromDate, toDate)
  const arr = selected.map(item => numDays * item.amount)
  const sum = arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )
  useEffect(() => {
    setTotalAmount(sum)
  }, [sum])

  const handleCheckboxClick = id => {
    const index = selected.indexOf(id)
    if (index === -1) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter(value => value !== id))
    }
  }

  const handleImageClick = zoneId => {
    const index = selected.indexOf(zoneId)
    if (index === -1) {
      setSelectedZone([...selected, zoneId])
    } else {
      setSelectedZone(selected.filter(value => value !== zoneId))
    }
  }

  function removeSelected(index) {
    const newSelected = [...selected] // create a copy of the selected array
    newSelected.splice(index, 1) // remove the selected row from the copy
    setSelected(newSelected) // update the state with the new array
  }

  const handleSubmit = () => {
    const formData = {
      token: token.slice(4),
      slots: selected.map(slot => ({ slotId: slot.id, eventId: slot.eventId })),
    }
    axiosApi
      .post("/send-listing-back", formData)
      .then(response => {
        setApiRes(response.data.data)
        location.href =
          "/events/bookings/listingReceived?id=" +
          JSON.parse(localStorage.getItem("shareEventRes")).data.data.id
      })
      .catch(error => {
        console.log(error)
      })
  }

  useState(() => {
    axiosApi
      .post("/view-quote", {
        token: token.slice(4),
      })
      .then(response => {
        setApiRes(response.data.data)

        const uniqueAgencies = response.data.data.slots
          .map(row => row.slotName)
          .filter((name, index, array) => array.indexOf(name) === index)
          .reduce((acc, name) => {
            acc.push({ value: name, label: name })
            return acc
          }, [])
        setUniqueAgencies(uniqueAgencies)

        const uniqueSlots = response.data.data.slots
          .map(row => row.zoneId)
          .filter((name, index, array) => array.indexOf(name) === index)
          .reduce((acc, name) => {
            acc.push({ value: name, label: name })
            return acc
          }, [])
        setUniqueSlots(uniqueSlots)
      })
      .catch(error => {
        console.log(error)
      })
  })

  const handleSlotNameChange = e => {
    setSelectedSlotName(e.target.value)
  }

  const handleCollateralTypeChange = e => {
    setSelectedCollateralType(e.target.value)
  }

  return (
    <div className="page-content m-2">
      <Row
        sm={1}
        md={1}
        lg={2}
        style={{
          backgroundColor: "#045b9e",
          marginRight: "-40px",
          minHeight: "800px",
        }}
      >
        <Col>
          <Row sm={2} md={3} lg={3}>
            <Input
              type="select"
              placeholder="Level 1 Zone 1"
              className="form-select  mt-2"
              style={{ marginInline: "auto" }}
              value={selectedSlotName}
              onChange={handleSlotNameChange}
            >
              <option value="">Level 1 Zone 1</option>
              {uniqueSlots.map(agency => (
                <option key={agency.value} value={agency.value}>
                  {agency.label}
                </option>
              ))}
            </Input>
            <Col>
              <Input
                type="select"
                placeholder="All"
                className="form-select  mt-2"
                value={selectedCollateralType}
                onChange={handleCollateralTypeChange}
              >
                <option value=""> Slots Name</option>
                {uniqueAgencies.map(agency => (
                  <option key={agency.value} value={agency.value}>
                    {agency.label}
                  </option>
                ))}
              </Input>
            </Col>
            <Col>
              <Input placeholder="From" type="date" className="w-auto mt-2" />
            </Col>
            <Col>
              <Input placeholder="To" type="date" className="w-auto mt-2" />
            </Col>
          </Row>
          <Row sm={1} md={3} lg={3}>
            {apiRes
              ? apiRes.slots
                  .filter(
                    row =>
                      row.slotName.includes(selectedSlotName) &&
                      row.slotName.includes(selectedCollateralType)
                  )
                  .map(data => (
                    <Card
                      checked={selected.includes(data)}
                      onClick={() => handleImageClick(data.zoneId)}
                      className="m-4 cardforGenState"
                      style={{
                        backgroundColor: "#1d74b7",
                        color: "white",
                        padding: "5px",
                        width: "145px",
                      }}
                    >
                      <CardImg top className="img-fluid" src={img1} />
                      <Input
                        style={{ marginTop: "-15px", alignSelf: "flex-end" }}
                        type="checkbox"
                        checked={selected.includes(data)}
                        onChange={() => handleCheckboxClick(data)}
                      />
                      <Row className="m-1">{data.slotName}</Row>
                      <Row> {data.icon ? data.icon : null}</Row>
                      <span className="m-1 row fa fa-regular fa-plug">
                        &nbsp;{" "}
                        {data.powerAvailability != 0
                          ? data.powerAvailability
                          : " Power Unavailable"}
                      </span>
                      <span className="m-1 row fa fa-solid fa-ruler-combined">
                        &nbsp;{" "}
                        {data.dimensions ? data.dimensions : " Not available"}
                      </span>
                      <CardBody
                        style={{ marginTop: "-20px", paddingLeft: "15px" }}
                      >
                        <Row style={{ fontSize: "small" }}>
                          AED {data.amount}/Day
                        </Row>
                        <Row>Weight {data.weight}Kg</Row>
                        <Row>{data.slotDescription}</Row>
                      </CardBody>
                    </Card>
                  ))
              : null}
          </Row>
        </Col>
        <Col style={{ backgroundColor: "#d7dee6" }}>
          <>
            {!showForm && (
              <LeafletEditor viewQuoteData={apiRes} zoneId={selectedZone} />
            )}
            <Row>
              <Row>
                <Button
                  style={{ ...buttonStyle }}
                  variant="contained"
                  onClick={handleRaiseBookingRequest}
                >
                  Raise Booking Request
                </Button>
              </Row>
              {showForm && (
                <Col className="col-12">
                  <Form>
                    <div>
                      <h3>Notice</h3>
                      <h4>Document</h4>
                      <br />
                      <h6>1. Police Permit</h6>
                      <h6>2. Media Permit</h6>
                      <h6>3. Municipality Permit</h6>
                      <br />
                      <p>
                        Raising the Booking Request renders you automatically
                        binding to agree and comply with the{" "}
                        <u>Terms and Conditions</u> of this Contract.
                      </p>
                      <br />
                      <h4>Next Steps</h4>
                      <h6>1. Request accepted by Event</h6>
                      <h6>
                        2. Expediting payment within 72 hours of Request
                        Accepted
                      </h6>
                      <h6>
                        3. Furnishing Document mentioned within 72 hours of
                        processing payment.
                      </h6>
                    </div>
                    <Row>
                      <Col style={{ marginLeft: "45%" }}>
                        <Button
                          className="btn btn- waves-effect waves-light"
                          onClick={handleCancel}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            margin: "3px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          style={{
                            backgroundColor: "#fc8160",
                            color: "black",
                          }}
                        >
                          Accept and Proceed
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              )}
            </Row>
          </>
          <Row>
            <Row>
              <Col>
                {showTable && (
                  <Table style={{ color: "black", marginTop: "-20px" }}>
                    <tr>
                      <th>CART</th>
                      <th>
                        {selected.length} Slot {totalAmount} AED
                      </th>
                    </tr>
                  </Table>
                )}
              </Col>
            </Row>
            <Row>
              <Col style={{ overflowX: "auto" }}>
                <Table style={{ color: "black" }}>
                  <thead>
                    <tr>
                      <th>Slot</th>
                      <th>Collateral Type</th>
                      <th>Duration</th>
                      <th>Rate/Day</th>
                      <th>Amount</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selected.map((item, index) => {
                      const selectedType = apiRes1.find(
                        type => type.id === item.type
                      )
                      const collateralTypeName = selectedType
                        ? selectedType.Name
                        : "-"

                      return (
                        <tr key={index}>
                          <td>{item.slotName}</td>
                          <td>{collateralTypeName}</td>
                          <td>{numDays}/days</td>
                          <td>{item.amount}</td>
                          <td>{item.amount * numDays}</td>
                          <td>
                            <button onClick={() => removeSelected(index)}>
                              <img
                                src="https://img.icons8.com/color/48/null/cancel--v1.png"
                                height={20}
                              />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default EventsClient
