import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
} from "availity-reactstrap-validation"
import { axiosApi } from "helpers/api_helper"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

const AdminAddUser = props => {
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("")
  const [response, setresponse] = useState("")

  const handleValidSubmit = async (event, values) => {
    try {
      // Construct the payload explicitly
      const payload = {
        propertyName: values.propertyName,
        email: values.email,
        password: values.password,
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNo: values.mobileNo,
        // Include other fields if necessary
      };
  
      // Make the API call with the constructed payload
      const response = await axiosApi.post(
        "/admin/createPropertyOwnerAuth",
        values,
        {
          headers: {
            "Content-Type": "application/json" // Set the content type to application/json
          },
        }
      );
  
      setresponse(response);
      setbasic(true);
  
      // Handling the response
      if (response.status === 201) {
        setSweetTitle(response.data.message || "Register successfully");
      } else {
        setSweetTitle(response.data.message || "Registration Failed");
      }
    } catch (error) {
      setbasic(true);
      if (error.response && error.response.data) {
        // Extracting the error message from response
        setSweetTitle(error.response.data.message || "Registration Failed");
      } else {
        // Default error message or log for an unexpected error format
        setSweetTitle("Registration Failed");
      }
      console.error("Registration error:", error);
    }
  };
  

  return (
    <Container className="page-content">
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic && (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
              if (response && response.status === 201) {
                window.location.href = "/admin/userManagement/userList"
              } else {
                window.location.reload()
              }
            }}
          />
        )}
      </Col>
      <Row className="mb-4">
        <Card>
          <AvForm onValidSubmit={handleValidSubmit}>
            <CardTitle>New User</CardTitle>
            <Row>
              <Col xl="4" lg="4" md="12" className="mb-3">
                {/* First set of form fields */}
                <Label>Property Name</Label>
                <AvField
                  type="text"
                  name="propertyName"
                  placeholder="Property Name"
                  required
                />
                <Label>First Name</Label>
                <AvField
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  required
                />
                 <Label>Last Name</Label>
                <AvField
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  required
                />
                <Label>DOB</Label>
                <AvField type="date" name="dob"  required />
                <Label>Mobile No</Label>
                <AvField type="tel" name="mobileNo" placeholder="Phone no"  required />
                <Label>Gender</Label>
                <AvField
                  type="select"
                  name="gender"
                  className="form-select"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </AvField>
              </Col>
              <Col xl="4" lg="4" md="12" className="mb-3">
              <Label>Roles</Label>
                <AvField
                  type="select"
                  className="form-select"
                  name="role"
                  required
                >
                  <option value="">Select Option</option>
                  <option value="admin">Admin</option>
                  <option value="owner">owner</option>
                  <option value="agency">agency</option>
                  <option value="event">event</option>
                </AvField>
              <Label>Assign To</Label>
                <AvField type="select" className="form-select" name="assignTo"  required>
                  <option value="">Select Option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </AvField>
                <Label>Designation</Label>
                <AvField
                  type="text"
                  name="designation"
                  required
                  placeholder="Designation"
                />
                <Label>Location</Label>
                <AvField type="select" className="form-select" name="location"  required>
                  <option value="1">select location</option>
                  <option value="2">India</option>
                  <option value="3">UAE</option>
                </AvField>
                <Label>Emirates ID</Label>
                <AvField
                  type="text"
                  name="emiratesid"
                  placeholder="Emirates id"
                />
                <Label>Restricted Level</Label>
                <AvField
                  type="select"
                  className="form-select"
                  name="restriction"
                  required
                >
                  <option value="">Select Option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </AvField>
              
              </Col>
              <Col xl="4" lg="4" md="12" className="mb-3">
                <Label>Email</Label>
                <AvField
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />

                <Label>Username</Label>
                <AvField
                  type="text"
                  name="userName"
                  placeholder="username"
                  required
                />
                <div className="mb-3">
                  <AvGroup>
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      required
                      placeholder="Enter Password"
                      autoComplete="off"
                    />
                    <AvFeedback>Password is required</AvFeedback>
                  </AvGroup>
                </div>

                <div className="mb-3">
                  <AvGroup>
                    <AvField
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      validate={{ match: { value: "password" } }}
                      required
                      placeholder="Confirm Password"
                      autoComplete="off"
                    />
                    <AvFeedback>Passwords do not match</AvFeedback>
                  </AvGroup>
                </div>
                <Label>Image</Label>
                <AvField type="file" name="image" id="image">
                  Select User Image
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link to="./">
                  <Button
                    className="m-3"
                    color="danger"
                    style={{ width: "150px" }}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  color="primary"
                  type="submit"
                  style={{ width: "150px" }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default AdminAddUser
