import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardImg,
  CardTitle,
} from "reactstrap"

import defaultImage from "../../assets/images/defaultImage.png"
import currentImage from "../../assets/images/currentImage.png"

const AdminAddBookings = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    //setIsShown(true)
  }
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Card>
          <Row>
            <Col>
              <Input
                type="select"
                placeholder="Cam Agency"
                className="form-select"
              >
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="" className="form-select">
                <option>Region</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>Slot Type</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>Budget per day</option>
                <option>Ceiling</option>
                <option>Wall</option>
                <option>Floor</option>
              </Input>
            </Col>
            <Col>
              <Button>Search</Button>
            </Col>
          </Row>
        </Card>
        <Row>
          <Card className="p-3">
            <Row>
              <Row>
                <Col>
                  <CardTitle>Dalma Mall</CardTitle>
                </Col>
                <Col style={styles.Container.cardHeadingBackBtn}>
                  <Button className="col-2">Back</Button>
                </Col>
              </Row>
              <Row style={styles.Container.cardRow2}>
                <Button className="col-2 m-2">Level 01</Button>
                <Button className="col-2 m-2">Zone 01</Button>
                <Label className="col-2 m-2">Smart Slots</Label>
                <Input className="col-2 m-2" type="checkbox"></Input>
                <Button className="col-2 m-2" color="primary">
                  Documents
                </Button>
                <Button className="col-2 m-2" color="primary">
                  Plan View
                </Button>
              </Row>
            </Row>
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Slot ID</th>
                    <th>Default Image</th>
                    <th>Slot Name</th>
                    <th>Slot Type</th>
                    <th>Slot Details</th>
                    <th>Description</th>
                    <th>Popularity</th>
                    <th>GPS</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>

        <Row>
          <Card className="p-3">
            <Row>
              <Row>
                <Col>
                  <CardTitle>Lulu Mall</CardTitle>
                </Col>
                <Col style={styles.Container.cardHeadingBackBtn}>
                  <Button className="col-2">Back</Button>
                </Col>
              </Row>
              <Row style={styles.Container.cardRow2}>
                <Button className="col-2 m-2">Level 01</Button>
                <Button className="col-2 m-2">Zone 01</Button>
                <Label className="col-2 m-2">Smart Slots</Label>
                <Input className="col-2 m-2" type="checkbox"></Input>
                <Button className="col-2 m-2" color="primary">
                  Documents
                </Button>
                <Button className="col-2 m-2" color="primary">
                  Plan View
                </Button>
              </Row>
            </Row>
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Slot ID</th>
                    <th>Default Image</th>
                    <th>Slot Name</th>
                    <th>Slot Type</th>
                    <th>Slot Details</th>
                    <th>Description</th>
                    <th>Popularity</th>
                    <th>GPS</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Input className="col-2 m-2" type="checkbox"></Input>S
                      1001
                    </th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>In between KFC and Pizza Hut</td>
                    <td>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </td>
                    <td>
                      Longitude: 76.308411
                      <br />
                      Latitude: 10.026617
                      <br />
                      DMS Long: 76°18'30.2796° E
                    </td>
                    <td>499 AED/day</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
        {/* 👇️ show elements on click */}
        {isShown && (
          <Card
            style={{
              width: 250,
              backgroundColor: "lightgrey",
              padding: 20,
              marginTop: "-25%",
              marginLeft: "30%",
              position: "absolute",
            }}
          >
            <Row className="mb-3">
              <Col>
                <Label>From</Label>
                <Input type="date" placeholder="" className="form-select">
                  <option>From</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Label className="pt-3">To</Label>
                <Input type="date" placeholder="" className="form-select">
                  <option>To</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Button onClick={handleClick}>Done</Button>
            </Row>
          </Card>
        )}
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    padding: "100px 50px 00px 50px",
    maxWidth: "100%",
    cardHeadingBackBtn: {
      textAlign: "right",
    },
    cardRow2: {
      justifyContent: "space-around",
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminAddBookings
