import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const Separator = styled('div')(
  ({ theme }) => `
  height: ${theme.spacing(2)};
`,
);

const marks = [
  {
    value: 0,
    label: 'Min',
  },
  {
    value: 20,
    label: '200',
  },
  {
    value: 50,
    label: '800',
  },
  {
    value: 100,
    label: 'Max',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export default function AdWizardSlider() {
  return (
    <Box sx={{ width: 250 }}>
      <Typography id="track-false-range-slider" gutterBottom>
        Select the price range {"(AED)"}
      </Typography>
      <Slider disabled 
        track={false}
        aria-labelledby="track-false-range-slider"
        getAriaValueText={valuetext}
        defaultValue={[20, 50]}
        marks={marks}
      />
    </Box>
  );
}