
import dfcm1 from "../../../assets/images/mall-img/dfcm01.png"
import dfcm2 from "../../../assets/images/mall-img/dfcm02.png"
import dfcm3 from "../../../assets/images/mall-img/dfcm03.png"
import dfcm4 from "../../../assets/images/mall-img/dfcm04.png"
import dfcm5 from "../../../assets/images/mall-img/dfcm05.png"



const EventsIndoorData = [
  {
    id: 1,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 2,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 16,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 17,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 18,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 19,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
  {
    id: 20,
    title: "Christmas Fest",
    description:
      "Christmas Fest Downtown Dubai Ground, Beside Burj Al Arab, 500001",
    details: {
      address: "Downtown Dubai Ground, Beside Burj Al Arab, 500001",
      banned: "Cigarettes & Alcohols",
      footFall: "100,000",
      contact: "Tim (Manager)",
      gps: {
        lat: "10.026617",
        lon: "76.308411",
        country: "Dubai",
      },
    },
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/events/eventDetails",
  },
]

export { EventsIndoorData }
