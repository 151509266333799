import React from "react"
import { Link } from "react-router-dom"
import { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
  Card,
  Tooltip,
  CardText,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//components
import TagsInput from "./TagsInput"
import TagsInput2 from "./TagsInput2"
import "./styles.scss"
// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const EventsAddForm = props => {
  const [eventName, setEventName] = useState("")
  const [footFall, setFootFall] = useState("")
  const [address, setAddress] = useState("")
  const [features, setFeatures] = useState("")
  const [eventFrom, setEventFrom] = useState("")
  const [eventTo, setEventTo] = useState("")
  const [adExcluded, setAdExcluded] = useState("")
  const [gpsLat, setGpsLat] = useState("")
  const [gpsLon, setGpsLon] = useState("")
  const [images, setImages] = useState([])
  const [banner, setBanner] = useState([])
  const [logo, setLogo] = useState([])
  const [footer, setFooter] = useState([])
  const [description, setDescription] = useState("")
  const [webLink, setWebLink] = useState("")
  const [editorContent, setEditorContent] = useState(null)
  const [editorState, setEditorState] = useState("")
  const [showPublic, setShowPublic] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    instagram: '',
    facebook: '',
    twitter: '',
    linkedIn:'',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleFile(event) {
    setImages(event.target.files)
  }
  function handleFile1(event) {
    setBanner(event.target.files)
  }
  function handleFile2(event) {
    setLogo(event.target.files)
  }
  function handleFile3(event) {
    setFooter(event.target.files)
  }
  const handleShowPublicChange = (e) => {
    setShowPublic(e.target.value);
  };
  const handleEditorChange = newEditorState => {
    setEditorState(newEditorState)
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSocialMediaLinks({
      ...socialMediaLinks,
      [name]: value
    });
  };

  let handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("eventName", eventName)
    formData.append("description", description)
    formData.append("webLink", webLink)
    formData.append("footFall", footFall)
    formData.append("address", address)
    formData.append("features", features)
    formData.append("eventFrom", eventFrom)
    formData.append("eventTo", eventTo)
    formData.append("adExcluded", adExcluded)
    formData.append("gpsCoordinate", [gpsLat, gpsLon])
    formData.append("images", images[0])
    formData.append("images", images[1])
    formData.append("images", images[2])
    formData.append("images", images[3])
    formData.append("banner", banner[0])
    formData.append("logo", logo[0])
    formData.append("footer", footer[0])
    formData.append("showPublic", showPublic);
    formData.append("contractualAgreement", JSON.stringify(editorState))
    formData.append("socialMediaLinks", JSON.stringify(socialMediaLinks));

     setIsSubmitting(true);
    axiosApi
      .post("/event-organizer/create-event", formData)
      .then(response => {
        window.location.href = "/events/events"
      })
      .catch(error => {
        console.error(error)
      })
  }
  if (editorContent != null) {
  }
  // Tooltip Open state
  const [eventNameOpen, seteventNameOpen] = React.useState(false)
  const [footFallOpen, setFootFallOpen] = React.useState(false)
  const [addressOpen, setAddressOpen] = React.useState(false)
  const [featuresOpen, setFeaturesOpen] = React.useState(false)
  const [fromOpen, setFromOpen] = React.useState(false)
  const [toOpen, setToOpen] = React.useState(false)
  const [fileOpen, setFileOpen] = React.useState(false)
  const [contentTypeExcOpen, setContentTypeExcOpen] = React.useState(false)
  const [gpsOpen, setGPSOpen] = React.useState(false)
  const [bannerOpen, setBannerOpen] = React.useState(false)
  const [logoOpen, setLogoOpen] = React.useState(false)
  const [footerOpen, setFooterOpen] = React.useState(false)
  const [contractOpen, setContractOpen] = React.useState(false)
  const [webLinkOpen, setWebLinkOpen] = React.useState(false)
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)
  const [socialMediaLink, setSocialMediaLink] = React.useState(false)
  

  const selectedTags = tags => setFeatures(tags)
  const selectedTags2 = tags2 => setAdExcluded(tags2)

  return (
    <div className="page-content">
      <Card className="shadow-sm mt-3">
        <CardText className="fs-3 ms-5">Add New Event</CardText>
      </Card>

      <Card
        className="shadow-sm pt-2"
        style={{ paddingLeft: 15, paddingRight: 15 }}
      >
        <Form onSubmit={handleSubmit} enctype="multipart/form-data">
          <Row sm={1} md={2} lg={2}>
            <Col>
              <FormGroup style={styles.formGroup}>
                <Label for="exampleEmail">Event Name</Label>
                <span id="TooltipExample4" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={eventNameOpen}
                  placement="right"
                  target="TooltipExample4"
                  toggle={() => {
                    seteventNameOpen(!eventNameOpen)
                  }}
                >
                  eg. abc
                </Tooltip>
                <Input
                  type="text"
                  value={eventName}
                  className="w-100"
                  id="exampleEmail"
                  onChange={e => setEventName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="exampleEmail">Description</Label>
                <span id="TooltipExample5" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={descriptionOpen}
                  placement="right"
                  target="TooltipExample5"
                  toggle={() => {
                    setDescriptionOpen(!descriptionOpen)
                  }}
                >
                  eg. Description about item
                </Tooltip>
                <Input
                  type="textarea"
                  className="w-100"
                  value={description}
                  id="DescriptionExample3"
                  onChange={e => setDescription(e.target.value)}
                  required
                />
              </FormGroup>
       
              <FormGroup style={styles.formGroup}>
                <Label for="exampleText">Address</Label>
                <span id="TooltipExample3" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={addressOpen}
                  placement="right"
                  target="TooltipExample3"
                  toggle={() => {
                    setAddressOpen(!addressOpen)
                  }}
                >
                  eg. Financial Center Street, Along Sheikh Zayed Road, Next to
                  Burj Khalifa - Dubai - United Arab Emirates
                </Tooltip>
                <Input
                  type="textarea"
                  className="w-100"
                  value={address}
                  id="exampleText"
                  onChange={e => setAddress(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="exampleEmail">WebLink</Label>
                <span id="TooltipExample6" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={webLinkOpen}
                  placement="right"
                  target="TooltipExample6"
                  toggle={() => {
                    setWebLinkOpen(!webLinkOpen)
                  }}
                >
                  eg. www.viewitonme.com
                </Tooltip>
                <Input
                  type="text"
                  className="w-100"
                  value={webLink}
                  id="exampleWeblink"
                  onChange={e => setWebLink(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
              <Label for="socialMediaLinks">Social Media </Label>
              <span id="TooltipExample11" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={socialMediaLink}
                  placement="right"
                  target="TooltipExample11"
                  toggle={() => {
                    setSocialMediaLink(!socialMediaLink)
                  }}
                >www.facebook/profilename</Tooltip>
          <Input
          className="mb-1"
        type="url"
        name="instagram"
        placeholder="Instagram URL"
        value={socialMediaLinks.instagram}
        onChange={handleInputChange}
      />
      <Input
        className="mb-1"
        type="url"
        name="facebook"
        placeholder="Facebook URL"
        value={socialMediaLinks.facebook}
        onChange={handleInputChange}
      />
      <Input
        className="mb-1"
        type="url"
        name="twitter"
        placeholder="Twitter URL"
        value={socialMediaLinks.twitter}
        onChange={handleInputChange}
      />
       <Input
       className="mb-1"
        type="url"
        name="linkedIn"
        placeholder="linkedIn URL"
        value={socialMediaLinks.linkedIn}
        onChange={handleInputChange}
      />
          </FormGroup>
            </Col>
            <Row>
              <Col>
                <FormGroup style={styles.formGroup}>
                  <Row>
                    <Col>
                      <Label>From</Label>
                      <span
                        id="TooltipFrom"
                        className="ms-1 fa fa-info-circle"
                      />
                      <Tooltip
                        isOpen={fromOpen}
                        placement="right"
                        target="TooltipFrom"
                        toggle={() => {
                          setFromOpen(!fromOpen)
                        }}
                      >
                        Event start date
                      </Tooltip>
                      <Input
                        type="date"
                        className="w-100"
                        value={eventFrom}
                        onChange={e => setEventFrom(e.target.value)}
                        required
                      />
                    </Col>
                    <Col>
                      <Label>To</Label>
                      <span id="TooltipTo" className="ms-1 fa fa-info-circle" />
                      <Tooltip
                        isOpen={toOpen}
                        placement="right"
                        target="TooltipTo"
                        toggle={() => {
                          setToOpen(!toOpen)
                        }}
                      >
                        Event end date
                      </Tooltip>
                      <Input
                        type="date"
                        className="w-100"
                        value={eventTo}
                        onChange={e => setEventTo(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                
                <FormGroup style={styles.formGroup}>
                  <Label for="gps">GPS Coordinates</Label>
                  <span id="TooltipGPS" className="ms-1 fa fa-info-circle" />
                  <Tooltip
                    isOpen={gpsOpen}
                    placement="right"
                    target="TooltipGPS"
                    toggle={() => {
                      setGPSOpen(!gpsOpen)
                    }}
                  >
                    eg. lat 40.741895 lon -73.989308
                  </Tooltip>
                  <Row
                    sm={1}
                    md={2}
                    lg={2}
                    style={{ marginLeft: "auto" }}
                    className="justify-content-between"
                  >
                    <Input
                      value={gpsLat}
                      className="mb-3"
                      placeholder="lat"
                      onChange={e => setGpsLat(e.target.value)}
                      required
                      style={{ width: "45%" }}
                    />
                    <Input
                      value={gpsLon}
                      className="mb-3"
                      placeholder="lon"
                      onChange={e => setGpsLon(e.target.value)}
                      required
                      style={{ width: "45%" }}
                    />
                  </Row>
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                    <Label for="exampleEmail">Show Public</Label>
                    <Input
                      type="select"
                      className="w-50"
                      value={showPublic}
                      onChange={handleShowPublicChange}
                    >
                    <option>Select</option>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                  </Input>
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                <Label for="exampleEmail">Footfall/Day</Label>
                <span id="TooltipExample2" className="ms-1 fa fa-info-circle" />
                <Tooltip
                  isOpen={footFallOpen}
                  placement="right"
                  target="TooltipExample2"
                  toggle={() => {
                    setFootFallOpen(!footFallOpen)
                  }}
                >
                  eg 100.000
                </Tooltip>
                <Input
                  type="number"
                  className="w-100"
                  value={footFall}
                  id="exampleEmail"
                  onChange={e => setFootFall(e.target.value)}
                  required
                />
              </FormGroup>
                <Col>
                  <FormGroup style={styles.formGroup} className="mt-3">
                    <Label for="req-doc">Content Type Excluded</Label>
                    <span
                      id="TooltipContentTypeExc"
                      className="ms-1 fa fa-info-circle"
                    />
                    <Tooltip
                      isOpen={contentTypeExcOpen}
                      placement="right"
                      target="TooltipContentTypeExc"
                      toggle={() => {
                        setContentTypeExcOpen(!contentTypeExcOpen)
                      }}
                    >
                      eg. Smoking, Drinking, Nudity etc...
                    </Tooltip>
                    <br />
                    <Form action="javascript:void(0)">
                      <TagsInput2 selectedTags2={selectedTags2} />
                    </Form>
                  </FormGroup>
                  <FormGroup style={styles.formGroup}>
                    <Label for="req-doc">Features</Label>
                    <span
                      id="TooltipFeatures"
                      className="ms-1 fa fa-info-circle"
                    />
                    <Tooltip
                      isOpen={featuresOpen}
                      placement="right"
                      target="TooltipFeatures"
                      toggle={() => {
                        setFeaturesOpen(!featuresOpen)
                      }}
                    >
                      eg. Kid Friendly, Pet Friendly etc...
                    </Tooltip>
                    <br />
                    <Form action="javascript:void(0)">
                      <TagsInput selectedTags={selectedTags} />
                    </Form>
                  </FormGroup>
  
                </Col>
              </Col>
            </Row>
            <br />
            <Row style={{ width: "100%" }} sm={1} md={4} lg={4}>
              <Col>
                <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                  <Label for="exampleFile">Logo</Label>
                  <span id="TooltipLogo" className="ms-1 fa fa-info-circle" />
                  <Tooltip
                    isOpen={logoOpen}
                    placement="right"
                    target="TooltipLogo"
                    toggle={() => {
                      setLogoOpen(!logoOpen)
                    }}
                  ></Tooltip>
                  <Input
                    type="file"
                    name="images"
                    onChange={handleFile2}
                    required
                  />
                  <FormText color="muted">
                    <Row className="mt-2 px-2">
                      Please choose an image for the event to be added
                    </Row>
                  </FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                  <Label for="exampleFile">Banner</Label>
                  <span id="TooltipBanner" className="ms-1 fa fa-info-circle" />
                  <Tooltip
                    isOpen={bannerOpen}
                    placement="right"
                    target="TooltipBanner"
                    toggle={() => {
                      setBannerOpen(!bannerOpen)
                    }}
                  ></Tooltip>
                  <Input
                    type="file"
                    name="images"
                    onChange={handleFile1}
                    required
                  />
                  <FormText color="muted">
                    <Row className="mt-2 px-2">
                      Please choose an image for the event to be added
                    </Row>
                  </FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                  <Label for="exampleFile">Footer</Label>
                  <span id="TooltipFooter" className="ms-1 fa fa-info-circle" />
                  <Tooltip
                    isOpen={footerOpen}
                    placement="right"
                    target="TooltipFooter"
                    toggle={() => {
                      setFooterOpen(!footerOpen)
                    }}
                  ></Tooltip>
                  <Input
                    type="file"
                    name="images"
                    onChange={handleFile3}
                    required
                  />
                  <FormText color="muted">
                    <Row className="mt-2 px-2">
                      Please choose an image for the event to be added
                    </Row>
                  </FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ ...styles.formGroup, width: "auto" }}>
                  <Label for="exampleFile">Images</Label>
                  <span id="TooltipFile" className="ms-1 fa fa-info-circle" />
                  <Tooltip
                    isOpen={fileOpen}
                    placement="right"
                    target="TooltipFile"
                    toggle={() => {
                      setFileOpen(!fileOpen)
                    }}
                  ></Tooltip>
                  <Input
                    type="file"
                    multiple
                    name="images"
                    onChange={handleFile}
                    required
                  />
                  <FormText color="muted">
                    <Row className="mt-2 px-2">
                      Please choose 4 images for the event to be added
                      <br />
                      *The first image will be used as image thumbnail
                    </Row>
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup style={{ ...styles.formGroup, width: "100%" }}>
              <Label for="exampleText">Contractual Agreement</Label>
              <span id="TooltipContract" className="ms-1 fa fa-info-circle" />
              <Tooltip
                isOpen={contractOpen}
                placement="right"
                target="TooltipContract"
                toggle={() => {
                  setContractOpen(!contractOpen)
                }}
              >
                eg. abc
              </Tooltip>
              <CKEditor
                editor={ClassicEditor}
                data={editorContent}
                onReady={editor => {}}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  console.log({ event, editor, data })
                  handleEditorChange(data)
                }}
              />
            </FormGroup>
            <Row style={{ width: "100%" }}>
  <Col
    style={{
      display: "inline-flex",
      justifyContent: "end",
      marginBottom: "25px",
    }}
  >
    <Link to="/events/events">
      <Button
        color="danger"
        style={{ marginBottom: 10, marginLeft: "20px", width: "120px" }}
      >
        Cancel
      </Button>
    </Link>
    {isSubmitting ? (
      <Button
        color="primary"
        style={{ marginBottom: 10, marginLeft: "20px", width: "120px" }}
        disabled
      >
        Submitting...
      </Button>
    ) : (
      <Button
        color="primary"
        style={{ marginBottom: 10, marginLeft: "20px", width: "120px" }}
        name="submit"
        disabled={isSubmitting} 
        onClick={handleSubmit}
      >
        Submit
      </Button>
    )}
  </Col>
</Row>

          </Row>
        </Form>
      </Card>
    </div>
  )
}
const styles = {
  formGroup: {
    paddingBottom: 15,
  },
}
export default EventsAddForm
