import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

const AdminAddNewContentTypeExcluded = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card className="p-3">
          <Form>
            <CardTitle>Content Type Excluded</CardTitle>
            <Row className="col-8">
              <Label>Mall Name</Label>
              <Input type="text" placeholder="Lulu Mall" name="notificationName" required />
              <Label>Content Type Excluded</Label>
              <Input type="text" placeholder="Eg. Alcohol, Cigarette" />
              <Button color="primary">Add Image</Button>
              <Input type="textarea" placeholder="Description" className="form-select"/>
            </Row>
            <Row>
              <Col className="col-10"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
                <Link to="./">
                  <Button color="primary">Add</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default AdminAddNewContentTypeExcluded
