import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardImg,
} from "reactstrap"

import defaultImage from "../../assets/images/defaultImage.png"
import currentImage from "../../assets/images/currentImage.png"

//components
import UiModal from "./AdminSmartSlotsEdit"

const AdminSmartSlotsDashboard = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    //setIsShown(true)
  }
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Row>
            <Col className="col-11">
              <CardText>
                <h5>Smart Slots</h5>
              </CardText>
            </Col>
            <Col>
              <Link to="/admin/smartSlots/addNew">
                <Button color="primary">
                  <i className="fa fa-plus"></i>Add
                </Button>
              </Link>
            </Col>
          </Row>
          <Card className="p-3">
            <Row>
              <Col>
                <Input
                  type="select"
                  placeholder="Cam Agency"
                  className="form-select"
                >
                  <option>Building Name</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Level</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="" className="form-select">
                  <option>Zones</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Smart Slots</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Type</option>
                  <option>Ceiling</option>
                  <option>Wall</option>
                  <option>Floor</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Status</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Slot ID</th>
                    <th>Default Image</th>
                    <th>Current Image</th>
                    <th>Slot Name</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Capacity</th>
                    <th>Slot Details</th>
                    <th>Booking (Current Month)</th>
                    <th>Under Maintenance</th>
                    <th>Active</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">S 1001</th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>
                      <CardImg src={currentImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">S 1001</th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>
                      <CardImg src={currentImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">S 1001</th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>
                      <CardImg src={currentImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">S 1001</th>
                    <td>
                      <CardImg src={defaultImage} />
                    </td>
                    <td>
                      <CardImg src={currentImage} />
                    </td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 inch
                      <br /> Height 50 inch <br /> Weight 15 Kg
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal/>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
        {/* 👇️ show elements on click */}
        {isShown && (
          <Card
            style={{
              width: 250,
              backgroundColor: "lightgrey",
              padding: 20,
              marginTop: "-25%",
              marginLeft: "30%",
              position: "absolute",
            }}
          >
            <Row className="mb-3">
              <Col>
                <Label>From</Label>
                <Input type="date" placeholder="" className="form-select">
                  <option>From</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Label className="pt-3">To</Label>
                <Input type="date" placeholder="" className="form-select">
                  <option>To</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Button onClick={handleClick}>Done</Button>
            </Row>
          </Card>
        )}
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    padding: "100px 50px 00px 50px",
    maxWidth: "100%",
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminSmartSlotsDashboard
