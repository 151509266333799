import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"
// availity-reactstrap-validation
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation"
import { AvFeedback } from "availity-reactstrap-validation"

import { registerUser, apiError, registerUserFailed } from "../../store/actions"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
// import images
import logoSm from "../../assets/images/logo-sm.png"
import { axiosApi } from "helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"

const countries = [
  { name: "India", code: "IN", prefix: "+91", phoneDigits: 13 },
  { name: "United Arab Emirates", code: "AE", prefix: "+971", phoneDigits: 13 }
]

const Register = props => {
  const [basic, setBasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("")
  const [response, setResponse] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("India")
  const [phonePrefix, setPhonePrefix] = useState("+91")
  const [phoneDigits, setPhoneDigits] = useState(13)

  const handleValidSubmit = async (event, values) => {
    try {
      values.role = "admin"
      values.createdBy = 1
      values.owner = "admin"
      values.countryId = countries.find(
        country => country.name === selectedCountry
      )?.code
      const response = await axiosApi.post("/createPropertyOwner", values)
      setResponse(response)
      setBasic(true)
      if (response.status === 201) {
        setSweetTitle(response.message || "Register successfully")
      } else {
        setSweetTitle(response.data.message || "Registration Failed")
      }
    } catch (error) {
      setBasic(true)
      setSweetTitle(error.response.data.message || "Registration Failed")
      console.error("Registration error:", error)
      console.log(response.message)
    }
  }

  const handleCountryChange = event => {
    const selectedCountry = event.target.value
    const selectedCountryData = countries.find(
      country => country.name === selectedCountry
    )
    setSelectedCountry(selectedCountry)
    setPhonePrefix(selectedCountryData?.prefix)
    setPhoneDigits(selectedCountryData?.phoneDigits)
  }

  const validatePhoneNumber = (value, ctx, input, cb) => {
    const isValid = value && value.length === phoneDigits
    cb(isValid)
  }

  useEffect(() => {
    props.apiError("")
  }, [])

  return (
    <React.Fragment>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic && (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setBasic(false)
              if (response && response.status === 201) {
                window.location.href = "/login"
              } else {
                window.location.reload()
              }
            }}
          />
        )}
      </Col>

      <MetaTags>
        <title>Register | VIOM - Responsive Bootstrap 5 Admin Dashboard</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={4}>
            <Card className="overflow-hidden">
              <div className="bg-primary">
                <div className="text-primary text-center p-4">
                  <h5 className="text-white font-size-20">Free Register</h5>
                  <p className="text-white-50">
                    Get your free VIOM account now.
                  </p>
                  <a href="/login" className="logo logo-admin">
                    <img src={logoSm} height="24" alt="logo" />
                  </a>
                </div>
              </div>
              <CardBody className="p-4">
                <div className="p-3">
                  <AvForm
                    className="mt-4"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {props.user && props.user ? (
                      <Alert color="success">Register User Successfully</Alert>
                    ) : null}

                    {props.registrationError && props.registrationError ? (
                      <Alert color="danger">{props.registrationError}</Alert>
                    ) : null}
                     {/* <Row>
                      <Col>
                        <div className="mb-3">
                          <AvField
                            id="name"
                            name="firstName"
                            label="First Name"
                            className="form-control"
                            placeholder="Enter First Name"
                            type="text"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="lastName"
                            label="Last Name"
                            className="form-control"
                            placeholder="Enter Last Name"
                            required
                            autoComplete="off"
                          >
                          </AvField>
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <AvField
                            id="name"
                            name="propertyName"
                            label="Name"
                            className="form-control"
                            placeholder="Enter Company Name"
                            type="text"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvField
                            type="select"
                            name="location"
                            label="Country"
                            className="form-control"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            autoComplete="off"
                          >
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </AvField>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <AvField
                            id="email"
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <AvGroup>
                            <AvField
                              id="phoneNumber"
                              name="mobileNo"
                              label="Phone Number"
                              className="form-control"
                              placeholder={`(${phonePrefix})Enter Phone Number `}
                              required
                              value={`${phonePrefix}`}
                              autoComplete="off"
                              validate={{ custom: validatePhoneNumber }}
                            />
           
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <AvField
                        name="userName"
                        label="Username"
                        type="text"
                        required
                        placeholder="Enter username"
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-3">
                      <AvGroup>
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                          autoComplete="off"
                        />
                        <AvFeedback>Password is required</AvFeedback>
                      </AvGroup>
                    </div>

                    <div className="mb-3">
                      <AvGroup>
                        <AvField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          validate={{ match: { value: "password" } }}
                          required
                          placeholder="Confirm Password"
                          autoComplete="off"
                        />
                        <AvFeedback>Passwords do not match</AvFeedback>
                      </AvGroup>
                    </div>
                    {/* <Row>
                      <Col>
                        <Input type="checkbox" className="me-2" />
                        Event Organizer
                      </Col>
                      <Col>
                        <Input type="checkbox" className="me-2" />
                        Ad Agency
                      </Col>
                      <Col>
                        <Input type="checkbox" className="me-2" />
                        Media Owner
                      </Col>
                    </Row> */}
                    <div className="mb-3 mt-2 row">
                      <div className="col-12 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </div>

                    <div className="mt-2 mb-0 row">
                      <div className="col-12 mt-4">
                        <p className="mb-0">
                          By registering you agree to the VIOM{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Already have an account ?{" "}
                <Link to="/login" className="fw-medium text-primary">
                  {" "}
                  Login
                </Link>{" "}
              </p>
              <p>
                © {new Date().getFullYear()} VIOM. Crafted with{" "}
                <i className="mdi mdi-heart text-danger" /> by Themesbrand
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
