import { useState } from "react";
import "./VisitedRenderer2.css"; // Import CSS file with custom styles

const VisitedRenderer2 = (props) => {
  const [isChecked2, setIsChecked2] = useState(props.active);
  const toggleButton = () => setIsChecked2(!isChecked2);

  const handleOnChange = () => {
    props.onMaintenanceChange2();
    setIsChecked2(!isChecked2);
  };
console.log("isChecked2",isChecked2);
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked2}
        onClick={handleOnChange}
      />
      <span className="slider"></span>
      <span className="text">{isChecked2 ? "YES" : "NO"}</span>
    </label>
  );
};

export default VisitedRenderer2;
