import React from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Input,
  CardBody,
  Table,
} from "reactstrap"
import classnames from "classnames"

// Charts
// import LineAreaChart from "../AllCharts/apex/lineareachart";
// import SparkLine1 from "../AllCharts/sparkline/sparkline1";
// import dountchart from "pages/AllCharts/chartist/dountchart";
import "chartist/dist/scss/chartist.scss"
import Bubblechart from "pages/AllCharts/echart/bubblechart"

export default class AdminRepoertsDetails extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {
    return (
      <Container style={styles.container} className="page-content">
        <Row>
          <Col>
            <CardTitle>Agency Report</CardTitle>
          </Col>
          <Col style={styles.container.col2}>
            <i className="fa fa-print p-3"></i>
            <i className="fa fa-envelope"></i>
          </Col>
        </Row>
        <Nav pills className="navtab-bg nav-justified">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1")
              }}
            >
              Listing
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2")
              }}
            >
              Booking
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3")
              }}
            >
              Revenue
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="pt-3">
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Card style={styles.tabPane1}>
                  <Row>
                    <Col>
                      <Input type="date"></Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2018</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2022</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  <Row>
                    <Col>
                      <Bubblechart />
                    </Col>
                    <Col>
                      <Row style={styles.tabPane1.content}>
                        <CardText>
                          Details<i className="fa fa-exclamation-circle"></i>
                        </CardText>
                        <CardBody>
                          No of new booking increased by 15% from last 3 years
                        </CardBody>
                      </Row>
                      <Row style={styles.tabPane1.row}>
                          <Button style={styles.tabPane1.btnSize} color="danger">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Print</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Generate</Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <CardTitle>Agency Details</CardTitle>
                  </Row>
                  <Row className="p-3">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Agency Name</th>
                          <th>Created On</th>
                          <th>Created By</th>
                          <th>Detals</th>
                          <th>Mail ID</th>
                          <th>Contact NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Card style={styles.tabPane1}>
                  <Row>
                    <Col>
                      <Input type="date"></Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2018</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2022</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>Property Name</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>Agency Name</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  <Row>
                    <Col>
                      <Bubblechart />
                    </Col>
                    <Col>
                      <Row style={styles.tabPane1.content}>
                        <CardText>
                          Details<i className="fa fa-exclamation-circle"></i>
                        </CardText>
                        <CardBody>
                          No of new booking increased by 15% from last 3 years
                        </CardBody>
                      </Row>
                      <Row style={styles.tabPane1.row}>
                          <Button style={styles.tabPane1.btnSize} color="danger">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Print</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Generate</Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <CardTitle>Booking Details</CardTitle>
                  </Row>
                  <Row className="p-3">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Agency Name</th>
                          <th>Created On</th>
                          <th>Created By</th>
                          <th>Detals</th>
                          <th>Mail ID</th>
                          <th>Contact NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Card style={styles.tabPane1}>
                  <Row>
                    <Col>
                      <Input type="date"></Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2018</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>2022</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>Property Name</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="select">
                        <option>Agency Name</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </Input>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  <Row>
                    <Col>
                      <Bubblechart />
                    </Col>
                    <Col>
                      <Row style={styles.tabPane1.content}>
                        <CardText>
                          Details<i className="fa fa-exclamation-circle"></i>
                        </CardText>
                        <CardBody>
                          No of new booking increased by 15% from last 3 years
                        </CardBody>
                      </Row>
                      <Row style={styles.tabPane1.row}>
                          <Button style={styles.tabPane1.btnSize} color="danger">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Print</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Reset</Button>
                          <Button style={styles.tabPane1.btnSize} color="primary">Generate</Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <CardTitle>Revenue Details</CardTitle>
                  </Row>
                  <Row className="p-3">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Agency Name</th>
                          <th>Created On</th>
                          <th>Created By</th>
                          <th>Detals</th>
                          <th>Mail ID</th>
                          <th>Contact NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                        <tr>
                          <td>CAM11</td>
                          <td>CAM Agency</td>
                          <td>12 November 2019</td>
                          <td>David Moes</td>
                          <td>Suite 17, First Floor, DRm Building, Dubai</td>
                          <td>camagency@gmail.com</td>
                          <td>050123456</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    )
  }
}

const styles = {
  container: {
    maxWidth: "100%",
    col2: {
      textAlign: "right",
    },
  },
  tabPane1: {
    row: {
        justifyContent: "space-between"
    },
    backgroundColor: "lightgrey",
    padding: 8,
    content: {
      paddingBottom: "33%",
    },
    btnSize: {
        width: 100
    },
  },
}
