import React from "react"
import { Link } from "react-router-dom"
import "./EventsCardIndoor.css"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardImgOverlay,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { axiosApi } from "../../../helpers/api_helper"
import { useState, useEffect } from "react"
// import component
import placeholderImage from "../../../assets/images/placeholderImg.webp"
import SweetAlert from "react-bootstrap-sweetalert"
import { idID } from "@mui/material/locale"

const EventsCardIndoor = props => {
  const [isLoading, setLoading] = useState(true)
  const [apiRes, setApiRes] = useState()
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("")
  const [toggleStates, setToggleStates] = useState([])

  useEffect(() => {
    axiosApi.get("/event-organizer/events").then(res => {
      setApiRes(res)
      setLoading(false)
    })
  }, [])

  const handleClick = async id => {
    const formData = new FormData()
    formData.append("id", id)
    axiosApi
      .delete("/event-organizer/event?id=" + id, formData)
      .then(response => {
        window.location.href = "/events/events"
      })
      .catch(error => {
        console.error(error)
        setbasic(true)
        setSweetTitle(error.response.data.message || "Error occurred")
      })
  }

  const handleToggle = async (id, index) => {
    const eventId = id
    try {
      const response = await axiosApi.post(
        "/event-organizer/toggle-event-public-status",
        { eventId }
      )
      const updatedApiRes = { ...apiRes }
      updatedApiRes.data.data[index].isPublic =
        !apiRes.data.data[index].isPublic
      setApiRes(updatedApiRes)

      const newToggleStates = [...toggleStates]
      newToggleStates[index] = !toggleStates[index] // Toggle the state of the clicked card
      setToggleStates(newToggleStates)
    } catch (error) {
      console.error("Error:", error)
      // Handle error if needed
    }
  }

  function truncateText(selector, maxLength) {
    var truncated = selector

    if (truncated.length > maxLength) {
      truncated = truncated.substr(0, maxLength) + "..."
    }
    return truncated
  }
  if (isLoading) {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }
  return (
    <React.Fragment>
      <div>
        <Col xl="3" lg="4" sm="6" className="mb-2">
          {basic ? (
            <SweetAlert
              title={sweetTitle}
              onConfirm={() => {
                setbasic(false)
              }}
            />
          ) : null}
        </Col>
        <div className="cards-grid">
          {apiRes.data.data.map((cards, index) => (
            <Col key={cards.id} className="flex-grow-0">
              <Card
                style={{
                  padding: "2.2%",
                  width: 250,
                  minWidth: 250,
                  marginBottom: 50,
                  borderRadius: 7,
                  boxShadow:
                    "-10px 0px 5px -12px, 4px 2px 8px 0px, 9px 0px 0px -9px",
                }}
              >
                <Link
                  to={"../events/eventDetails?" + cards.id}
                  style={{ zIndex: 99 }}
                >
                  <CardImg
                    top
                    className="img-fluid"
                    src={cards.imageURLs[0] || placeholderImage}
                    alt={cards.description}
                    style={{
                      objectFit: "cover",
                      height: 150,
                      borderRadius: 10,
                    }}
                  />
                </Link>

                <CardImgOverlay>
                  <CardTitle
                    style={{
                      background: "black",
                      opacity: 0.6,
                      height: 25,
                      width: 238,
                      position: "absolute", // child
                      bottom: "45%", // position where you want
                      left: 6,
                      zIndex: 99,
                      borderRadius: 5,
                    }}
                    className="text-white mt-0 h4"
                  >
                    &nbsp;  {truncateText(cards.eventName, 23)}
                    <UncontrolledDropdown
                      style={{ float: "right", direction: "ltr" }}
                    >
                      <DropdownToggle
                        style={{
                          color: "white",
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          marginTop: "-4px",
                        }}
                      >
                        <i className="fas fa-ellipsis-v"></i>
                      </DropdownToggle>
                      <DropdownMenu
                        style={{
                          minWidth: "15%",
                          left: "auto",
                          right: 0,
                          backgroundColor: "black",
                          opacity: "1.0",
                        }}
                      >
                        <DropdownItem style={{ color: "white" }}>
                          <Link to={"./eventEditForm?" + cards.id}>
                            <Button
                              to={"./eventEditForm?" + cards.id}
                              className="fa fa-edit"
                            ></Button>
                          </Link>
                        </DropdownItem>
                        <DropdownItem style={{ width: "100%" }}>
                          {/* <EventDelete id={cards.id} /> */}
                          <Button
                            className="fa fa-trash "
                            style={{ zIndex: 9999, width: "100%" }}
                            onClick={() => handleClick(cards.id)}
                          ></Button>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <Link to="#">
                          <i style={{ float: 'right' }} className="ti-more-alt"></i>
                        </Link> */}
                  </CardTitle>
                </CardImgOverlay>
                <CardBody>
                  
                  <p className="text-wrap mb-0" style={{ fontWeight: "bold", marginLeft:'-15px', marginTop:'-10px' }}>
                  &#128197; {cards.eventFrom} - {cards.eventTo}
                  </p>
                  <p className="text-wrap  fa fa-building" style={{ marginLeft:'-12px' }}>
                    {" "}
                    &nbsp;
                    {/* {truncateText(cards.eventName, 100)} */}
                    {truncateText(cards.address, 25)}
                  </p>{" "}
                  &nbsp;
                  <br/>
                
                  <p style={{marginLeft:'-12px', marginTop:'-5px'}}><i  className="fa fa-square me-3"/> {cards.slotsNos} &nbsp;
                  &#x1F463; {truncateText(cards.footFall, 100)}</p>
                  <CardTitle
                    style={{
                      opacity: 0.6,
                      height: 15,
                      width: 235,
                      position: "absolute", 
                      bottom: "50%", 
                      left: 6,
                      zIndex: 99,
                      borderRadius: 5,
                    }}
                    className="text-white mt-0 h4"
                  >
                    <span
                      style={{
                        float: "left",
                        marginRight: "12px",
                        cursor: "pointer",
                        marginTop: "130px",
                        paddingLeft:'8px',
                        color: cards.isPublic ? "green" : "red", 
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        handleToggle(cards.id, index) 
                      }}
                      title={ "Click to make public"} 
                    >
                      <span style={{color:'black'}}>Public </span>
                      {cards.isPublic ? (
                        <i className="fas fa-toggle-on"></i>
                      ) : (
                        <i className="fas fa-toggle-off"></i>
                      )}
                    </span>
                  </CardTitle>
                  {/* <p className="card-text">
                      <small className="text-muted">
                        Last updated 3 mins ago
                      </small>
                    </p> */}
                </CardBody>
                <Row style={{ zIndex: 9999 }}>
                  {/* <Col>
                      <EventDelete id={cards.id} />
                    </Col>
                    <Col>
                      <Link to={"./eventEditForm?" + cards.id}>
                        <Button
                          className="fa fa-edit bg-primary"
                          style={{ zIndex: 9999, width: "100%" }}
                        ></Button>
                      </Link>
                    </Col> */}
                </Row>
              </Card>
            </Col>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
export default EventsCardIndoor
