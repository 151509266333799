import React from "react"

import { Col, Container, Row } from "reactstrap"

const EventsSettingsDashboard = props => {
  return (
    <React.Fragment>
      <Container className="page-content">
        <Row></Row>
      </Container>
    </React.Fragment>
  )
}

export default EventsSettingsDashboard
