import React, { useEffect, useState } from "react"
import {
  Card,
  Row,
  Col,
  Container,
  Input,
  Label,
  Button,
  Spinner,
  CardText,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//components
import UiModal2 from "./EventsSlotsExportModal"
import AgGrid from "./AgGrid"

const VISIBLE_FIELDS = ["name", "rating", "country", "dateCreated", "isAdmin"]

const EventsSlotDashboard = props => {
  const [dataFromChild, setDataFromChild] = useState(null)
  const [dataFromChild2, setDataFromChild2] = useState(null)
  const [bookingFrom, setBookingFrom] = useState(null)
  const [bookingTo, setBookingTo] = useState(null)
  const [undermaintance, setUndermaintance] = useState(null)
  const [checkBox, setCheckBox] = useState("")
  const [isLoading, setLoading] = useState(true)
  const [apiRes, setApiRes] = useState()
  const [isShown, setIsShown] = useState(false)

  const handleChildData = data => {
    setDataFromChild(data)
  }
  const handleChildData2 = data => {
    setDataFromChild2(data)
  }
  const handleChildData3 = data => {
    setBookingFrom(data)
  }
  const handleChildData4 = data => {
    setBookingTo(data)
  }
  const handleChildData5 = data => {
    setCheckBox(data)
  }
  const handleChildData6 = data => {
    setUndermaintance(data)
  }

  useEffect(() => {
    axiosApi.get("/event-organizer/list-event-slot").then(res => {
      setApiRes(res)
      setLoading(false)
    })
  }, [])

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)
  }
  if (isLoading) {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }

  return (
    <Container className="page-content" fluid>
      <Card className="shadow-sm mb-0 mt-3">
        <CardText className="fs-4 ms-5">Slots Management</CardText>
      </Card>
      <UiModal2
        dataFromChild={dataFromChild}
        dataFromChild2={dataFromChild2}
        bookingFrom={bookingFrom}
        bookingTo={bookingTo}
        checkBox={checkBox}
        undermaintance={undermaintance}
      />
      <Row>
        <AgGrid
          apiRes={apiRes.data.data}
          onChildData={handleChildData}
          onChildData2={handleChildData2}
          onChildData3={handleChildData3}
          onChildData4={handleChildData4}
          onChildData5={handleChildData5}
          onChildData6={handleChildData6}
        />
      </Row>

      {/* 👇️ show elements on click */}
      {isShown && (
        <Card
          style={{
            width: 250,
            backgroundColor: "lightgrey",
            padding: 20,
            marginTop: "-25%",
            marginLeft: "30%",
            position: "absolute",
          }}
        >
          <Row className="mb-3">
            <Col>
              <Label>From</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Label className="pt-3">To</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Button onClick={handleClick}>Done</Button>
          </Row>
        </Card>
      )}
    </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default EventsSlotDashboard
