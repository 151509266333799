import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

import Accordion from "react-bootstrap/Accordion"

// import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

const AdminBookingDashboard = props => {
  const [open, setOpen] = useState("1")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Row className="p-3">
            <Col>
              <CardText>
                <h4>Booking</h4>
              </CardText>
            </Col>
            <Col style={styles.Container.colAddBookings}>
              <Link to="/admin/bookings/addBookings" >
                <Button>Add Bookings</Button>
              </Link>
            </Col>
          </Row>
          <Card>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filters</Accordion.Header>
                <Accordion.Body>
                  <Row className="pb-2">
                    <Col>
                      <Input
                        type="select"
                        placeholder="Cam Agency"
                        className="form-select"
                      >
                        <option>Agency</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input type="date" placeholder="From"></Input>
                    </Col>
                    <Col>
                      <Input type="date" placeholder="To"></Input>
                    </Col>
                    <Col>
                      <Input
                        type="select"
                        placeholder="All"
                        className="form-select"
                      >
                        <option>Period</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="select"
                        placeholder=""
                        className="form-select"
                      >
                        <option>Building</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        type="select"
                        placeholder="All"
                        className="form-select"
                      >
                        <option>Type</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        type="select"
                        placeholder="All"
                        className="form-select"
                      >
                        <option>Price</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        type="select"
                        placeholder="All"
                        className="form-select"
                      >
                        <option>Status</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Row>
              <MultiLevelProgressBar />
            </Row> */}
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Booking ID</th>
                    <th>Agency Icon</th>
                    <th>Agency</th>
                    <th>Slots (Nos)</th>
                    <th>Period</th>
                    <th>Days</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">B 5201 CAM 0522</th>
                    <td>
                      <i className="fa fa-circle fa-2x"></i>
                    </td>
                    <td>CAM Agency</td>
                    <td>50</td>
                    <td>01 April 2022-08 April 2022</td>
                    <td>25</td>
                    <td>25,000 AED</td>
                    <td style={styles.Container.statusTd.review}>Review</td>
                    <td>
                      <td>
                        <Button color="danger">Cancel</Button>
                      </td>
                      <td>
                        <Button color="primary">Shift</Button>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B 5201 CAM 0522</th>
                    <td>
                      <i className="fa fa-circle fa-2x"></i>
                    </td>
                    <td>CAM Agency</td>
                    <td>50</td>
                    <td>01 April 2022-08 April 2022</td>
                    <td>25</td>
                    <td>25,000 AED</td>
                    <td style={styles.Container.statusTd.paymentPending}>
                      Payment Pending
                    </td>
                    <td>
                      <td>
                        <Button color="danger">Cancel</Button>
                      </td>
                      <td>
                        <Button color="primary">Shift</Button>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B 5201 CAM 0522</th>
                    <td>
                      <i className="fa fa-circle fa-2x"></i>
                    </td>
                    <td>CAM Agency</td>
                    <td>50</td>
                    <td>01 April 2022-08 April 2022</td>
                    <td>25</td>
                    <td>25,000 AED</td>
                    <td style={styles.Container.statusTd.rejected}>Rejected</td>
                    <td>
                      <td>
                        <Button color="danger">Cancel</Button>
                      </td>
                      <td>
                        <Button color="primary">Shift</Button>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B 5201 CAM 0522</th>
                    <td>
                      <i className="fa fa-circle fa-2x"></i>
                    </td>
                    <td>CAM Agency</td>
                    <td>50</td>
                    <td>01 April 2022-08 April 2022</td>
                    <td>25</td>
                    <td>25,000 AED</td>
                    <td style={styles.Container.statusTd.inProgress}>
                      In Progress
                    </td>
                    <td>
                      <td>
                        <Button color="danger">Cancel</Button>
                      </td>
                      <td>
                        <Button color="primary">Shift</Button>
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B 5201 CAM 0522</th>
                    <td>
                      <i className="fa fa-circle fa-2x"></i>
                    </td>
                    <td>CAM Agency</td>
                    <td>50</td>
                    <td>01 April 2022-08 April 2022</td>
                    <td>25</td>
                    <td>25,000 AED</td>
                    <td style={styles.Container.statusTd.completed}>
                      Completed
                    </td>
                    <td>
                      <td>
                        <Button color="danger">Cancel</Button>
                      </td>
                      <td>
                        <Button color="primary">Shift</Button>
                      </td>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    colAddBookings: {
      textAlign: "right",
    },
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
    statusTd: {
      review: {
        color: "blue",
      },
      paymentPending: {
        color: "orange",
      },
      rejected: {
        color: "red",
      },
      inProgress: {
        color: "yellow",
      },
      completed: {
        color: "green",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminBookingDashboard
