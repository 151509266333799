import React from "react"
import {
  Card,
  Row,
  Col,
  CardText,
  Container,
  Input,
  Table,
  Button,
} from "reactstrap"

const EventsPaymentReports = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Row className="mb-3">
            <CardText>
              <h4>Payment Reports</h4>
            </CardText>
          </Row>
          <Card>
            <Row className="mb-3">
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>From</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>To</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Agency Name</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Status</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col className="col=2" style={styles.tableBtnCol}>
                <Button color="primary">Print</Button>
              </Col>
            </Row>
            <Row>
              <Table striped style={styles.tableStyle}>
                <thead style={styles.tableStyle.thead}>
                  <tr>
                    <th>Booking ID</th>
                    <th>Agency Name</th>
                    <th>Payment Date</th>
                    <th>Payment Status</th>
                    <th>Payment Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cam11</td>
                    <td>Cam Agency</td>
                    <td>12 Jun 2022</td>
                    <td>Completed</td>
                    <td>
                      <Button>Details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Cam11</td>
                    <td>Cam Agency</td>
                    <td>12 Jun 2022</td>
                    <td>Completed</td>
                    <td>
                      <Button>Details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Cam11</td>
                    <td>Cam Agency</td>
                    <td>12 Jun 2022</td>
                    <td>Completed</td>
                    <td>
                      <Button>Details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Cam11</td>
                    <td>Cam Agency</td>
                    <td>12 Jun 2022</td>
                    <td>Completed</td>
                    <td>
                      <Button>Details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Cam11</td>
                    <td>Cam Agency</td>
                    <td>12 Jun 2022</td>
                    <td>Completed</td>
                    <td>
                      <Button>Details</Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  tableStyle: {
    backgroundColor: "lightblue",
    thead: {
      backgroundColor: "lightgray",
    },
  },
  tableBtnCol: {
    textAlign: "right",
  },
}

export default EventsPaymentReports
