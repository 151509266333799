import img1 from "../../../assets/images/mall-img/mall2.jpeg"
import img2 from "../../../assets/images/mall-img/mall4.jpeg"
import img3 from "../../../assets/images/mall-img/mall1.jpeg"
import img4 from "../../../assets/images/mall-img/mall3.jpeg"
import img5 from "../../../assets/images/mall-img/mall5.jpeg"
import img6 from "../../../assets/images/mall-img/mall6.jpeg"

import dfcm1 from "../../../assets/images/mall-img/dfcm01.png"  
import dfcm2 from "../../../assets/images/mall-img/dfcm02.png"
import dfcm3 from "../../../assets/images/mall-img/dfcm03.png"
import dfcm4 from "../../../assets/images/mall-img/dfcm04.png"
import dfcm5 from "../../../assets/images/mall-img/dfcm05.png"

import cfcm1 from "../../../assets/images/mall-img/cfcm01.png"
import cfcm2 from "../../../assets/images/mall-img/cfcm02.png"
import cfcm3 from "../../../assets/images/mall-img/cfcm03.png"
import cfcm4 from "../../../assets/images/mall-img/cfcm04.png"
import cfcm5 from "../../../assets/images/mall-img/cfcm05.png"

import dofcm1 from "../../../assets/images/mall-img/dofcm01.png"
import dofcm2 from "../../../assets/images/mall-img/dofcm02.png"
import dofcm3 from "../../../assets/images/mall-img/dofcm03.png"
import dofcm4 from "../../../assets/images/mall-img/dofcm04.png"
import dofcm5 from "../../../assets/images/mall-img/dofcm05.png"

import zm1 from "../../../assets/images/mall-img/zm01.png"
import zm2 from "../../../assets/images/mall-img/zm02.png"
import zm3 from "../../../assets/images/mall-img/zm03.png"
import zm4 from "../../../assets/images/mall-img/zm04.png"
import zm5 from "../../../assets/images/mall-img/zm05.png"

import fp1 from "../../../assets/images/mall-img/fp01.png"
import fp2 from "../../../assets/images/mall-img/fp02.png"
import fp3 from "../../../assets/images/mall-img/fp03.png"
import fp4 from "../../../assets/images/mall-img/fp04.png"
import fp5 from "../../../assets/images/mall-img/fp05.png"

// const cardDataIndoor = [
//   {
//     id: 1,
//     title: "Lulu Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-warning text-white",
//     zones: 10,
//     slots: 100,
//     maintenanceZone: 2,
//     LeasedZone: 3,
//     maintenanceSlot: 10,
//     LeasedSlot: 10,
//     ceiling: 50,
//     wall: 30,
//     floor: 20,
//     img: img1,
//     subImg1: img2,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img1,
//     to: "/properties/propertyDetails"
//   },
//   {
//     id: 2,
//     title: "Dalma Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-info text-white",
//     zones: 20,
//     slots: 200,
//     maintenanceZone: 6,
//     LeasedZone: 4,
//     maintenanceSlot: 30,
//     LeasedSlot: 15,
//     ceiling: 70,
//     wall: 50,
//     floor: 60,
//     img: img2,
//     subImg1: img1,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img6,
//     to: "/properties/floorPlanEditor2"
//   },
//   {
//     id: 3,
//     title: "Lulu Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-warning text-white",
//     zones: 10,
//     slots: 100,
//     maintenanceZone: 2,
//     LeasedZone: 3,
//     maintenanceSlot: 10,
//     LeasedSlot: 10,
//     ceiling: 50,
//     wall: 30,
//     floor: 20,
//     img: img1,
//     subImg1: img2,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img1,
//     to: "/properties/propertyDetails"
//   },
//   {
//     id: 4,
//     title: "Dalma Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-info text-white",
//     zones: 20,
//     slots: 200,
//     maintenanceZone: 6,
//     LeasedZone: 4,
//     maintenanceSlot: 30,
//     LeasedSlot: 15,
//     ceiling: 70,
//     wall: 50,
//     floor: 60,
//     img: img2,
//     subImg1: img1,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img6,
//     to: "/properties/floorPlanEditor2"
//   },
//   {
//     id: 5,
//     title: "Lulu Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-warning text-white",
//     zones: 10,
//     slots: 100,
//     maintenanceZone: 2,
//     LeasedZone: 3,
//     maintenanceSlot: 10,
//     LeasedSlot: 10,
//     ceiling: 50,
//     wall: 30,
//     floor: 20,
//     img: img1,
//     subImg1: img2,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img1,
//     to: "/properties/propertyDetails"
//   },
//   {
//     id: 6,
//     title: "Dalma Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-info text-white",
//     zones: 20,
//     slots: 200,
//     maintenanceZone: 6,
//     LeasedZone: 4,
//     maintenanceSlot: 30,
//     LeasedSlot: 15,
//     ceiling: 70,
//     wall: 50,
//     floor: 60,
//     img: img2,
//     subImg1: img1,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img6,
//     to: "/properties/floorPlanEditor2"
//   },
//   {
//     id: 7,
//     title: "Lulu Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-warning text-white",
//     zones: 10,
//     slots: 100,
//     maintenanceZone: 2,
//     LeasedZone: 3,
//     maintenanceSlot: 10,
//     LeasedSlot: 10,
//     ceiling: 50,
//     wall: 30,
//     floor: 20,
//     img: img1,
//     subImg1: img2,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img1,
//     to: "/properties/propertyDetails"
//   },
//   {
//     id: 8,
//     title: "Dalma Mall",
//     description:
//       "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
//     className: "bg-info text-white",
//     zones: 20,
//     slots: 200,
//     maintenanceZone: 6,
//     LeasedZone: 4,
//     maintenanceSlot: 30,
//     LeasedSlot: 15,
//     ceiling: 70,
//     wall: 50,
//     floor: 60,
//     img: img2,
//     subImg1: img1,
//     subImg2: img3,
//     subImg3: img4,
//     subImg4: img5,
//     subImg5: img6,
//     to: "/properties/floorPlanEditor2"
//   },
// ]

const cardDataIndoor = [
  {
    id: 1,
    title: "Festival City Mall",
    description:
      "One of Dubai's most favourite shopping destinations. With over 400 outlets, the mall is home to UAE’s first IKEA and renowned fashion brands like Zara, H&M, Marks & Spencer . It also houses the global attraction, IMAGINE, a two-time Guinness World Records visual and sensory extravaganza that plays live every night on the iconic Festival Bay A modern business and entertainment area, Dubai Festival City centers on a massive mall of the same name, home to international fashion and furniture brands, global food courts and a cinema complex. Upmarket hotels on Dubai Creek host major conferences, and have stylish European restaurants with city skyline views. The annual Festival of Literature is popular for workshops and poetry readings.",
    zones: 15,
    slots: 310,
    maintenanceZone: 2,
    LeasedZone: 30,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dfcm5,
    subImg1: dfcm1,
    subImg2: dfcm2,
    subImg3: dfcm3,
    subImg4: dfcm4,
    subImg5: dfcm5,
    to: "/properties/propertyDetails"
  },
  {
    id: 2,
    title: "Cairo Festival City Mall",
    description:
      "Established in 2013, Cairo Festival City Mall has risen to become the top shopping and entertainment destination. The mall is at the heart of Cairo Festival City, a mixed-use urban community in New Cairo City.",
    slots: 300,
    maintenanceZone: 5,
    LeasedZone: 20,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: cfcm1,
    subImg1: cfcm1,
    subImg2: cfcm2,
    subImg3: cfcm3,
    subImg4: cfcm4,
    subImg5: cfcm5,
    to: "/properties/propertyDetails"
  },
  {
    id: 3,
    title: "Doha Festival City Mall",
    description:
      "Doha Festival City is Qatar’s preferred destination for shopping, entertainment and dining. Leisure for all the family Leisure for all the family Bringing several firsts to Doha, this incomparable mall is one of the largest developments in the Middle East, providing something for everyone and creating memorable experiences with every visit.",
    slots: 300,
    maintenanceZone: 5,
    LeasedZone: 20,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: dofcm1,
    subImg1: dofcm1,
    subImg2: dofcm2,
    subImg3: dofcm3,
    subImg4: dofcm4,
    subImg5: dofcm5,
    to: "/properties/propertyDetails"
  },
  {
    id: 4,
    title: "Zenata Mall",
    description:
      "A diverse, locally relevant and dynamic version of a “retail-tainment” shopping centre, Zenata Mall is poised to be the largest shopping destination in Morocco, offering a wide range of products and brands (both local and international) and a highly distinguished food hall",
    slots: 300,
    maintenanceZone: 5,
    LeasedZone: 20,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: zm1,
    subImg1: zm1,
    subImg2: zm2,
    subImg3: zm3,
    subImg4: zm4,
    subImg5: zm5,
    to: "/properties/propertyDetails"
  },
  {
    id: 5,
    title: "Festival Plaza",
    description:
      "Opened in December 2019, Festival Plaza is Al-Futtaim Malls’s newest local community shopping project in the south of Dubai. The huge retail precinct covers 64,800 square metres and serves as a convenient and local lifestyle destination at the centre of the upcoming Wasl Gate community.",
    slots: 300,
    maintenanceZone: 5,
    LeasedZone: 20,
    maintenanceSlot: 20,
    LeasedSlot: 40,
    ceiling: 50,
    wall: 30,
    floor: 20,
    img: fp1,
    subImg1: fp1,
    subImg2: fp2,
    subImg3: fp3,
    subImg4: fp4,
    subImg5: fp5,
    to: "/properties/propertyDetails"
  },
]

export { cardDataIndoor }
