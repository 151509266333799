import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Container,
  Button,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const UiModal = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <i
        className="fa fa-edit"
        onClick={() => {
          tog_center()
        }}
      ></i>

      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0"></h5>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col>
                        <Label>Slot Name</Label>
                        <Input type="text" />
                        <Label>
                          Smart Slots
                          <Input type="checkbox" />
                        </Label>
                        <Label>Type</Label>
                        <Input type="text" />
                        <Label>
                          Slot Description
                          <Input type="select">
                            <option>Ft</option>
                            <option>2</option>
                            <option>3</option>
                          </Input>
                        </Label>
                        <Row>
                          <Col>
                            <Label>
                              Max Weight
                              <Input type="text" />
                            </Label>
                          </Col>
                          <Col>
                            <Label>
                              Max Length
                              <Input type="text" />
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>
                              Max Height
                              <Input type="text" />
                            </Label>
                          </Col>
                          <Col>
                            <Label>
                              Max Width
                              <Input type="text" />
                            </Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Label>Slot Image</Label>
                        <Input type="file" />
                        <Label>
                            GPS Coordinates
                        </Label>
                        <Input type="textarea" />
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col style={styles.modalBtnStyle}>
                      <Button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
