import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import avatar1 from "../../assets/images/users/user-1.jpg"
import avatar2 from "../../assets/images/users/user-2.jpg"
import avatar3 from "../../assets/images/users/user-3.jpg"
import ButtonToggle from "reactstrap"

//components
import UiModal from "./DeleteModal"
const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

const userData = {
  columns: [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "USER NAME",
      field: "user_img",
      sort: "asc",
      width: 150,
    },
    {
      label: "EMAIL",
      field: "email",
      sort: "asc",
      width: 150,
    },
    {
      label: "LOCATION",
      field: "location",
      sort: "asc",
      width: 150,
    },
    {
      label: "Phone No",
      field: "phoneNo",
      sort: "asc",
      width: 150,
    },
    {
      label: "DESIGNATION",
      field: "designation",
      sort: "asc",
      width: 150,
    },
    {
      label: "ACTION",
      field: "action",
      width: 150,
    },
  ],
  rows: [
    {
      id: (
        <Link to="" style={styles.tagBtnStyle}>
          LM31
        </Link>
      ),
      user_img: (
        <span>
          <img
            src={avatar1}
            alt="veltrix"
            className="rounded-circle avatar-sm"
          />{" "}
          Ebin Mathew
        </span>
      ),
      email: "ebinmathew@gmail.com",
      location: "Dubai",
      designation: "Manager",
      phoneNo:'+9154525454554',
      action: <td>
      <td>  <Link to="/admin/userManagement/userList/view"><i className="fa fa-eye"></i></Link></td>
    <td>
    &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/admin/userManagement/userList/edit">
          <i className="fas fa-edit "></i>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        <UiModal />
      </td>
    </td>,
    },
    {
      id: (
        <Link to="" style={styles.tagBtnStyle}>
          LM31
        </Link>
      ),
      user_img: (
        <span>
          <img
            src={avatar2}
            alt="veltrix"
            className="rounded-circle avatar-sm"
          />{" "}
          Joel Joseph
        </span>
      ),
      email: "	joeljoseph@gmail.com",
      location: "Dubai",
      designation: "Technician",
      phoneNo:'+9154525454554',
      action: <td>
        <td>  <Link to="/admin/userManagement/userList/view"><i className="fa fa-eye"></i></Link></td>
      <td>
      &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/admin/userManagement/userList/edit">
          <i className="fas fa-edit "></i>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        <UiModal />
      </td>
    </td>,
    },
    {
      id: (
        <Link to="" style={styles.tagBtnStyle}>
          LM31
        </Link>
      ),
      user_img: (
        <span>
          <img
            src={avatar3}
            alt="veltrix"
            className="rounded-circle avatar-sm"
          />{" "}
          Rathul
        </span>
      ),
      email: "rathul@gmail.com	",
      location: "Dubai",
      designation: "Technician",
      phoneNo:'+9154525454554',
      action: <td>
      <td>  <Link to="/admin/userManagement/userList/view"><i className="fa fa-eye"></i></Link></td>
    <td>
    &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/admin/userManagement/userList/edit">
          <i className="fas fa-edit "></i>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        <UiModal />
      </td>
    </td>,
    },
  ],
}
const AdminUserManagementDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText><h5>Users</h5></CardText>
          <Row style={styles.Container.Row.AddZoneBtnRow}>
            <Link to={'/admin/userManagement/userList/addUser'} style={{textAlign: "end"}}>
            <Button color="warning" className="col-1"><i className="fa fa-plus"> Add User</i></Button>
            </Link>
        </Row>
            
        <MDBDataTable noBottomColumns={true} responsive striped data={userData} 
        // onClick={() => {window.location.href = "/users/viewUser"}} 
        />
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}


export default AdminUserManagementDashboard
