import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  CardBody,
} from "reactstrap"

//components
import UiModal from "./AdNotificationsInstallationModal"

const AdNotificationsDashboard = props => {
  return (
    <React.Fragment>
      <Container className="page-content mt-3">
        <Row className="mb-3">
          <Row>
            <Col>
              <Input
                type="select"
                placeholder="Agency Name"
                className="form-select"
              >
                <option>Property Name</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>Type</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Input type="select" placeholder="All" className="form-select">
                <option>Status</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
          </Row>
        </Row>

        <Row>
          <div class="card">
            <h4 class="card-header">
              Installation Request CAM Agency Rejected
              <i className="fa fa-times-circle"></i>
            </h4>
            <div class="card-body">
              <h4 class="card-title">
                Request from CAM Agency Booking ID{" "}
                <Link to="">B 5201 CAM 0522</Link>
              </h4>
              <p class="card-text">
                {" "}
                Notification for the property owner for the installation on the
                selected slots on <b>25 February 2022, 10:30 AM</b>
              </p>
              <p>
                {" "}
                Mall Technician wont be available on 25 Feb 2022. So could you
                please extend the date.
              </p>
              <div class="float-md-end">
                <UiModal/>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div class="card">
            <h4 class="card-header">
              Installation Request Accepted
              <i className="fa fa-check-circle"></i>
            </h4>
            <div class="card-body">
              <h4 class="card-title">
                Installation request has been accepted for Booking ID{" "}
                <Link to="">B 5201 CAM 0522</Link>
              </h4>
              <p>
                Notify the property owner for installation on the selected slots
                on 28 February 2022, 03:00 PM
              </p>
              <div class="float-md-end">
                <Link to="/agency/notifications/details" className="btn btn-primary">Details</Link>
                <span> </span>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div class="card">
            <h4 class="card-header">Mall Technician Details</h4>
            <div class="card-body">
              <h4 class="card-title">
                Dalma mall has assigned a technician to connect with you.
              </h4>
              <div class="float-md-end">
                <Link to="#" className="btn btn-primary">Details</Link>
                <span> </span>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default AdNotificationsDashboard
