import React from "react"
import { Link } from "react-router-dom"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle,Container, CardSubtitle, Label, Input } from "reactstrap"

import pepsico from "../../assets/images/product/pepsico.png"
import mcdonald from "../../assets/images/product/mcdonald.png"
import volkswagen from "../../assets/images/product/volkswagen.png"
import kfc from "../../assets/images/product/kfc.png"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"
const bookingData = {
  columns: [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Agency Icon",
      field: "agency_icon",
      sort: "asc",
      width: 150,
    },
    {
      label: "Agency",
      field: "agency",
      sort: "asc",
      width: 150,
    },
    {
      label: "Slot Nos",
      field: "slot_nos",
      sort: "asc",
      width: 150,
    },
    {
      label: "From",
      field: "from",
      sort: "asc",
      width: 150,
    },
    {
      label: "To",
      field: "to",
      sort: "asc",
      width: 150,
    }, 
    {
      label: "Days",
      field: "days",
      sort: "asc",
      width: 150,
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
  ],
  rows:[
    {
      id: <Link to="/bookings/review"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={volkswagen} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Review",
    },
    {
      id: <Link to="/bookings/paymentPending"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={mcdonald} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Payment Pending",
    },
    {
      id: <Link to="/bookings/review"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={volkswagen} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Document Verification",
    },
    {
      id: <Link to="/bookings/review"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={kfc} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Booked",
    },
    {
      id: <Link to="/bookings/installation"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={volkswagen} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Installation",
    },
    {
      id: <Link to="/bookings/completed"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={kfc} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Completed",
    },
    {
      id: <Link to="/bookings/review"> B 5201 CAM 0522 </Link>,
      agency_icon: <img src={volkswagen} alt="veltrix" className="rounded-circle avatar-sm" />,
      agency: "Edinburgh",
      slot_nos: "61",
      from: "2022/04/25",
      to: "2022/08/25",
      days: "20",
      amount: "$3500",
      status: "Rejected",
    },
  ]
}


const BookingDashboard = props => {
  return (
      <Container className="page-content" fluid>
        <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">BOOKING DASHBOARD</CardTitle>
              </CardBody>
              <Row>
                {/* <MultiLevelProgressBar /> */}
              </Row>
              <CardBody>
                <Row className="mt-3 ml-2">
                  <Col xs={3}>
                    <Label>Agency name</Label>
                    <Input type="text" placeholder="Cam Agency"></Input>
                  </Col>
                  <Col xs={3}>
                    <Label>Booking</Label>
                    <Input
                      type="select"
                      placeholder="Cam Agency"
                      className="form-select"
                    >
                      <option selected disabled>Indoor/Outdoor</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={3}>
                    <Label>From</Label>
                    <Input type="date" placeholder="From"></Input>
                  </Col>
                  <Col xs={3}>
                    <Label>To</Label>
                    <Input type="date" placeholder="To"></Input>
                  </Col>
                  <Col xs={3}>
                    <Label>Buildings</Label>
                    <Input
                      type="select"
                      placeholder="All"
                      className="form-select"
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={3}>
                    <Label>Content Type</Label>
                    <Input type="select" placeholder="" className="form-select">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={3}>
                    <Label>Price</Label>
                    <Input
                      type="select"
                      placeholder="All"
                      className="form-select"
                    >
                      <option selected disabled>All</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={3}>
                    <Label>Status</Label>
                    <Input
                      type="select"
                      placeholder="All"
                      className="form-select"
                    >
                      <option selected disabled>All</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                </Row>
                </CardBody>
              <Row>
            <Col className="col-12">
              <MDBDataTable noBottomColumns={true} responsive striped data={bookingData} />
            </Col>
          </Row>
            </Card>
        </Row>
      </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default BookingDashboard
