import React from "react"
import { Link } from "react-router-dom"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
  CardText,
  Table,
  Button,
} from "reactstrap"
import Doughnut from "./CollateralType"
import { cardDataIndoor } from "../../propertyOwner/Common/Data/PropertyIndoorData"

//mallJson
import {mallData} from "../Common/Data/mallData.js"

// import images
import img5 from "../../assets/images/small/img-5.jpg"

const AdminPropertyDetailCard = props => {
  // let mallId = 0
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card style={{ margin: 20 }}>
            <Card style={{ background: "#F1F5F9", margin: 10, padding: 10 }}>
              <Row>
                <Col>
                  <CardTitle className="h3">{mallData[localStorage.getItem("mallId")].buildingName}</CardTitle>
                </Col>
                <Col>
                  <Link to="../properties/floorPlans">
                    <Button
                      className="btn btn-sm"
                      color="danger"
                      style={{ float: "right", marginBottom: 10, borderRadius: "8px", width: 150 }}
                    >
                      FLOOR PLANS
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <CardText>{mallData[localStorage.getItem("mallId")].address}</CardText>
                </Col>
                <Col xs={5}>
                  <CardImg
                    top
                    className="img-fluid"
                    src={cardDataIndoor[0].img}
                    alt="Veltrix"
                  />
                </Col>
                <Col xs={5}>
                  <table>
                    <tr>
                      <td><CardImg
                        top
                        className="img-fluid"
                        src={cardDataIndoor[0].subImg1}
                        alt="Veltrix"
                      /></td>
                      <td><CardImg
                        top
                        className="img-fluid"
                        src={cardDataIndoor[0].subImg2}
                        alt="Veltrix"
                      /></td>
                    </tr>
                    <tr>
                      <td><CardImg
                        top
                        className="img-fluid"
                        src={cardDataIndoor[0].subImg3}
                        alt="Veltrix"
                      /></td>
                      <td><CardImg
                        top
                        className="img-fluid"
                        src={cardDataIndoor[0].subImg4}
                        alt="Veltrix"
                      /></td>
                    </tr>
                  </table>
                </Col>
              </Row>
            </Card>
            <table>
              <tr>
                <td>
                  <Card style={{ background: "#F1F5F9", margin: 10, padding: 10 }}>
                    <CardTitle className="h4">Zones</CardTitle>
                    <Row>
                      <Col xs={4}>
                        <h1>{cardDataIndoor[0].zones}</h1>
                      </Col>
                      <Col xs={8}>
                        <table>
                          <tr>
                            <td>Maintenance</td>
                            <td>{cardDataIndoor[0].maintenanceZone}</td>
                          </tr>
                          <tr>
                            <td>Leased</td>
                            <td>{cardDataIndoor[0].LeasedZone}</td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                  </Card>
                </td>
                <td>
                  <Card style={{ background: "#F1F5F9", margin: 10, padding: 10 }}>
                    <CardTitle className="h4">Slots</CardTitle>
                    <Row>
                      <Col xs={4}>
                        <h1>{mallData[localStorage.getItem("mallId")].slotsAvailable}</h1>
                      </Col>
                      <Col xs={8}>
                        <table>
                          <tr>
                            <td>Maintenance</td>
                            <td>{cardDataIndoor[0].maintenanceSlot}</td>
                          </tr>
                          <tr>
                            <td>Leased</td>
                            <td>{cardDataIndoor[0].LeasedSlot}</td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                  </Card>
                </td>
                <td rowSpan={2}>
                  <Card style={{ background: "#F1F5F9", margin: 10, padding: 10 }}>
                    <CardTitle className="h4">Collateral Type</CardTitle>
                    <CardBody>
                      <div id="doughnut-chart" className="e-chart">
                        <Doughnut />
                      </div>
                    </CardBody>
                  </Card>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Card style={{ background: "#F1F5F9", margin: 10, padding: 10 }}>
                    <CardTitle className="h4">Top 10 Slots</CardTitle>
                    <Table className="table table-striped mb-0">
                      <thead style={{ background: "#bcc5d1"}}>
                        <tr>
                          <th>Slot Id</th>
                          <th>Zone</th>
                          <th>Avg Booking</th>
                          <th>Revenue (Aed)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">SL130</th>
                          <td>02</td>
                          <td>85%</td>
                          <td>2334</td>
                        </tr>
                        <tr>
                          <th scope="row">SL110</th>
                          <td>01</td>
                          <td>85%</td>
                          <td>2304</td>
                        </tr>
                        <tr>
                          <th scope="row">SL101</th>
                          <td>01</td>
                          <td>85%</td>
                          <td>2000</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </td>
              </tr>
            </table>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  )
}

const cardStyle = {
  fontSize: 20,
  padding: 10,
  fontWeight: "bold",
}

export default AdminPropertyDetailCard


//  <Row>
// <Col>
//   <CardTitle className="h4">{cardDataIndoor[0].title}</CardTitle>
// </Col>
// <Col>
//   <Link to="../properties/floorPlans">
//     <Button
//       color="primary"
//       style={{ float: "right", marginBottom: 10 }}
//     >
//       FLOOR PLANS
//     </Button>
//   </Link>
// </Col>
// <Card>
//   <Row>
//     <Col className="col-3">
{/* {alert("props", props.cardid)} */ }
//       <CardText>{cardDataIndoor[0].description}</CardText>
//     </Col>
//     <Col className="col-5">
//       <Card>
//         <Col>
//           <CardImg
//             top
//             className="img-fluid"
//             src={cardDataIndoor[0].img}
//             alt="Veltrix"
//           />
//         </Col>
//       </Card>
//     </Col>

//     <Col className="col-2">
//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg1}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>

//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg2}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>
//     </Col>
//     <Col className="col-2">
//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg3}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>

//       <Col>
//         <Card>
//           <Col>
//             <CardImg
//               top
//               className="img-fluid"
//               src={cardDataIndoor[0].subImg4}
//               alt="Veltrix"
//             />
//           </Col>
//         </Card>
//       </Col>
//     </Col>
//   </Row>
// </Card>
// </Row>
// <Row>
// <Col className="col-6">
//   <Row>
//     <Col className="col-6">
//       <Card>
//         <CardTitle style={cardStyle}>Zones</CardTitle>
//         <Row>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 50,
//                 padding: 10,
//                 fontWeight: "bold",
//               }}
//             >
//               {cardDataIndoor[0].zones}
//             </CardBody>
//           </Col>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 15,
//                 padding: 10,
//                 fontWeight: "semi-bold",
//               }}
//             >
//               Maintantance {cardDataIndoor[0].maintenanceZone}
//               <br />
//               Leased {cardDataIndoor[0].LeasedZone}
//             </CardBody>
//           </Col>
//         </Row>
//       </Card>
//     </Col>
//     <Col className="col-6">
//       <Card>
//         <CardTitle style={cardStyle}>Slots</CardTitle>
//         <Row>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 50,
//                 padding: 10,
//                 fontWeight: "bold",
//               }}
//             >
//               {cardDataIndoor[0].slots}
//             </CardBody>
//           </Col>
//           <Col>
//             <CardBody
//               style={{
//                 fontSize: 15,
//                 padding: 10,
//                 fontWeight: "semi-bold",
//               }}
//             >
//               Maintantance {cardDataIndoor[0].maintenanceSlot}
//               <br />
//               Leased {cardDataIndoor[0].LeasedSlot}
//             </CardBody>
//           </Col>
//         </Row>
//       </Card>
//     </Col>
//   </Row>
//   <Col className="col-12">
//     <CardTitle>Top 10 Slots</CardTitle>
//     <CardBody>
//       <Table striped>
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>First Name</th>
//             <th>Last Name</th>
//             <th>Username</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <th scope="row">1</th>
//             <td>Mark</td>
//             <td>Otto</td>
//             <td>@mdo</td>
//           </tr>
//           <tr>
//             <th scope="row">2</th>
//             <td>Jacob</td>
//             <td>Thornton</td>
//             <td>@fat</td>
//           </tr>
//           <tr>
//             <th scope="row">3</th>
//             <td>Larry</td>
//             <td>the Bird</td>
//             <td>@twitter</td>
//           </tr>
//         </tbody>
//       </Table>
//     </CardBody>
//   </Col>
// </Col>
// <Col className="col-6">
//   <Card>
//     <CardTitle style={cardStyle}>Collateral Type</CardTitle>
//     <Row>
//       <Col className="col-4">
//         <CardBody style={{ fontSize: 20 }}>
//           Ceiling {cardDataIndoor[0].ceiling}
//           <br />
//           Wall {cardDataIndoor[0].wall}
//           <br />
//           Floor {cardDataIndoor[0].floor}
//         </CardBody>
//       </Col>
//       <Col className="col-8">
//         <CardBody>
//           <CardImg
//             top
//             className="img-fluid"
//             src={cardDataIndoor[0].subImg5}
//             alt="Veltrix"
//           />
//         </CardBody>
//       </Col>
//     </Row>
//   </Card>
// </Col>
// </Row> 