import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardImg,
  NavLink,
} from "reactstrap"

import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"
import UiModal from "./ContentImagesModal"

const BookingDetails = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    //setIsShown(true)
  }
  return (
    <React.Fragment>
      <Container className="page-content" fluid>
      <Link to="">
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <h1 className="page-title"> B 5201 CAM 0522</h1>
            </Col>
          </Row>
        </div>
      </Link>
        <Row>
          <Card>
            <Row>

              <Col>
                <UiModal/>
              </Col>

              <CardText>
                <b>Bookings</b>
              </CardText>
            </Row>
            <Row className="pt-2 pb-4">
              <MultiLevelProgressBar />
            </Row>
            <Row>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Indoor</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Cam Agency</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col className="col-4">
                <Input type="select" placeholder="" className="form-select">
                  <option>01 April 2022-25 April 2022</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Dubai Mall</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Food & Beverages</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Review</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>SLOT ID</th>
                    <th>SLOT NAME</th>
                    <th>CATEGORY</th>
                    <th>ZONE</th>
                    <th>AMOUNT</th>
                    <th>
                      ACCEPT <Input type="checkbox" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Sl101</th>
                    <td>Slot 01</td>
                    <td>Ceilling</td>
                    <td>Zone 01</td>
                    <td>4000</td>
                    <td>
                      <Input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Sl102</th>
                    <td>Slot 02</td>
                    <td>Wall</td>
                    <td>Zone 02</td>
                    <td>2000</td>
                    <td>
                      <Input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Sl131</th>
                    <td>Slot 21</td>
                    <td>Ceiling</td>
                    <td>Zone 23</td>
                    <td>2000</td>
                    <td>
                      <Input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Sl201</th>
                    <td>Slot 31</td>
                    <td>Wall</td>
                    <td>Zone 21</td>
                    <td>2000</td>
                    <td>
                      <Input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Sl331</th>
                    <td>Slot 41</td>
                    <td>Floor</td>
                    <td>Zone 11</td>
                    <td>2000</td>
                    <td>
                      <Input type="checkbox" />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
        <Row style={{ float: "right", flexDirection: "row-reverse" }}>
          <Link to={"/bookings"} style={{ textAlign: "right" }}>
            <Button color="danger">Back</Button>
          </Link>
        </Row>
        <div>
          {/* 👇️ show elements on click */}
          {isShown && (
            <Card style={styles.modalCardStyle}>
              <span style={styles.modalCardStyle.span}>
                <Button
                  onClick={handleClick}
                  style={styles.modalCardStyle.span.btn}
                >
                  x
                </Button>
              </span>
              <Row style={styles.modalCardStyle.modalCardStyleRow}>
                <Card style={styles.innerCardStyle}>
                  <Row>
                    <Col>
                      <CardTitle>
                        <b>Payments</b>
                        <CardText>10 Feb 2022</CardText>
                      </CardTitle>
                    </Col>
                    <Col>
                      <CardTitle
                        style={styles.modalCardStyle.modalCardStyleCardTitle}
                      >
                        Completed <span className="fa fa-check"></span>
                      </CardTitle>
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row style={styles.modalCardStyle.modalCardStyleRow}>
                <Card style={styles.innerCardStyle}>
                  <CardTitle>
                    Documents <span className="fa fa-file"></span>
                  </CardTitle>
                  <Row>
                    <Card style={styles.modalCardStyle.modalCardStyleCardInner}>
                      <Row
                        style={styles.modalCardStyle.modalCardStyleCardInnerRow}
                      >
                        <Col>
                          <CardTitle>Police Permit</CardTitle>
                        </Col>
                        <Col>
                          <CardTitle>Municipality Permit</CardTitle>
                        </Col>
                        <Col>
                          <CardTitle>Media Permit</CardTitle>
                        </Col>
                      </Row>
                      <Card style={styles.modalCardStyle.modalCardStyleCard2}>
                        <Row>
                          <Col>
                            <Card
                              style={
                                styles.modalCardStyle.modalCardStyleCard2.inner
                              }
                            >
                              <CardImg></CardImg>
                              <CardText>
                                PolicePermit.pdf{" "}
                                <span className="fa fa-file"></span>
                              </CardText>
                            </Card>
                          </Col>
                          <Col>
                            <Card
                              style={
                                styles.modalCardStyle.modalCardStyleCard2.inner
                              }
                            >
                              <CardImg></CardImg>
                              <CardText>
                                PolicePermit.pdf{" "}
                                <span className="fa fa-file"></span>
                              </CardText>
                            </Card>
                          </Col>
                          <Col>
                            <Card
                              style={
                                styles.modalCardStyle.modalCardStyleCard2.inner
                              }
                            >
                              <CardImg></CardImg>
                              <CardText>
                                PolicePermit.pdf{" "}
                                <span className="fa fa-file"></span>
                              </CardText>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                      <Row style={styles.modalCardStyle.btns}>
                        <Button
                          onClick={handleClick}
                          color="danger"
                          style={styles.modalCardStyle.btns.reject}
                        >
                          Reject
                        </Button>

                        <Button
                          onClick={handleClick}
                          color="success"
                          style={styles.modalCardStyle.btns.accept}
                        >
                          Accept
                        </Button>
                      </Row>
                    </Card>
                  </Row>
                </Card>
              </Row>
            </Card>
          )}
        </div>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 50,
    DetailBtnNavLink: {
      textAlign: "right",
    },
  },
  innerCardStyle: {
    color: "black",
    backgroundColor: "#ecedf5",
    width: "80%",
    padding: 20,
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    width: 150,
    padding: 2,
  },
  modalCardStyle: {
    marginTop: -450,
    color: "white",
    width: "60%",
    height: 550,
    float: "right",
    marginRight: -35,
    span: {
      textAlign: "end",
      btn: {
        width: 50,
      },
    },
    modalCardStyleRow: {
      justifyContent: "center",
    },
    modalCardStyleCardTitle: {
      textAlign: "end",
    },
    modalCardStyleCardInner: {
      color: "grey",
      backgroundColor: "white",
    },
    modalCardStyleCardInnerRow: {
      backgroundColor: "lightgray",
      marginTop: -10,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    modalCardStyleCard2: {
      padding: 10,
      width: "100%",
      inner: {
        backgroundColor: "#ecedf5",
      },
    },
    btns: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      reject: {
        width: 100,
      },
      accept: {
        width: 100,
        marginLeft: 5,
      },
    },
  },
}
export default BookingDetails
