import imagePath from "../../../assets/images/floorPlans/DFCL1.jpg"
import imagePath2 from "../../../assets/images/floorPlans/DFCL2.jpg"
import imagePath3 from "../../../assets/images/floorPlans/DFCLG.jpg"
import imagePath4 from "../../../assets/images/mallplan4.png"

const leafletData = [
    {
      levelName: "01",
      levelID: "0",
      floorPlanImg: imagePath,
    },
    {
      levelName: "02",
      levelID: "1",
      floorPlanImg: imagePath2,
    },
    {
      levelName: "03",
      levelID: "2",
      floorPlanImg: imagePath3,
    }
  ]

  export {leafletData}