import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

import { AvFeedback, AvField, AvForm, AvGroup } from "availity-reactstrap-validation"

//image
import userAvatar from "../../assets/images/userAvatar.png"

const AdUserEdit = props => {
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("")
  const [response, setresponse] = useState("")

  const handleValidSubmit = async (event, values) => {
    try {
      const response = await axiosApi.post(
        "/admin/createPropertyOwnerAuthEdit",
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      setresponse(response)
      setbasic(true)
      if (response.status === 201) {
        setSweetTitle(response.message || "Register successfully")
      } else {
        setSweetTitle(response.data.message || "Registration Failed")
      }
    } catch (error) {
      setbasic(true)
      setSweetTitle(error.response.data.message || "Registration Failed")
      console.error("Registration error:", error)
      console.log(response.message)
    }
  }
  return (
    <Container className="page-content">
    <Col xl="3" lg="4" sm="6" className="mb-2">
      {basic && (
        <SweetAlert
          title={sweetTitle}
          onConfirm={() => {
            setbasic(false)
            if (response && response.status === 201) {
              window.location.href = "/admin/userManagement/userList"
            } else {
              window.location.reload()
            }
          }}
        />
      )}
    </Col>
    <Row className="mb-4">
      <Card>
        <AvForm onValidSubmit={handleValidSubmit}>
          <CardTitle>New User</CardTitle>
          <Row>
            <Col xl="4" lg="4" md="12" className="mb-3">
              {/* First set of form fields */}
              <Label>Name</Label>
              <AvField
                type="text"
                name="propertyName "
                placeholder="Name"
                required
              />
              <Label>DOB</Label>
              <AvField type="date" name="dob"  required />
              <Label>Mobile No</Label>
              <AvField type="tel" name="phone" placeholder="Phone no"  required />
              <Label>Gender</Label>
              <AvField
                type="select"
                name="gender"
                className="form-select"
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </AvField>
              <Label>Roles</Label>
              <AvField
                type="select"
                className="form-select"
                name="role"
                required
              >
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <Label>Email</Label>
              <AvField
                type="email"
                name="email"
                placeholder="Email"
                required
              />

              <Label>Username</Label>
              <AvField
                type="text"
                name="username"
                placeholder="username"
                required
              />
            </Col>
            <Col xl="4" lg="4" md="12" className="mb-3">
            <Label>Assign To</Label>
              <AvField type="select" className="form-select" name="assign"  required>
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <Label>Designation</Label>
              <AvField
                type="text"
                name="designation"
                required
                placeholder="Designation"
              />
              <Label>Location</Label>
              <AvField type="select" className="form-select" name="location"  required>
                <option value="1">select location</option>
                <option value="2">India</option>
                <option value="3">UAE</option>
              </AvField>
              <Label>Emirates ID</Label>
              <AvField
                type="text"
                name="emiratesid"
                placeholder="Emirates id"
              />
              <Label>Restricted Level</Label>
              <AvField
                type="select"
                className="form-select"
                name="restriction"
                required
              >
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <div className="mb-3">
                <AvGroup>
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    required
                    placeholder="Enter Password"
                    autoComplete="off"
                  />
                  <AvFeedback>Password is required</AvFeedback>
                </AvGroup>
              </div>

              <div className="mb-3">
                <AvGroup>
                  <AvField
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    validate={{ match: { value: "password" } }}
                    required
                    placeholder="Confirm Password"
                    autoComplete="off"
                  />
                  <AvFeedback>Passwords do not match</AvFeedback>
                </AvGroup>
              </div>
            </Col>
            <Col xl="4" lg="4" md="12" className="mb-3">
          
             
              <CardImg src={userAvatar} />
              <Label>Image</Label>
              <AvField type="file" name="image" id="image">
                Select User Image
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="./">
                <Button
                  className="m-3"
                  color="danger"
                  style={{ width: "150px" }}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                color="primary"
                type="submit"
                style={{ width: "150px" }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </AvForm>
      </Card>
    </Row>
  </Container>
  )
}

const Styles = {}
export default AdUserEdit
