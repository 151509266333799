import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

import UiModal from "./DeleteModal"
import ButtonToggle from "reactstrap"

const AdminPropertiesDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText>
              <h5>Properties</h5>
            </CardText>
            <Row style={styles.Container.Row.AddZoneBtnRow}>
              <Link to={"/admin/properties/addMall"} style={{ textAlign: "end" }}>
                <Button color="warning" className="col-1">
                  <span className="fa fa-plus"> Add Mall</span>
                </Button>
              </Link>
            </Row>

            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Mall ID</th>
                    <th>Mall Name</th>
                    <th>Image</th>
                    <th>Created On</th>
                    <th>Created By</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td>Lulu Mall</td>
                    <td><i className="fa fa-circle fa-2x"></i></td>
                    <td>02 Mar 2021</td>
                    <td>David Moes</td>
                    <td>
                      <td>
                        <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                      <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                    <td><input type="checkbox"></input></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td>Dalma Mall</td>
                    <td><i className="fa fa-circle fa-2x"></i></td>
                    <td>02 Mar 2021</td>
                    <td>David Moes</td>
                    <td>
                      <td>
                        <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                    <td><input type="checkbox" checked="true"></input></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td>City Mall</td>
                    <td><i className="fa fa-circle fa-2x"></i></td>
                    <td>02 Mar 2021</td>
                    <td>David Moes</td>
                    <td>
                      <td>
                        <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-eye"></i></Link>
                      </td>
                      <td>
                        <Link to="/admin/properties/propertyDetailsView"><i className="fa fa-edit"></i></Link>
                      </td>
                      <td>
                        <UiModal/>
                      </td>
                    </td>
                    <td><input type="checkbox"></input></td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdminPropertiesDashboard
