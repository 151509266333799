import React from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardImg,
  Progress,
  Row,
  Col,
  Container,
} from "reactstrap"

import img5 from "../../assets/images/FloorPlanEditor.png"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

class AdminAddSmartSlots extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="page-content">
        <Row className="pt-3 pb-5">
          <MultiLevelProgressBar />
        </Row>
        <Row>
          <Col style={styles.colStyle}>
            <span style={styles.btnSpan} id="btnSpan">
              <Button
                color="primary"
                // style={styles.buttonStyle}
                className="mb-3"
              >
                Add Level
              </Button>
              <br />
              <Button style={styles.buttonStyle.dark} className="mb-3">
                Level 01
              </Button>
              <br />
              <Button style={styles.buttonStyle} className="mb-3">
                Level 02
              </Button>
              <br />
              <Button style={styles.buttonStyle} className="mb-3">
                Level 03
              </Button>
              <br />
              <Button style={styles.buttonStyle} className="mb-3">
                Level 04
              </Button>
              <br />
              <Button style={styles.buttonStyle} className="mb-3">
                Level 05
              </Button>
            </span>
            <Container style={styles.containerStyle}>
              <CardImg top className="img-fluid" src={img5} />
            </Container>
          </Col>
          <Row
            style={{
              float: "right",
              flexDirection: "row-reverse",
              marginTop: 50,
            }}
          >
            <Link to={"./"} style={{ textAlign: "right" }}>
              <Button color="primary" style={styles.roundBtn}>
                Back
              </Button>
            </Link>
          </Row>
        </Row>
      </div>
    )
  }
}

const styles = {
  buttonStyle: {
    // width: 100,
    // marginBottom: -10,
    color: "white",
    backgroundColor: "#859bad",
    dark: {
      backgroundColor: "#464647",
      color: "white",
    },
  },
  roundBtn: {
    // width: "10%",
    // marginTop: '5%'
  },
  btnSpan: {
    marginTop: "50px",
  },
  containerStyle: {
    marginLeft: "-10%",
    zIndex: -1,
    maxWidth: "100%",
  },
  colStyle: {
    display: "flex",
  },
  mapIconBtn: {
    float: "right",
    marginTop: "-70px",
    position: "relative",
    marginRight: "50px",
  },
  addZoneBtn: {
    position: "relative",
    float: "right",
    marginTop: "-35%",
  },
}
export default AdminAddSmartSlots
