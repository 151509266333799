import React from 'react'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom';

function AccessDenied() {
    const history = useHistory();

    const goBack = () => {
      history.goBack();
    };
  return (
    <div>
        <h3 style={{textAlign:'center'}}>Access Denied</h3>
        <div style={{textAlignLast:'center'}}> <Button onClick={goBack}>Go Back To page</Button></div>
       
    </div>
  )
}

export default AccessDenied