import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import PropertOwnerSidebarContent from "./PropertOwnerSidebarContent"
import AdSidebarContent from "./AdSidebarContent"
import AdminSidebarContent from "./AdminSidebarContent"
import EventsSidebarContent from "./EventsSidebarContent"

const Sidebar = props => {
  const obj = JSON.parse(sessionStorage.getItem("authUser"))
  return (
    <React.Fragment>
      <div className="vertical-menu" style={{zIndex:9999999}}>
        <div data-simplebar className="h-100">
          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}
          {obj.role == "owner" ? <PropertOwnerSidebarContent /> : '' }
          {obj.role == "agency" ? <AdSidebarContent /> : ''}
          {obj.role == "admin" ? <AdminSidebarContent /> : ''}
          {obj.role == "event" ? <EventsSidebarContent /> : ''}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
