import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

const AddUser = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card>
          <Form>
            <CardTitle>New User</CardTitle>
            <Row>
              <Col>
                <Label>Username</Label>
                <Input
                  type="text"
                  name="username"
                  placeholder="username"
                  required
                />
                <Label>DOB</Label>
                <Input type="text" />
                <Label>Mobile No</Label>
                <Input type="text" />
                <Label>Gender</Label>
                <Input type="text" />
                <Label>Roles</Label>
                <Input type="select" className="form-select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
                <Label>Assign To</Label>
                <Input type="select" className="form-select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
              </Col>
              <Col>
                <Label>Username</Label>
                <Input
                  type="text"
                  name="username"
                  placeholder="username"
                  required
                />
                <Label>Email</Label>
                <Input type="text" />
                <Label>Designation</Label>
                <Input type="text" />
                <Label>Location</Label>
                <Input type="text" />
                <Label>Emirates ID</Label>
                <Input type="text" />
                <Label>Restricted Level</Label>
                <Input type="select" className="form-select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
              </Col>
              <Col>
                <Input type="file">Select User Image</Input>
              </Col>
            </Row>
            <Row>
              <Col className="col-10"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
                <Link to="./">
                  <Button color="primary">Add</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default AddUser