import React from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Row,
} from "reactstrap"

//components
import MultiLevelProgressBar from "./MultiLevelProgressBar"
import LeafletEditor from "./LeafletEditor"

class EventsAddZone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }
  //levels = {['Mark']}
  render() {
    return (
      <div className="page-content">
        <MultiLevelProgressBar />
        <Row className="mt-3">
          <LeafletEditor />
          <Row
            style={{
              float: "right",
              flexDirection: "row-reverse",
              marginTop: 50,
            }}
          >
            <Link to={"../zones"} style={{ textAlign: "right" }}>
              <Button color="primary" style={styles.roundBtn}>
                Back
              </Button>
            </Link>
          </Row>
        </Row>
        <Row className="pt-5"></Row>
      </div>
    )
  }
}

const styles = {
  buttonStyle: {
    // width: 100,
    // marginBottom: -10,
    color: "white",
    backgroundColor: "#859bad",
    dark: {
      backgroundColor: "#464647",
      color: "white",
    },
  },
  roundBtn: {
    // width: "10%",
    // marginTop: '5%'
  },
  btnSpan: {
    marginTop: "50px",
  },
  containerStyle: {
    marginLeft: "-10%",
    zIndex: -1,
    maxWidth: "100%",
  },
  colStyle: {
    display: "flex",
  },
  mapIconBtn: {
    float: "right",
    marginTop: "-70px",
    position: "relative",
    marginRight: "50px",
  },
  addZoneBtn: {
    position: "relative",
    float: "right",
    marginTop: "-35%",
  },
}
export default EventsAddZone
