import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  CardBody,
} from "reactstrap"

const NotificationsDashboard = props => {
  return (
      <Container className="page-content" fluid>
        <Row className="mb-3">
            <Row>
              <Col>
                <Input
                  type="select"
                  placeholder="Agency Name"
                  className="form-select"
                >
                  <option>Agency Name</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>From</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="" className="form-select">
                  <option>To</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Type</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Status</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
        </Row>
     
       <Row>
       <div class="card">
        <h4 class="card-header">Installation Request</h4>
        <div class="card-body">
            <h4 class="card-title">Request from CAM Agency Booking ID  <Link to="">B 5201 CAM 0522</Link></h4>
            <p class="card-text"> Notification for the property owner for the installation on the selected
              slots on <b>25 February 2022, 10:30 AM</b></p>
            <div class="float-md-end">
              <a href="#" class="btn btn-primary">Accept</a><span> </span>
              <a href="#" class="btn btn-danger">Reject</a>
            </div>
        </div>
        </div>
       </Row>

       <Row>
       <div class="card">
        <h4 class="card-header">Installation Completed</h4>
        <div class="card-body">
            <h4 class="card-title">Installation has been completed for Booking ID  <Link to="">B 5201 CAM 0522</Link></h4>
            <div class="float-md-end">
              <a href="#" class="btn btn-success">Details</a><span> </span>
            </div>
        </div>
        </div>
       </Row>

       <Row>
       <div class="card">
        <h4 class="card-header">Installation Request</h4>
        <div class="card-body">
            <h4 class="card-title">Installation has been completed for Booking ID <Link to="">B 5201 CAM 0522</Link></h4>
            <p class="card-text"> Notification for the property owner for the installation on the selected
              slots on <b>25 February 2022, 10:30 AM</b></p>
            <div class="float-md-end">
              <a href="#" class="btn btn-primary">Accept</a><span> </span>
              <a href="#" class="btn btn-danger">Reject</a>
            </div>
        </div>
        </div>
       </Row>
       
      </Container>
  )
}

export default NotificationsDashboard
