import React from "react"

import {
  Card,
  CardText,
  Container,
  Row,
  Col,
  Input,
  CardBody,
  CardImg,
  Table,
  Button,
} from "reactstrap"

//images
import DashImg4 from "../../assets/images/adAgency/DashCardImg4.png"
import DashImg5 from "../../assets/images/adAgency/DashCardImg5.png"
import DashImg6 from "../../assets/images/adAgency/DashCardImg6.png"

//charts
import "chartist/dist/scss/chartist.scss"
import Linechart from "pages/AllCharts/echart/linechart"
import LineBarchart from "pages/AllCharts/echart/linebarchart"
import DoughnutChart from "pages/AllCharts/echart/doughnutchart"
import PieChart from "pages/AllCharts/echart/piechart"
import BarChart from "pages/AllCharts/chartist/barchart"
import { Link } from "react-router-dom"

const jsonData = [
  {
    title: "Bookings",
    value: "1,685",
    percentage: "+ 12%",
  },
  {
    title: "Users",
    value: "685",
    percentage: "+ 59%",
  },
  {
    title: "Revenue",
    value: "$56,098",
    percentage: "+ 32%",
  },
  {
    title: "Total Slots Booked",
    value: "890",
    percentage: "+ 78%",
  },
  {
    title: "Best Performing Event",
    value: "Christmas",
    percentage: "+ 12%",
  },
  {
    title: "Best Performing Agency",
    value: "Media Agency",
    percentage: "+ 2%",
  },
  {
    title: "Approval Pending",
    value: "20",
    percentage: "+ 15%",
  },
  {
    title: "Payment Pending",
    value: "22",
    percentage: "+ 18%",
  },
]

const AdminDashboard = props => {
  function truncateText(selector, maxLength) {
    var truncated = selector

    if (truncated.length > maxLength) {
      truncated = truncated.substr(0, maxLength) + "..."
    }
    return truncated
  }
  return (
    <React.Fragment>
      <Row className="page-content">
      <Row className="pt-4" xs={1} md={4}>
          {jsonData.map((item, index) => (
            <Col key={index} className="pt-3">
              <div className="card mini-stat bg-primary text-white">
                <div
                  className="card-body"
                  style={{
                    height: "150px",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <div className="mb-4" style={{ marginTop: "10px" }}>
                    <div className="float-start mini-stat-img me-4">
                      <i className="fa fa-book fa-4x"></i>
                    </div>
                    <h5 className="font-size-14 text-uppercase text-white-50 w-100">
                      {item.title}
                    </h5>
                    <h4 className="fw-medium font-size-15">
                      {truncateText(item.value, 20)}
                    </h4>
                    <div className="mini-stat-label bg-success top-0">
                      <p className="mb-0">{item.percentage}</p>
                    </div>
                  </div>
                  <div
                    className=" position-absolute bottom-0 d-flex"
                    style={{ alignItems: "flex-end" }}
                  >
                    <div className="float-end mb-2">
                      <a href="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </a>
                    </div>
                    <p className="text-white-50 mb-2 mt-2">Since last month</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* <Row className="pt-3">
          <Col>
            <Card>
              <Row>
              <div class="card mini-stat bg-primary text-white">
                <div class="card-body">
                    <div class="mb-4">
                        <div class="float-start mini-stat-img me-4">
                        <i className="fa fa-book fa-3x"></i>
                        </div>
                        <h5 class="font-size-16 text-uppercase text-white-50">Bookings</h5>
                        <h4 class="fw-medium font-size-24">1,685 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                        <div class="mini-stat-label bg-success">
                            <p class="mb-0">+ 12%</p>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="float-end">
                            <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                        </div>
                        <p class="text-white-50 mb-0 mt-1">Since last month</p>
                    </div>
                </div>
              </div>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card >
            <div class="card mini-stat bg-primary text-white">
                <div class="card-body">
                    <div class="mb-4">
                        <div class="float-start mini-stat-img me-4">
                        <i className="fa fa-user fa-3x"></i>
                        </div>
                        <h5 class="font-size-16 text-uppercase text-white-50">Users</h5>
                        <h4 class="fw-medium font-size-24">685 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                        <div class="mini-stat-label bg-success">
                            <p class="mb-0">+ 52%</p>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="float-end">
                            <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                        </div>
                        <p class="text-white-50 mb-0 mt-1">Since last month</p>
                    </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card >
            <div class="card mini-stat bg-primary text-white">
                <div class="card-body">
                    <div class="mb-4">
                        <div class="float-start mini-stat-img me-4">
                        <i className="fa fa-table fa-3x"></i>
                        </div>
                        <h5 class="font-size-16 text-uppercase text-white-50">Revenue</h5>
                        <h4 class="fw-medium font-size-24">$56,098 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                        <div class="mini-stat-label bg-success">
                            <p class="mb-0">+ 32%</p>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="float-end">
                            <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                        </div>
                        <p class="text-white-50 mb-0 mt-1">Since last month</p>
                    </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card >
            <div class="card mini-stat bg-primary text-white">
                <div class="card-body">
                    <div class="mb-4">
                        <div class="float-start mini-stat-img me-4">
                        <i className="fa fa-cube fa-3x"></i>
                        </div>
                        <h5 class="font-size-16 text-uppercase text-white-50">Total slots booked</h5>
                        <h4 class="fw-medium font-size-24">890 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                        <div class="mini-stat-label bg-success">
                            <p class="mb-0">+ 78%</p>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="float-end">
                            <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                        </div>
                        <p class="text-white-50 mb-0 mt-1">Since last month</p>
                    </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Card>
              <CardText>Slot Type</CardText>
              <Linechart />
            </Card>
          </Col>
          <Col>
            <Card>
              <CardText>Revenue VS Time</CardText>
              <DoughnutChart />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardText>Booking VS Years</CardText>
              <LineBarchart />
            </Card>
          </Col>
          <Col>
            <Card>
              <CardText>Top 10 Malls</CardText>
              <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Property</th>
                          <th>Location</th>
                          <th>Total Bookings</th>
                          <th>Current Bookings</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Dubail Mall</td>
                          <td>Dubai</td>
                          <td>3800</td>
                          <td>2334</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Dalma Mall</td>
                          <td>Sharja</td>
                          <td>3000</td>
                          <td>2304</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Lulu Mall</td>
                          <td>Abu Dhabi</td>
                          <td>2800</td>
                          <td>2000</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Dubail Mall</td>
                          <td>Dubai</td>
                          <td>3800</td>
                          <td>2334</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Dalma Mall</td>
                          <td>Sharja</td>
                          <td>3000</td>
                          <td>2304</td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Lulu Mall</td>
                          <td>Abu Dhabi</td>
                          <td>2800</td>
                          <td>2000</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardText>Booked Category</CardText>
              <PieChart />
            </Card>
          </Col>
          <Col>
            <Card>
              <CardText>Smart Slot Booking</CardText>
              <BarChart />
            </Card>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  )
}


export default AdminDashboard