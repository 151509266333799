import { useState, useEffect } from "react";

const INACTIVE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
// const INACTIVE_TIMEOUT = 5 * 1000;

const SessionManager = () => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const handleActivity = () => {
      setLastActivityTime(Date.now());
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keyup", handleActivity);
    window.addEventListener("touchstart", handleActivity);
    window.addEventListener("touchmove", handleActivity);
    window.addEventListener("touchend", handleActivity);
    window.addEventListener("scroll", handleActivity);

    return () => {
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keyup", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
      window.removeEventListener("touchmove", handleActivity);
      window.removeEventListener("touchend", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    };
  }, []);

  useEffect(() => {
    const checkActivity = () => {
      const now = Date.now();
      const elapsed = now - lastActivityTime;

      if (elapsed >= INACTIVE_TIMEOUT && window.location.pathname != '/home') {
        // clear localStorage if there has been no activity for the timeout period
        window.location.replace("/home");

      }
    };

    const timerId = setInterval(checkActivity, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [lastActivityTime]);


  return null;
};

export default SessionManager;