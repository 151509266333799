import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Container,
  Button,
  Label,
  Input,
  CardImg,
  FormGroup
} from "reactstrap"

//img
import slotDescSample from "../../assets/images/slotDescSample.png"
import AddZoneImg from "../../assets/images/AddZoneImg.png"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const UiModal = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <i
        className="fa fa-edit"
        onClick={() => {
          tog_center()
        }}
      ></i>
      <Row>
      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                  style={{ minWidth: 750 }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">
                      Festival City Mall Level 01
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Slot Name</Label>
                          <Input type="text" placeholder="Slot 01" />
                        </FormGroup>
                        <FormGroup>
                          <Label>Collateral Type</Label>
                          <Input type="select" className="form-select">
                            <option>Ceiling</option>
                            <option>Floor</option>
                            <option>Wall</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input type="textarea" placeholder="Beside store 101" />
                        </FormGroup>
                        <FormGroup>
                          <Label>Default Image</Label>
                          <Input type="file" />
                        </FormGroup>
                        <FormGroup>
                          <Label>GPS Coordinates</Label>
                          <Input
                            type="text"
                            placeholder="3585567.83,11163.84,-106.13"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Documents</Label>
                          <Input type="select" className="form-select" name="selectMulti" id="exampleSelectMulti" multiple>
                            <option>Police Permit</option>
                            <option>Muncipality Permit</option>
                            <option>Media Permit</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Slot Limits</Label>
                          <CardImg src={AddZoneImg} />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>a</Label>
                              <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                              <Label>b</Label>
                              <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                              <Label>Power</Label>
                              <Input type="select" className="form-select">
                                <option>Power Available</option>
                                <option>Power Unavailable</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>c</Label>
                              <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                              <Label>d</Label>
                              <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                              <Label>Weight</Label>
                              <Input type="text" placeholder="kg" />
                            </FormGroup>
                          </Col>
                          <FormGroup>
                          <Label>Price Category</Label>
                          <Input type="select" className="form-select">
                            <option disabled>Select Price Category</option>
                          </Input>
                        </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <Row className="text-end">
                    <Col style={styles.modalBtnStyle} className="mb-3">
                      <Button
                        className="btn btn-primary waves-effect waves-light me-2"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Back
                      </Button>
                    
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light me-3"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
