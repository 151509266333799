import React, { Component } from "react"
import ChartistGraph from "react-chartist"

class piechart extends Component {
  render() {
    var pieChartData = {
      series: [5, 3, 4],
      labels: ["42%", "25%", "33%"],
    }
    var pieChartOptions = {
      showLabel: true,
    }
    const responsiveOptions = [
      [
        "screen and (max-width: 640px)",
        {
          width: "100%",
          height: "210px",
        },
      ],
      [
        "screen and (min-width: 641px) and (max-width: 1024px)",
        {
          width: "100%",
          height: "250px",
        },
      ],
      [
        "screen and (min-width: 1025px)",
        {
          width: "50%",
          height: "270px",
        },
      ],
    ]
    return (
      <React.Fragment>
        <ChartistGraph
          className=" mx-auto d-block"
          data={pieChartData}
          options={pieChartOptions}
          style={{ height: "300px" }}
          type={"Pie"}
          responsiveOptions={responsiveOptions}
        />
      </React.Fragment>
    )
  }
}

export default piechart
