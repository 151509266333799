import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Card,
  CardText,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

import { useHistory } from "react-router-dom"
import { axiosApi } from "helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"

const FloorPlans = props => {
  const [zoneName, setZoneName] = useState("")
  const [eventId, setEventId] = useState("")
  const [footfallPerDay, setFootfallPerDay] = useState("")
  const [slotsNos, setSlotsNos] = useState("")
  const [nonLeasable, setNonLeasable] = useState("")
  const [description, setDescription] = useState("")
  const [gpsCoordinate, setGpsCoordinate] = useState("")
  const [image, setImage] = useState(null)
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("Please ")
  const history = useHistory()

  const handleCancelClick = () => {
    history.goBack() // Go back to the previous page
  }
  useEffect(() => {
    const queryString = window.location.search
    setEventId(queryString.slice(4))
  })

  const handleImageChange = event => {
    const file = event.target.files
    setImage(file)
  }
  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append("zoneName", zoneName)
    formData.append("eventId", eventId)
    formData.append("floorId", 1)
    formData.append("footfallPerDay", footfallPerDay)
    formData.append("slotsNos", slotsNos)
    formData.append("smartSlotsNos", 1)
    formData.append("nonLeasable", nonLeasable)
    formData.append("description", description)
    formData.append("gpsCoordinate", gpsCoordinate)
    if (image) {
      formData.append("image", image[0])
    }

    axiosApi
      .post("/event-organizer/zone", formData)
      .then(response => {
        console.log("response", response.data)
        history.goBack()
      })
      .catch(error => {
        setbasic(true)
        setSweetTitle("Please Fill Data")
        // handle error
        console.log(error)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Col xl="3" lg="4" sm="6" className="mb-2">
          {basic ? (
            <SweetAlert
              title={sweetTitle}
              onConfirm={() => {
                setbasic(false)
              }}
            />
          ) : null}
        </Col>
        <Card className="shadow-sm mt-3">
          <CardText className="fs-4 ms-5">Add Zone</CardText>
        </Card>
        <Form onSubmit={handleSubmit}>
          {" "}
          <br />
          <Row sm={1} md={3} lg={3}>
            <Col>
              <FormGroup style={styles.formGroup}>
                <Label for="zoneName">Zone Name</Label>
                <Input
                  type="text"
                  name="zoneName"
                  id="zoneName"
                  placeholder="zoneName"
                  value={zoneName}
                  onChange={e => setZoneName(e.target.value)}
                  className="w-100"
                />
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="slotsNos">Slots Nos</Label>
                <Input
                  type="text"
                  name="slotsNos"
                  id="slotsNos"
                  placeholder="slotsNos"
                  value={slotsNos}
                  onChange={e => setSlotsNos(e.target.value)}
                  className="w-100"
                />
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={description}
                  className="w-100"
                  onChange={e => setDescription(e.target.value)}
                />
              </FormGroup>
              <FormGroup style={styles.formGroup}>
                <Label for="image">Image</Label>
                <Input
                  type="file"
                  name="image"
                  id="image"
                  onChange={handleImageChange}
                  className="w-100"
                />
              </FormGroup>
            </Col>
            <Row>
              <Col>
                <FormGroup style={styles.formGroup}>
                  <Label for="gpsCoordinate">GPS Coordinate</Label>
                  <Input
                    type="text"
                    name="gpsCoordinate"
                    id="gpsCoordinate"
                    value={gpsCoordinate}
                    className="w-100"
                    onChange={e => setGpsCoordinate(e.target.value)}
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="footfallPerDay">Footfall Per Day</Label>
                  <Input
                    type="text"
                    name="footfallPerDay"
                    id="footfallPerDay"
                    placeholder="footfallPerDay"
                    value={footfallPerDay}
                    onChange={e => setFootfallPerDay(e.target.value)}
                    className="w-100"
                  />
                </FormGroup>
                <FormGroup style={styles.formGroup}>
                  <Label for="nonLeasable">NonLeasable</Label>
                  <Input
                    type="number"
                    name="nonLeasable"
                    id="nonLeasable"
                    placeholder="nonLeasable"
                    value={nonLeasable}
                    className="w-100"
                    onChange={e => setNonLeasable(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row style={{ width: "100%" }}>
              <Col
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Button
                  onClick={handleCancelClick}
                  color="danger"
                  style={{ marginBottom: 10, marginLeft: 5 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  style={{ marginBottom: 10, marginLeft: "5px" }}
                  name="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}
const styles = {
  formGroup: {
    paddingBottom: 15,
  },
}

export default FloorPlans
