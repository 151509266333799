import React from "react"
import {
  Card,
  Row,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"


const EventsRolesAndPermissions = props => {
  
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText>
              <h5>Roles And Permissions</h5>
            </CardText>
            <Label>
              Role Name
              <Input type="text" />
            </Label>
            <Row>
              <Label>
                <Input type="checkbox" />
                Check All
              </Label>
            </Row>
            <Row>
              <Table>
                <thead>
                  <th>Permissions</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Dashboard</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Filters</td>
                    <td>
                      <Input type="checkbox" name="filtersRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="filtersWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="filtersEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="filtersDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Malls</td>
                    <td>
                      <Input type="checkbox" name="mallsRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="mallsWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="mallsEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="mallsDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Slots</td>
                    <td>
                      <Input type="checkbox" name="slotsRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="slotsWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="slotsEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="slotsDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Documents</td>
                    <td>
                      <Input type="checkbox" name="documentsRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="documentsWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="documentsEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="documentsDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Notification</td>
                    <td>
                      <Input type="checkbox" name="notificationRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="notificationWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="notificationEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="notificationDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Bookings</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Notifications</td>
                    <td>
                      <Input type="checkbox" name="bookingsNotificationsRead" />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name="bookingsNotificationsWrite"
                      />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsNotificationsEdit" />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name="bookingsNotificationsDelete"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Booking Status</td>
                    <td>
                      <Input type="checkbox" name="bookingsBookingStatusRead" />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name="bookingsBookingStatusWrite"
                      />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsBookingStatusEdit" />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name="bookingsBookingStatusDelete"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Filters</td>
                    <td>
                      <Input type="checkbox" name="bookingsFiltersRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsFiltersWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsFiltersEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsFiltersDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Payments</td>
                    <td>
                      <Input type="checkbox" name="bookingsPaymentsRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsPaymentsWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsPaymentsEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsPaymentsDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td>Documents</td>
                    <td>
                      <Input type="checkbox" name="bookingsDocumentsRead" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsDocumentsWrite" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsDocumentsEdit" />
                    </td>
                    <td>
                      <Input type="checkbox" name="bookingsDocumentsDelete" />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row className="flex-row-reverse mt-3">
                <Button color="primary" className="col-1">
                  Save
                </Button>
              </Row>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 30,
    maxWidth: "100%",
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default EventsRolesAndPermissions
