import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import paser from "react-html-parser"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  Table,
  Button,
  Input,
  Modal,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
import Doughnut from "./CollateralType"
import { EventsIndoorData } from "../../Common/Data/EventsIndoorData"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-modal-video/scss/modal-video.scss"
//components
import EventsSlotsAdd from "../../Slots/EventsSlotsAdd"
// import images
import placeholderImage from "../../../assets/images/placeholderImg.webp"
import { autofill } from "redux-form"

const EventsDetailCard = props => {
  const [isLoading, setLoading] = useState(true)
  const [apiRes, setApiRes] = useState([])
  const [i, setI] = useState("")
  const obj = JSON.parse(sessionStorage.getItem("authUser"))
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()

  useEffect(() => {
    const queryString = window.location.search
    setI(queryString.slice(1))
  })

  useEffect(() => {
    axiosApi
      .get("/event-organizer/events")
      .then(res => {
        setApiRes(res.data.data.filter(data => data.id == i))
        setImg1(res.data.data.filter(data => data.id == i)[0].imageURLs[0])
        setImg2(res.data.data.filter(data => data.id == i)[0].imageURLs[1])
        setImg3(res.data.data.filter(data => data.id == i)[0].imageURLs[2])
        setImg4(res.data.data.filter(data => data.id == i)[0].imageURLs[3])
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
      })
  }, [i])

  const [modal_center, setmodal_center] = useState(false)
  const [modal_center2, setmodal_center2] = useState(false)


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }


  const [photoIndex, setphotoIndex] = useState(0)
  const [isFits, setisFits] = useState(false)
  const [isEffects, setisEffects] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const images = apiRes
    ? [
        img1 ? img1 : placeholderImage,
        img2 ? img2 : placeholderImage,
        img3 ? img3 : placeholderImage,
        img4 ? img4 : placeholderImage,
      ]
    : [placeholderImage, placeholderImage, placeholderImage, placeholderImage]

  return (
    <div>
      {isLoading ? (
        <div
          className="page-content"
          style={{
            position: "fixed",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <>
          <React.Fragment>
            <div className="page-content">
              <Card>
                <Card
                  style={{
                    background: "#F1F5F9",
                    margin: 10,
                    padding: 10,
                    display: "inline-table",
                  }}
                  id="main-card"
                >
                  <Row>
                    <Col>
                      <CardTitle className="h3">
                        {apiRes.length != 0 ? apiRes[0].title : "null"}
                      </CardTitle>
                      <CardTitle>
                        <h4>
                          {apiRes.length != 0 ? apiRes[0].eventName : "null"}
                        </h4>
                      </CardTitle>
                    </Col>
                  </Row>
                  <Row xs={1} md={3}>
                    <Col>
                      <CardText
                        id="card-description"
                        style={{
                          whiteSpace: "-moz-pre-wrap",
                          height: autofill,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: "bold",
                          fontSize: "inherit",
                        }}
                      >
                        <br />
                        <span className="fa fa-building mb-3">
                          {" "}
                          &nbsp;&nbsp;
                        </span>
                        {apiRes.length != 0 ? apiRes[0].address : "null"}
                        <br />
                        <span className="fa fa-ban mb-3"> &nbsp;&nbsp;</span>
                        {apiRes.length != 0 ? apiRes[0].adExcluded : "null"}
                        <br />
                        <span className="mb-3"> &#x1F463; &nbsp;</span>
                        {apiRes.length != 0 ? apiRes[0].footFall : "null"}
                        <br />
                        <span className="fa fa-user mb-3"> &nbsp;&nbsp;</span>
                        {obj.userName} ({obj.role})
                        <br />
                        <span className="fa fa-map mb-3"></span>
                        &nbsp;
                        <span> </span> &nbsp; Longitude:{" "}
                        {apiRes[0] ? apiRes[0].gpsCoordinate.lat : ""}
                        <br />
                        <span className="ms-3"> </span>
                        &nbsp; &nbsp; Latitude:{" "}
                        {apiRes[0] ? apiRes[0].gpsCoordinate.lon : ""}
                        <span className="ms-3"> &nbsp;</span>
                        <br />
                        <span className="fa fa-clipboard  mb-3"> &nbsp;</span>
                        {apiRes.length != 0 ? apiRes[0].description : "null"}
                        <br />
                        <span className="fa fa-link  mb-3"> &nbsp;</span>
                        {apiRes.length != 0 ? apiRes[0].webLink : ""}
                        <br />
                        {/* <span className="fa fa-clipboard  mb-3"> &nbsp;</span>
                        {paser(
                          apiRes.length != 0
                            ? apiRes[0].contractualAgreement.replace(/"/g, "")
                            : ""
                        )} */}
                      </CardText>
                      {/* <Link
                        xs={3}
                        to={`../events/floorPlans?id=${apiRes[0].id}`}
                        className="btn"
                        style={styles.primarybtn}
                      >
                        AREA LAYOUT
                      </Link> */}
                      <Link
                        xs={3}
                        to={`/events/slots?id=${apiRes[0].id}`}
                        className="btn btn-primary"
                        style={{
                          marginLeft: "1%",
                        }}
                      >
                        EXPORT SLOTS
                      </Link>
                    </Col>
                    <Col>
                      <CardImg
                        top
                        className="img-fluid"
                        src={img1 ? img1 : placeholderImage}
                        alt="Event"
                        style={{
                          borderRadius: 8,
                          minHeight: "280px",
                          maxHeight: "350px",
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      {isFits ? (
                        <Lightbox
                          mainSrc={images[photoIndex]}
                          enableZoom={false}
                          onCloseRequest={() => {
                            setisFits(!isFits)
                          }}
                        />
                      ) : null}
                      {isEffects ? (
                        <Lightbox
                          mainSrc={images[3]}
                          enableZoom={false}
                          onCloseRequest={() => {
                            setisEffects(!isEffects)
                          }}
                        />
                      ) : null}
                      {isGallery ? (
                        <Lightbox
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={
                            images[
                              (photoIndex + images.length - 1) % images.length
                            ]
                          }
                          enableZoom={true}
                          onCloseRequest={() => {
                            setisGallery(false)
                          }}
                          onMovePrevRequest={() => {
                            setphotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }}
                          onMoveNextRequest={() => {
                            setphotoIndex((photoIndex + 1) % images.length)
                          }}
                          imageCaption={"Project " + parseFloat(photoIndex + 1)}
                        />
                      ) : null}
                      {isGalleryZoom ? (
                        <Lightbox
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={
                            images[
                              (photoIndex + images.length - 1) % images.length
                            ]
                          }
                          onCloseRequest={() => {
                            setisGalleryZoom(false)
                          }}
                          onMovePrevRequest={() => {
                            setphotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }}
                          onMoveNextRequest={() => {
                            setphotoIndex((photoIndex + 1) % images.length)
                          }}
                        />
                      ) : null}
                      <span>{props.i}</span>
                      <div
                        className="popup-gallery d-flex flex-wrap"
                        style={{ marginLeft: "18%" }}
                      >
                        <div className="img-fluid float-left m-1">
                          <img
                            src={images[0]}
                            onClick={() => {
                              setisGallery(true)
                              setphotoIndex(0)
                            }}
                            alt=""
                            width="135"
                            height="135"
                            style={{ borderRadius: 8 }}
                          />
                        </div>
                        <div className="img-fluid float-left m-1">
                          <img
                            src={images[1]}
                            onClick={() => {
                              setisGallery(true)
                              setphotoIndex(1)
                            }}
                            alt=""
                            width="135"
                            height="135"
                            style={{ borderRadius: 8 }}
                          />
                        </div>
                        <div className="img-fluid float-left m-1">
                          <img
                            src={images[2]}
                            onClick={() => {
                              setisGallery(true)
                              setphotoIndex(2)
                            }}
                            alt=""
                            width="135"
                            height="135"
                            style={{ borderRadius: 8 }}
                          />
                        </div>
                        <div className="img-fluid float-left m-1">
                          <img
                            src={images[3]}
                            onClick={() => {
                              setisGallery(true)
                              setphotoIndex(3)
                            }}
                            alt=""
                            width="135"
                            height="135"
                            style={{ borderRadius: 8 }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            background: "#F1F5F9",
                            margin: 1,
                            padding: 0,
                          }}
                        >
                          <Row id="levels-card">
                            <Col className="col-5">
                              <CardTitle className="h5 ps-2 pt-2 pb-2">
                                Levels/Zones
                              </CardTitle>
                              <h1 className="p-2 pt-0">
                                {EventsIndoorData[i]
                                  ? EventsIndoorData[i].zones
                                  : "10"}
                              </h1>
                            </Col>
                            <Col>
                              <Link
                                xs={3}
                                to={`../events/floorPlans?id=${apiRes[0].id}`}
                                className="btn mt-2 mb-2"
                                style={styles.primarybtn}
                              >
                                ADD LEVEL/ZONE
                              </Link>
                              <table>
                                <tr>
                                  <td>Maintenance</td>
                                  <td>
                                    {EventsIndoorData[i]
                                      ? EventsIndoorData[i].maintenanceZone
                                      : "null"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Leased</td>
                                  <td>
                                    {EventsIndoorData[i]
                                      ? EventsIndoorData[i].LeasedZone
                                      : "null"}
                                  </td>
                                </tr>
                              </table>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          style={{
                            background: "#F1F5F9",
                          }}
                        >
                          <Row>
                            <Col>
                              <CardTitle className="h5 p-2">Slots </CardTitle>
                              <h1 className="p-2 pt-0">
                                {EventsIndoorData[i]
                                  ? EventsIndoorData[i].slots
                                  : "100"}
                              </h1>
                            </Col>
                            <Col>
                              <Button
                                className="mt-2 mb-2"
                                onClick={() => {
                                  tog_center(apiRes[0].id)
                                }}
                                style={styles.primarybtn}
                              >
                                ADD SLOTS<span className="fa fa-plus"></span>
                              </Button>
                              <table>
                                <tr>
                                  <td>Maintenance</td>
                                  <td>
                                    {EventsIndoorData[i]
                                      ? EventsIndoorData[i].maintenanceSlot
                                      : "null"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Leased</td>
                                  <td>
                                    {EventsIndoorData[i]
                                      ? EventsIndoorData[i].LeasedSlot
                                      : "null"}
                                  </td>
                                </tr>
                              </table>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Card
                        style={{
                          background: "#F1F5F9",
                          marginTop: 10,
                          padding: 10,
                        }}
                      >
                        <CardTitle className="h5">Top 10 Slots</CardTitle>
                        <Table className="table table-striped mb-0">
                          <thead style={{ background: "#bcc5d1" }}>
                            <tr>
                              <th>Slot Id</th>
                              <th>Zone</th>
                              <th>Avg Booking</th>
                              <th>Revenue (Aed)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">SL130</th>
                              <td>02</td>
                              <td>85%</td>
                              <td>2334</td>
                            </tr>
                            <tr>
                              <th scope="row">SL110</th>
                              <td>01</td>
                              <td>85%</td>
                              <td>2304</td>
                            </tr>
                            <tr>
                              <th scope="row">SL101</th>
                              <td>01</td>
                              <td>85%</td>
                              <td>2000</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </Row>
                  </Col>
                  <Col className="col-5">
                    <Card className="">
                      <Row style={{ marginRight: "20px" }}>
                        <Card
                          style={{
                            background: "#F1F5F9",
                            margin: 10,
                            padding: 10,
                            height: "355px",
                          }}
                        >
                          <CardTitle className="h5">Collateral Type</CardTitle>
                          <CardBody>
                            <div
                              id="doughnut-chart"
                              className="e-chart"
                              style={{ textAlign: "center" }}
                            >
                              <Doughnut />
                            </div>
                          </CardBody>
                        </Card>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody style={{ display: "none" }}>
                    <Row>
                      <Col sm={6} md={4} xl={3}>
                        <EventsSlotsAdd
                          id={i}
                          isOpen={modal_center}
                          centered={true}
                          style={{ marginTop: "500px" }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody style={{ display: "none" }}>
                    <Row>
                      <Col sm={12} md={6} xl={4}>
                        <Modal
                          isOpen={modal_center2}
                          toggle={tog_center2}
                          centered={true}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">ZONE LISTING</h5>
                            <button
                              type="button"
                              onClick={tog_center2}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <FormGroup className="mb-3" row>
                              <Label sm={3}>Zone Name</Label>
                              <Col sm={9}>
                                <Input type="text" placeholder="Zone 01" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                              <Label sm={3}>Description</Label>
                              <Col sm={9}>
                                <Input type="textarea" />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                              <Label sm={3}>Floor Plan</Label>
                              <Col sm={9}>
                                <Input type="file" multiple />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                              <Label sm={3}>GPS Coordinates</Label>
                              <Col sm={9}>
                                <Row>
                                  <Col sm={6}>
                                    <Input
                                      className="mb-3"
                                      placeholder="lat"
                                      style={{ width: "100%" }}
                                    />
                                  </Col>
                                  <br />
                                  <Col sm={6}>
                                    <Input
                                      className="mb-3"
                                      placeholder="lon"
                                      style={{ width: "100%" }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          </div>
                          <div className="modal-footer">
                            <Button
                              className="btn btn-primary me-2"
                              onClick={tog_center2}
                              color="danger"
                            >
                              Back
                            </Button>
                            <Button
                              className="btn btn-primary"
                              onClick={tog_center2}
                              color="primary"
                            >
                              Submit
                            </Button>
                          </div>
                        </Modal>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </>
      )}
    </div>
  )
}

const cardStyle = {
  fontSize: 20,
  padding: 10,
  fontWeight: "bold",
  mainCard: {
    width: 250,
    height: 180,
  },
}

const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
  primarybtn: {
    backgroundColor: "#f97316",
    color: "white",
    fontSize: "13px",
  },
}

export default EventsDetailCard
