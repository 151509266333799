import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardImg,Nav, NavItem, NavLink,
} from "reactstrap"

import {
  Accordion,
  // Offcanvas,
  // OffcanvasHeader,
  // OffcanvasBody
} from "react-bootstrap"

import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet"

import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "bootstrap/dist/css/bootstrap.min.css"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

//components
import WizardIcon from "../Common/WizardModal"

//mallJson
import position from "../Common/Data/mallData.json"

// import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

const AdBookNowMapView = props => {
  let data = [{pid: 1}]
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
        <Col className="w-100">
          <button type="button" class="btn btn-outline-primary w-50 btn-lg">
            <Link to="/agency/bookNow">
                Map
            </Link>
          </button>
          <button type="button" class="btn btn-outline-primary w-50 btn-lg">
            <Link
              to="/agency/bookNow/gridView"
            >
              Grid
            </Link>
          </button>
        </Col>
        <Card></Card>
        {/* <Row>
             <Card >
                <div class="card mini-stat bg-primary text-white">
                    <div class="card-body">
                        <div class="mb-4">
                            <div class="float-start mini-stat-img me-4">
                            <i className="fa fa-user fa-3x"></i>
                            </div>
                            <h5 class="font-size-16 text-uppercase text-white-50">Total properties</h5>
                            <h4 class="fw-medium font-size-24">685 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                            <div class="mini-stat-label bg-success">
                                <p class="mb-0">+ 52%</p>
                            </div>
                        </div>
                        <div class="pt-2">
                            <div class="float-end">
                                <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                            </div>
                            <p class="text-white-50 mb-0 mt-1">Since last month</p>
                        </div>
                    </div>
                  </div>
              </Card>
              <Card >
                <div class="card mini-stat bg-primary text-white">
                    <div class="card-body">
                        <div class="mb-4">
                            <div class="float-start mini-stat-img me-4">
                            <i className="fa fa-user fa-3x"></i>
                            </div>
                            <h5 class="font-size-16 text-uppercase text-white-50">Total Slots</h5>
                            <h4 class="fw-medium font-size-24">7897 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
                            <div class="mini-stat-label bg-success">
                                <p class="mb-0">+ 12%</p>
                            </div>
                        </div>
                        <div class="pt-2">
                            <div class="float-end">
                                <a href="#" class="text-white-50"><i class="mdi mdi-arrow-right h5"></i></a>
                            </div>
                            <p class="text-white-50 mb-0 mt-1">Since last month</p>
                        </div>
                    </div>
                  </div>
              </Card>
            </Row> */}
          <Card>
            <Row>
              <Col className="col-10">
              </Col>
              <Col className="col-12">
                <Row>
                        <Col>
                          <Label>Booking</Label>
                          <Input
                            type="select"
                            placeholder="Cam Agency"
                            className="form-select"
                          >
                            <option></option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </Col>
                        <Col>
                          <Label>Region</Label>
                          <Input type="text" placeholder="Dubai" />
                        </Col>
                        <Col>
                          <Label>From</Label>
                          <Input type="date" placeholder="From"></Input>
                        </Col>
                        <Col>
                          <Label>To</Label>
                          <Input type="date" placeholder="To"></Input>
                        </Col>
                        <Col>
                          <Label>Slot Type</Label>
                          <Input
                            type="select"
                            placeholder="All"
                            className="form-select"
                          >
                            <option>Period</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </Col>
                        <Col>
                          <Label>Content Type</Label>
                          <Input type="text" placeholder="Food, Clothing..." />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label>Dimensions</Label>
                          <Input type="text" placeholder="a x b x c x d" />
                        </Col>
                        <Col>
                          <Label>Price min</Label>
                          <Input type="text" placeholder="min" />
                        </Col>
                        <Col>
                          <Label>Price Max</Label>
                          <Input type="text" placeholder="max" />
                        </Col>
                        <Col>
                          <Label>w (Kilogram)</Label>
                          <Input type="text" />
                        </Col>
                        <Col>
                          <Label>d (Meter)</Label>
                          <Input type="text" />
                        </Col>
                  </Row>
                  <Card>


                  </Card>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row>
          <MapContainer
            style={{ height: "80.5vh", width: "100%", zIndex: "1" }}
            center={[24.194, 53.718]}
            zoom={8}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {position.map(data => (
              <Link to="/agency/bookNow/propertyDetails" onClick={ () => { localStorage.mallId = data.pid-1 }}>
                <Marker position={[data.lat, data.lon]} key={data.lat}>
                  <Popup>
                    <CardTitle>{data.buildingNickname}</CardTitle>
                    <CardImg src={data.mediaUploadUrl} />
                    <CardText>Slots: {data.slotsAvailable}</CardText>
                  </Popup>
                </Marker>
              </Link>
            ))}
          </MapContainer>
          <Row style={styles.wizardIconRow}>
            <WizardIcon />
          </Row>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
    statusTd: {
      color: "black",
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  wizardIconRow: {
    justifyContent: "right",
    wizardIcon: {
      marginTop: -50,
      borderRadius: "100%",
      width: 70,
      height: 70,
      backgroundColor: "orange",
    },
  },
  bookNowViewBtns: {
    padding: 5,
    margin: 2,
    borderRadius: 2,
    backgroundColor: "#f0ebeb",
  },
}

export default AdBookNowMapView
