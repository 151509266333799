import React from "react"
import { Card, Row, Col, CardBody, Table, CardTitle } from "reactstrap"
//charts
import "chartist/dist/scss/chartist.scss"
import LineBarchart from "pages/AllCharts/echart/linebarchart"
import DoughnutChart from "pages/AllCharts/echart/doughnutchart"
import PieChart from "pages/AllCharts/echart/scatterchart"
import BarChart from "pages/AllCharts/chartjs/barchart"
import Bar from "pages/AllCharts/chartist/stackedbarchart"

const jsonData = [
  {
    title: "Bookings",
    value: "1,685",
    percentage: "+ 12%",
  },
  {
    title: "Users",
    value: "685",
    percentage: "+ 59%",
  },
  {
    title: "Revenue",
    value: "$56,098",
    percentage: "+ 32%",
  },
  {
    title: "Total Slots Booked",
    value: "890",
    percentage: "+ 78%",
  },
  {
    title: "Best Performing Event",
    value: "Christmas",
    percentage: "+ 12%",
  },
  {
    title: "Best Performing Agency",
    value: "Media Agency",
    percentage: "+ 2%",
  },
  {
    title: "Approval Pending",
    value: "20",
    percentage: "+ 15%",
  },
  {
    title: "Payment Pending",
    value: "22",
    percentage: "+ 18%",
  },
]
const EventsDashboard = props => {
  function truncateText(selector, maxLength) {
    var truncated = selector

    if (truncated.length > maxLength) {
      truncated = truncated.substr(0, maxLength) + "..."
    }
    return truncated
  }

  return (
    <React.Fragment>
      <Row className="page-content">
        <Row className="pt-4" xs={1} md={4}>
          {jsonData.map((item, index) => (
            <Col key={index} className="pt-3">
              <div className="card mini-stat bg-primary text-white">
                <div
                  className="card-body"
                  style={{
                    height: "150px",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <div className="mb-4" style={{ marginTop: "10px" }}>
                    <div className="float-start mini-stat-img me-4">
                      <i className="fa fa-book fa-4x"></i>
                    </div>
                    <h5 className="font-size-14 text-uppercase text-white-50 w-100">
                      {item.title}
                    </h5>
                    <h4 className="fw-medium font-size-15">
                      {truncateText(item.value, 20)}
                    </h4>
                    <div className="mini-stat-label bg-success top-0">
                      <p className="mb-0">{item.percentage}</p>
                    </div>
                  </div>
                  <div
                    className=" position-absolute bottom-0 d-flex"
                    style={{ alignItems: "flex-end" }}
                  >
                    <div className="float-end mb-2">
                      <a href="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </a>
                    </div>
                    <p className="text-white-50 mb-2 mt-2">Since last month</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Events Performance</CardTitle>
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">2541</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">84845</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">12001</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row>
                <BarChart />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Advertising Agency</CardTitle>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Media Agency</th>
                        <th>120000</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Impressario</td>
                        <td>76000</td>
                      </tr>
                      <tr>
                        <td>MgM</td>
                        <td>45000</td>
                      </tr>
                      <tr>
                        <td>Dubai Neo</td>
                        <td>44003</td>
                      </tr>
                      <tr>
                        <td>Boston View</td>
                        <td>32343</td>
                      </tr>
                      <tr>
                        <td>AVM</td>
                        <td>42343</td>
                      </tr>
                      <tr>
                        <td>BMW</td>
                        <td>45343</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Booking Status of events
                </CardTitle>
                <LineBarchart />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Revenue VS Collateral Type
                </CardTitle>
                <DoughnutChart />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Booked Category</CardTitle>
                <PieChart />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Smart Slot Booking</CardTitle>
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">5241</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">65411</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">51654</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row>
                <Bar />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  )
}

export default EventsDashboard
