//img
import cardImg1 from "../../../assets/images/gridViewImg1.png"

const data = [
    {
        id: 1,
        cardImg: cardImg1,
        mallName: "Dalma Mall",
        mallNameSub: "Layer 01",
        cardDescription : {
            hookName: "CAM12",
            zoneName: "Central Lobby",
            dimensions: {
                length: "20",
                height: "10"
                },
            weight: "5"
        }
    },
    {
        id: 2,
        cardImg: cardImg1,
        mallName: "Dalma Mall",
        mallNameSub: "Layer 01",
        cardDescription : {
            hookName: "CAM12",
            zoneName: "Central Lobby",
            dimensions: {
                length: "20",
                height: "10"
                },
            weight: "5"
        }
    },
    {
        id: 2,
        cardImg: cardImg1,
        mallName: "Dalma Mall",
        mallNameSub: "Layer 01",
        cardDescription : {
            hookName: "CAM12",
            zoneName: "Central Lobby",
            dimensions: {
                length: "20",
                height: "10"
                },
            weight: "5"
        }
    }
    ,
    {
        id: 2,
        cardImg: cardImg1,
        mallName: "Dalma Mall",
        mallNameSub: "Layer 01",
        cardDescription : {
            hookName: "CAM12",
            zoneName: "Central Lobby",
            dimensions: {
                length: "20",
                height: "10"
                },
            weight: "5"
        }
    }
    
]

export {data}