import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

//image
import userAvatar from "../../assets/images/userAvatar.png"
import { AvFeedback, AvField, AvForm, AvGroup } from "availity-reactstrap-validation"

const AdminUserListView = props => {
  return (
    <Container className="page-content">
    <Row className="mb-4">
      <Card>
        <AvForm>
          <CardTitle>New User</CardTitle>
          <Row>
            <Col xl="4" lg="4" md="12" className="mb-3">
              {/* First set of form fields */}
              <Label>Name</Label>
              <AvField
                type="text"
                name="propertyName "
                placeholder="Name"
                disabled
              />
              <Label>DOB</Label>
              <AvField type="date" name="dob"  disabled />
              <Label>Mobile No</Label>
              <AvField type="tel" name="phone" placeholder="Phone no"  disabled />
              <Label>Gender</Label>
              <AvField
                type="select"
                name="gender"
                className="form-select"
                disabled
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </AvField>
              <Label>Roles</Label>
              <AvField
                type="select"
                className="form-select"
                name="role"
                disabled
              >
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <Label>Email</Label>
              <AvField
                type="email"
                name="email"
                placeholder="Email"
                disabled
              />

              <Label>Username</Label>
              <AvField
                type="text"
                name="username"
                placeholder="username"
                disabled
              />
            </Col>
            <Col xl="4" lg="4" md="12" className="mb-3">
            <Label>Assign To</Label>
              <AvField type="select" className="form-select" name="assign"  disabled>
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <Label>Designation</Label>
              <AvField
                type="text"
                name="designation"
                disabled
                placeholder="Designation"
              />
              <Label>Location</Label>
              <AvField type="select" className="form-select" name="location"  disabled>
                <option value="1">select location</option>
                <option value="2">India</option>
                <option value="3">UAE</option>
              </AvField>
              <Label>Emirates ID</Label>
              <AvField
                type="text"
                name="emiratesid"
                placeholder="Emirates id"
              />
              <Label>Restricted Level</Label>
              <AvField
                type="select"
                className="form-select"
                name="restriction"
                disabled
              >
                <option value="">Select Option</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </AvField>
              <div className="mb-3">
                <AvGroup>
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    disabled
                    placeholder="Enter Password"
                    autoComplete="off"
                  />
                  <AvFeedback>Password is disabled</AvFeedback>
                </AvGroup>
              </div>
            </Col>
            <Col xl="4" lg="4" md="12" className="mb-3">
              <CardImg src={userAvatar} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="./">
                <Button
                  className="m-3"
                  color="danger"
                  style={{ width: "150px" }}
                >
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
        </AvForm>
      </Card>
    </Row>
  </Container>
  )
}

const Styles = {}
export default AdminUserListView
