import React from "react"
import { Link } from "react-router-dom"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle,Container, CardSubtitle, Label, Input } from "reactstrap"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"

// import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"
const bookingData = {
  columns: [
    {
      label: "Booking ID",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 150,
    },
    {
      label: "Group",
      field: "group",
      sort: "asc",
      width: 150,
    },
    {
      label: "Properties",
      field: "properties",
      sort: "asc",
      width: 150,
    },
    {
      label: "Slot(s)",
      field: "slots",
      sort: "asc",
      width: 150,
    }, 
    {
      label: "Content Type",
      field: "content_type",
      sort: "asc",
      width: 150,
    },
    {
      label: "Days",
      field: "days",
      sort: "asc",
      width: 150,
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
  ],
  rows:[
    {
      id: <Link to="bookings/details"> B 5201 CAM 0522 </Link>,
      date: '1/1/2022',
      group: "None",
      properties: "3",
      slots: "578",
      content_type: "Beverages",
      days: "20",
      amount: "$3500",
      status: "Pending Approval",
    },
    {
      id: <Link to="bookings/details"> B 5201 CAM 0522 </Link>,
      date: '1/1/2022',
      group: "None",
      properties: "3",
      slots: "578",
      content_type: "Beverages",
      days: "20",
      amount: "$3500",
      status: "Pending Approval",
    },
    {
      id: <Link to="bookings/details"> B 5201 CAM 0522 </Link>,
      date: '1/1/2022',
      group: "None",
      properties: "3",
      slots: "578",
      content_type: "Beverages",
      days: "20",
      amount: "$3500",
      status: "Pending Approval",
    },
    {
      id: <Link to="bookings/details"> B 5201 CAM 0522 </Link>,
      date: '1/1/2022',
      group: "None",
      properties: "3",
      slots: "578",
      content_type: "Beverages",
      days: "20",
      amount: "$3500",
      status: "Pending Approval",
    },
    {
      id: <Link to="bookings/details"> B 5201 CAM 0522 </Link>,
      date: '1/1/2022',
      group: "None",
      properties: "3",
      slots: "578",
      content_type: "Beverages",
      days: "20",
      amount: "$3500",
      status: "Pending Approval",
    },
  ]
}
const AdBookingDashboard = props => {
  return (
    <React.Fragment>
      <Container className="page-content">
      <Row>
          <CardBody>
                <CardTitle className="h4">BOOKINGS</CardTitle>
          </CardBody>
      </Row>
        <Row>
          
          <div class="col-sm-12">
          <Row>
              <Col>
                <Input
                  type="select"
                  placeholder="Cam Agency"
                  className="form-select"
                >
                  <option>Indoor/Outdoor</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="date" placeholder="From"></Input>
              </Col>
              <Col>
                <Input type="date" placeholder="To"></Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Building</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="" className="form-select">
                  <option>Status</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col>
                <Input type="select" placeholder="All" className="form-select">
                  <option>Content Type</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
            </Row>
          <Col className="col-12">
            <MDBDataTable noBottomColumns={true} responsive striped data={bookingData} exportToCSV />
          </Col>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default AdBookingDashboard
