import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Container,
  Button,
  CardImg,
  CardText,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//img
import imgIndoor from "../../assets/images/adAgencyWizardIndoor.png"
import imgOutdoor from "../../assets/images/adAgencyWizardOutdoor.png"
import imgDigital from "../../assets/images/adAgencyWizardDigital.png"

//component
import AdFormWizard from "../Bookings/AdFormWizard"

const UiModal = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <Row style={styles.wizardIconRow}>
        <Button
          className="ti-wand"
          style={styles.wizardIconRow.wizardIcon}
          onClick={() => {
            tog_center()
          }}
        ></Button>
      </Row>
      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                  style={{maxWidth: 1000}}
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <AdFormWizard/>
                  </div>
                  {/* <Row>
                    <Col style={styles.modalBtnStyle}>
                      <Button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        color="danger"
                      >
                        Yes
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        No
                      </Button>
                    </Col>
                  </Row> */}
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
  wizardIconRow: {
    justifyContent: "right",
    wizardIcon: {
      marginTop: -120,
      zIndex: 1,
      borderRadius: "100%",
      width: 70,
      height: 70,
      backgroundColor: "orange",
    },
  },
  wizardCard: {
    borderRadius: 7,
  }
}
export default UiModal
