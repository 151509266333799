import React from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
  CardImgOverlay
} from "reactstrap"

import { cardDataIndoor } from "../../Common/Data/PropertyIndoorData"
import PropertyDetailCard from "./PropertyDetailCard"

// import images

// import img1 from "../../../assets/images/mall-img/mall1.jpeg"

const PropertyCardIndoor = props => {
  console.log("cardDataIndoor=>",cardDataIndoor)
  function truncateText(selector, maxLength) {
        var truncated = selector;

    if (truncated.length > maxLength) {
        truncated = truncated.substr(0,maxLength) + '...';
    }
    return truncated;
}
  return (
    <React.Fragment>
      <div>
        <Container fluid={true} className="mt-3">
          <Row>
            {cardDataIndoor.map((cards) => (
              <Col xs={12} sm={6} md={4} lg={3} key={cards.id}>
                <Link to={cards.to + "?" + cards.id}>
                  <Card style={{ padding: '1.5%', marginBottom: '20px' }}>
                    <CardImg
                      top
                      className="img-fluid"
                      style={{ maxWidth: '100%' }}
                      src={cards.img}
                      alt={cards.description}
                    />
                    <CardImgOverlay>
                      <CardTitle
                        style={{
                          background: 'black',
                          opacity: 0.6,
                          height: 25,
                          width: '100%',
                          position: "absolute",
                          bottom: "100%",
                          left: 0,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          borderRadius:'10px',
                          textAlign:'center'
                        }}
                        className="text-white mt-0 h4"
                      >
                        {cards.title}
                      </CardTitle>
                    </CardImgOverlay>
                    <CardBody>
                      <p className="text-wrap">{truncateText(cards.description, 100)}</p>
                      <p className="card-text">
                        <small className="text-muted">
                          Last updated 3 mins ago
                        </small>
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PropertyCardIndoor
