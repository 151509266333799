//work in progress backup of SidebarContent.js
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardImg,
  NavLink,
} from "reactstrap"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import Header from "./Header"
import SidebarContent from "./SidebarContent"

const PropertOwnerSidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/properties" className=" waves-effect">
                <i className="fa fa-building"></i>
                <span>{props.t("Properties")}</span>
              </Link>
            </li>
            <li>
              <Link to="/bookings" className=" waves-effect">
                <i className="fa fa-calendar-check"></i>
                <span>{props.t("Bookings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/zones" className=" waves-effect">
                <i className="ti-layout-width-full"></i>
                <span>{props.t("Zones")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/slots" className=" waves-effect">
                <i className="ti-view-grid"></i>
                <span>{props.t("Slots")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/users" className=" waves-effect">
                <i className="ti-user"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/notifications" className=" waves-effect">
                <i className="ti-bell"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/reservations" className=" waves-effect">
                <i className="ti-calendar"></i>
                <span>{props.t("Reservations")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/logs" className=" waves-effect">
                <i className="ti-save"></i>
                <span>{props.t("Logs")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to="/subscription" className=" waves-effect">
                <i className="ti-shopping-cart"></i>
                <span>{props.t("Subscription")}</span>
              </Link>
            </li>{" "}
            <li>
              <Link to={"/reports"} className=" waves-effect">
                <i className="ti-file"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              {/* Sub Menu Start */}
              <ul className="sub-menu mm-collapse" id="side-menu">
                <li>
                  <Link to="/reports/revenueReports" className="waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Revenue Reports")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/reports/buildingReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Building Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/reports/zoneReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Zone Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/reports/bookingReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Booking Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/reports/smartSlotReports"
                    className=" waves-effect"
                  >
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Smart Slot Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/reports/pricingReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Pricing Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/reports/slotReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Slot Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/reports/paymentReports" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("PaymentReports")}</span>
                  </Link>
                </li>
              </ul>
              {/* Sub Menu End */}
            </li>{" "}
            <li>
              <Link to="/settings" className=" waves-effect">
                <i className="ti-settings"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              {/* Sub Menu Start */}
              <ul className="sub-menu mm-collapse" id="side-menu">
                <li>
                  <Link to="/settings/departments" className="waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Departments")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/settings/designation" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Designation")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings/pricingTable" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Pricing Table")}</span>
                  </Link>
                </li>
                {/* <li>
                    <Link
                      to="/settings/contentExclusion"
                      className=" waves-effect"
                    >
                      <i className="ti-layout-width-full"></i>
                      <span>{props.t("Content Exclusion")}</span>
                    </Link>
                  </li> */}
                  <li>
                  <Link to="/settings/properties" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Properties")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings/rolesAndPermissions" className=" waves-effect">
                    <i className="ti-layout-width-full"></i>
                    <span>{props.t("Roles & Permissions")}</span>
                  </Link>
                </li>
              </ul>
              {/* Sub Menu End */}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

PropertOwnerSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PropertOwnerSidebarContent))
