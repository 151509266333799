import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Container,
  Button,
  Input,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const UiModal = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Row>
      <Button
        className="btn btn-danger"
        onClick={() => {
          tog_center()
        }}
      >
        Reschedule
      </Button>

      <Col className="col-12">
        <Card>
          <CardBody style={{ display: "none" }}>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center()
                  }}
                  centered={true}
                >
                  <Form>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Installation Request</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Reqest from CAM Agency Booking ID <span className="bg-primary text-white p-1 rounded">B 5201 CAM 0522</span> to
                        notify the property owner for the installation on the
                        selected slots on
                      </p>
                      <Input type="date" required />
                      <Input type="select" className="form-select" required>
                        <option>9 AM</option>
                        <option>10 AM</option>
                        <option>11 AM</option>
                        <option>12 PM</option>
                      </Input>
                    </div>
                    <Row>
                      <Col style={styles.modalBtnStyle}>
                        <Button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                          color="danger"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Link to="/agency/notifications">
                          <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              tog_center()
                            }}
                            data-toggle="modal"
                            data-target=".bs-example-modal-center"
                          >
                            Confirm
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal
