import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardImg,
  CardBody,
  CardTitle,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"

import defaultImg from "../../assets/images/product/default.png"

//components
import UiModal from "./SlotsEdit"

const SlotDashboard = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    //setIsShown(true)
  }
  return (
    <Container className="page-content" fluid>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h1">SLOTS</CardTitle>
          </CardBody>
          <CardBody>
            <Row>
              <Col xs={2}>
                <Input
                  type="select"
                  placeholder="Cam Agency"
                  className="form-select"
                >
                  <option selected disabled>Building Name</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={1}>
                <Input type="select" placeholder="All" className="form-select">
                  <option selected disabled>Level</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={1}>
                <Input type="select" placeholder="" className="form-select">
                  <option selected disabled>Zones</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={2}>
                <Input type="select" placeholder="All" className="form-select">
                  <option selected disabled>Normal/ Smart Slots</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={2}>
                <Input type="select" placeholder="All" className="form-select">
                  <option selected disabled>Collateral Type</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={1}>
                <Input type="select" placeholder="All" className="form-select">
                  <option selected disabled>Status</option>
                  <option>Booked</option>
                  <option>Blocked</option>
                  <option>Vaccant</option>
                </Input>
              </Col>
              <Col xs={1}>
                <Input
                  type="select"
                  className="form-select mb-3"
                >
                  <option selected disabled>Period</option>
                  <option>1 month</option>
                  <option>3 month</option>
                  <option>6 month</option>
                  <option>1 year</option>
                </Input>
              </Col>
              <Col xs={2}>
                <Input
                  type="select"
                  className="form-select mb-3"
                >
                  <option selected disabled>Pricing Category</option>
                  <option>Unlisted</option>
                  <option>Category 01</option>
                  <option>Category 02</option>
                </Input>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-striped mb-0">
                <thead align="center" className="table-light">
                  <tr className="align-baseline">
                    <th>#</th>
                    <th>Default Image</th>
                    <th>Current Image</th>
                    <th>Slot Name</th>
                    <th>Collateral Type</th>
                    <th>
                      Booking <br />
                      Status
                    </th>
                    <th>
                      Capacity
                      <br />
                      kg
                    </th>
                    <th>
                      Slot Details
                      <br />
                      cm
                    </th>
                    <th>
                      Booking
                      <br />
                      (Current Month)
                    </th>
                    <th>Blocked</th>
                    <th>Active</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody align="center">
                  <tr>
                    <th scope="row">
                      <Link to="" style={styles.tagBtnStyle}>
                        S 1001
                      </Link>
                    </th>
                    <td>
                      <CardImg
                        src={defaultImg}
                        alt="veltrix"
                        className="rounded avatar-md"
                      />
                    </td>
                    <td>Current-Image.png</td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 <br /> Height 50 <br />
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="" style={styles.tagBtnStyle}>
                        S 1002
                      </Link>
                    </th>
                    <td>
                      <CardImg
                        src={defaultImg}
                        alt="veltrix"
                        className="rounded avatar-md"
                      />{" "}
                    </td>
                    <td>Current-Image.png</td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 <br /> Height 50 <br />
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="" style={styles.tagBtnStyle}>
                        S 1003
                      </Link>
                    </th>
                    <td>
                      <CardImg
                        src={defaultImg}
                        alt="veltrix"
                        className="rounded avatar-md"
                      />{" "}
                    </td>
                    <td>Current-Image.png</td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 <br /> Height 50 <br />
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <th scope="row">
                        <Link to="" style={styles.tagBtnStyle}>
                          S 1004
                        </Link>
                      </th>
                    </th>
                    <td>
                      <CardImg
                        src={defaultImg}
                        alt="veltrix"
                        className="rounded avatar-md"
                      />{" "}
                    </td>
                    <td>Current-Image.png</td>
                    <td>S1 Z101</td>
                    <td>Wall</td>
                    <td>Vacant</td>
                    <td>15</td>
                    <td>
                      Length 75 <br /> Height 50 <br />
                    </td>
                    <td>81.5%</td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <Input type="checkbox" onChange={handleClick}></Input>
                    </td>
                    <td>
                      <UiModal />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      {/* 👇️ show elements on click */}
      {isShown && (
        <Card
          style={{
            width: 250,
            backgroundColor: "lightgrey",
            padding: 20,
            marginTop: "-25%",
            marginLeft: "30%",
            position: "absolute",
          }}
        >
          <Row className="mb-3">
            <Col>
              <Label>From</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>From</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col>
              <Label className="pt-3">To</Label>
              <Input type="date" placeholder="" className="form-select">
                <option>To</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Button onClick={handleClick}>Done</Button>
          </Row>
        </Card>
      )}
    </Container>
  )
}

const styles = {
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default SlotDashboard
