import React, { useState, useEffect, useCallback } from "react"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { Col, Input, Label, Row, Spinner } from "reactstrap"
import { Card, FormGroup } from "@material-ui/core"
import ImageRenderer from "../../../src/components/Common/ImageRenderer"
import VisitedRenderer from "../../../src/components/Common/VisitedRenderer"
import placeholderImage from "../../assets/images/placeholderImg.webp"
import VisitedRenderer2 from "../../../src/components/Common/VisitedRenderer2"
import SlotMaintenanceModal from "./SlotMaintenanceModal"
import EventsSlotsEdit from "./EventsSlotsEdit-new"
import EventsSlotsDelete from "./EventSlotsDelete"
import { axiosApi } from "helpers/api_helper"
import { Accordion } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"

const gridOptions = {}

function CheckboxRenderer(props) {
  const { data, onCheckboxChange } = props

  return (
    <Input type="checkbox" onChange={event => onCheckboxChange(event, data)} />
  )
}

const AgGrid = props => {
  const [rowData, setRowData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [inputValue, setInputValue] = useState("")
  const [checkboxChecked, setCheckboxChecked] = useState("")
  const [bookingFrom, setBookingFrom] = useState("")
  const [bookingTo, setBookingTo] = useState("")
  const [undermaintance, setUndermaintance] = useState()
  const [isShow, setIsShow] = useState(false)
  const [rowIndex, setRowIndex] = useState()
  const [dataFromChild, setDataFromChild] = useState("")
  const [dataFromChild2, setDataFromChild2] = useState("")
  const [columnDefs, setColumnDefs] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [apiRes, setApiRes] = useState([])
  const [i, setI] = useState("")
  const [uniqueAgencies, setUniqueAgencies] = useState([])
  const [visitedRenderProp, setVisitedRenderProp] = useState()
  const [visitedRenderProp2, setVisitedRenderProp2] = useState()
  const [basic, setbasic] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("")
  const toggleButton = () => setIsChecked(!isChecked)
  const toggleButton2 = () => setIsChecked2(!isChecked2)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [apiRes3, setApiRes3] = useState([])
  const [apiRes1, setApiRes1] = useState()
  const [apiRes4, setApiRes4] = useState()
  const [umApiStatus, setUmApiStatus] = useState(false)
  const [data, setData] = useState({
    slotId: null,
    eventId: null,
    underMaintenance: null,
    underMaintenanceFrom: null,
    underMaintenanceTo: null,
  })
  const [toggleButtonPosition, setToggleButtonPosition] = useState(
    visitedRenderProp?.data.underMaintenance || 0
  )

  const handleDataFromChild = data => {
    setDataFromChild(data)
  }

  const handleDataFromChild2 = data => {
    setDataFromChild2(data)
    setIsShow(dataFromChild2)
  }

  useEffect(() => {
    axiosApi.get("/event-organizer/list-event-slot").then(res => {
      setApiRes1(res.data.data)
    })
  }, [apiRes3, toggleButtonPosition])

  useEffect(() => {
    const queryString = window.location.search
    setI(queryString.slice(4))
  })

  const handleMaintenanceChange2 = (rowIndex, active, dataFromChild, props) => {
    setIsChecked2(active)
    setVisitedRenderProp2(dataFromChild)
    // Perform function for underMaintenance change
    if (active) {
      setRowIndex(rowIndex)
    } else {
      setRowIndex(rowIndex)
    }
  }

  const handleMaintenanceChange = (
    rowIndex,
    underMaintenance,
    dataFromChild,
    props
  ) => {
    setIsChecked(underMaintenance)
    setVisitedRenderProp(dataFromChild)
    // Perform function for underMaintenance change
    if (underMaintenance) {
      setIsShow(false)
      setRowIndex(rowIndex)
    } else {
      setIsShow(true)
      setRowIndex(rowIndex)
    }
  }

  const getValueFromResponseMessage = message => {
    if (message.includes("marked from maintenance")) {
      return 1
    } else if (message.includes("unmarked from maintenance")) {
      return 0
    } else {
      return -1 // Return -1 or handle the unknown message case accordingly
    }
  }

  useEffect(() => {}, [getValueFromResponseMessage])

  useEffect(() => {
    if (visitedRenderProp) {
      const { slotId, eventId, underMaintenance } = visitedRenderProp.data
      setData({
        slotId,
        eventId,
        underMaintenance: !underMaintenance,
        underMaintenanceFrom: dataFromChild[0],
        underMaintenanceTo: dataFromChild[1],
      })
    }
  }, [visitedRenderProp, dataFromChild])

  // Method to handle API call
  const callApi = () => {
    axiosApi
      .post("/event-organizer/mark-e-slot-for-maintenance", data)
      .then(res => {
        setApiRes3(res)
        const message = res.data.message
        const words = message.split(" ")
        const secondWord = words[1]
        if (res.data.code == 200) {
          setUmApiStatus(true)
          if (secondWord == "marked") {
            setIsShow(false)
          } else if (secondWord == "unmarked") {
            return
          }
          setIsSuccess(true)
          toggleButton()
          const message = res.data.message || "Success"
          const toggleButtonPosition = getValueFromResponseMessage(message)
          setToggleButtonPosition(toggleButtonPosition === 1 ? 1 : 0)
          setDataFromChild({
            0: null,
            1: null,
          })
        }
      })
      .catch(error => {
        setApiRes3(error)
        setIsSuccess(false)
        toggleButton()
        setErrorMessage(error.response.data.message || "Error occurred")
        setBasic(true)
        setSweetTitle(error.response.data.message || "Error occurred")
        location.reload()
      })
  }

  useEffect(() => {
    callApi()
  }, [data])
  // active toggle button function

  useEffect(() => {
    if (
      visitedRenderProp2
        ? visitedRenderProp2
        : "" && visitedRenderProp2.data.active === 0
    ) {
      if (visitedRenderProp2.data.underMaintenance == 1) {
        setbasic(true)
        setSweetTitle("UnderMaintenance is Going")
      } else {
        const data3 = {
          slotId: visitedRenderProp2.data.slotId,
          eventId: visitedRenderProp2.data.eventId,
          active: true,
        }
        if (visitedRenderProp2.data.slotId !== 0) {
          axiosApi
            .post("/event-organizer/toggle-eslot-active", data3)
            .then(res => {
              setApiRes4(res)
              if (res.status === 200) {
                // Update the relevant state or perform any required action instead of reloading the page
              }
            })
            .catch(error => {
              setApiRes4(error)
              console.error(error)
              setbasic(true)
              setSweetTitle(error.response.data.message)
              // Update the relevant state or perform any required action instead of reloading the page
            })
        }
      }
    } else if (visitedRenderProp2 && visitedRenderProp2.data.active === 1) {
      const data4 = {
        slotId: visitedRenderProp2.data.slotId,
        eventId: visitedRenderProp2.data.eventId,
        active: false,
      }
      if (visitedRenderProp2.data.eventId !== 0) {
        axiosApi
          .post("/event-organizer/toggle-eslot-active", data4)
          .then(res => {
            setApiRes4(res)
            if (res.status === 200) {
              location.reload()
              // Update the relevant state or perform any required action instead of reloading the page
            }
          })
          .catch(error => {
            setApiRes4(error)
            setbasic(true)
            console.error(
              "error.response.data.message",
              error.response.data.message
            )
            setSweetTitle(error.response.data.message)
            console.error(error)
            location.reload()
            // Update the relevant state or perform any required action instead of reloading the page
          })
      }
    }
  }, [visitedRenderProp2])

  useEffect(() => {}, [toggleButton2, toggleButton, rowData])

  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (checkboxChecked) {
      props.onChildData(inputValue, true)
    } else {
      props.onChildData(inputValue, false)
    }
  }, [checkboxChecked, inputValue, props.onChildData])

  useEffect(() => {
    props.onChildData2(rowData)
  })

  useEffect(() => {
    props.onChildData3(bookingFrom)
  }, [bookingFrom])
  useEffect(() => {
    props.onChildData4(bookingTo)
  }, [bookingTo])
  useEffect(() => {
    props.onChildData6(undermaintance)
  }, [undermaintance])

  function handleClick(event, rowData) {
    if (event.target.checked) {
      setCheckboxChecked(true)
      setInputValue(rowData)
    } else {
      setCheckboxChecked(false)
      setInputValue(rowData.slotId)
    }
  }

  const handleBookingFromChange = e => {
    setBookingFrom(e.target.value)
  }

  const handleBookingToChange = e => {
    setBookingTo(e.target.value)
  }
  useEffect(() => {
    setColumnDefs([
      {
        headerName: "#",
        sort: "asc",
        sortable: true,
        field: "slotId",
        filter: "agSetColumnFilter",
        width: 80,
        filterParams: {
          selectAllOnMiniFilter: true,
          newRowsAction: "keep",
          clearButton: true,
        },
      },
      {
        headerName: "Default Image",
        field: "defaultImage",
        width: 150,
        // Set ImageRenderer component as cell renderer
        cellRenderer: ImageRenderer,
      },
      {
        headerName: "Current Image",
        field: "currentImage",
        width: 150,
        cellRenderer: ImageRenderer,
      },
      {
        headerName: "Slot Name",
        field: "slotName",
        sortable: true,
      },
      {
        headerName: "Collateral Type",
        field: "collateralType",
        filter: "collateralTypeFilter",
        width: 140,
      },
      {
        headerName: "Event Id",
        field: "eventId",
        filter: "EventIdFilter",
        width: 140,
      },
      {
        headerName: "Booking Status",
        field: "bookingStatus",
        sortable: true,
        width: 160,
      },
      {
        headerName: "Capacity kg",
        field: "capacity",
        sortable: true,
        width: 130,
      },
      {
        headerName: "Slot Details cm",
        field: "slotDetails",
        sortable: true,
        width: 320,
      },
      {
        headerName: "Booking (Current Month)",
        field: "booking",
        sortable: true,
        width: 190,
      },
      {
        headerName: "Under Maintenance",
        field: "blocked",
        filter: "activeFilter",
        width: 155,
        value: toggleButtonPosition,
        cellRenderer: props => (
          <VisitedRenderer
            {...props}
            umApiStatus={umApiStatus}
            onMaintenanceChange={() =>
              handleMaintenanceChange(
                props.rowIndex,
                props.data.underMaintenance,
                props
              )
            }
            dataFromChild={dataFromChild}
            field="blocked"
          />
        ),
      },
      {
        headerName: "Active",
        field: "active",
        filter: "activeFilter",
        width: 120,
        cellRenderer: props => (
          <VisitedRenderer2
            {...props}
            onMaintenanceChange2={() =>
              handleMaintenanceChange2(props.rowIndex, props.data.active, props)
            }
            active={props.data.active}
            field="active"
          />
        ),
      },
      {
        headerName: "Slot Edit",
        field: "slotEdit",
        width: 120,
        cellRenderer: props => (
          <EventsSlotsEdit
            {...props}
            apiRes={apiRes}
            zoneId={props.data.zoneId}
            id={props.data.slotId}
            eventId={props.data.eventId}
            slotName={props.data.slotName}
            type={props.data.type}
            status={props.data.bookingStatus}
            capacity={props.data.capacity}
            slotDetails={props.data.slotDetails}
            defaultImage={props.data.defaultImage}
            currentImage={props.data.currentImage}
            booking={props.data.booking}
            underMaintenance={props.data.underMaintenance}
            active={props.data.active}
            gpsCoordinate={props.data.gpsCoordinate}
            priceCategory={props.data.priceCategory}
            slotDescription={props.data.slotDescription}
            description={props.data.description}
            weight={props.data.weight}
            dimensions={props.data.dimensions}
            documents={props.data.documents}
            powerAvailability={props.data.powerAvailability}
          />
        ),
      },
      {
        headerName: "Slot Delete",
        field: "slotDelete",
        width: 120,
        cellRenderer: props => (
          <EventsSlotsDelete
            {...props}
            eventId={props.data.eventId}
            slotId={props.data.slotId}
          />
        ),
      },
      {
        headerName: "Select",
        field: "checkbox",
        pinned: "right",
        width: 80,
        cellRendererFramework: CheckboxRenderer,
        cellRendererParams: {
          onCheckboxChange: handleClick,
        },
      },
    ])
  }, [dataFromChild, umApiStatus])

  useEffect(() => {
    if (apiRes1) {
      setRowData(() => {
        return apiRes1.map(item => {
          const selectedType = apiRes.find(type => type.id === item.type)
          const collateralTypeName = selectedType ? selectedType.Name : "-"
          return {
            slotId: item.id ? item.id : "0",
            eventId: item.eventId ? item.eventId : "0",
            zone: item.zone ? item.zone : "s 1001",
            zoneId: item.zoneId ? item.zoneId : "0",
            column: item.b ? item.b : "s 1002",
            defaultImage: item.defaultImage
              ? item.defaultImage
              : placeholderImage,
            currentImage: item.currentImage
              ? item.currentImage
              : placeholderImage,
            slotName: item.slotName ? item.slotName : "-",
            collateralType: collateralTypeName,
            type: item.type,
            bookingStatus: item.status ? item.status : "-",
            capacity: item.capacity ? item.capacity : 0,
            slotDetails: item.slotDetails ? item.slotDetails : "-",
            booking: item.booking ? item.booking : "-",
            underMaintenance: item.underMaintenance
              ? item.underMaintenance
              : "",
            active: item.active ? item.active : "",
            description: item.description ? item.description : 0,
            gpsCoordinate: item.gpsCoordinate ? item.gpsCoordinate : 0,
            priceCategory: item.priceCategory ? item.priceCategory : 0,
            slotDescription: item.slotDescription ? item.slotDescription : 0,
            weight: item.weight ? item.weight : 0,
            dimensions: item.dimensions ? item.dimensions : 0,
            powerAvailability: item.powerAvailability
              ? item.powerAvailability
              : 0,
            documents: item.documents ? item.documents : 0,
            select: React.createElement(Input, {
              type: "checkbox",
              onChange: handleClick,
            }),
          }
        })
      })

      const uniqueAgencies = apiRes1
        .map(row => row.eventId)
        .filter((name, index, array) => array.indexOf(name) === index)
        .reduce((acc, name) => {
          acc.push({ value: name, label: name })
          return acc
        }, [])

      setUniqueAgencies(uniqueAgencies)
    } else {
      setUniqueAgencies([])
    }
  }, [apiRes, isLoading, apiRes3, apiRes1, apiRes4])

  useEffect(() => {
    apiRes1 ? setIsLoading(false) : setIsLoading(true)
  }, [apiRes1])

  const [zoneFilter, setzoneFilter] = useState("")
  const [collateralTypeFilter, setCollateralTypeFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [activeFilter, setActiveFilter] = useState("")
  const [priceFilter, setPriceFilter] = useState("")
  const [eventNameFilter, setEventNameFilter] = useState("")
  const [fromDateFilter, setFromDateFilter] = useState(null)
  const [toDateFilter, setToDateFilter] = useState(null)
  const [api, setApi] = useState(null)
  useEffect(() => {
    if (api) {
      api.setFilterModel({
        zone: {
          type: "equals",
          filter: zoneFilter,
        },
        collateralType: {
          type: "equals",
          filter: collateralTypeFilter,
        },
        eventId: {
          type: "equals",
          filter: i,
        },
        bookingStatus: {
          type: "equals",
          filter: statusFilter,
        },
        active: {
          type: "equals",
          filter: activeFilter,
        },
        priceCategory: {
          type: "equals",
          filter: priceFilter,
        },
        eventName: {
          type: "equals",
          filter: eventNameFilter,
        },
        fromDate: {
          type: "greaterThanOrEqual",
          filter: fromDateFilter,
        },
        toDate: {
          type: "lessThanOrEqual",
          filter: toDateFilter,
        },
      })
      api.onFilterChanged()
    }
  }, [
    zoneFilter,
    collateralTypeFilter,
    i,
    statusFilter,
    activeFilter,
    priceFilter,
    eventNameFilter,
    fromDateFilter,
    toDateFilter,
    api,
  ])
  const [selectedRows, setSelectedRows] = useState([])
  const onSelectionChanged = useCallback(() => {
    const selectedNodes = api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    setSelectedRows(selectedData)
  }, [api, setSelectedRows])
  if (isLoading) {
    return (
      <div
        className="page-content"
        style={{
          position: "fixed",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }
  return (
    <div className="page-content pt-3">
      <Card className="shadow-sm">
        <Accordion defaultActiveKey="0" className="shadow-sm">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters</Accordion.Header>
            <Accordion.Body>
              <Row xs={2} md={4} className="align-items-end">
                <Col>
                  <FormGroup className="mb-4">
                    <Label>Collateral Type</Label>
                    <select
                      className="form-select"
                      onChange={e => setCollateralTypeFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      {apiRes.map(item => (
                        <option key={item.id} value={item.Name}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col xs>
                  <FormGroup className="mb-4">
                    <Label>Booking From</Label>
                    <input
                      type="date"
                      id="bookingFrom"
                      className="form-select"
                      name="bookingFrom"
                      value={bookingFrom}
                      onChange={handleBookingFromChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs>
                  <FormGroup className="mb-4">
                    <Label>Booking To</Label>
                    <input
                      type="date"
                      id="bookingTo"
                      className="form-select"
                      name="bookingTo"
                      value={bookingTo}
                      onChange={handleBookingToChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      {isShow ? (
        <SlotMaintenanceModal
          isShow={isShow}
          rowIndex={rowIndex}
          onData={handleDataFromChild}
          onModalToggle={handleDataFromChild2}
        />
      ) : null}
      <Card className="shadow-sm mt-3 p-3">
        <AgGridReact
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          rowData={rowData ? rowData : "null"}
          pagination={true}
          paginationPageSize={10}
          onGridReady={params => setApi(params.api)}
          domLayout={"autoHeight"}
          className="ag-theme-alpine"
        />
      </Card>
    </div>
  )
}

export default AgGrid
