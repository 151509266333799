import React, { useState, useEffect } from "react"
import AddZoneImg from "../../assets/images/AddZoneImg.png"
import { axiosApi } from "helpers/api_helper"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Input,
  Label,
  FormGroup,
  CardImg,
  Form,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

const EventsSlotsEdit = props => {
  const [modal_center, setmodal_center] = useState(false)
  const [apiRes, setApiRes] = useState([])
  const [selectedType, setSelectedType] = useState("")
  const [apiRes1, setApiRes1] = useState([])
  const [apiRes4, setApiRes4] = useState([])
  const [basic, setbasic] = useState(false)
  const [dimension, setDimension] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [sweetTitle, setSweetTitle] = useState("Have a Error")
  const [formData, setFormData] = useState({
    eventId: "",
    zoneId: "",
    slotId: "",
    slotName: "",
    type: "",
    bookingStatus: "",
    capacity: "",
    slotDetails: "",
    defaultImage: [],
    currentImage: [],
    gpsCoordinate: [gpsLat, gpsLon],
    gpsLat: "",
    gpsLon: "",
    priceCategory: "",
    slotDescription: "",
    weight: "",
    dimensions: "",
    documents: "",
    powerAvailability: "",
    a: "",
    b: "",
    c: "",
    d: "",
    description: "",
  })

  const {
    eventId,
    zoneId,
    slotId,
    slotName,
    type,
    bookingStatus,
    capacity,
    slotDetails,
    defaultImage,
    currentImage,
    gpsLat,
    gpsLon,
    priceCategory,
    slotDescription,
    weight,
    dimensions,
    documents,
    description,
    powerAvailability,
  } = formData

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleDefaultImageChange = e => {
    setFormData({ ...formData, defaultImage: e.target.files[0] })
  }

  const handleCurrentImageChange = e => {
    setFormData({ ...formData, currentImage: e.target.files[0] })
  }

  const handleInputChange = (e, key, index) => {
    const updatedApiRes4 = { ...apiRes4 }
    if (!Array.isArray(updatedApiRes4.dimensions[key])) {
      updatedApiRes4.dimensions[key] = []
    }
    updatedApiRes4.dimensions[key][0] = e.target.value
    setDimension(updatedApiRes4)
  }

  const onEditClick = data => {
    setmodal_center(true)
    const gpsCoordinates = data.gpsCoordinate.split(" ")
    let latitude = gpsCoordinates[1]
    let longitude = gpsCoordinates[3]

    setFormData({
      eventId: data.eventId,
      zoneId: data.zoneId,
      slotId: data.slotId,
      slotName: data.slotName,
      type: data.type,
      bookingStatus: data.bookingStatus,
      capacity: data.capacity,
      slotDetails: data.slotDetails,
      defaultImage: [],
      currentImage: [],
      gpsCoordinate: data.gpsCoordinate,
      gpsLat: latitude,
      gpsLon: longitude,
      priceCategory: data.priceCategory,
      slotDescription: data.slotDescription,
      description: data.description,
      weight: data.weight,
      dimensions: data.dimensions,
      a: data.dimensions.a,
      b: data.dimensions.b,
      c: data.dimensions.c,
      d: data.dimensions.d,
      documents: data.documents,
      powerAvailability: data.powerAvailability,
    })
  }

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      slotId: props.data.slotId,
      zoneId: props.data.zoneId,
      eventId: props.data.eventId,
      slotName: props.data.slotName,
      type: props.data.type,
      bookingStatus: props.data.bookingStatus,
      capacity: props.data.capacity,
      slotDetails: props.data.slotDetails,
      defaultImage: [],
      currentImage: [],
      gpsCoordinate: props.data.gpsCoordinate,
      gpsLat: props.data.gpsCoordinate,
      gpsLon: props.data.gpsCoordinate,
      priceCategory: props.data.priceCategory,
      slotDescription: props.data.slotDescription,
      description: props.data.description,
      weight: props.data.weight,
      dimensions: props.data.dimensions,
      a: props.data.dimensions.a,
      b: props.data.dimensions.b,
      c: props.data.dimensions.c,
      d: props.data.dimensions.d,
      documents: props.data.documents,
      powerAvailability: props.data.powerAvailability,
    }))
  }, [props.data])

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("id", slotId)
    formData.append("zoneId", zoneId)
    formData.append("eventId", eventId)
    formData.append("slotName", slotName)
    formData.append("type", type)
    formData.append("status", bookingStatus)
    formData.append("capacity", capacity)
    formData.append("slotDetails", slotDetails)
    formData.append("description", description)
    if (defaultImage) {
      formData.append("defaultImage", defaultImage)
    }
    if (currentImage) {
      formData.append("currentImage", currentImage)
    }
    formData.append("gpsCoordinate", [gpsLat, gpsLon])
    formData.append("priceCategory", priceCategory)
    formData.append("slotDescription", slotDescription)
    formData.append("weight", weight)
    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.a &&
      dimension.dimensions.a.length > 0
    ) {
      formData.append("a", dimension.dimensions.a[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("a", dimensions.a)
    }

    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.b &&
      dimension.dimensions.b.length > 0
    ) {
      formData.append("b", dimension.dimensions.b[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("b", dimensions.b)
    }

    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.c &&
      dimension.dimensions.c.length > 0
    ) {
      formData.append("c", dimension.dimensions.c[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("c", dimensions.c)
    }
    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.d &&
      dimension.dimensions.d.length > 0
    ) {
      formData.append("d", dimension.dimensions.d[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("d", dimensions.d)
    }
    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.e &&
      dimension.dimensions.e.length > 0
    ) {
      formData.append("e", dimension.dimensions.e[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("e", dimensions.e)
    }
    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.f &&
      dimension.dimensions.f.length > 0
    ) {
      formData.append("f", dimension.dimensions.f[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("f", dimensions.f)
    }
    if (
      dimension &&
      dimension.dimensions &&
      dimension.dimensions.g &&
      dimension.dimensions.g.length > 0
    ) {
      formData.append("g", dimension.dimensions.g[0])
    } else {
      // Set a default value for formData when dimensions.g is null or empty
      formData.append("g", dimensions.g)
    }

    formData.append("documents", documents)
    formData.append("powerAvailability", powerAvailability)
    axiosApi
      .post("/event-organizer/edit-event-slot", formData)
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        setbasic(true)
        setSweetTitle("Plaese Try Again and try to fill All")
        console.error(error)
      })
  }

  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [type])

  useEffect(() => {
    axiosApi.get("/common/pricing?show=dropdown").then(response => {
      setApiRes1(response.data.data)
    })
  }, [])

  useEffect(() => {
    if (type) {
      axiosApi
        .get(`/common/collateralTypes?id=${type}`)
        .then(response => {
          setApiRes4(response.data.data)
          setShowForm(true)
        })
        .catch(error => {
          console.error(error)
          setShowForm(false)
        })
    }
  }, [type, selectedType])

  useEffect(() => {}, [])

  return (
    <Row>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      <span
        className="fa fa-edit"
        onClick={() => {
          tog_center()
          onEditClick({
            eventId: props.data.eventId,
            zoneId: props.data.zoneId,
            slotId: props.data.slotId,
            slotName: props.data.slotName,
            type: props.data.type,
            bookingStatus: props.data.bookingStatus,
            capacity: props.data.capacity,
            slotDetails: props.data.slotDetails,
            defaultImage: props.data.defaultImage,
            currentImage: props.data.currentImage,
            booking: props.data.booking,
            gpsCoordinate: props.data.gpsCoordinate,
            priceCategory: props.data.priceCategory,
            slotDescription: props.data.slotDescription,
            description: props.data.description,
            weight: props.data.weight,
            dimensions: props.data.dimensions,
            documents: props.data.documents,
            powerAvailability: props.data.powerAvailability,
          })
        }}
      ></span>
      <Card>
        <CardBody style={{ display: "none" }}>
          <Row>
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center()
              }}
              centered={true}
              style={{ marginTop: 80, minWidth: 'auto' }}
            >
              <Form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Edit Slots</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setmodal_center(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row sm={1} md={2}>
                    <Col>
                      <FormGroup>
                        <Label>Slot Id</Label>
                        <Input
                          type="text"
                          placeholder="Slot 01"
                          value={slotId}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              slotId: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Event Id</Label>
                        <Input
                          type="text"
                          placeholder="Slot 01"
                          value={eventId}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              eventId: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Zone Id</Label>
                        <Input
                          type="text"
                          placeholder="01"
                          value={zoneId}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              zoneId: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Slot Name</Label>
                        <Input
                          type="text"
                          placeholder="Slot 01"
                          value={slotName}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              slotName: e.target.value,
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Booking Status</Label>
                        <Input
                          type="text"
                          value={bookingStatus}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              bookingStatus: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label sm={3}>Weight</Label>
                        <Input
                          type="text"
                          placeholder="kg"
                          value={weight}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              weight: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>slot Details</Label>
                        <Input
                          type="text"
                          value={slotDetails}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              slotDetails: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>description</Label>
                        <Input
                          type="text"
                          value={description}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label sm={3}>Gps Coordinate</Label>
                        <div className="d-flex">
                          <Input
                            value={gpsLat}
                            className="mr-3"
                            placeholder="Latitude"
                            onChange={e =>
                              setFormData({
                                ...formData,
                                gpsLat: e.target.value,
                              })
                            }
                            style={{ width: "45%" }}
                          />
                          <Input
                            value={gpsLon}
                            className="ml-3"
                            placeholder="Longitude"
                            onChange={e =>
                              setFormData({
                                ...formData,
                                gpsLon: e.target.value,
                              })
                            }
                            style={{ width: "45%" }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>Default Image</Label>
                        <Input
                          id="defaultImageInput"
                          type="file"
                          onChange={handleDefaultImageChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Current Image</Label>
                        <Input
                          type="file"
                          id="currentImageInput"
                          onChange={handleCurrentImageChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Col sm={3}>
                            <FormGroup style={{ width: "235px" }}>
                              <Label sm={6}>Slot Limits</Label>
                              <br />
                              <CardImg
                                src={AddZoneImg}
                                className="img-fluid"
                                style={{ width: "235px" }}
                              />
                            </FormGroup>
                            <Row>
                              <Col sm={3}>
                                <FormGroup>
                                  <Label>Collateral Type</Label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    style={{ width: "235px" }}
                                    value={type}
                                    onChange={e => {
                                      const selectedName = e.target.value
                                      const selectedTypeId = apiRes.find(
                                        item => item.id === selectedName
                                      )?.id
                                      setFormData(prevFormData => ({
                                        ...prevFormData,
                                        type: selectedTypeId,
                                      }))
                                      setSelectedType(selectedTypeId)
                                    }}
                                  >
                                    {apiRes.map(item => (
                                      <option key={item.id} value={item.id}>
                                        {item.Name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                                {showForm && (
                                  <Col sm={3}>
                                    {Object.entries(apiRes4.dimensions).map(
                                      ([key, value], index) => (
                                        <FormGroup
                                          style={{ width: "235px" }}
                                          key={key}
                                        >
                                          <Label sm={3}>{key}</Label>
                                          <Input
                                            type="text"
                                            value={
                                              isEditing
                                                ? value[0]
                                                : dimensions[key] || ""
                                            }
                                            onChange={e =>
                                              handleInputChange(e, key, index)
                                            }
                                            onFocus={() => setIsEditing(true)}
                                            onBlur={() => setIsEditing(false)}
                                          />
                                        </FormGroup>
                                      )
                                    )}
                                  </Col>
                                )}
                              </Col>
                              <Col>
                                <FormGroup style={{ width: "235px" }}>
                                  <Label>Capacity</Label>
                                  <Input
                                    type="text"
                                    value={capacity}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        capacity: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col></Col>
                              <FormGroup>
                                <Label sm={3}>Power</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  style={{ width: "235px" }}
                                  value={powerAvailability}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      powerAvailability: e.target.value,
                                    })
                                  }
                                >
                                  <option>Power Available</option>
                                  <option>Power Unavailable</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label sm={3}>Price Category</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  style={{ width: "235px" }}
                                  value={priceCategory}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      priceCategory: e.target.value,
                                    })
                                  }
                                >
                                  {apiRes1
                                    ? apiRes1.map(item => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))
                                    : null}
                                </Input>
                              </FormGroup>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row className="text-end">
                  <Col style={styles.modalBtnStyle} className="mb-3">
                    <Button
                      className="btn btn-primary waves-effect waves-light me-2"
                      onClick={() => {
                        tog_center()
                      }}
                      data-toggle="modal"
                      data-target=".bs-example-modal-center"
                      color="danger"
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn btn-primary waves-effect waves-light me-3"
                      onClick={() => {
                        tog_center()
                      }}
                      data-toggle="modal"
                      data-target=".bs-example-modal-center"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Row>
        </CardBody>
      </Card>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default EventsSlotsEdit
