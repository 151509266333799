import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class Scatter extends Component {
    getOption = () => {
        return {
            tooltip: {
                trigger: 'axis',
                show: false
            },
            toolbox: {
                show: false,
            },
            grid: {
                zlevel: 0,
                x: 50,
                x2: 50,
                y: 30,
                y2: 30,
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#74788d"
                    }
                }
            },
            yAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#74788d"
                    }
                }
            },
            series: [{
                symbolSize: 30,
                data: [
                    { value: [4.0, 3.04], itemStyle: { color: '#3c4ccf' } },
                    { value: [6.0, 6.95], itemStyle: { color: '#ff6384' } },
                    { value: [13.0, 10.58], itemStyle: { color: '#32a852' } },
                    { value: [9.0, 8.81], itemStyle: { color: '#ff9f40' } },
                ],
                type: 'scatter'
            }],
         
        };
    };
    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "350px" }}
                    option={this.getOption()}
                />
            </React.Fragment>
        );
    }
}
export default Scatter;

