import React, { useState } from "react"
//components
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

import { Navbar, Nav } from "react-bootstrap"
import logo from "../../assets/images/Statements/ListingRecived/image/favicon.png"
import avatar from "../../assets/images/bookingStatement/HIOM-Statment-Avatar.png"
import qrcodeImg from "../../assets/images/bookingStatement/HIOM-Statment-QRCode.png"
import placeholderImage from "../../assets/images/placeholderImg.webp"
import { axiosApi } from "helpers/api_helper"
import { useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"

const steps = [
  "Booking Requested",
  "Request Accepted",
  "Payment Honoured",
  "Document Submitted",
  "Installation",
  "Payment Tranferred",
]

export const EventsBookingDetailsProposalSent = () => {
  const queryString = window.location.search
  const [navbarVisible, setNavbarVisible] = useState(true)
  const [tokenId, setTokenId] = useState(queryString.slice(4))
  const [apiRes, setApiRes] = useState(null)
  const [apiRes1, setApiRes1] = useState()
  const [sweetTitle, setSweetTitle] = useState("No Data On ID")
  const [basic, setbasic] = useState(false)


  useEffect(() => {
    axiosApi
      .get("/common/collateralTypes?show=dropdown")
      .then(response => {
        setApiRes1(response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const handleSave = () => {
    const currentUrl = window.location.href
    const link = document.createElement("a")
    link.href = currentUrl
    link.download = "my_page.html"
    link.click()
  }

  const handlePrint = () => {
    window.print()
    // Handle print functionality
  }

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this page!",
          url: window.location.href,
        })
        .then(() => {
          console.log("Shared successfully.")
        })
        .catch(error => {
          console.error("Error sharing:", error)
        })
    } else {
      console.log("Web Share API not supported.")
    }
  }

  useEffect(() => {
    if (apiRes === null) {
      axiosApi
        .get(`/event-organizer/listing-to-statement?id=${tokenId}`)
        .then(res => {
          setApiRes(res.data.data)
        })
        .catch(err => {
          setbasic(true)
          setSweetTitle("No Data")
          console.error(err)
        })
    }
  }, [apiRes])

  useEffect(() => {}, [apiRes])

  let tableData = null

  if (apiRes !== null) {
    tableData = apiRes.map(item => {
      const selectedType = apiRes1.find(type => type.id === item.type)
      const collateralTypeName = selectedType ? selectedType.Name : "-"

      return {
        Zone: item.zoneId,
        SlotNo: item.slotName,
        SlotType: collateralTypeName,
        LocalRef: item.slotDetails,
        RateDay: item.amount,
        TotalAmount: item.totalAmount,
      }
    })
  }

  let SampleData = null

  if (apiRes !== null) {
    SampleData = apiRes.map((row, index) => {
      const selectedType = apiRes1.find(type => type.id === row.type)
      const collateralTypeName = selectedType ? selectedType.Name : "-"

      return {
        SlNo: index + 1,
        Particulars: collateralTypeName,
        Remarks: row.description,
        Images: (
          <img
            src={row.defaultImage || placeholderImage}
            className="w-50 h-50"
            alt="Sample Image"
          />
        ),
      }
    })
  }
  let Document = null

  if (apiRes !== null) {
    Document = apiRes.map((row, index) => {
      return {
        SlNo: index + 1,
        Particulars: row.documents || "null",
        Image: "Submitted",
      }
    })
  }
  return (
    <Container className="page-content" fluid>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      {navbarVisible && (
        <Navbar
          style={{
            position: "fixed",
            width: "-webkit-fill-available",
            marginLeft: "-12px",
            flexFlow: "row-reverse",
            backgroundColor: "#5c656f",
            height: "30px",
            zIndex: "9",
          }}
        >
          <Nav>
            <Nav.Link
              style={{ color: "white", fontSize: "xx-small" }}
              className="bg-primary m-1"
              onClick={handleSave}
            >
              <i className="fa fa-save"></i> Save
            </Nav.Link>
            <Nav.Link
              style={{ color: "white", fontSize: "xx-small" }}
              className="bg-primary m-1"
              onClick={handlePrint}
            >
              <i className="fa fa-print"></i> Print
            </Nav.Link>
            <Nav.Link
              style={{ color: "white", fontSize: "xx-small" }}
              className="bg-primary m-1"
              onClick={handleShare}
            >
              <i className="fa fa-share-alt"></i> Share
            </Nav.Link>
          </Nav>
        </Navbar>
      )}
      <Row
        id="header"
        style={{
          backgroundColor: "#045B9E",
          color: "white",
          height: "600px",
          margin: 1,
        }}
      >
        <Row id="level-1" className="align-items-center">
          <Col xs="auto">
            <CardImg src={logo} style={{ width: 40, height: 40 }} />
          </Col>
          <Col>
            <Row
              style={{ float: "right", width: "max-content" }}
              className="text-white "
            >
              <Col xs="auto">
                David
                <br />
                Mall Manager
              </Col>
              <Col xs="auto">
                <Row>
                  <Col xs="auto">
                    <CardImg src={avatar} style={{ width: 65, height: 55 }} />
                  </Col>
                  <Col xs="auto">
                    <CardImg
                      src={qrcodeImg}
                      style={{ width: 65, height: 55 }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <CardTitle className="text-white">SLOT BOOKING STATEMENT</CardTitle>
          <Table
            id="table1"
            className=" text-center border"
            style={{ color: "white", width: "100%", textAlignLast: "start" }}
          >
            <tr>
              <td>
                <div>Account Name</div>
                <div>Account No</div>
                <div>Request Raised By</div>
              </td>
              <td style={{ borderRight: "1px solid white" }}>
                <div> null</div>
                <div>null</div>
                <div>null</div>
              </td>
              <td>
                <div>Print Date</div>
                <div>
                  Bookings <br /> Requested Date
                </div>
                <div>Payment Term</div>
              </td>
              <td>
                <div> null</div>
                <div> null</div>
                <div>Offline</div>
              </td>
            </tr>
          </Table>
        </Row>
        <CardTitle className="text-white" style={{ overflow: "auto" }}>
          PROGRESS
        </CardTitle>
        <MultiLevelProgressBar />
      </Row>
      <br />
      <Row id="table2">
        <h5>BOOKING SUMMARY</h5>
        <tr>
          <td className="m-4 p-4">
            <tr>Booking Ref</tr>
            <tr>Duration</tr>
            <tr>Booking Period</tr>
            <tr>Slots (Nos)</tr>
            <tr>Collateral Type</tr>
            <tr>Amount (AED)</tr>
            {/* <tr>Discount Appiled</tr>
          <tr>Total Amount</tr> */}
          </td>
          <td className="m-4">
            <tr>null</tr>
            <tr>null</tr>
            <tr>null</tr>
            <tr>null</tr>
            <tr>null</tr>
            <tr>null</tr>
            {/* <tr> 25 %</tr>
          <tr>
            <b>42,5150.00</b>
          </tr> */}
          </td>
        </tr>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              BOOKING DETAILS PROPERTY 1 BKD123 BBC32
            </CardTitle>
            <div className="table-responsive">
              <Table id="table3" className="table table-borderless mb-0 ">
                <thead style={{ color: "white", backgroundColor: "#045B9E" }}>
                  <tr>
                    {tableData &&
                      Object.keys(tableData[0]).map(key => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {apiRes !== null &&
                    tableData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cellValue, cellIndex) => (
                          <td key={cellIndex}>{cellValue}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>

                <td>
                  <b>Total</b>{" "}
                </td>
                <td> {apiRes !== null && apiRes.length}(Nos)</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{/* <b>{apiRes.map(item=>{item.amount})}</b>AED */}</td>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              BOOKING DETAILS PROPERTY 2 BKD123 BBC43
            </CardTitle>
            <div className="table-responsive">
              <Table id="table3" className="table table-borderless mb-0 ">
                <thead style={{ color: "white", backgroundColor: "#045B9E" }}>
                  <tr>
                    {tableData &&
                      Object.keys(tableData[0]).map(key => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {apiRes !== null &&
                    tableData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cellValue, cellIndex) => (
                          <td key={cellIndex}>{cellValue}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
                <td>
                  <b>Total</b>{" "}
                </td>
                <td> {apiRes !== null && apiRes.length}(Nos)</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {/* <b>{apiRes !== null && apiRes[0].map(item=>{item.amount})}</b>AED */}
                </td>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h4">SAMPLE IMAGE SUBMITTED</CardTitle>
            <div className="table-responsive">
              <Table id="table3" className="table table-borderless mb-0 ">
                <thead style={{ color: "white", backgroundColor: "#045B9E" }}>
                  <tr>
                    {SampleData &&
                      Object.keys(SampleData[0]).map(key => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {apiRes !== null &&
                    SampleData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cellValue, cellIndex) => (
                          <td key={cellIndex}>{cellValue}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
                <u></u>
                <td></td>
                <td> </td>
                <td>Sumitted {apiRes !== null && apiRes.length} (Nos)</td>
                <td></td>
                <td></td>
                <td></td>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h4">DOCUMENTS REQUIRED</CardTitle>
            <div className="table-responsive">
              <Table id="table6" className="table table-borderless mb-0">
                <thead style={{ color: "white", backgroundColor: "#045B9E" }}>
                  <tr>
                    {Document &&
                      Object.keys(Document[0]).map(key => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {apiRes !== null &&
                    Document.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cellValue, cellIndex) => (
                          <td key={cellIndex}>{cellValue}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <h4>Important information</h4>
        <Col>
          1. Please remember to examine this statement and inform the related
          party of any discrepancy within 3 days from the statement generation
          date.
          <br />
          2. The booking confirmation will be made under the complete discretion
          of the property owner. The Property owner will have the right to not
          accept the booking request in full or partial as he/she wishes.
          <br />
          3. Once the booking request is accepted by the Property owner, the
          payment has to be made within 3 working days after which the booking
          will be confirmed for the concerned party. The payment has to be
          transferred to Hangitonme account via bank cheque, or online payment
          portal as deemed suitable. The amount will be reserved by Hangitonme
          till the booking start date.
          <br />
          4. In the event of non submission of 'the required documents', before
          7 days from the start of the booking period, the Property owner has
          the discretion to terminate the booking and the refund of the amount
          paid will not be refunded.
          <br />
          5. In the event of cancellation of the booking 20 days before the
          start date of the booking period, the 50% of the booking amount will
          be reserved as a penalty
        </Col>
        <Col>
          6. Foreign Transaction Charge - This tee (Up to 2.99% ot the
          transaction amount) is charged in addition to the standard processing
          fee and is applicable to transactions performed acquired
          internationally or in any currency other than the UAE Dirham. The
          exchange rate is determined byVisa from withina range of wholesale
          market rates in e ect on the date of conversion.
          <br />
          7. The installation of the collaterals will be the responsibility of
          the booking agency and the property owner will not be responsible for
          providing the
          <br /> <br />
          المعلومات مهمة
          <br /> <br />
          .1 يرجى تذكر فحص هذا البيان وإبلاغ الطرف ذي الصلة بأي تناقض فيغضون 3
          أيام من تاريخ إصدار البيان
          <br /> <br />
          .2 سيتم إجراء تأكيد الحجز وفقا لتقدير مالك العقار بالكامل. يحق لمالك
          العقار عدم قبول طلب الحجز كليا أو جزئيا كما يشاء.
          <br /> <br />
          .3 بمجرد قبول طلب الحجز من قبل مالك العقار . يجب أن يتم السداد فيغضون
          3 أيام عمل وبعد ذلك سيتم تأكيد الحجز للطرف المعني. يجب تحويل الدفعة
          إلى
        </Col>
        <br />
        <br />
      </Row>
      <Row>
        <br />

        <p className="text-center">
          Hangitonme-PO Box 25604, Dubai, UAE.| Tel: 04-267 5244 |
          hangitonme.com| contactus@hangitonme.com
        </p>
      </Row>
    </Container>
  )
}
