import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

const AdminViewNotification = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card className="p-3">
          <Form>
            <CardTitle>Notifications</CardTitle>
            <Row className="col-8">
              <Label>Notification Name</Label>
              <Input type="text" name="notificationName" placeholder="Management Notification" required disabled/>
              <Label>Notification Text</Label>
              <Input type="textarea" placeholder="There will be a maintenance from 10 June 2022 to 15 June 2022" disabled/>
              <Label>Mall Name</Label>
              <Input type="select" className="form-select" disabled>
                <option>Dalma Mall</option>
                <option>Lulu Mall</option>
                <option>Dubai Mall</option>
              </Input>
              <Row>
                <Col>
                  <Label>From</Label>
                  <Input type="select" className="form-select" disabled>
                  <option>10-06-21</option>
                    <option>2</option>
                    <option>3</option>
                  </Input>
                </Col>
                <Col>
                  <Label>To</Label>
                  <Input type="select" className="form-select" disabled>
                    <option>15-06-21</option>
                    <option>2</option>
                    <option>3</option>
                  </Input>
                </Col>
              </Row>
              <Label>Priority</Label>
              <Input type="select" className="form-select" disabled>
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
                <option>Urgent</option>
              </Input>
            </Row>
            <Row>
              <Col className="col-10"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
                <Link to="./">
                  <Button color="primary">Add</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {}
export default AdminViewNotification
