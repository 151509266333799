import React, { useEffect } from "react"
import { Row } from "reactstrap"
import MultiLevelProgressBar from "propertyOwner/Common/Components/MultiLevelProgressBar"
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
//component
import LeafletEditor from "./LeafletEditor"

const buttonData = [
  {
    levelName: "01",
    floorPlanImg: "",
  },
]

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

class FloorPlanEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="page-content">
        <Row className="pt-3 pb-5">
          <MultiLevelProgressBar /*levels={['Step 1', 'Step 2', 'Step 3']}*/ />
        </Row>
        <LeafletEditor />
      </div>
    )
  }
}

const styles = {
  buttonStyle: {
    color: "white",
    backgroundColor: "#859bad",
    dark: {
      backgroundColor: "#464647",
      color: "white",
    },
  },
  roundBtn: {
    // width: "10%",
    // marginTop: '5%'
  },
  btnSpan: {
    marginTop: "50px",
  },
  containerStyle: {
    marginLeft: "-10%",
    zIndex: -1,
    maxWidth: "100%",
  },
  colStyle: {
    display: "flex",
  },
  mapIconBtn: {
    float: "right",
    marginTop: "-70px",
    position: "relative",
    marginRight: "50px",
  },
  addZoneBtn: {
    position: "relative",
    float: "right",
    marginTop: "-35%",
  },
}
export default FloorPlanEditor
