import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Modal,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap"

import { axiosApi } from "helpers/api_helper"
//components
import ProspectCreateModal from "./ProspectCreateModal"
import SweetAlert from "react-bootstrap-sweetalert"

const UiModal2 = props => {
  const [dataFromChild, setDataFromChild] = useState([])

  useEffect(() => {
    setDataFromChild(prevData => {
      // Add the new data to the previous data
      const newData = [...prevData, props.dataFromChild]
      newData.map(function (element, index_id) {
        if (Number.isInteger(element)) {
         
          newData.splice(index_id, 1)
          
        }
      })
      return newData
    })
  }, [props.dataFromChild])

  const [modal_center, setmodal_center] = useState(false)
  const [contactName, setContactName] = useState()
  const [mobile, setMobile] = useState()
  const [email, setEmail] = useState()
  const [bodyText, setBodyText] = useState()
  const [sharePrice, setSharePrice] = useState(true)
  const [basic, setbasic] = useState(false)
  const [formData, setFormData] = useState()
  const [sweetTitle, setSweetTitle] = useState("Please select DateRange & slot")



  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    if (
      formData.bookingFrom &&
      formData.bookingTo &&
      dataFromChild.length > 2
    ) {
      setmodal_center(!modal_center)
      removeBodyCss()
    } else {
      setbasic(true)
      if (!bookingFrom || !bookingTo) {
        setSweetTitle("Please select date range")
      } else if (dataFromChild.length <= 2) {
        setSweetTitle("Please select a slot")
      } else {
        setSweetTitle("Please select date range after select slot")
      }
    }
  }



  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [prospects, setProspects] = useState([])
  const [matchingProspectB, setMatchingProspectB] = useState()
  const [dataFromChild2, setDataFromChild2] = useState()
  const [bookingFrom, setBookingFrom] = useState()
  const [bookingTo, setBookingTo] = useState()

  useEffect(() => {
    setBookingFrom(props.bookingFrom)
  }, [props.bookingFrom])

  useEffect(() => {
    setBookingTo(props.bookingTo)
  }, [props.bookingTo])

  useEffect(() => {
    setDataFromChild2(props.dataFromChild2)
  }, [props.dataFromChild2])

  // handle onChange for selecting company name
  function handleCompanyNameChange(event) {
    setSelectedCompanyName(event.target.value)
    const matchingProspect = prospects.find(
      prospect => prospect.contactName === event.target.value
    )
    setMatchingProspectB(matchingProspect)
    // populate other input fields with matching prospect data
    if (matchingProspect) {
      document.querySelector("#companyName").value =
        matchingProspect.companyName
      document.querySelector("#mobileNo").value = matchingProspect.mobileNo
      document.querySelector("#email").value = matchingProspect.email
    }
  }

  // fetch data from API and set prospects state
  useEffect(() => {
    axiosApi.get("/event-organizer/list-prospects").then(response => {
      setProspects(response.data.data)
    })
  }, [])

  const handleDropdownClick = () => {
    axiosApi.get("/event-organizer/list-prospects").then(response => {
      setProspects(response.data.data)
    })
  }

  useEffect(() => {}, [formData])

  useEffect(() => {
    setFormData({
      prospectId: matchingProspectB ? matchingProspectB.email : "null",
      prospect: {
        email: matchingProspectB ? matchingProspectB.email : "null",
        contactName: matchingProspectB ? matchingProspectB.contactName : "null",
        companyName: matchingProspectB ? matchingProspectB.companyName : "null",
        mobileNo: matchingProspectB ? matchingProspectB.mobileNo : "null",
      },
      slots:
        // {
        //     "slotId": "16",
        //     "eventId": "21"
        // },
        // {
        //     "slotId": "17",
        //     "eventId": "21"
        // },
        // {
        //     "slotId": "18",
        //     "eventId": "21"
        // }
        dataFromChild
          ? dataFromChild.slice(2).map(item => ({
              slotId: item != null ? item.slotId : null,
              eventId: item != null ? item.eventId : null,
            }))
          : "",
      bookingFrom: bookingFrom,
      bookingTo: bookingTo,
      messageBody: bodyText || "Hi please find details below",
      sharePricingInfo: sharePrice,
    })
  }, [
    matchingProspectB,
    bodyText,
    sharePrice,
    dataFromChild,
    bookingFrom,
    bookingTo,
  ])

  const handleSubmit = () => {
    axiosApi
      .post("/event-organizer/share-event-slots", formData)
      .then(res => {
        localStorage.setItem("shareEventRes", JSON.stringify(res))
        window.location.href = "/events/slots/listingExportStatement"
      })
      .catch(error => {
        // handle error
      })
  }
  return (
    <Row>
      <Col xl="3" lg="4" sm="6" className="mb-2">
        {basic ? (
          <SweetAlert
            title={sweetTitle}
            onConfirm={() => {
              setbasic(false)
            }}
          />
        ) : null}
      </Col>
      <Row className="d flex-row-reverse mt-4">
        <Button
          style={{ width: "150px" }}
          color="primary"
          onClick={() => {
            tog_center()
          }}
        >
          Export
        </Button>
        {dataFromChild.length - 2} Slot(s)selected &nbsp;
      </Row>
      <CardBody style={{ display: "none" }}>
        <Row sm={1} md={2} lg={2}>
          <Col>
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center()
              }}
              centered={true}
              style={{ minWidth: "auto", marginTop: "80px" }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Portfolio Export</h5>
                <div className="m-2 p-2">
                  Selected:{dataFromChild.length - 2} /{" "}
                  {dataFromChild2 ? dataFromChild2.length : 0}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ProspectCreateModal />
              <div sm={1} md={2} lg={2}>
                <label htmlFor="bookingFrom">
                  {" "}
                  &nbsp; Booking From: &nbsp;
                </label>
                <input
                  disabled
                  type="date"
                  id="bookingFrom"
                  name="bookingFrom"
                  value={bookingFrom}
                />
                <br />
                <label htmlFor="bookingTo">
                  &nbsp; Booking To:&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <input
                  disabled
                  type="date"
                  id="bookingTo"
                  name="bookingTo"
                  value={bookingTo}
                />
                {/* your form goes here */}
              </div>

              <div className="modal-body">
                <Row>
                  <Form action="#">
                    <FormGroup>
                      <Label>Contact Name</Label>
                      <Input
                        type="select"
                        className="form-select"
                        onChange={handleCompanyNameChange}
                        onClick={handleDropdownClick}
                      >
                        <option selected disabled></option>
                        {prospects.map(prospect => (
                          <option key={prospect.id}>
                            {prospect.contactName}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>Company Name</Label>
                      <Input
                        type="text"
                        id="companyName"
                        value={contactName}
                        style={{ width: "100%" }}
                        onChange={e => setContactName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Mobile Number</Label>
                      <Input
                        type="text"
                        id="mobileNo"
                        value={mobile}
                        style={{ width: "100%" }}
                        onChange={e => setMobile(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Email ID</Label>
                      <Input
                        type="text"
                        id="email"
                        value={email}
                        style={{ width: "100%" }}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Body Text</Label>
                      <Input
                        type="textarea"
                        id="body"
                        value={bodyText}
                        style={{ width: "100%" }}
                        onChange={e => setBodyText(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Share Pricing Info
                        <Input
                          type="checkbox"
                          id="sharePrice"
                          checked={sharePrice}
                          onChange={() => setSharePrice(!sharePrice)}
                        />
                      </Label>
                    </FormGroup>
                  </Form>
                </Row>
              </div>
              <Row className="text-end">
                <Col style={styles.modalBtnStyle} className="mb-3">
                  <Button
                    className="btn btn-primary waves-effect waves-light me-2"
                    onClick={() => {
                      tog_center()
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
                    color="danger"
                  >
                    Back
                  </Button>

                  <Button
                    color="primary"
                    type="submit"
                    className="btn btn-primary waves-effect waves-light me-3"
                    onClick={() => {
                      handleSubmit()
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
                  >
                    Proceed
                  </Button>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </CardBody>
    </Row>
  )
}
const styles = {
  modalBtnStyle: {
    textAlign: "right",
  },
}
export default UiModal2
