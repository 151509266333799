import React from "react"
import { Link } from "react-router-dom"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Container,
  CardText,
  Table,
  Button,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"

import MultiLevelProgressBar from "../Common/Components/MultiLevelProgressBar"

const FloorPlans = props => {
  return (
    <React.Fragment>
      <Container className="page-content">
      <Row className="pt-5">
          <MultiLevelProgressBar />
        </Row>
        <div style={{ marginLeft: "15%", marginRight: "15%", marginTop: "5%" }}>
          <Row>
            <Col
              className="col-5"
              style={{
                backgroundColor: "#5b78ad",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            >
              <CardBody style={{ color: "white", marginTop: "100%" }}>
                Note:
                <br />
                1. Image should not be greater than 5Mb
                <br />
                2. Resolution 1500 x 800px
                <br />
                3. Level name can be combination of Alphabets & Nummericals
                <br />
                4. Special characters not allowed
              </CardBody>
            </Col>
            <Col
              className="col-7"
              style={{
                backgroundColor: "#a6b5d1",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Col>
                <Row style={{ backgroundColor: "#f1f1f1", padding: 7 }}>
                  <CardTitle>Dubai Mall</CardTitle>
                </Row>
                <Row style={{ margin: "15%" }}>
                  <Card body inverse style={{ backgroundColor: "#fff" }}>
                    <Link to="" className="d-flex justify-content-center">
                      <CardText
                        className="fa fa-upload"
                        style={{
                          color: "#333",
                          textAlign: "center",
                          padding: 40,
                        }}
                      >
                        <CardImg></CardImg>
                        UPLOAD THE LEVEL IMAGE
                      </CardText>
                    </Link>
                  </Card>

                  <FormGroup style={{ marginBottom: "15%" }}>
                    <Label for="exampleEmail">ENTER THE LEVEL NAME</Label>
                    <Input style={{ borderRadius: 10 }} />
                    <FormFeedback>
                      You will not be able to see this
                    </FormFeedback>
                  </FormGroup>
                  <Row
                    style={{
                      marginTop: "15%",
                      textAlign: "center",
                      display: "contents",
                    }}
                  >
                    <Col>
                      <Link to="floorPlanEditor">
                        <Button color="success" style={{ width: 100 }}>
                          Save
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="propertyDetails">
                        <Button color="danger" style={{ width: 100 }}>
                          Close
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default FloorPlans
