const cardDataOutdoor = [
  {
    id: 1,
    title: "Hoarding Near Parking Lot",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    className: "bg-warning text-white",
  },
  {
    id: 2,
    title: "Hoarding Near Mall Entrance",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    className: "bg-info text-white",
  },
]

export { cardDataOutdoor }
