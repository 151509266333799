import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Typography from "@mui/material/Typography"

let steps = ["Upload Plan", "Mark", "Insert Slots"]

export default function HorizontalLinearStepper(prop) {
  const [activeStep, setActiveStep] = React.useState(1)
  const [skipped, setSkipped] = React.useState(new Set())
  console.log("levels =>", prop.levels)
  if (prop.levels) {
    steps = prop.levels
  }

  const isStepOptional = step => {
    return step === 1
  }

  const isStepSkipped = step => {
    return skipped.has(step)
  }


  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {console.log("steps =>", steps)}
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Levels & Zones</Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
