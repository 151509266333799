import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import MultiLevelProgressBar from "../../propertyOwner/Common/Components/MultiLevelProgressBar"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardImg,
  Label,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//img
import imgIndoor from "../../assets/images/adAgencyWizardIndoor.png"
import imgOutdoor from "../../assets/images/adAgencyWizardOutdoor.png"
import imgDigital from "../../assets/images/adAgencyWizardDigital.png"
import imgCeiling from "../../assets/images/wizardCeiling.png"
import imgCeilingVertical from "../../assets/images/wizardCeilingVertical.png"
import imgWall from "../../assets/images/wizardWall.png"
import imgFloor from "../../assets/images/wizardFloor.png"

//components
import AdFormWizardDimensionsMain from "./AdFormWizardDimensionsMain"
import AdFormWizardDimensionsCustom from "./AdFormWizardDimensionsCustom"
import AdWizardSlider from "./AdWizardSlider"

const AdFormWizard = () => {
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(25)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [showMain, setShowMain] = useState(true)
  const [isDone, setIsDone] = useState(false)

    useEffect(()=> {
      if(activeTab == 8){
        setIsDone(true)
      }else{
        setIsDone(false)
      }
      
    },[activeTab])

    useEffect(()=> {
      if(activeTab < 7){
        setShowMain(false)
      }else{
        setShowMain(true)
      }
      
    },[activeTab])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 8) {
        setactiveTab(tab)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 8) {
        setoggleTabVertical(tab)
      }
    }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 8) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          setprogressValue(25)
        }
        if (tab === 2) {
          setprogressValue(50)
        }
        if (tab === 3) {
          setprogressValue(75)
        }
        if (tab === 4) {
          setprogressValue(100)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={styles.pageContent}>
        <Container fluid={true}>
          <MultiLevelProgressBar levels={["Type","Region","Date Range","Mall","Collateral","Content Ex","Dimensions","Price",]}/>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Booking Wizard</h4>
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="number">1.</span> Ad Type
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="number">2.</span> Region
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                          >
                            <span className="number">3.</span>
                            Date Range
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                          >
                            <span className="number">4.</span>
                            Mall
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                          >
                            <span className="number">5.</span>
                            Collateral Type
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 6 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 6 })}
                            onClick={() => {
                              setactiveTab(6)
                            }}
                          >
                            <span className="number">6.</span>
                            Content Type
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 7 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 7 })}
                            onClick={() => {
                              setactiveTab(7)
                            }}
                          >
                            <span className="number">7.</span>
                            Dimensions
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 8 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 8 })}
                            onClick={() => {
                              setactiveTab(8)
                            }}
                          >
                            <span className="number">8.</span>
                            Price Range
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Row>
                            <CardText>What are you looking for?</CardText>
                            <Col>
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                <Card>
                                  <CardImg
                                    src={imgIndoor}
                                    style={styles.wizardCard}
                                  />
                                  <CardTitle>Indoor</CardTitle>
                                </Card>
                              </Link>
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                <Card>
                                  <CardImg
                                    src={imgOutdoor}
                                    style={styles.wizardCard}
                                  />
                                  <CardTitle>Outdoor</CardTitle>
                                </Card>
                              </Link>
                            </Col>
                            <Col>
                              <Link
                                to="/agency/bookNow/gridViewDigital"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                <Card>
                                  <CardImg
                                    src={imgDigital}
                                    style={styles.wizardCard}
                                  />
                                  <CardTitle>Digital</CardTitle>
                                </Card>
                              </Link>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <CardTitle>
                            Select the region you are looking for
                          </CardTitle>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    <Card style={styles.regionCard}>
                                      <CardText>Abu Dhabi</CardText>
                                    </Card>
                                  </Link>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Dubai</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Sharja</CardText>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Ajman</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Al Ain</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Jabel Ali</CardText>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Hatta</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Al Karama</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Al Barsha</CardText>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <CardText>
                                10,464
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Row>
                            <Col>
                              <Label>From</Label>
                              <Input type="date" name="from" />
                              <Label>To</Label>
                              <Input type="date" name="to" />
                            </Col>
                            <Col>
                              <CardText>
                                10,464
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={4}>
                          <CardTitle>
                            Select the region you are looking for
                          </CardTitle>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    <Card style={styles.regionCard}>
                                      <CardText>Dalma Mall</CardText>
                                    </Card>
                                  </Link>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Dubai Mall</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Lulu Mall</CardText>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Marina Mall</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>City Mall</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>AK Mall</CardText>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <CardText>
                                5,464
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <CardTitle>Select the collateral type</CardTitle>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    <Card style={styles.regionCard}>
                                      <CardText>Ceiling</CardText>
                                    </Card>
                                  </Link>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Wall</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Floor</CardText>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <CardText>
                                1,250
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={6}>
                          <CardTitle>Select the Content type</CardTitle>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    <Card style={styles.regionCard}>
                                      <CardText>Food {"&"} Beverages</CardText>
                                    </Card>
                                  </Link>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Clothing</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Electronics</CardText>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Medical</CardText>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card style={styles.regionCard}>
                                    <CardText>Apparells</CardText>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <CardText>
                                1,250
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={7}>
                          {showMain ? (
                            <Row>
                              <CardTitle>
                                Select the dimensions (a x b x c x d) Mtr
                              </CardTitle>
                              <Row>
                                <Col>
                                  <Row>
                                    <Col>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          toggleTab(activeTab + 1)
                                        }}
                                      >
                                        <Card style={styles.regionCard}>
                                          <CardText>10 x 05 x 0</CardText>
                                        </Card>
                                      </Link>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>20 x 05 x 0</CardText>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>20 x 15 x 0</CardText>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>20 x 05 x 0</CardText>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>10 x 05 x 0</CardText>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>10 x 05 x 0</CardText>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>05 x 02 x 0</CardText>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>10 x 05 x 0</CardText>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setShowMain(false)
                                        }}
                                      >
                                        <Card style={styles.regionCard}>
                                          <CardText>Customize</CardText>
                                        </Card>
                                      </Link>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>Ceiling</CardText>
                                        <CardImg src={imgCeiling} />
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>Wall</CardText>
                                        <CardImg src={imgWall} />
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card style={styles.regionCard}>
                                        <CardText>Floor</CardText>
                                        <CardImg src={imgFloor} />
                                      </Card>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <CardText>
                                    10,464
                                    <br />
                                    Slots out of
                                    <br />
                                    70,382
                                    <br />
                                    Available
                                  </CardText>
                                </Col>
                              </Row>
                            </Row>
                          ) : (
                            <AdFormWizardDimensionsCustom />
                          )}
                        </TabPane>
                        <TabPane tabId={8}>
                          <Row>
                            <Col>
                              <AdWizardSlider/>
                            </Col>
                            <Col>
                              <CardText>
                                10,464
                                <br />
                                Slots out of
                                <br />
                                70,382
                                <br />
                                Available
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                    {isDone ? (
                      <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="/agency/bookNow/gridDetail"
                            className="btn btn-primary"
                          >
                            Done
                          </Link>
                        </li>
                      </ul>
                    </div>
                    ):(
                      <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={() => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                    )}
                    
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const styles = {
  pageContent: {
    zIndex: 99999,
  },
  regionCard: {
    backgroundColor: "skyblue",
    width: "100px",
    textAlign: "center",
  },
}
export default AdFormWizard
