import React from 'react'
import AppLanding from './App';

function Landing() {
  return (
    <>
<AppLanding/>
    </>
  );
}

export default Landing