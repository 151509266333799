import React from "react"

import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button,
  CardTitle,
  CardBody,
} from "reactstrap"

const RevenueReports = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h1">
                  <h4>Revenue Reports</h4>
                </CardTitle>
              </CardBody>
              <CardBody>
                <Row>
                  <Col xs={2}>
                    <Input type="date" placeholder="All" className="form-select"/>
                  </Col>
                  <Col xs={2}>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Level</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={2}>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Indoor/Outdoor</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={2}>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Agency Name</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={2}>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Property Name</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col xs={2}>
                    <Link
                      to="/"
                      className="btn btn-primary btn-rounded waves-effect waves-light"
                      style={{ float: "right" }}
                    >
                      <i className="ti-printer"></i> &nbsp;
                      <span>Print</span>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead align="center" className="table-light">
                      <tr>
                        <th>Booking ID</th>
                        <th>Agency Name</th>
                        <th>Location</th>
                        <th>Property Name</th>
                        <th>No Of Slots</th>
                        <th>Indoor/Outdoor</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr>
                        <td>Cam11</td>
                        <td>Cam Agency</td>
                        <td>Dubai</td>
                        <td>Dalma Mall</td>
                        <td>120</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Fam23</td>
                        <td>Fam Agency</td>
                        <td>Abu Dhabi</td>
                        <td>Dubai Mall</td>
                        <td>220</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Bbc12</td>
                        <td>BBC Agency</td>
                        <td>Dubai</td>
                        <td>Dalma Mall</td>
                        <td>60</td>
                        <td>Outdoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Fam23</td>
                        <td>Fam Agency</td>
                        <td>Sharja</td>
                        <td>Dubai Mall</td>
                        <td>180</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Bbc12</td>
                        <td>BBC Agency</td>
                        <td>Dubai</td>
                        <td>Dubai Mall</td>
                        <td>120</td>
                        <td>Outdoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <h4>Revenue Reports</h4>
                </CardTitle>
              </CardBody>
              <CardBody>
                <Row className="mb-3">
                  <Col>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>From</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>To</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Indoor/Outdoor</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Agency Name</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col>
                    <Input type="select" placeholder="All" className="form-select">
                      <option>Property Name</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </Col>
                  <Col className="col=2" style={styles.tableBtnCol}>
                    <Button color="primary">Print</Button>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead align="center" className="table-light">
                      <tr>
                        <th>Booking ID</th>
                        <th>Agency Name</th>
                        <th>Location</th>
                        <th>Property Name</th>
                        <th>No Of Slots</th>
                        <th>Indoor/Outdoor</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cam11</td>
                        <td>Cam Agency</td>
                        <td>Dubai</td>
                        <td>Dalma Mall</td>
                        <td>120</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Fam23</td>
                        <td>Fam Agency</td>
                        <td>Abu Dhabi</td>
                        <td>Dubai Mall</td>
                        <td>220</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Bbc12</td>
                        <td>BBC Agency</td>
                        <td>Dubai</td>
                        <td>Dalma Mall</td>
                        <td>60</td>
                        <td>Outdoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Fam23</td>
                        <td>Fam Agency</td>
                        <td>Sharja</td>
                        <td>Dubai Mall</td>
                        <td>180</td>
                        <td>Indoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                      <tr>
                        <td>Bbc12</td>
                        <td>BBC Agency</td>
                        <td>Dubai</td>
                        <td>Dubai Mall</td>
                        <td>120</td>
                        <td>Outdoor</td>
                        <td>3,45,643 AED</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <Card>
            </Col>
        </Row> */}
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: "100%",
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
  tableStyle: {
    backgroundColor: "lightblue",
    thead: {
      backgroundColor: "lightgray",
    },
  },
  tableBtnCol: {
    textAlign: "right",
  },
}

export default RevenueReports
