import React from "react"
import { Container, Row } from "reactstrap"
import PropertyDetailCard from "./Cards/PropertyDetailCard"

const PropertyDetails = props => {
  return (
    <Container>
      <Row className="col-6">
        <PropertyDetailCard />
      </Row>
    </Container>
  )
}

export default PropertyDetailCard
