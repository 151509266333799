import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Container,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"


const EventsZonesDashboard = props => {
  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    //setIsShown(true)
  }
  return (
    <Container className="page-content" fluid>
      <Row>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              ZONES
              <Link
                to={"/zones/addzone"}
                className="btn btn-danger waves-effect waves-light"
                style={{ float: "right" }}
              >
                <i className="ti-plus"></i> &nbsp;
                <span>Add Zone</span>
              </Link>
            </CardTitle>
          </CardBody>
          <CardBody>
            <Row className="mt-3">
              <Col xs={3}>
                <Input
                  type="select"
                  placeholder="Cam Agency"
                  className="form-select"
                >
                  <option selected disabled>
                    Buildings
                  </option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={3}>
                <Input type="select" placeholder="All" className="form-select">
                  <option selected disabled>
                    Level
                  </option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={3}>
                <Input type="select" placeholder="" className="form-select">
                  <option selected disabled>
                    Zones
                  </option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </Col>
              <Col xs={3}>
                <Input type="select" className="form-select mb-3">
                  <option selected disabled>
                    Period
                  </option>
                  <option>1 month</option>
                  <option>3 month</option>
                  <option>6 month</option>
                  <option>1 year</option>
                </Input>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <Table className="table table-striped mb-0">
              <thead align="center" className="table-light">
                <tr>
                  <th>
                    <span className="fa fa-sort"></span>#
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Zones Name
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Footfall Per Day
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Slots No(s)
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Smart Slots No(s)
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Booking (Current Month)
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Under Maintenance
                  </th>
                  <th>
                    <span className="fa fa-sort"></span>Non Leasable
                  </th>
                </tr>
              </thead>
              <tbody align="center">
                <tr>
                  <th scope="row">
                    <Link to="/zones/addzone" style={styles.tagBtnStyle}>
                      Z 1001
                    </Link>
                  </th>
                  <td>Central Lobby</td>
                  <td>1200</td>
                  <td>25</td>
                  <td>15</td>
                  <td>81.5%</td>
                  <td>
                    <Input type="checkbox" onChange={handleClick}></Input>
                  </td>
                  <td>12</td>
                </tr>
                <tr>
                  <th scope="row">
                    <Link to="/zones/addZone" style={styles.tagBtnStyle}>
                      Z 1002
                    </Link>
                  </th>
                  <td>Central Lobby</td>
                  <td>1200</td>
                  <td>25</td>
                  <td>15</td>
                  <td>81.5%</td>
                  <td>
                    <Input type="checkbox" onChange={handleClick}></Input>
                  </td>
                  <td>12</td>
                </tr>
                <tr>
                  <th scope="row">
                    <Link to="/zones/addZone" style={styles.tagBtnStyle}>
                      Z 1003
                    </Link>
                  </th>
                  <td>Central Lobby</td>
                  <td>1200</td>
                  <td>25</td>
                  <td>15</td>
                  <td>81.5%</td>
                  <td>
                    <Input type="checkbox" onChange={handleClick}></Input>
                  </td>
                  <td>12</td>
                </tr>
                <tr>
                  <th scope="row">
                    <Link to="/zones/addZone" style={styles.tagBtnStyle}>
                      Z 1004
                    </Link>
                  </th>
                  <td>Central Lobby</td>
                  <td>1200</td>
                  <td>25</td>
                  <td>15</td>
                  <td>81.5%</td>
                  <td>
                    <Input type="checkbox" onChange={handleClick}></Input>
                  </td>
                  <td>12</td>
                </tr>
                <tr>
                  <th scope="row">
                    <Link to="/zones/addZone" style={styles.tagBtnStyle}>
                      Z 1005
                    </Link>
                  </th>
                  <td>Central Lobby</td>
                  <td>1200</td>
                  <td>25</td>
                  <td>15</td>
                  <td>81.5%</td>
                  <td>
                    <Input type="checkbox" onChange={handleClick}></Input>
                  </td>
                  <td>12</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <div>
          {/* 👇️ show elements on click */}
          {isShown && (
            <Card
              style={{
                width: 250,
                backgroundColor: "lightgrey",
                padding: 20,
                marginTop: "-18%",
                marginLeft: "40%",
              }}
            >
              <Row className="mb-3">
                <Col>
                  <Label>From</Label>
                  <Input type="date" placeholder="" className="form-select">
                    <option>From</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </Col>
                <Col>
                  <Label className="pt-3">To</Label>
                  <Input type="date" placeholder="" className="form-select">
                    <option>To</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Button onClick={handleClick}>Done</Button>
              </Row>
            </Card>
          )}
        </div>
      </Row>
    </Container>
  )
}

const styles = {
  Container: {
    Row: {
      AddZoneBtnRow: {
        flexDirection: "row-reverse",
      },
    },
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#626ED4",
    borderRadius: 7,
    textAlign: "center",
    padding: 2,
  },
}

export default EventsZonesDashboard
