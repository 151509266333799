import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Progress,
  CardText,
  Container,
  Input,
  Label,
  Table,
  Button
} from "reactstrap"

import ButtonToggle from "reactstrap"

//components
import UiModal from "./DeleteModal"

const AdUsersDashboard = props => {
  return (
    <React.Fragment>
      <Container style={styles.Container} className="page-content">
        <Row>
          <Card>
            <CardText><h5>Users</h5></CardText>
          <Row style={styles.Container.Row.AddZoneBtnRow}>
            <Link to={'/agency/users/addUser'} style={{textAlign: "end"}}>
            <Button color="warning" className="col-1"><i className="fa fa-plus"> Add User</i></Button>
            </Link>
        </Row>
            
            <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th><i className="fa fa-sort"></i>ID</th>
                    <th><i className="fa fa-sort"></i>User Name</th>
                    <th><i className="fa fa-sort"></i>Email</th>
                    <th><i className="fa fa-sort"></i>Phone No.</th>
                    <th><i className="fa fa-sort"></i>City</th>
                    <th><i className="fa fa-sort"></i>Designation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM31
                      </Link>
                    </th>
                    <td><i className="fa fa-user-circle"></i> Ebin Mathew</td>
                    <td>ebinmathew@gmail.com</td>
                    <td>+971 557866563</td>
                    <td>Abu Dhabi</td>
                    <td>Manager</td>
                    <td><Link to="/agency/users/viewUser"><i className="fa fa-eye"></i></Link></td>
                    <td><Link to="/agency/users/editUser"><i className="fa fa-edit"></i></Link></td>
                    <td><UiModal/></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM32
                      </Link>
                    </th>
                    <td><i className="fa fa-user-circle"></i> Ebin Mathew</td>
                    <td>ebinmathew@gmail.com</td>
                    <td>+971 557866563</td>
                    <td>Abu Dhabi</td>
                    <td>Manager</td>
                    <td><Link to="/agency/users/viewUser"><i className="fa fa-eye"></i></Link></td>
                    <td><Link to="/agency/users/editUser"><i className="fa fa-edit"></i></Link></td>
                    <td><UiModal/></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link to="bookingDetails" style={styles.tagBtnStyle}>
                        LM33
                      </Link>
                    </th>
                    <td><i className="fa fa-user-circle"></i> Ebin Mathew</td>
                    <td>ebinmathew@gmail.com</td>
                    <td>+971 557866563</td>
                    <td>Abu Dhabi</td>
                    <td>Manager</td>
                    <td><Link to="/agency/users/viewUser"><i className="fa fa-eye"></i></Link></td>
                    <td><Link to="/agency/users/editUser"><i className="fa fa-edit"></i></Link></td>
                    <td><UiModal/></td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const styles = {
  Container: {
   paddingLeft: 30,
   paddingRight: 30,
   marginTop: 30,
   maxWidth: "100%",
    Row: {
        AddZoneBtnRow: {
            flexDirection: "row-reverse"
        }
    }
  },
  tagBtnStyle: {
    color: "white",
    backgroundColor: "#0066ff",
    borderRadius: 2,
    textAlign: "center",
    padding: 2,
  },
}

export default AdUsersDashboard
