import React from "react"
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

//images
import img1 from "../../assets/images/contentTypeExcluded-PlaceholderImg2.png"

const AdminCollateralTypeEdit = props => {
  return (
    <Container className="page-content">
      <Row>
        <Card className="p-3">
          <Form>
            <CardTitle>Collateral Type</CardTitle>
            <Row className="col-8">
              <Label>Mall ID</Label>
              <Input
                type="text"
                placeholder="Lulu Mall"
                name="notificationName"
                required
              />
              <Label>Type</Label>
              <Input type="text" placeholder="Eg. Alcohol, Cigarette" />
              <CardImg src={img1} style={Styles.cardImgSize} />
              <Label>Created On</Label>
              <Input type="text" value="02 May 2021" />
              <Label>Created By</Label>
              <Input type="text" value="David Moes" />
            </Row>
            <Row>
              <Col className="col-8"></Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="danger">
                    Cancel
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link to="./">
                  <Button className="m-3" color="primary">
                    Save
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </Container>
  )
}

const Styles = {
  cardImgSize: {
    width: 200,
    height: 100,
  },
}
export default AdminCollateralTypeEdit
